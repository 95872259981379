import { CommonFileType, CommonType, TableSettingsType } from 'types'
import { PriorityWO, TypeWO, WorkOrderStatus } from 'components/NewWorkOrder/config'
import { InventoryItemsListTab } from 'pages/InventoryItemsPage/config'
import { LocationTransferRequest } from 'components/ActionsWithItemDialogs'

export type InventoryItemsStateType = {
    isWaitingForResponse: boolean

    inventoryItems: InventoryDetailsType[]
    currentInventoryItem: CurrentInventoryDetailType
    inventoryItemsListTab: InventoryItemsListTab
    inventoryItemsPaginationPage: number
    inventoryItemsCount: number
    inventoryItemsSearchValue: string
    inventoryItemProfileTab: InventoryItemProfileTab
    inventoryItemsSettings: TableSettingsType<InventoryItemsFiltersType>

    currentVendor: CurrentVendorType
    vendors: VendorType[]
    vendorsPaginationPage: number
    vendorsCount: number
    vendorsSearchValue: string
    vendorsSettings: TableSettingsType<VendorsFiltersType>

    currentPurchaseRequest: CurrentPurchaseRequestType
    purchaseRequests: PurchaseRequestType[]
    purchaseRequestsPaginationPage: number
    purchaseRequestsCount: number
    purchaseRequestsSettings: TableSettingsType<PurchaseRequestsFiltersType>
}

export type InventoryItemsFiltersType = Omit<RequestDetailsInventoryItemsType, 'limit' | 'offset'>

export type VendorsFiltersType = Omit<RequestVendorsType, 'limit' | 'offset' | 'searchString'>

export type PurchaseRequestsFiltersType = Omit<RequestPurchaseRequestsType, 'limit' | 'offset'>

export enum PurchaseRequestStatus {
    PENDING = 'Pending',
    ORDER_PLACED = 'Order Placed',
    FULFILLED = 'Fulfilled',
}

export const INVENTORY_PER_PAGE = 50
export const VENDOR_PER_PAGE = 50
export const PURCHASE_REQUEST_PER_PAGE = 50

export enum InventoryItemProfileTab {
    CURRENT_STOCK = 'CURRENT_STOCK',
    VENDORS = 'VENDORS',
    ORDER_HISTORY = 'ORDER_HISTORY',
}

export enum InventoryItemStatus {
    AVAILABLE = 'Available',
    UNAVAILABLE = 'UNAVAILABLE',
    ALLOCATED_FOR_WO = 'Allocated for WO',
    ON_HOLD = 'On Hold',
    TRANSFER_REQUESTED = 'Transfer Requested',
}

export type RequestInventoryItemsType = {
    typeIdes?: string[]
    offset?: number
    limit?: number
    status?: InventoryItemStatus
    allocatedToWorkOrderId?: string
}

export type RequestDetailsInventoryItemsType = {
    offset?: number
    limit?: number
    searchString?: string
    hasOpenTransferRequests?: boolean
    hasQuantity?: boolean
    hasLowStockAlert?: boolean
    isAllocatedForWO?: boolean
    categoryIdes?: string[]
    typeIdes?: string[]
    avgStockAgeFrom?: number
    avgStockAgeTo?: number
}

export type RequestVendorsType = {
    offset?: number
    limit?: number
    categoryIdes?: string[]
    typeIdes?: string[]
    lastOrderDateFrom?: Date | null
    lastOrderDateTo?: Date | null
    searchString?: string
}

export type RequestPurchaseRequestsType = {
    offset?: number
    limit?: number
    categoryIdes?: string[]
    typeIdes?: string[]
    orderDateFrom?: Date | null
    orderDateTo?: Date | null
    isForWOs?: boolean
    statuses?: PurchaseRequestStatus[]
}

export type InventoryItemType = {
    id: string
    status: InventoryItemStatus
    price: number
    stockAge: number
    manufacturer: string
    manufacturerPartNumber: string
    equipmentId: string
    shelf: string
    bin: string
    createdAt: Date
    building: CommonType | null
    room: CommonType | null
    type: CommonType & {
        categories: CommonType & {
            color: string
            file: { url: string } | null
        }
    }
    workOrder: {
        id: string
        number: string
        title: string
    } | null
}

export type WorkOrderInInventoryType = {
    id: string
    title: string
    description: string
    type: TypeWO
    subType: string
    priority: PriorityWO
    status: WorkOrderStatus
}

export type TransferRequestInInventoryType = {
    variant: LocationTransferRequest | null
    companyName: string | null
    address: string | null
    city: string | null
    state: string | null
    zip: string | null
    note: string | null
    building: CommonType | null
    workOrder: { id: string; title: string } | null
    supervisors: {
        id: string
        firstName: string
        lastName: string
        TransferRequestUser: {
            id: string
            transferRequest: string
            userId: string
        }
    }[]
}

export type CurrentInventoryItemType = InventoryItemType & {
    note: string | null
    model: string
    onHoldReason: string | null
    workOrder: WorkOrderInInventoryType | null
    transferRequest: TransferRequestInInventoryType
}

export type InventoryDetailsType = CommonType & {
    categories: CommonType & {
        color: string
        file: { url: string } | null
    }
    details: {
        avgCost: number | null
        avgStockAge: number
        itemsCount: number
    }
}

export type CurrentInventoryDetailType = {
    id: string
    avgStockAge: number
    isEnabledLowStockAlert: boolean
    isEnabledReOrderAlert: boolean
    currentPrice: number
    billableCost: number
    profitMargin: number
    minQuantity: number
    maxQuantity: number
    totalCost: number
    type: CommonType & {
        categories: CommonType & {
            file: { url: string } | null
            color: string
        }
    }
}

export type VendorType = CommonType & {
    address: string
    city: string
    state: string
    zipCode: string
    website: string
    lastOrderDate: Date | null
    phone: string
    types: Array<
        CommonType & {
            VendorAssetType: {
                id: string
                vendorId: string
                assetTypeId: string
            }
        }
    >
    categories: Array<
        CommonType & {
            color: string
            file: { url: string } | null
            VendorAssetCategory: {
                id: string
                vendorId: string
                assetCategoryId: string
            }
        }
    >
}

export type CurrentVendorType = VendorType & {
    note: string
    contacts: {
        type: string
        email: string
        phone: string
        title: string
        lastName: string
        firstName: string
        contactName: string
    }[]
}

export type PurchaseRequestType = {
    id: string
    createdAt: Date
    status: PurchaseRequestStatus
    requestedBy: {
        id: string
        firstName: string
        lastName: string
    }
    workOrder: {
        id: string
        number: string
        title: string
    } | null
    type: CommonType & {
        categories: CommonType & {
            color: string
            file: { url: string } | null
        }
    }
    orderDetails: {
        orderDate: Date | null
        quantityOrdered: number
    }
}

export type CurrentPurchaseRequestType = PurchaseRequestType & {
    quantityRequested: number
    neededByDate: Date | null
    additionalInformation: {
        model: string
        manufacturer: string
        manufacturerPartNumber: string
    }
    note: string
    receivedDate: Date | null
    shelf: string
    bin: string
    hasOrderArrived: boolean
    hasOrderDetails: boolean
    isNeededForWO: boolean
    storageLocationInformation: null
    building: CommonType | null
    room: CommonType | null
    sendTo: {
        id: string
        firstName: string
        lastName: string
    }
    orderDetails: {
        files: CommonFileType[]
        note: string | null
        orderAmount: number | null
        orderDate: Date | null
        orderNumber: string | null
        quantityOrdered: number
        shippingCost: number | null
        trackingNumber: string | null
        vendor: CommonType | null
    }
}
