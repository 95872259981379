import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DirectionEnum, ListViewMode, TableSettingsType } from 'types'
import { Tenant, User, UserFiltersType, UserProfilePageTab } from './types'
import extraReducers from './extraReducers'
import {
    adminsVisibleColumns,
    requestersVisibleColumns,
    supervisorsVisibleColumns,
    techniciansVisibleColumns,
    tenantsColumns,
    tenantsVisibleColumns,
} from './const'
import { dropInLS, getSettingsFromLS, LsKeys } from '../../lib/workWithLS'
import { TableColumnType } from '../../components/Table'
import { WorkOrdersLayout } from '../../components/WorkOrdersListLayout/WorkOrdersListLayout'

export interface UserState {
    users: User[]
    selectedUserProfile: User | null
    selectedUserCard: User | null
    isUsersLoading: boolean
    passwordAccepted: boolean

    loading: boolean
    userProfilePageTab: UserProfilePageTab

    usersCount: number
    usersPage: number
    usersSearch: string
    usersListMode: ListViewMode

    tenants: Array<Tenant>
    selectedTenantProfile: Tenant | null

    tenantsCount: number
    tenantsPage: number
    tenantsSearch: string

    workOrdersLayout: WorkOrdersLayout | null
    teamsLayout: { userId: string } | null

    //settings
    tenantsSettings: TableSettingsType<any>

    userRequesterSettings: TableSettingsType<UserFiltersType>
    userTechniciansSettings: TableSettingsType<UserFiltersType>
    userSupervisorsSettings: TableSettingsType<UserFiltersType>
    userAdminsSettings: TableSettingsType<UserFiltersType>
    userSuperAdminsSettings: TableSettingsType<UserFiltersType>
}

const initialState: UserState = {
    users: [],
    selectedUserProfile: null,
    selectedUserCard: null,
    isUsersLoading: false,
    passwordAccepted: false,

    loading: false,
    userProfilePageTab: UserProfilePageTab.PROFILE,

    usersCount: 0,
    usersPage: 1,
    usersSearch: '',
    usersListMode: ListViewMode.TABLE,

    tenants: [],
    selectedTenantProfile: null,

    tenantsCount: 0,
    tenantsPage: 1,
    tenantsSearch: '',

    tenantsSettings: {
        filters: getSettingsFromLS('filters', LsKeys.TENANTS_SETTINGS, null),
        columns: getSettingsFromLS('columns', LsKeys.TENANTS_SETTINGS, tenantsVisibleColumns),
        currentViewId: getSettingsFromLS('currentViewId', LsKeys.TENANTS_SETTINGS, ''),
        currentViewTitle: getSettingsFromLS('currentViewTitle', LsKeys.TENANTS_SETTINGS, ''),
        isSystemCurrentView: getSettingsFromLS('isSystemCurrentView', LsKeys.TENANTS_SETTINGS, false),
        listMode: getSettingsFromLS('listMode', LsKeys.TENANTS_SETTINGS, ListViewMode.TABLE),
        sortDirection: DirectionEnum.ASC,
        sortField: 'name',
    },

    workOrdersLayout: null,
    teamsLayout: null,

    userRequesterSettings: {
        filters: getSettingsFromLS('filters', LsKeys.USER_REQUESTER_SETTINGS, null),
        columns: getSettingsFromLS('columns', LsKeys.USER_REQUESTER_SETTINGS, requestersVisibleColumns),
        currentViewId: getSettingsFromLS('currentViewId', LsKeys.USER_REQUESTER_SETTINGS, ''),
        currentViewTitle: getSettingsFromLS('currentViewTitle', LsKeys.USER_REQUESTER_SETTINGS, ''),
        isSystemCurrentView: getSettingsFromLS('isSystemCurrentView', LsKeys.USER_REQUESTER_SETTINGS, false),
        listMode: getSettingsFromLS('listMode', LsKeys.USER_REQUESTER_SETTINGS, ListViewMode.TABLE),
        sortDirection: DirectionEnum.ASC,
        sortField: 'name',
    },

    userTechniciansSettings: {
        filters: getSettingsFromLS('filters', LsKeys.USER_TECHNICIANS_SETTINGS, null),
        columns: getSettingsFromLS('columns', LsKeys.USER_TECHNICIANS_SETTINGS, techniciansVisibleColumns),
        currentViewId: getSettingsFromLS('currentViewId', LsKeys.USER_TECHNICIANS_SETTINGS, ''),
        currentViewTitle: getSettingsFromLS('currentViewTitle', LsKeys.USER_TECHNICIANS_SETTINGS, ''),
        isSystemCurrentView: getSettingsFromLS('isSystemCurrentView', LsKeys.USER_TECHNICIANS_SETTINGS, false),
        listMode: getSettingsFromLS('listMode', LsKeys.USER_TECHNICIANS_SETTINGS, ListViewMode.TABLE),
        sortDirection: DirectionEnum.ASC,
        sortField: 'name',
    },

    userSupervisorsSettings: {
        filters: getSettingsFromLS('filters', LsKeys.USER_SUPERVISORS_SETTINGS, null),
        columns: getSettingsFromLS('columns', LsKeys.USER_SUPERVISORS_SETTINGS, supervisorsVisibleColumns),
        currentViewId: getSettingsFromLS('currentViewId', LsKeys.USER_SUPERVISORS_SETTINGS, ''),
        currentViewTitle: getSettingsFromLS('currentViewTitle', LsKeys.USER_SUPERVISORS_SETTINGS, ''),
        isSystemCurrentView: getSettingsFromLS('isSystemCurrentView', LsKeys.USER_SUPERVISORS_SETTINGS, false),
        listMode: getSettingsFromLS('listMode', LsKeys.USER_SUPERVISORS_SETTINGS, ListViewMode.TABLE),
        sortDirection: DirectionEnum.ASC,
        sortField: 'name',
    },
    userAdminsSettings: {
        filters: getSettingsFromLS('filters', LsKeys.USER_ADMINS_SETTINGS, null),
        columns: getSettingsFromLS('columns', LsKeys.USER_ADMINS_SETTINGS, adminsVisibleColumns),
        currentViewId: getSettingsFromLS('currentViewId', LsKeys.USER_ADMINS_SETTINGS, ''),
        currentViewTitle: getSettingsFromLS('currentViewTitle', LsKeys.USER_ADMINS_SETTINGS, ''),
        isSystemCurrentView: getSettingsFromLS('isSystemCurrentView', LsKeys.USER_ADMINS_SETTINGS, false),
        listMode: getSettingsFromLS('listMode', LsKeys.USER_ADMINS_SETTINGS, ListViewMode.TABLE),
        sortDirection: DirectionEnum.ASC,
        sortField: 'name',
    },
    userSuperAdminsSettings: {
        filters: getSettingsFromLS('filters', LsKeys.USER_SUPER_ADMINS_SETTINGS, null),
        columns: getSettingsFromLS('columns', LsKeys.USER_SUPER_ADMINS_SETTINGS, adminsVisibleColumns),
        currentViewId: getSettingsFromLS('currentViewId', LsKeys.USER_SUPER_ADMINS_SETTINGS, ''),
        currentViewTitle: getSettingsFromLS('currentViewTitle', LsKeys.USER_SUPER_ADMINS_SETTINGS, ''),
        isSystemCurrentView: getSettingsFromLS('isSystemCurrentView', LsKeys.USER_SUPER_ADMINS_SETTINGS, false),
        listMode: getSettingsFromLS('listMode', LsKeys.USER_SUPER_ADMINS_SETTINGS, ListViewMode.TABLE),
        sortDirection: DirectionEnum.ASC,
        sortField: 'name',
    },
}

export const userState = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setWorkOrdersLayout: (state, action: PayloadAction<WorkOrdersLayout | null>) => {
            state.workOrdersLayout = action.payload
        },

        setTeamsLayout: (state, action: PayloadAction<{ userId: string } | null>) => {
            state.teamsLayout = action.payload
        },
        setSelectedUserProfile: (state, action: PayloadAction<User | null>) => {
            state.selectedUserProfile = action.payload
        },
        setSelectedUserCard: (state, action: PayloadAction<User | null>) => {
            state.selectedUserCard = action.payload
        },
        setUsersList: (state, action: PayloadAction<Array<User>>) => {
            state.users = action.payload
        },
        setUserProfilePageTab: (state, action: PayloadAction<UserProfilePageTab | string>) => {
            state.userProfilePageTab = action.payload as UserProfilePageTab
        },
        setUsersPage: (state, action: PayloadAction<number>) => {
            state.usersPage = action.payload
        },
        setUsersSearch: (state, action: PayloadAction<string>) => {
            state.usersSearch = action.payload
        },
        setUsersListMode: (state, action: PayloadAction<ListViewMode>) => {
            state.usersListMode = action.payload
        },

        setTenantsPage: (state, action: PayloadAction<number>) => {
            state.tenantsPage = action.payload
        },
        setTenantsSearch: (state, action: PayloadAction<string>) => {
            state.tenantsSearch = action.payload
        },

        //listMode
        setTenantsListMode: (state, action: PayloadAction<ListViewMode>) => {
            dropInLS(LsKeys.TENANTS_SETTINGS, {
                listMode: action.payload,
            })

            state.tenantsSettings.listMode = action.payload
        },

        //columns
        setRequestorsColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.USER_REQUESTER_SETTINGS, {
                columns: action.payload,
            })

            state.userRequesterSettings.columns = action.payload
        },
        setTechniciansColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.USER_TECHNICIANS_SETTINGS, {
                columns: action.payload,
            })

            state.userTechniciansSettings.columns = action.payload
        },
        setSupervisorsColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.USER_SUPERVISORS_SETTINGS, {
                columns: action.payload,
            })

            state.userSupervisorsSettings.columns = action.payload
        },
        setAdminsColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.USER_ADMINS_SETTINGS, {
                columns: action.payload,
            })

            state.userAdminsSettings.columns = action.payload
        },
        setSuperAdminsColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.USER_SUPER_ADMINS_SETTINGS, {
                columns: action.payload,
            })

            state.userSuperAdminsSettings.columns = action.payload
        },

        setTenantsColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.TENANTS_SETTINGS, {
                columns: action.payload,
            })

            state.tenantsSettings.columns = action.payload
        },

        //filters
        setRequestorsFiltes: (state, action: PayloadAction<UserFiltersType | null>) => {
            dropInLS(LsKeys.USER_REQUESTER_SETTINGS, {
                filters: action.payload,
            })

            state.userRequesterSettings.filters = action.payload
        },
        setTechniciansFilters: (state, action: PayloadAction<UserFiltersType | null>) => {
            dropInLS(LsKeys.USER_TECHNICIANS_SETTINGS, {
                filters: action.payload,
            })

            state.userTechniciansSettings.filters = action.payload
        },
        setSupervisorsFilters: (state, action: PayloadAction<UserFiltersType | null>) => {
            dropInLS(LsKeys.USER_SUPERVISORS_SETTINGS, {
                filters: action.payload,
            })

            state.userSupervisorsSettings.filters = action.payload
        },
        setAdminsFilters: (state, action: PayloadAction<UserFiltersType | null>) => {
            dropInLS(LsKeys.USER_ADMINS_SETTINGS, {
                filters: action.payload,
            })

            state.userAdminsSettings.filters = action.payload
        },
        setSuperAdminsFilters: (state, action: PayloadAction<UserFiltersType | null>) => {
            dropInLS(LsKeys.USER_SUPER_ADMINS_SETTINGS, {
                filters: action.payload,
            })

            state.userSuperAdminsSettings.filters = action.payload
        },

        //current save view
        setCurrentRequestorView: (
            state,
            action: PayloadAction<{ id: string; title: string; isSystemView: boolean }>,
        ) => {
            dropInLS(LsKeys.USER_REQUESTER_SETTINGS, {
                currentViewId: action.payload.id,
                currentViewTitle: action.payload.title,
                isSystemCurrentView: action.payload.isSystemView,
            })
            state.userRequesterSettings.currentViewId = action.payload.id
            state.userRequesterSettings.currentViewTitle = action.payload.title
            state.userRequesterSettings.isSystemCurrentView = action.payload.isSystemView
        },

        setCurrentTechniciansView: (
            state,
            action: PayloadAction<{ id: string; title: string; isSystemView: boolean }>,
        ) => {
            dropInLS(LsKeys.USER_TECHNICIANS_SETTINGS, {
                currentViewId: action.payload.id,
                currentViewTitle: action.payload.title,
                isSystemCurrentView: action.payload.isSystemView,
            })
            state.userTechniciansSettings.currentViewId = action.payload.id
            state.userTechniciansSettings.currentViewTitle = action.payload.title
            state.userTechniciansSettings.isSystemCurrentView = action.payload.isSystemView
        },

        setCurrentSupervisorsView: (
            state,
            action: PayloadAction<{ id: string; title: string; isSystemView: boolean }>,
        ) => {
            dropInLS(LsKeys.USER_SUPERVISORS_SETTINGS, {
                currentViewId: action.payload.id,
                currentViewTitle: action.payload.title,
                isSystemCurrentView: action.payload.isSystemView,
            })
            state.userSupervisorsSettings.currentViewId = action.payload.id
            state.userSupervisorsSettings.currentViewTitle = action.payload.title
            state.userSupervisorsSettings.isSystemCurrentView = action.payload.isSystemView
        },

        setCurrentAdminsView: (state, action: PayloadAction<{ id: string; title: string; isSystemView: boolean }>) => {
            dropInLS(LsKeys.USER_ADMINS_SETTINGS, {
                currentViewId: action.payload.id,
                currentViewTitle: action.payload.title,
                isSystemCurrentView: action.payload.isSystemView,
            })
            state.userAdminsSettings.currentViewId = action.payload.id
            state.userAdminsSettings.currentViewTitle = action.payload.title
            state.userAdminsSettings.isSystemCurrentView = action.payload.isSystemView
        },
        setCurrentSuperAdminsView: (
            state,
            action: PayloadAction<{ id: string; title: string; isSystemView: boolean }>,
        ) => {
            dropInLS(LsKeys.USER_SUPER_ADMINS_SETTINGS, {
                currentViewId: action.payload.id,
                currentViewTitle: action.payload.title,
                isSystemCurrentView: action.payload.isSystemView,
            })
            state.userSuperAdminsSettings.currentViewId = action.payload.id
            state.userSuperAdminsSettings.currentViewTitle = action.payload.title
            state.userSuperAdminsSettings.isSystemCurrentView = action.payload.isSystemView
        },
    },
    extraReducers,
})

export const userAction = userState.actions
export const UserReducer = userState.reducer
