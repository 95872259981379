import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import {
    addAssetAvatar,
    addFilesToAsset,
    clearPanel,
    createAffectedArea,
    createAsset,
    createAssetFeed,
    deleteAffectedArea,
    deleteAsset,
    deleteAssetFeed,
    deleteAssetFile,
    editAffectedArea,
    editAsset,
    editPanel,
    getAffectedAreaAssetById,
    getAllAssetsList,
    getAssetByIdCommon,
    getAssetFeeds,
    getAssetFilesList,
    getAssetPagesByAssetId,
    getLocationAssetById,
    getPropsAssetById,
    replaceAsset,
} from 'api/assets'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import {
    AffectedAreaInAssetType,
    AssetPageType,
    AssetsState,
    CategoryThreeType,
    CurrentAssetType,
    FedType,
    FeedFromType,
    FeedToType,
    LocationAssetType,
    PropsAssetType,
    TypeInCategoryType,
} from 'slices/asset/types'
import { getLeftAssetPointsByPageIdForAsset, getRightAssetPointsByPageIdForAsset } from 'api/points'
import { AssetPoint } from 'slices/floorPlanViever'
import { transformLeftPointToNormal, transformRightPointToNormal } from 'lib/common'
import {
    createAssetCategory,
    createAssetProperty,
    createAssetType,
    editAssetCategory,
    getAssetCategoriesList,
    getAssetTypesList,
} from 'api/assetManagement'

export const extraReducers = (builder: ActionReducerMapBuilder<AssetsState>) => {
    builder.addCase(getAllAssetsList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getAllAssetsList.fulfilled, (state, action) => {
        state.isWaitingForResponse = false
        state.assets = action.payload.assets
        state.assetsCount = action.payload.count
    })
    builder.addCase(getAllAssetsList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getAssetByIdCommon.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getAssetByIdCommon.fulfilled, (state, action: PayloadAction<CurrentAssetType>) => {
        state.isWaitingForResponse = false
        state.currentAsset = action.payload
    })
    builder.addCase(getAssetByIdCommon.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getPropsAssetById.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getPropsAssetById.fulfilled, (state, action: PayloadAction<PropsAssetType>) => {
        state.isWaitingForResponse = false
        state.propsCurrentAsset = action.payload.assetPropsAnswers
    })
    builder.addCase(getPropsAssetById.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(deleteAsset.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteAsset.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Asset has been deleted', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(deleteAsset.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(createAsset.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createAsset.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Asset has been created', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(createAsset.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(editAsset.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(editAsset.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Asset has been edited', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(editAsset.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(addAssetAvatar.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(addAssetAvatar.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(addAssetAvatar.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    //location
    builder.addCase(getLocationAssetById.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getLocationAssetById.fulfilled, (state, action: PayloadAction<LocationAssetType>) => {
        state.isWaitingForResponse = false
        state.locationCurrentAsset.building = action.payload.building
        state.locationCurrentAsset.region = action.payload.region
        state.locationCurrentAsset.floor = action.payload.floor
        state.locationCurrentAsset.room = action.payload.room
    })
    builder.addCase(getLocationAssetById.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    // affected area
    builder.addCase(createAffectedArea.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createAffectedArea.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(createAffectedArea.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(deleteAffectedArea.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteAffectedArea.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Affected area has been deleted', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(deleteAffectedArea.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(editAffectedArea.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(editAffectedArea.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Affected area has been edited', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(editAffectedArea.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getAffectedAreaAssetById.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getAffectedAreaAssetById.fulfilled,
        (state, action: PayloadAction<{ count: number; affectedAreas: AffectedAreaInAssetType[] }>) => {
            state.isWaitingForResponse = false
            state.affectedAreaCurrentAsset = action.payload.affectedAreas
        },
    )
    builder.addCase(getAffectedAreaAssetById.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    // files
    builder.addCase(addFilesToAsset.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(addFilesToAsset.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(addFilesToAsset.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getAssetFilesList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getAssetFilesList.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(getAssetFilesList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(deleteAssetFile.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteAssetFile.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('File has been deleted', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(deleteAssetFile.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    // feeds
    builder.addCase(createAssetFeed.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createAssetFeed.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(createAssetFeed.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getAssetFeeds.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getAssetFeeds.fulfilled,
        (state, action: PayloadAction<{ count: number; fed: FeedFromType[] | FeedToType[]; fedType: FedType }>) => {
            if (action.payload.fedType === FedType.FROM) {
                state.feedFromCurrentAsset = action.payload.fed as FeedFromType[]
            }
            if (action.payload.fedType === FedType.TO) {
                state.feedToCurrentAsset = action.payload.fed as FeedToType[]
            }
            state.isWaitingForResponse = false
        },
    )
    builder.addCase(getAssetFeeds.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(deleteAssetFeed.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteAssetFeed.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(deleteAssetFeed.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    // pages
    builder.addCase(getAssetPagesByAssetId.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getAssetPagesByAssetId.fulfilled,
        (state, action: PayloadAction<{ assetId: string; count: number; pages: AssetPageType[] }>) => {
            state.assetPages = action.payload.pages
            state.selectedPlanPage = action.payload.count ? action.payload.pages[0] : null
            state.isWaitingForResponse = false
            if (!action.payload.count) {
                state.isWaitingFloorPlan = false
            }
        },
    )
    builder.addCase(getAssetPagesByAssetId.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getLeftAssetPointsByPageIdForAsset.pending, (state) => {
        state.isWaitingFloorPlan = true
        state.isNeedUpdatePoints = false
    })
    builder.addCase(
        getLeftAssetPointsByPageIdForAsset.fulfilled,
        (state, action: PayloadAction<{ count: number; points: AssetPoint[] }>) => {
            state.assetsPointsOnPlan = state.assetsPointsOnPlan.concat(
                transformLeftPointToNormal(action.payload?.points).flatMap((o) => o),
            )
            state.isWaitingFloorPlan = false
        },
    )
    builder.addCase(getLeftAssetPointsByPageIdForAsset.rejected, (state, action) => {
        state.isWaitingFloorPlan = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getRightAssetPointsByPageIdForAsset.pending, (state) => {
        state.isWaitingFloorPlan = true
        state.isNeedUpdatePoints = false
    })
    builder.addCase(
        getRightAssetPointsByPageIdForAsset.fulfilled,
        (state, action: PayloadAction<{ count: number; points: AssetPoint[] }>) => {
            state.assetsPointsOnPlan = state.assetsPointsOnPlan.concat(
                transformRightPointToNormal(action.payload?.points, state.currentAsset?.id).flatMap((o) => o),
            )
            state.isWaitingFloorPlan = false
        },
    )
    builder.addCase(getRightAssetPointsByPageIdForAsset.rejected, (state, action) => {
        state.isWaitingFloorPlan = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    // panels
    builder.addCase(editPanel.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(editPanel.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(editPanel.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(clearPanel.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(clearPanel.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(clearPanel.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    //category management
    builder.addCase(getAssetCategoriesList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getAssetCategoriesList.fulfilled,
        (state, action: PayloadAction<{ count: number; categories: Omit<CategoryThreeType, 'types'>[] }>) => {
            state.isWaitingForResponse = false
            state.categoriesThree = action.payload.categories?.map((category) => ({ ...category, types: [] }))
        },
    )
    builder.addCase(getAssetCategoriesList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getAssetTypesList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getAssetTypesList.fulfilled,
        (
            state,
            action: PayloadAction<{
                count: number
                categoryId: string
                types: TypeInCategoryType[]
            }>,
        ) => {
            state.isWaitingForResponse = false
            state.categoriesThree = state.categoriesThree.map((category) =>
                category.id === action.payload.categoryId ? { ...category, types: action.payload.types } : category,
            )
        },
    )
    builder.addCase(getAssetTypesList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(createAssetCategory.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createAssetCategory.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(createAssetCategory.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(editAssetCategory.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(editAssetCategory.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(editAssetCategory.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(createAssetType.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createAssetType.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(createAssetType.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(createAssetProperty.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createAssetProperty.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(createAssetProperty.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    builder.addCase(replaceAsset.rejected, (state, action) => {
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    /*builder.addMatcher(
        (action) => action.type.endsWith('/pending'),
        (state) => {
            state.isWaitingForResponse = true
        },
    )
    builder.addMatcher(
        (action) => action.type.endsWith('/fulfilled'),
        (state) => {
            state.isWaitingForResponse = false
        },
    )
    builder.addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state) => {
            state.isWaitingForResponse = false
        },
    )*/
}
