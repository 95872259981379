import { useEffect, useState } from 'react'
import {
    CurrentWorkOrderTechnicianType,
    WorkOrderFileType,
    WorkOrderSupervisorType,
    WorkOrderType,
} from 'slices/workOrder'
import { Availabilities } from 'components/NewSubcontractor/config'
import { getWorkOrderTechnicians } from 'api/workOrders'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { currentUserSelect } from 'store/selectors'

export type TeamsType = {
    id: string
    name: string
    avatar?: WorkOrderFileType
    availability?: Availabilities
    supervisors?: WorkOrderSupervisorType[]
    technicians?: CurrentWorkOrderTechnicianType[]
}

type PropsType = {
    currentWorkOrder: WorkOrderType
    isAllow: boolean
}

export const useTeamsForWoWithTech = ({ currentWorkOrder, isAllow = true }: PropsType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const [teams, setTeams] = useState<TeamsType[]>([])
    const [isLoadingTech, setIsLoadingTech] = useState(false)

    const getTeams = async () => {
        setIsLoadingTech(true)
        try {
            const res: CurrentWorkOrderTechnicianType[] = await dispatch(
                getWorkOrderTechnicians({ workOrderId: currentWorkOrder.id }),
            ).unwrap()

            if (res) {
                setTeams((prev) =>
                    prev.map((team) => {
                        const techniciansFromTeam = res?.filter(({ technician }) =>
                            technician?.buckets?.some(({ id }) => id === team.id),
                        )
                        return { ...team, technicians: techniciansFromTeam }
                    }),
                )
            }
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoadingTech(false)
        }
    }

    useEffect(() => {
        if (!user || !isAllow) {
            return
        }
        if (currentWorkOrder && currentWorkOrder?.buckets?.length) {
            setTeams(
                currentWorkOrder?.buckets?.map((team) => ({
                    id: team.id,
                    name: team.name,
                    availability: team?.availability,
                    avatar: team?.avatar,
                    supervisors: team?.users,
                })),
            )

            getTeams()
        } else {
            setTeams([])
        }
    }, [user, currentWorkOrder, isAllow])

    return { teams, isLoadingTech, getTeams }
}
