import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import {
    InventoryItemsStateType,
    PurchaseRequestType,
    InventoryDetailsType,
    VendorType,
    CurrentInventoryDetailType,
} from './types'
import sendNotification from 'lib/notification'
import { CommonOtherResponseType, NOTIFICATION_STATUS } from 'types'
import {
    editItemRequest,
    getCurrentInventoryDetail,
    getDetailsInventoryItemsList,
    getPurchaseRequestList,
    getVendorsList,
} from 'api/partsInventory'

export const extraReducers = (builder: ActionReducerMapBuilder<InventoryItemsStateType>) => {
    //Inventory Items
    builder.addCase(getDetailsInventoryItemsList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getDetailsInventoryItemsList.fulfilled,
        (state, action: PayloadAction<CommonOtherResponseType<InventoryDetailsType>>) => {
            state.isWaitingForResponse = false
            state.inventoryItems = action.payload.payload
            state.inventoryItemsCount = action.payload.count
        },
    )
    builder.addCase(getDetailsInventoryItemsList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action?.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getCurrentInventoryDetail.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getCurrentInventoryDetail.fulfilled, (state, action: PayloadAction<CurrentInventoryDetailType>) => {
        state.isWaitingForResponse = false
        state.currentInventoryItem = action.payload
    })
    builder.addCase(getCurrentInventoryDetail.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action?.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(editItemRequest.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        editItemRequest.fulfilled,
        (state, action: PayloadAction<CommonOtherResponseType<InventoryDetailsType>>) => {
            state.isWaitingForResponse = false
        },
    )
    builder.addCase(editItemRequest.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action?.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    //Vendors
    builder.addCase(getVendorsList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getVendorsList.fulfilled, (state, action: PayloadAction<CommonOtherResponseType<VendorType>>) => {
        state.isWaitingForResponse = false
        state.vendors = action.payload.payload
        state.vendorsCount = action.payload.count
    })
    builder.addCase(getVendorsList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action?.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    //Purchase Request
    builder.addCase(getPurchaseRequestList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getPurchaseRequestList.fulfilled,
        (state, action: PayloadAction<CommonOtherResponseType<PurchaseRequestType>>) => {
            state.isWaitingForResponse = false
            state.purchaseRequests = action.payload.payload
            state.purchaseRequestsCount = action.payload.count
        },
    )
    builder.addCase(getPurchaseRequestList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action?.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
}
