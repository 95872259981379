import {
    createBuilding,
    deleteBuilding,
    getBuildingById,
    getBuildingsList,
    getBuildingsListByCoords,
    updateBuilding,
} from '../../api/buildings'
import sendNotification from '../../lib/notification'
import { NOTIFICATION_STATUS } from '../../types'
import {
    createFloor,
    deleteFloor,
    getFloorsListByBuildingId,
    getRoomsListByFloorId,
    updateFloor,
} from '../../api/floors'
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import { Floor, FloorPlan, Room } from '../floorPlanViever'
import { createPlan, deleteFloorPlan, getPlansListByFloorId } from '../../api/plans'
import { UtilityInsurance } from './types'
import { BuildingsState } from './BuildingSlice'
import {
    deleteUtilityInsurance,
    getInsuranceListByBuildingId,
    getUtilitiesListByBuildingId,
    updateUtilityInsurance,
} from '../../api/buildingDetails'
import { createRegion, deleteRegion, getAllRegionsForAutocomplete, updateRegion } from '../../api/regions'
import { RegionType } from '../region'

const extraReducers = (builder: ActionReducerMapBuilder<BuildingsState>) => {
    builder.addCase(createBuilding.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createBuilding.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('A new building has been created', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(createBuilding.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    builder.addCase(getBuildingById.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getBuildingById.fulfilled, (state, action) => {
        state.isWaitingForResponse = false
        state.currentBuilding = action.payload
    })
    builder.addCase(getBuildingById.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Cant find =(', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get floors list
    builder.addCase(getFloorsListByBuildingId.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getFloorsListByBuildingId.fulfilled, (state, action: PayloadAction<{ rows: Array<Floor> }>) => {
        state.isWaitingForResponse = false

        const floors = action.payload.rows

        if (floors.length > 0) {
            const buildingId = floors[0].buildingId

            state.floorsTableThree = {
                ...state.floorsTableThree,
                [buildingId]: floors,
            }
        }

        state.floorsThree = floors
    })
    builder.addCase(getFloorsListByBuildingId.rejected, (state) => {
        state.isWaitingForResponse = false
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get plans list
    // builder.addCase(getPlansListByFloorId.pending, (state) => {
    //     state.openFloorLoading = true
    // })
    builder.addCase(getPlansListByFloorId.fulfilled, (state, action: PayloadAction<{ plans: Array<FloorPlan> }>) => {
        const plans = action.payload.plans
        if (plans.length > 0) {
            const floorId = plans[0].floorId

            state.plansThree = {
                ...state.plansThree,
                [floorId]: plans,
            }
        }
    })
    builder.addCase(getPlansListByFloorId.rejected, () => {
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get rooms list
    // builder.addCase(getRoomsListByFloorId.pending, (state) => {
    //     state.openFloorLoading = true
    // })
    builder.addCase(getRoomsListByFloorId.fulfilled, (state, action: PayloadAction<{ rows: Array<Room> }>) => {
        const rooms = action.payload.rows
        if (rooms.length > 0) {
            const floorId = rooms[0].floor.id

            state.roomsThree = {
                ...state.roomsThree,
                [floorId]: rooms,
            }
        }
    })
    builder.addCase(getRoomsListByFloorId.rejected, () => {
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //building list
    builder.addCase(getBuildingsList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getBuildingsList.fulfilled, (state, action) => {
        state.isWaitingForResponse = false
        state.buildings = action.payload.rows
        state.buildingsCount = action.payload.count
    })
    builder.addCase(getBuildingsList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action?.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //regions list
    builder.addCase(getAllRegionsForAutocomplete.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getAllRegionsForAutocomplete.fulfilled,
        (state, action: PayloadAction<{ rows: Array<RegionType> }>) => {
            state.isWaitingForResponse = false
            state.regionsList = action.payload.rows
        },
    )
    builder.addCase(getAllRegionsForAutocomplete.rejected, (state) => {
        state.isWaitingForResponse = false
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //create region
    builder.addCase(createRegion.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createRegion.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(createRegion.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //delete region
    builder.addCase(deleteRegion.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteRegion.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(deleteRegion.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //update region
    builder.addCase(updateRegion.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(updateRegion.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(updateRegion.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //building list by coords
    builder.addCase(getBuildingsListByCoords.pending, () => {
        //state.isWaitingForResponse = true
    })
    builder.addCase(getBuildingsListByCoords.fulfilled, (state, action) => {
        state.buildingsForMap = action.payload.rows
    })
    builder.addCase(getBuildingsListByCoords.rejected, () => {
        //state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //update building
    builder.addCase(updateBuilding.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(updateBuilding.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('The building has been updated', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(updateBuilding.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    builder.addCase(deleteBuilding.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteBuilding.fulfilled, (state, action) => {
        state.isWaitingForResponse = false
        // state.buildings = на удаление из массива
        sendNotification((action.payload as string) || 'The building has been deleted', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(deleteBuilding.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //create floor
    builder.addCase(createFloor.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createFloor.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('A new floor has been created', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(createFloor.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get utilities
    builder.addCase(getUtilitiesListByBuildingId.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getUtilitiesListByBuildingId.fulfilled, (state, action: PayloadAction<Array<UtilityInsurance>>) => {
        state.isWaitingForResponse = false
        state.utilitiesBuilding = action.payload
    })
    builder.addCase(getUtilitiesListByBuildingId.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get insurances
    builder.addCase(getInsuranceListByBuildingId.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getInsuranceListByBuildingId.fulfilled, (state, action: PayloadAction<Array<UtilityInsurance>>) => {
        state.isWaitingForResponse = false
        state.insuranceBuilding = action.payload
    })
    builder.addCase(getInsuranceListByBuildingId.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //delete utilitiesInsurance
    builder.addCase(deleteUtilityInsurance.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteUtilityInsurance.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(deleteUtilityInsurance.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //update utilitiesInsurance
    builder.addCase(updateUtilityInsurance.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(updateUtilityInsurance.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(updateUtilityInsurance.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //delete floor
    builder.addCase(deleteFloor.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteFloor.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('A floor has been deleted', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(deleteFloor.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    //update floor
    builder.addCase(updateFloor.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(updateFloor.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('A floor has been updated', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(updateFloor.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    //create plan
    builder.addCase(createPlan.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createPlan.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('The floor plan has been successfully loaded into the system.', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(createPlan.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //delete floorPlan
    builder.addCase(deleteFloorPlan.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteFloorPlan.fulfilled, (state) => {
        state.isWaitingForResponse = false
        state.plansThree = {}
        sendNotification('The floor plan has been successfully removed from the system.', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(deleteFloorPlan.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
}

export default extraReducers
