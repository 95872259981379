import { useEffect, useState } from 'react'
import { useAppDispatch } from 'hooks/redux'
import { AssetType } from 'slices/floorPlanViever'
import { getAssetTypesListByCategory } from 'api/floors'
import { useInfinityScroll } from 'hooks/index'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'

type UseAssetCategoriesType = {
    isAllow: boolean
    categoryIdes?: string[]
    assetPropId?: string
}

const TYPES_LIMIT = 100

export const useAssetTypes = ({ isAllow, categoryIdes, assetPropId }: UseAssetCategoriesType) => {
    const dispatch = useAppDispatch()
    const [assetTypes, setAssetTypes] = useState<AssetType[]>([])
    const [isTypesLoading, setIsTypesLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)

    const { setLastElement: setTypeLastElement } = useInfinityScroll({
        fetchNextData: () => setPage(page + 1),
        hasMore: assetTypes.length < count,
    })

    const getAssetTypes = async () => {
        setIsTypesLoading(true)
        try {
            const res = await dispatch(
                getAssetTypesListByCategory({
                    limit: TYPES_LIMIT,
                    offset: TYPES_LIMIT * (page - 1),
                    categoryIdes,
                    assetPropId,
                }),
            ).unwrap()
            if (res) {
                setAssetTypes(page === 1 ? res.types : [...assetTypes, ...res.types])
                setCount(res?.count)
            }
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsTypesLoading(false)
        }
    }
    useEffect(() => {
        if (isAllow && (categoryIdes?.length || assetPropId)) {
            getAssetTypes()
        }
        if (!categoryIdes?.length && !assetPropId) {
            setAssetTypes([])
            setPage(1)
        }
    }, [isAllow, page, JSON.stringify(categoryIdes), assetPropId])

    return { assetTypes, getAssetTypes, setTypeLastElement, isTypesLoading }
}
