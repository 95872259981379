import { USER_ROLE } from 'types'

export type AssetHistoryType = {
    action: string
    createdAt: Date
    data: {
        buildingId: string
        categoryId: string
        cost: number
        description: string
        floorId: string
        installDate: Date
        isAddAssigmentTab: false
        isCritical: true
        laborValue: number
        manufacturer: string
        model: string
        name: string
        regionId: string
        roomId: string
        serialNumber: string
        typeId: string
    }
    id: string
    link: string
    object: string
    subObject: string
    user: {
        firstName: string
        id: string
        lastName: string
        role: USER_ROLE
    }
}

export const HISTORY_LIMIT = 25

export type HistoryStateType = {
    isWaitingForResponse: boolean
    assetHistory: AssetHistoryType[]
    assetHistoryPaginationPage: number
    assetHistoryCount: number
}
