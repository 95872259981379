import { forwardRef } from 'react'

import styles from './styles.module.scss'
import TooltipMenu, { TooltipMenuItem } from '../../../TooltipMenu'
import Icons from '../../../../assets/icons'
import { Note } from 'slices/building'
import dayjs from 'dayjs'
import { CurrentFilesList } from '../../../FilesDropzoneLoader'

type Props = {
    note: Note
    onDeleteNote: (id: string) => void
    isEditable?: boolean
}
const NoteItem = forwardRef<HTMLDivElement, Props>(({ note, onDeleteNote, isEditable = true }, ref) => {
    return (
        <div ref={ref} className={styles.root}>
            <div className={styles.content}>
                <div className={styles.headerNote}>
                    <div className={styles.name}>{note.creator.firstName + ' ' + note.creator.lastName}</div>
                    <div className={styles.date}>{dayjs(note.creationDate).format('hh:mma YYYY-MM-DD')}</div>
                </div>
                <div className={styles.textNote}>{note.text}</div>
                <CurrentFilesList files={note.noteFiles} />
            </div>

            {isEditable && (
                <TooltipMenu classesRoot={styles.tooltip}>
                    {/*<TooltipMenuItem icon={<Icons.Edit />} title="Edit note" />*/}
                    <TooltipMenuItem icon={<Icons.Trash />} title="Delete" onClick={() => onDeleteNote(note.id)} />
                </TooltipMenu>
            )}
        </div>
    )
})

export default NoteItem
