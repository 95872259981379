import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector, useInfinityScroll } from 'hooks/index'
import { PURCHASE_REQUEST_PER_PAGE } from 'slices/inventoryItems'
import { CommonOtherResponseType, NOTIFICATION_STATUS } from 'types'
import { getOrderHistoryForHook, OrderHistoryType } from 'api/partsInventory'
import sendNotification from 'lib/notification'
import { currentUserSelect } from 'store/selectors'

type UseVendorType = {
    typeId: string
    isAllow?: boolean
    limit?: number
}

export const useOrdersHistory = ({ typeId, isAllow = true, limit = PURCHASE_REQUEST_PER_PAGE }: UseVendorType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const [isLoading, setIsLoading] = useState(false)
    const [orders, setOrders] = useState<OrderHistoryType[]>([])
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)

    const { setLastElement } = useInfinityScroll({
        fetchNextData: () => setPage(page + 1),
        hasMore: orders.length < count,
    })

    const getOrdersHistory = async () => {
        setIsLoading(true)
        try {
            const res: CommonOtherResponseType<OrderHistoryType> = await dispatch(
                getOrderHistoryForHook({
                    typeId,
                    limit,
                    offset: limit * (page - 1),
                }),
            ).unwrap()

            if (res) {
                setOrders(page === 1 ? res?.payload : [...orders, ...res.payload])
                setCount(res?.count)
            }
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!user || !isAllow) {
            return
        }
        getOrdersHistory()
    }, [user, page, typeId, isAllow])

    return { orders, isLoading, count, setLastElement, getOrdersHistory }
}
