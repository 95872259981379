import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { axiosErrorHandler, client } from 'api/setupAxios'
import { CommonResponseType, DirectionEnum, USER_ROLE } from 'types'
import { AxiosResponse } from 'axios'
import { TEAMS_PER_PAGE } from 'pages/WorkOrdersPage/components/WorkOrdersTeams/config'
import { AssignedTeamType, CurrentTeamType, TeamType } from 'slices/team'
import { checkIsGoodStatus } from 'lib/common'
import { NewTeamType } from 'components/NewTeam/config'
import { EditTeamType } from 'pages/TeamProfile/config'

export const getTeamsList = createAppAsyncThunk('teams/getTeamsList', async (_, { rejectWithValue, getState }) => {
    const page = getState().team.teamPaginationPage
    const search = getState().team.teamsSearchValue
    const customerId = getState().auth?.user?.customerId || ''
    const regionId = getState().auth?.user?.regionId || ''
    const role = getState().auth?.user?.role
    const sortDirection = getState().team.teamsSettings.sortDirection
    const sortField = getState().team.teamsSettings.sortField
    const filters = getState().team.teamsSettings.filters

    const params = {
        page,
        size: TEAMS_PER_PAGE,
        keySearchValue: search !== '' ? search : undefined,
        sortField,
        sortDirection,
        customerId: role === USER_ROLE.ADMIN ? customerId : undefined,
        requiresApproval: filters?.requiresApproval ? filters?.requiresApproval : undefined,
        availability: filters?.availabilities?.length ? filters?.availabilities : undefined,
        workOrderTypes: filters?.types?.length ? filters.types : undefined,
        regionIdes: role && ![USER_ROLE.ADMIN, USER_ROLE.REQUESTOR].includes(role) ? [regionId] : undefined,
    }

    try {
        const result = await client.get<CommonResponseType<TeamType>, AxiosResponse<CommonResponseType<TeamType>>>(
            `/api/bucket/get-buckets-list`,
            { params },
        )
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getTeamsListForAutocomplete = createAppAsyncThunk<
    any,
    { page: number; customerId: string; search?: string; size?: number; regionId?: string }
>(
    'teams/getTeamsListForAutocomplete',
    async ({ page, customerId, search, size = TEAMS_PER_PAGE, regionId }, { rejectWithValue, getState }) => {
        const region = regionId ?? getState().auth?.user?.regionId
        const role = getState().auth?.user?.role

        const params = {
            page,
            size,
            keySearchValue: search !== '' ? search : undefined,
            sortField: 'id',
            sortDirection: DirectionEnum.ASC,
            customerId: role === USER_ROLE.ADMIN ? customerId : undefined,
            regionIdes: role && ![USER_ROLE.REQUESTOR, USER_ROLE.ADMIN].includes(role) && region ? [region] : undefined,
        }

        try {
            const result = await client.get<CommonResponseType<TeamType>, AxiosResponse<CommonResponseType<TeamType>>>(
                `/api/bucket/get-buckets-list`,
                { params },
            )
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getTeamById = createAppAsyncThunk(
    'teams/getTeamById',
    async ({ id }: { id: string }, { rejectWithValue }) => {
        const params = { bucketId: id }
        try {
            const result = await client.get<CurrentTeamType, AxiosResponse<CurrentTeamType>>(
                `/api/bucket/get-bucket-by-id`,
                { params },
            )
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const removeUserFromTeam = createAppAsyncThunk<any, { userId: string; bucketId: string }>(
    'teams/removeUserFromTeam',
    async ({ userId, bucketId }, { rejectWithValue, dispatch }) => {
        const data = { userId, bucketId }
        try {
            const result = await client.delete(`/api/bucket/delete-user-from-bucket`, { data })

            if (checkIsGoodStatus(result.status)) {
                await dispatch(getTeamsList())
                await dispatch(getTeamById({ id: bucketId }))
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const addUserToTeam = createAppAsyncThunk<any, { usersId: string[]; bucketId: string }>(
    'teams/addUserToTeam',
    async ({ usersId, bucketId }, { rejectWithValue, dispatch }) => {
        const data = { usersId, bucketId }
        try {
            const result = await client.post(`/api/bucket/add-user-to-bucket`, data)

            if (checkIsGoodStatus(result.status)) {
                await dispatch(getTeamsList())
                await dispatch(getTeamById({ id: bucketId }))
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createTeam = createAppAsyncThunk<any, NewTeamType & { checkResponse?: () => void }>(
    'teams/createTeam',
    async ({ checkResponse, ...arg }, { rejectWithValue, getState }) => {
        const customerId = getState().auth.user?.customerId ?? ''
        try {
            const result = await client.post(
                `/api/bucket/create-bucket`,
                { ...arg, customerId },
                { headers: { 'Content-Type': 'multipart/form-data' } },
            )

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const editTeam = createAppAsyncThunk<any, EditTeamType & { bucketId: string }>(
    'teams/editTeam',
    async (arg, { rejectWithValue, dispatch }) => {
        try {
            const result = await client.patch(`/api/bucket/update-bucket`, { ...arg })

            if (checkIsGoodStatus(result?.status)) {
                dispatch(getTeamById({ id: arg.bucketId }))
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const editTeamImage = createAppAsyncThunk<any, { bucketId: string; image: File }>(
    'teams/editTeamImage',
    async (arg, { rejectWithValue }) => {
        try {
            const result = await client.patch(
                `/api/bucket/upload-bucket-image`,
                { ...arg },
                { headers: { 'Content-Type': 'multipart/form-data' } },
            )

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAssignedTeams = createAppAsyncThunk('teams/getAssignedTeams', async (_, { rejectWithValue }) => {
    try {
        const result = await client.get<
            { count: number; payload: AssignedTeamType[] },
            AxiosResponse<{ count: number; payload: AssignedTeamType[] }>
        >(`/api/bucket/assigned`)
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

interface GetUsersTeamsList {
    userId: string
    //value?: string
    page?: number
    size?: number
}

export const getUsersTeamsList = createAppAsyncThunk(
    'teams/getUsersTeamsList',
    async ({ page, size = 25, userId }: GetUsersTeamsList, { rejectWithValue }) => {
        const params = {
            userId,
            sortField: 'id',
            sortDirection: DirectionEnum.ASC,
            page,
            size,
            //keySearchValue: value,
        }
        try {
            const result = await client.get(`/api/bucket/get-buckets-by-user-id`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
