import { StoreRootState } from '..'
import { AssignedTeamType, CurrentTeamType, TeamFiltersType, TeamType } from 'slices/team'
import { CommonSortType, ListViewMode } from 'types'
import { TableColumnType } from 'components/Table'
import { TeamProfileTab } from 'pages/TeamProfile/config'

export const currentTeamSelect = (state: StoreRootState): CurrentTeamType => state.team.currentTeam
export const isLoadingTeamSelect = (state: StoreRootState): boolean => state.team.isWaitingForResponse
export const teamProfileTabSelect = (state: StoreRootState): TeamProfileTab => state.team.teamProfileTab
export const teamSearchValueSelect = (state: StoreRootState): string => state.team.teamsSearchValue
export const teamCurrentPageSelect = (state: StoreRootState): number => state.team.teamPaginationPage
export const teamsCountSelect = (state: StoreRootState): number => state.team.teamsCount
export const teamsSelect = (state: StoreRootState): TeamType[] => state.team.teams
export const teamListModeSelect = (state: StoreRootState): ListViewMode => state.team.teamsSettings.listMode
export const teamsSortSelect = (state: StoreRootState): CommonSortType => ({
    sortField: state.team.teamsSettings.sortField,
    sortDirection: state.team.teamsSettings.sortDirection,
})
export const teamsColumnsSelect = (state: StoreRootState): TableColumnType[] => state.team.teamsSettings.columns
export const teamsFiltersSelect = (state: StoreRootState): TeamFiltersType | null => state.team.teamsSettings.filters
export const assignedTeamsSelect = (state: StoreRootState): AssignedTeamType[] => state.team.assignedTeams
