import { USER_ROLE, USER_TYPE } from '../../types'
import {
    SupervisorProfileTabs,
    AdminProfileTabs,
    RequestorProfileTabs,
    TechnicianProfileTabs,
    requiredUserColumns,
    userColumns,
} from '../../slices/users/const'
import { FiltersForReportsType } from '../../components/CommonSavedView'
import { UserReportsFiltersType } from '../../hooks/userHooks/useUserFiltersForReports'
import { LeaseStatus, TenantStatus, UserFiltersType, UserStatus } from '../../slices/users'
import { TableColumnType } from '../../components/Table'
import { ColorVariant } from '../../components/StatusBadge'

export const getUserProfileTabs = (role: USER_ROLE) => {
    switch (role) {
        case USER_ROLE.REQUESTOR:
            return RequestorProfileTabs

        case USER_ROLE.TECHNICIAN:
            return TechnicianProfileTabs

        case USER_ROLE.ADMIN:
        case USER_ROLE.SUPERADMIN:
            return AdminProfileTabs

        case USER_ROLE.SUPERVISOR:
            return SupervisorProfileTabs

        default:
            return null
    }
}

export const selectRoleData: { label: string; value: string }[] = [
    {
        value: USER_ROLE.ADMIN,
        label: 'Administrator',
    },
    {
        value: USER_ROLE.SUPERVISOR,
        label: 'Supervisor',
    },
    {
        value: USER_ROLE.REQUESTOR,
        label: 'Requestor',
    },
    {
        value: USER_ROLE.TECHNICIAN,
        label: 'Technician',
    },
    {
        value: USER_ROLE.SUPERADMIN,
        label: 'SuperAdmin',
    },
]

export const getUserFiltersFromView = (filter?: FiltersForReportsType<UserReportsFiltersType>[]) => {
    const filters: UserFiltersType = {}

    if (filter) {
        for (const val of Object.values(filter)) {
            if ('regionId' in val) {
                // @ts-ignore
                filters.regions = val?.regionId?.map((item: string) => ({ value: item, label: '' }))
            }
            // if ('buildingId' in val) {
            //     // @ts-ignore
            //     assetFilters.buildings = val?.buildingId?.map((item: string) => ({ value: item, label: '' }))
            // }

            if ('type' in val) {
                filters.types = val.type as USER_TYPE[]
            }

            if ('status' in val) {
                filters.statuses = val.status as UserStatus[]
            }

            if ('lastActivity' in val) {
                // @ts-ignore
                if ('>=' in val.lastActivity && val.lastActivity['>=']) {
                    filters.startLastActivity = new Date(val.lastActivity['>='])
                }
                // @ts-ignore
                if ('<=' in val.lastActivity && val.lastActivity['<=']) {
                    filters.endLastActivity = new Date(val.lastActivity['<='])
                }
            }

            if ('creationDate' in val) {
                // @ts-ignore
                if ('>=' in val.creationDate && val.creationDate['>=']) {
                    filters.startDateAdded = new Date(val.creationDate['>='])
                }
                // @ts-ignore
                if ('<=' in val.creationDate && val.creationDate['<=']) {
                    filters.endDateAdded = new Date(val.creationDate['<='])
                }
            }

            if ('lastLogin' in val) {
                // @ts-ignore
                if ('>=' in val.lastLogin && val.lastLogin['>=']) {
                    filters.startLastLogin = new Date(val.lastLogin['>='])
                }
                // @ts-ignore
                if ('<=' in val.lastLogin && val.lastLogin['<=']) {
                    filters.startLastLogin = new Date(val.lastLogin['<='])
                }
            }
        }
    }
    return filters
}

export const getUserColumnsFromView = (columns: string[], role: USER_ROLE) => {
    const otherColumns: TableColumnType[] = []

    const requiredColumns = requiredUserColumns[role] || []
    const tableColumns = userColumns[role] || []

    for (const val of columns.slice(requiredColumns.length)) {
        const currentColumn = tableColumns.find((item) => item.label === val)

        if (currentColumn) {
            otherColumns.push(currentColumn)
        }
    }
    return otherColumns
}

export const getUserSearchValueFromView = (filter?: FiltersForReportsType<UserReportsFiltersType>[]) => {
    if (filter) {
        for (const val of Object.values(filter)) {
            // eslint-disable-next-line no-prototype-builtins
            if (Object.values(val)[0].hasOwnProperty('search')) {
                return Object.values(Object.values(val)[0])[0]
            }
        }
    }
}

export const getColorUserStatus = (option: UserStatus): ColorVariant => {
    switch (option) {
        case UserStatus.ACTIVE:
            return ColorVariant.green

        case UserStatus.BLOCKED:
            return ColorVariant.red

        case UserStatus.PENDING:
            return ColorVariant.yellow

        default:
            return ColorVariant.grey
    }
}

export const getColorTenantCompanyStatus = (option: TenantStatus): ColorVariant => {
    switch (option) {
        case TenantStatus.ACTIVE:
            return ColorVariant.green

        case TenantStatus.EXPIRED:
            return ColorVariant.red

        default:
            return ColorVariant.grey
    }
}
