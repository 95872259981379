import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ListViewMode } from 'types'
export enum ImportModelsEnum {
    ASSET = 'asset',
    BUILDING = 'building',
    USER_SUPERADMIN = 'user-superadmin',
    USER_ADMIN = 'user-admin',
    USER_SUPERVISOR = 'user-supervisor',
    USER_REQUESTOR = 'user-requestor',
    USER_TECHNICIAN = 'user-technician',
    USER_VENDOR = 'user-vendor',
    USER_TENANT = 'user-tenant',
    USER_FMPRO = 'user-fmpro',
    SUBCONTRACTOR = 'subcontractor',
    INVENTORY_ITEM = 'inventoryitem',
}

export type ImportDialogSateType = {
    isOpen: boolean
    table: ImportModelsEnum | null
}

export interface ToolsetState {
    buildingFilter: null
    buildingListMode: ListViewMode
    partsInventoryListMode: ListViewMode
    emergencyPlansListMode: ListViewMode
    emergencyContactsListMode: ListViewMode

    importDialog: ImportDialogSateType
    //assetCategoriesThree: { [key: string]: CategoryInAssetType & { types: AssetType[] } } | null
}

const initialState: ToolsetState = {
    buildingFilter: null,
    buildingListMode: ListViewMode.TABLE,
    partsInventoryListMode: ListViewMode.TABLE,
    emergencyPlansListMode: ListViewMode.TABLE,
    emergencyContactsListMode: ListViewMode.TABLE,

    importDialog: {
        isOpen: false,
        table: null,
    },
    //assetCategoriesThree: null,
}

export const ToolsetSlice = createSlice({
    name: 'toolset',
    initialState,
    reducers: {
        setBuildingFilter: (state, action: PayloadAction<null>) => {
            state.buildingFilter = action.payload
        },
        setBuildingListMode: (state, action: PayloadAction<ListViewMode>) => {
            state.buildingListMode = action.payload
        },
        setPartsInventoryListMode: (state, action: PayloadAction<ListViewMode>) => {
            state.partsInventoryListMode = action.payload
        },
        setEmergencyPlansListMode: (state, action: PayloadAction<ListViewMode>) => {
            state.emergencyPlansListMode = action.payload
        },
        setEmergencyContactsListMode: (state, action: PayloadAction<ListViewMode>) => {
            state.emergencyContactsListMode = action.payload
        },
        setImportDialog: (state, action: PayloadAction<ImportDialogSateType>) => {
            state.importDialog = action.payload
        },
        /*setAssetCategoriesThree: (state, action: PayloadAction<{ categories: CategoryInAssetType[] }>) => {
            state.assetCategoriesThree = action.payload.categories.reduce(
                (acc: { [key: string]: CategoryInAssetType & { types: AssetType[] } }, item) =>
                    item.id in acc ? acc : { ...acc, [item.id]: { ...item, types: [] } },
                {},
            )
        },
        setAssetTypesThree: (state, action: PayloadAction<{ categoryId: string; data: AssetType[] }>) => {
            if (state.assetCategoriesThree) {
                state.assetCategoriesThree[action.payload.categoryId].types = action.payload.data
            }
        },*/
    },
    //extraReducers: () => {},
})

export const {
    setBuildingFilter,
    setBuildingListMode,
    setPartsInventoryListMode,
    setEmergencyPlansListMode,
    setEmergencyContactsListMode,
    setImportDialog,
} = ToolsetSlice.actions
export const ToolsetReducer = ToolsetSlice.reducer
