import { client, axiosErrorHandler } from './setupAxios'
import { DirectionEnum } from '../types'
import { createAppAsyncThunk } from '../common/createAppAsyncThunk'
import { Customer, CustomerModule } from '../slices/customer'
import { BUILDS_PER_PAGE } from '../pages/BuildingsPage/helpers'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const createCustomer = createAppAsyncThunk(
    'customers/createCustomer',
    async (data: Partial<Omit<Customer, 'contacts'>> & { image?: File | null }, { rejectWithValue }) => {
        try {
            const result = await client.post(`api/customers/create-customer`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type UploadCustomerImage = {
    customerId: string
    image: File
}
export const uploadCustomerImage = createAppAsyncThunk(
    'customers/createCustomer',
    async (data: UploadCustomerImage, { rejectWithValue }) => {
        try {
            const result = await client.patch(`api/customers/upload-customer-image`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const updateCustomer = createAppAsyncThunk(
    'customers/updateCustomer',
    async (data: Partial<Customer> | { customerId: string }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`api/customers/update-customer`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const updateCustomerModule = createAppAsyncThunk(
    'customers/updateCustomerModule',
    async (data: Partial<CustomerModule> | { customerModuleId: string }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`api/customers/update-customer-module`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetCustomerList = {
    page: number
    keySearchValue: string
    size?: number
    sortField?: string
    sortDirection?: DirectionEnum
}

export const getCustomerList = createAppAsyncThunk(
    'customers/getList',
    async (
        { page, keySearchValue, size = 25, sortField = 'id', sortDirection = DirectionEnum.ASC }: GetCustomerList,
        { rejectWithValue },
    ) => {
        try {
            const result = await client.get(`/api/customers/get-customers-list`, {
                params: {
                    page,
                    keySearchValue,
                    size,
                    sortField,
                    sortDirection,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getCustomerById = createAppAsyncThunk(
    'customers/getCustomerById',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.get(`api/customers/get-customer-by-id`, {
                params: {
                    customerId: id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteCustomer = createAppAsyncThunk(
    'customers/deleteCustomer',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/customers/delete-customer`, {
                params: {
                    customerId: id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetCustomerFiles = {
    page: number
    customerId: string
}

export const getCustomerFiles = createAppAsyncThunk(
    'customers/getCustomerFiles',
    async (params: GetCustomerFiles, { getState, rejectWithValue }) => {
        try {
            const result = await client.get(`/api/customers/files/get-customer-files`, {
                params: {
                    ...params,
                    size: BUILDS_PER_PAGE,
                    sortField: 'id',
                    sortDirection: DirectionEnum.ASC,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreateCustomerFiles = {
    files: Array<File>
    customerId: string
}

export const createCustomerFiles = createAsyncThunk(
    'customers/createCustomerFiles',
    async (data: CreateCustomerFiles, { rejectWithValue }) => {
        try {
            const result = await client.post(`api/customers/files/create-customer-file`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteCustomerFile = createAsyncThunk(
    'customers/deleteCustomerFile',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`api/customers/files/delete-customer-file`, {
                params: {
                    id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
