import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { Table as MuiTable, TableProps } from '@mui/material'

type Props = {
    children: ReactNode
    className?: string
} & TableProps
const Table: FC<Props> = ({ children, className, ...props }) => {
    return (
        <MuiTable className={classNames(styles.table, className)} {...props}>
            {children}
        </MuiTable>
    )
}

export default Table
