import { useFormik } from 'formik'
import CustomInput from 'ui-kit/components/CustomInput'
import React, { useEffect } from 'react'
import SaveCancelPanel from 'components/SaveCancelPanel'
import { noop } from 'lib/common'
import CustomSelect from 'ui-kit/components/CustomSelect'
import { initContact, Schema } from './config'
import ReactInputMask from 'react-input-mask'
import { User } from 'types'
import { Contact } from '../ContactsList/types'

import styles from './styles.module.scss'

type Props = {
    contact?: Partial<Contact> | Partial<User> | null
    onClose?: () => void
    onSave?: (val: Partial<Contact> | Partial<User>) => void
    isCard?: boolean
    types?: Array<string>
    typeDefault?: string
    emailDefault?: string
}

export const ContactForm = ({
    contact,
    onClose = noop,
    onSave = noop,
    isCard,
    types,
    typeDefault,
    emailDefault,
}: Props) => {
    const { setValues, getFieldProps, handleSubmit, values, setFieldValue, errors, touched } = useFormik<
        Partial<Contact> | Partial<User>
    >({
        initialValues: {
            ...initContact,
            type: types && types.length > 0 ? types[0] : '',
            email: emailDefault || '',
        },
        onSubmit: onSave,
        validateOnChange: false,
        enableReinitialize: true,
        validationSchema: Schema,
    })

    const readOnly = isCard

    useEffect(() => {
        if (contact) {
            setValues({ ...contact })
        }
    }, [contact])

    return (
        <div className={styles.root}>
            <div className={styles.inputsRowContainer}>
                <CustomInput
                    errorText={touched.firstName && errors.firstName ? errors.firstName : ''}
                    readOnly={readOnly}
                    label={'First name'}
                    id="firstName"
                    variant="white"
                    width="48%"
                    {...getFieldProps('firstName')}
                />
                <CustomInput
                    errorText={touched.lastName && errors.lastName ? errors.lastName : ''}
                    readOnly={readOnly}
                    label={'Last name'}
                    id="lastName"
                    variant="white"
                    width="48%"
                    {...getFieldProps('lastName')}
                />
            </div>

            {types && !typeDefault && (
                <CustomSelect
                    readonly={readOnly}
                    label="Type"
                    values={types}
                    value={values.type || types[0]}
                    onChange={(val) => setFieldValue('type', val)}
                    background="#FFFFFF"
                />
            )}

            {typeDefault && <CustomInput readOnly disabled label={'Type'} variant="white" defaultValue={typeDefault} />}

            <CustomInput readOnly={readOnly} label={'Title'} id="title" variant="white" {...getFieldProps('title')} />
            <ReactInputMask
                maskPlaceholder={null}
                alwaysShowMask={true}
                mask="+1 999-999-9999"
                {...getFieldProps('phone')}
                readOnly={readOnly}
            >
                <CustomInput
                    errorText={touched.phone && errors.phone ? errors.phone : ''}
                    label={'Phone'}
                    id="phone"
                    variant="white"
                />
            </ReactInputMask>
            {!emailDefault && (
                <CustomInput
                    errorText={touched.email && errors.email ? errors.email : ''}
                    readOnly={readOnly}
                    label={'Email'}
                    id="email"
                    variant="white"
                    {...getFieldProps('email')}
                />
            )}

            {emailDefault && (
                <CustomInput readOnly disabled label={'Email'} variant="white" defaultValue={emailDefault} />
            )}

            {!readOnly && <SaveCancelPanel onCancel={() => onClose()} onSave={handleSubmit} />}
        </div>
    )
}
