import { createAsyncThunk } from '@reduxjs/toolkit'
import { axiosErrorHandler, client } from './setupAxios'
import { createAppAsyncThunk } from '../common/createAppAsyncThunk'

export const getNotificationsCount = createAsyncThunk(
    'notifications/getNotificationsCount',
    async (_, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/notifications/count`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetNotificationsList = {
    offset?: number
    limit?: number
}

export const getNotificationsList = createAppAsyncThunk(
    'notifications/getNotificationsList',
    async ({ offset = 0, limit = 100 }: GetNotificationsList, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/notifications/my`, {
                params: {
                    offset,
                    limit,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const updateNotificationStatus = createAppAsyncThunk(
    'notifications/updateNotificationStatus',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/notifications/toggle-viewed/${id}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const readAllNotifications = createAppAsyncThunk(
    'notifications/readAllNotifications',
    async (_, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/notifications/read-all`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteNotification = createAppAsyncThunk(
    'notifications/deleteNotification',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/notifications/${id}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
