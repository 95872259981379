import { useAppDispatch, useAppSelector } from '../../../../../../hooks'
import { USER_ROLE } from '../../../../../../types'
import { CommonColumnsView } from '../../../../../../components/CommonColumnsView'
import {
    requestersColumns,
    techniciansColumns,
    supervisorsColumns,
    adminsColumns,
} from '../../../../../../slices/users/const'
import { userAction } from '../../../../../../slices/users'

type Props = {
    userRole: USER_ROLE
}

const UsersColumns = ({ userRole }: Props) => {
    const dispatch = useAppDispatch()

    const visibleRequestersColumns = useAppSelector(({ users }) => users.userRequesterSettings.columns)
    const visibleTechniciansColumns = useAppSelector(({ users }) => users.userTechniciansSettings.columns)
    const visibleSupervisorsColumns = useAppSelector(({ users }) => users.userSupervisorsSettings.columns)
    const visibleAdminsColumns = useAppSelector(({ users }) => users.userAdminsSettings.columns)
    const visibleSuperAdminsColumns = useAppSelector(({ users }) => users.userSuperAdminsSettings.columns)

    switch (userRole) {
        case USER_ROLE.REQUESTOR:
            return (
                <CommonColumnsView
                    tableColumns={requestersColumns}
                    visibleTableColumns={visibleRequestersColumns}
                    onChangeTableColumn={(value) => dispatch(userAction.setRequestorsColumns(value))}
                />
            )

        case USER_ROLE.TECHNICIAN:
            return (
                <CommonColumnsView
                    tableColumns={techniciansColumns}
                    visibleTableColumns={visibleTechniciansColumns}
                    onChangeTableColumn={(value) => dispatch(userAction.setTechniciansColumns(value))}
                />
            )

        case USER_ROLE.SUPERVISOR:
            return (
                <CommonColumnsView
                    tableColumns={supervisorsColumns}
                    visibleTableColumns={visibleSupervisorsColumns}
                    onChangeTableColumn={(value) => dispatch(userAction.setSupervisorsColumns(value))}
                />
            )

        case USER_ROLE.ADMIN:
            return (
                <CommonColumnsView
                    tableColumns={adminsColumns}
                    visibleTableColumns={visibleAdminsColumns}
                    onChangeTableColumn={(value) => dispatch(userAction.setAdminsColumns(value))}
                />
            )
        case USER_ROLE.SUPERADMIN:
            return (
                <CommonColumnsView
                    tableColumns={adminsColumns}
                    visibleTableColumns={visibleSuperAdminsColumns}
                    onChangeTableColumn={(value) => dispatch(userAction.setSuperAdminsColumns(value))}
                />
            )

        default:
            return <></>
    }
}

export default UsersColumns
