import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector, useInfinityScroll } from 'hooks/index'
import { VENDOR_PER_PAGE, VendorType } from 'slices/inventoryItems'
import { CommonOtherResponseType, NOTIFICATION_STATUS } from 'types'
import { getVendorsForHook } from 'api/partsInventory'
import sendNotification from 'lib/notification'
import { currentUserSelect } from 'store/selectors'

type UseVendorType = {
    typeIdes?: string[]
    categoryIdes?: string[]
    isAllow?: boolean
    limit?: number
}

export const useVendors = ({ typeIdes, categoryIdes, isAllow = true, limit = VENDOR_PER_PAGE }: UseVendorType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const [isLoading, setIsLoading] = useState(false)
    const [vendors, setVendors] = useState<VendorType[]>([])
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)

    const { setLastElement } = useInfinityScroll({
        fetchNextData: () => setPage(page + 1),
        hasMore: vendors.length < count,
    })

    const getVendors = async () => {
        setIsLoading(true)
        try {
            const res: CommonOtherResponseType<VendorType> = await dispatch(
                getVendorsForHook({
                    typeIdes: typeIdes?.length ? typeIdes : undefined,
                    categoryIdes: categoryIdes?.length ? categoryIdes : undefined,
                    limit,
                    offset: limit * (page - 1),
                }),
            ).unwrap()

            if (res) {
                setVendors(page === 1 ? res?.payload : [...vendors, ...res.payload])
                setCount(res?.count)
            }
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!user || !isAllow) {
            return
        }
        getVendors()
    }, [user, page, JSON.stringify(typeIdes), JSON.stringify(categoryIdes), isAllow])

    return { vendors, isLoading, count, setLastElement, getVendors }
}
