import { Route, Routes, useNavigate } from 'react-router-dom'
import React, { lazy } from 'react'
import { useAppSelector } from '../hooks'
import { permissionsUser } from '../store/selectors'
import { ROUTES } from './const'

const AssetProfile = lazy(() => import('pages/AssetProfile'))
const NewAssetPage = lazy(() => import('pages/NewAssetPage'))
const AssetsPage = lazy(() => import('pages/AssetsPage'))

const AssetsRoutes = () => {
    const navigate = useNavigate()
    const { isRequestor } = useAppSelector(permissionsUser)

    if (isRequestor) {
        navigate(ROUTES.DASHBOARD)
    }

    return (
        <Routes>
            <Route index element={<AssetsPage />} />
            <Route path="/:id" element={<AssetProfile />} />
            <Route path="/new-asset" element={<NewAssetPage />} />
        </Routes>
    )
}

export default AssetsRoutes
