import { dropInLS, getSettingsFromLS, LsKeys } from 'lib/workWithLS'
import { CommonSortType, DirectionEnum, ListViewMode } from 'types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TableColumnType } from 'components/Table'
import { extraReducers } from './extraReducers'
import { CurrentTeamType, TeamFiltersType, TeamsState } from './types'
import { initialTeamColumns } from 'pages/WorkOrdersPage/components/WorkOrdersTeams/config'
import { TeamProfileTab } from 'pages/TeamProfile/config'

const initialState: TeamsState = {
    currentTeam: {} as CurrentTeamType,
    teams: [],
    isWaitingForResponse: false,
    teamProfileTab: TeamProfileTab.DETAILS,
    teamPaginationPage: 1,
    teamsCount: 0,
    teamsSearchValue: '',
    teamsSettings: {
        filters: getSettingsFromLS('filters', LsKeys.TEAMS_SETTINGS, null),
        columns: getSettingsFromLS('columns', LsKeys.TEAMS_SETTINGS, initialTeamColumns),
        listMode: getSettingsFromLS('listMode', LsKeys.TEAMS_SETTINGS, ListViewMode.CARD),
        sortField: getSettingsFromLS('sortField', LsKeys.TEAMS_SETTINGS, 'id'),
        sortDirection: getSettingsFromLS('sortDirection', LsKeys.TEAMS_SETTINGS, DirectionEnum.ASC),
    },
    assignedTeams: [],
}

export const teamSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        clearCurrentTeam: (state) => {
            state.currentTeam = initialState.currentTeam
        },
        clearTeamSettings: (state) => {
            state.teamPaginationPage = initialState.teamPaginationPage
            state.teamsSearchValue = initialState.teamsSearchValue
        },
        setTeamProfileTab: (state, action: PayloadAction<TeamProfileTab>) => {
            state.teamProfileTab = action.payload
        },
        setTeamPaginationPage: (state, action: PayloadAction<number>) => {
            state.teamPaginationPage = action.payload
        },
        setTeamsSearchValue: (state, action: PayloadAction<string>) => {
            state.teamsSearchValue = action.payload
        },
        setIsWaitingForResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingForResponse = action.payload
        },

        //settings
        setTeamsColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.TEAMS_SETTINGS, {
                columns: action.payload,
            })

            state.teamsSettings.columns = action.payload
        },
        setTeamsFilters: (state, action: PayloadAction<TeamFiltersType | null>) => {
            dropInLS(LsKeys.TEAMS_SETTINGS, { filters: action.payload })
            state.teamsSettings.filters = action.payload
        },
        setTeamListMode: (state, action: PayloadAction<ListViewMode>) => {
            dropInLS(LsKeys.TEAMS_SETTINGS, { listMode: action.payload })
            state.teamsSettings.listMode = action.payload
        },
        setTeamSort: (state, action: PayloadAction<CommonSortType>) => {
            const checkIsNewSortField = state.teamsSettings.sortField !== action.payload.sortField

            dropInLS(LsKeys.TEAMS_SETTINGS, {
                sortField: action.payload.sortField,
                sortDirection: checkIsNewSortField ? DirectionEnum.ASC : action.payload.sortDirection,
            })
            state.teamsSettings.sortField = action.payload.sortField
            state.teamsSettings.sortDirection = checkIsNewSortField ? DirectionEnum.ASC : action.payload.sortDirection
        },
    },
    extraReducers,
})

export const {
    clearCurrentTeam,
    clearTeamSettings,
    setTeamsColumns,
    setTeamsFilters,
    setTeamSort,
    setTeamPaginationPage,
    setTeamsSearchValue,
    setTeamListMode,
    setIsWaitingForResponse,
    setTeamProfileTab,
} = teamSlice.actions

export const TeamReducer = teamSlice.reducer
