import styles from './styles.module.scss'
import { NotificationType } from './types'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import dayjs from 'dayjs'
import classNames from 'classnames'
import Badge from '@mui/material/Badge'
import { getSvgNotification } from 'assets/notifications-icons'
import TooltipMenu, { TooltipMenuItem } from '../../../TooltipMenu'
import Icons from 'assets/icons'
import HideShowEye from '../../../HideShowEye'
import React from 'react'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import { useAppDispatch } from 'hooks'
import { deleteNotification, updateNotificationStatus } from 'api/notifications'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'routes/const'

type Props = {
    notification: NotificationType
    update: () => Promise<void>
    onClose: () => void
}

export enum NotificationGroup {
    SYSTEM = 'System',
    SUPPORT = 'Support',
    USER_MANAGEMENT = 'User management',
    BUILDINGS = 'Buildings',
    WORK_ORDERS = 'Work Orders',
    PM = 'PM',
    INVENTORY = 'Inventory',
    EMERGENCY = 'Emergency',
    FLOOR_PLAN = 'Floor Plan',
    ASSET = 'Asset',
}
const getPatchByGroup = ({ group, link, body }: NotificationType) => {
    switch (group) {
        case NotificationGroup.WORK_ORDERS:
        case NotificationGroup.EMERGENCY:
            return link ? `${ROUTES.WORK_ORDERS}/${link}` : ROUTES.WORK_ORDERS
        case NotificationGroup.PM:
            if (body.includes('scheduled today')) {
                return `${ROUTES.CALENDAR}/day`
            }
            return link ? `${ROUTES.PMs}/${link}` : ROUTES.PMs
        case NotificationGroup.INVENTORY:
            if (body.includes('purchase request')) {
                return `${ROUTES.PARTS_INVENTORY}?tab=PURCHASE_REQUESTS`
            }
            return link ? `${ROUTES.PARTS_INVENTORY}?${link}` : ROUTES.PARTS_INVENTORY
        case NotificationGroup.ASSET:
            return link ? `${ROUTES.ASSETS}/${link}` : ROUTES.ASSETS
        case NotificationGroup.BUILDINGS:
            if (body.includes('Tenant')) {
                return `${ROUTES.TENANTS}/${link}`
            }
            return link ? `${ROUTES.BUILDINGS}/${link}` : ROUTES.BUILDINGS
        case NotificationGroup.USER_MANAGEMENT:
            return link ? `${ROUTES.USERS}/user/${link}` : ROUTES.USERS
        /*case NotificationGroup.SUPPORT:
            return ROUTES.SUPPORT*/
        /*case NotificationGroup.FLOOR_PLAN:
            return link ? `${ROUTES.FLOORS}/${link}/plans/${link}` : ROUTES.BUILDINGS*/

        default:
            return '#'
    }
}

const NotificationItem = ({ notification, update, onClose }: Props) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const handleChangeStatus = async () => {
        try {
            await dispatch(updateNotificationStatus(notification.id)).unwrap()
            await update()
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }

    const handleClickNotification = async () => {
        if (!notification.isViewed) {
            await handleChangeStatus()
        }
        onClose()
        navigate(getPatchByGroup(notification))
    }

    const handleDeleteNotification = async () => {
        try {
            await dispatch(deleteNotification(notification.id))
            await update()
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }

    return (
        <div
            className={classNames(styles.notificationRoot, notification.isViewed && styles.viewed)}
            onClick={(e) => {
                e.stopPropagation()
                handleClickNotification()
            }}
        >
            <div className={styles.notificationTitle}>
                <Badge
                    variant="dot"
                    color="error"
                    badgeContent={notification.isViewed ? 0 : 1}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <img alt="notification" src={notification.icon?.url || getSvgNotification('')} />
                </Badge>
                <div>
                    <Paragraph size="s" weight="semi-bold">
                        {notification.title}
                    </Paragraph>
                    <Paragraph size="s" weight="tiny" color="grey">
                        {dayjs(notification.createdAt).format('MM/DD/YY, hh:mm A')}
                    </Paragraph>
                </div>

                <TooltipMenu>
                    <TooltipMenuItem
                        icon={<HideShowEye isHidden={!notification.isViewed} color="#848A9B" />}
                        title={notification.isViewed ? 'Mark as unread' : 'Mark as read'}
                        onClick={handleChangeStatus}
                    />
                    {notification.isViewed && (
                        <TooltipMenuItem icon={<Icons.Trash />} title="Delete" onClick={handleDeleteNotification} />
                    )}
                </TooltipMenu>
            </div>

            <Paragraph size="s" weight="tiny">
                {notification.body}
            </Paragraph>
        </div>
    )
}

export default NotificationItem
