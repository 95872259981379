import React, { useMemo } from 'react'
import { getSvgImageTypeWo } from 'assets/workOrders-icons'
import { PriorityWO } from 'components/NewWorkOrder/config'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import WorkOrderBadge from 'pages/CalendarPage/components/WorkOrderBadge'
import { WorkOrderInInventoryType } from 'slices/inventoryItems'
import cn from 'classnames'

import s from './styles.module.scss'

type PropsType = {
    workOrder?: WorkOrderInInventoryType | null
}

export const SmallWorkOrderCArd = ({ workOrder }: PropsType) => {
    const styleCard = useMemo(() => {
        switch (workOrder?.priority) {
            case PriorityWO.CRITICAL:
                return s.critical
            case PriorityWO.HIGH:
                return s.high
            case PriorityWO.MEDIUM:
                return s.medium
            case PriorityWO.LOW:
                return s.low
            case PriorityWO.EMERGENCY:
                return s.critical
            case PriorityWO.SCHEDULED:
                return s.scheduled
            case PriorityWO.SCHEDULED_PM:
                return s.scheduled

            default:
                return s.scheduled
        }
    }, [workOrder])

    if (!workOrder) {
        return null
    }

    return (
        <div className={cn(s.root, styleCard)}>
            <div className={s.headerCard}>
                <div className={s.cardNameWrap}>
                    <img src={getSvgImageTypeWo(workOrder.type)} alt="type" />

                    <Paragraph size="s" weight="semi-bold">
                        {workOrder.title}
                    </Paragraph>
                </div>
                <WorkOrderBadge status={workOrder.priority} size={12} />
            </div>

            {workOrder.description && (
                <Paragraph size="xs" weight="semi-bold">
                    {workOrder.description}
                </Paragraph>
            )}
        </div>
    )
}
