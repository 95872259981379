import styles from './HideShowEye.module.scss'
import SvgSelector from '../../assets/SvgSelector'
import React, { MouseEvent } from 'react'

type Props = {
    isHidden: boolean
    onChangeVisibility?: (visible: boolean) => void
    color?: string
    colorHidden?: string
}
const HideShowEye = ({ isHidden, onChangeVisibility, color, colorHidden }: Props) => {
    const handleChangeVisibility = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        onChangeVisibility && onChangeVisibility(!isHidden)
    }
    return (
        <>
            {isHidden ? (
                <div className={styles.passwordIcon} onClick={handleChangeVisibility}>
                    <SvgSelector id={'passwordHiddenIcon'} color={colorHidden || color} />
                </div>
            ) : (
                <div className={styles.passwordIcon} onClick={handleChangeVisibility}>
                    <SvgSelector id={'passwordFreeIcon'} color={color} />
                </div>
            )}
        </>
    )
}

export default HideShowEye
