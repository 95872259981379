import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import { FloorsState } from './FloorPlanViewer'
import {
    deleteFloorPlan,
    getPagesPlanByRootId,
    getPlanById,
    getPlansListByFloorId,
    getPlansTypesList,
    renameFloorPlan,
} from '../../api/plans'
import {
    Asset,
    AssetCategory,
    AssetPoint,
    AssetType,
    Floor,
    FloorPlan,
    PagesPlan,
    Room,
    RoomPoint,
    TypePlan,
} from './types'
import {
    getAssetsByCategoryAndTypes,
    getAssetTypesListByCategory,
    getCategoriesList,
    getFloorsListByBuildingId,
    getRoomsListByFloorId,
} from '../../api/floors'
import sendNotification from '../../lib/notification'
import { NOTIFICATION_STATUS } from '../../types'
import {
    createObjectPoint,
    createPoint,
    getAssetPointsByPageId,
    getObjectPointsByPageId,
    updateObjectPoint,
    updatePoint,
} from '../../api/points'
import { createRoom, createRoomPoint, getRoomsIdesFromAffectedAreaAsset, getRoomsPointsByPageId } from '../../api/rooms'
import { deleteObjectOrGroup } from 'api/objects'
import { createAffectedArea } from 'api/assets'

const extraReducers = (builder: ActionReducerMapBuilder<FloorsState>) => {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get current plan
    builder.addCase(getPlanById.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getPlanById.fulfilled, (state, action: PayloadAction<FloorPlan>) => {
        state.isWaitingForResponse = false

        state.currentPlan = action.payload
        state.selectedFloorId = action.payload.floorId
    })
    builder.addCase(getPlanById.rejected, (state, action) => {
        state.isWaitingForResponse = false
    })

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get pages
    builder.addCase(getPagesPlanByRootId.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getPagesPlanByRootId.fulfilled, (state, action: PayloadAction<PagesPlan>) => {
        state.isWaitingForResponse = false
        state.pagesPlan = action.payload
    })
    builder.addCase(getPagesPlanByRootId.rejected, (state, action) => {
        state.isWaitingForResponse = false
    })

    //get floors list
    builder.addCase(getFloorsListByBuildingId.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getFloorsListByBuildingId.fulfilled, (state, action: PayloadAction<{ rows: Array<Floor> }>) => {
        state.isWaitingForResponse = false

        state.floors = action.payload.rows
        state.selectedFloor = action.payload.rows.find((item) => item.id === state.selectedFloorId) || null
    })
    builder.addCase(getFloorsListByBuildingId.rejected, (state, action) => {
        state.isWaitingForResponse = false
    })

    //get plans list
    builder.addCase(getPlansListByFloorId.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getPlansListByFloorId.fulfilled, (state, action: PayloadAction<{ plans: Array<FloorPlan> }>) => {
        state.isWaitingForResponse = false
        state.plansByFloor = action.payload.plans
    })
    builder.addCase(getPlansListByFloorId.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get categories list
    builder.addCase(getCategoriesList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getCategoriesList.fulfilled,
        (state, action: PayloadAction<{ categories: Array<AssetCategory> }>) => {
            state.isWaitingForResponse = false
            state.categories = action.payload.categories
        },
    )
    builder.addCase(getCategoriesList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get types categories list
    builder.addCase(getAssetTypesListByCategory.pending, (state) => {
        state.assetTypeLoading = true
    })
    builder.addCase(
        getAssetTypesListByCategory.fulfilled,
        (state, action: PayloadAction<{ categoryId: string; types: Array<AssetType> }>) => {
            state.assetTypeLoading = false

            state.typeAssetThree = {
                ...state.typeAssetThree,
                [action.payload.categoryId]: action.payload.types.filter((item) => item.assetsCount !== 0),
            }
        },
    )
    builder.addCase(getAssetTypesListByCategory.rejected, (state, action) => {
        state.assetTypeLoading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get assets list
    builder.addCase(getAssetsByCategoryAndTypes.pending, (state) => {
        state.assetsLoading = true
    })
    builder.addCase(getAssetsByCategoryAndTypes.fulfilled, (state, action: PayloadAction<{ assets: Array<Asset> }>) => {
        state.assetsLoading = false

        const assets = action.payload.assets

        if (state.searchStringAssets.length > 0) {
            state.searchAssets = assets
        } else {
            state.searchAssets = []

            if (assets.length > 0) {
                const typeId = assets[0].types.id

                state.assetThree = {
                    ...state.assetThree,
                    [typeId]: assets,
                }
            }
        }
    })
    builder.addCase(getAssetsByCategoryAndTypes.rejected, (state, action) => {
        state.assetsLoading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //create room
    builder.addCase(createRoom.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createRoom.fulfilled, (state, action: PayloadAction<Room>) => {
        state.isWaitingForResponse = false
        state.rooms = [...state.rooms, action.payload]

        sendNotification('The Room has been added!', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(createRoom.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //create point
    builder.addCase(createPoint.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createPoint.fulfilled, (state, action: PayloadAction<Room>) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(createPoint.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //create object point
    builder.addCase(createObjectPoint.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createObjectPoint.fulfilled, (state, action: PayloadAction<Room>) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(createObjectPoint.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //update point
    builder.addCase(updatePoint.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(updatePoint.fulfilled, (state, action: PayloadAction<Room>) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(updatePoint.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //delete object or group
    builder.addCase(deleteObjectOrGroup.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteObjectOrGroup.fulfilled, (state, action: PayloadAction<Room>) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(deleteObjectOrGroup.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //update object point
    builder.addCase(updateObjectPoint.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(updateObjectPoint.fulfilled, (state, action: PayloadAction<Room>) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(updateObjectPoint.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get rooms list
    builder.addCase(getRoomsListByFloorId.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getRoomsListByFloorId.fulfilled, (state, action: PayloadAction<{ rows: Array<Room> }>) => {
        state.isWaitingForResponse = false
        state.rooms = action.payload.rows
    })
    builder.addCase(getRoomsListByFloorId.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get rooms affected area list
    builder.addCase(getRoomsIdesFromAffectedAreaAsset.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getRoomsIdesFromAffectedAreaAsset.fulfilled,
        (state, action: PayloadAction<{ payload: Array<string> }>) => {
            state.isWaitingForResponse = false
            state.roomsAffectedAreasIds = action.payload.payload
        },
    )
    builder.addCase(getRoomsIdesFromAffectedAreaAsset.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //plans types
    builder.addCase(getPlansTypesList.pending, (state) => {
        state.isWaitingForResponse = true
    })

    builder.addCase(
        getPlansTypesList.fulfilled,
        (state, action: PayloadAction<{ count: number; planTypes: Array<TypePlan> }>) => {
            state.planTypes = action.payload.planTypes
            state.isWaitingForResponse = false
        },
    )
    builder.addCase(getPlansTypesList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong›. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //plans assets
    builder.addCase(getAssetPointsByPageId.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getAssetPointsByPageId.fulfilled,
        (state, action: PayloadAction<{ count: number; points: Array<AssetPoint> }>) => {
            state.isWaitingForResponse = false
            state.assetsPoints = action.payload.points
        },
    )
    builder.addCase(getAssetPointsByPageId.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //plans objects
    builder.addCase(getObjectPointsByPageId.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getObjectPointsByPageId.fulfilled,
        (state, action: PayloadAction<{ count: number; payload: Array<AssetPoint> }>) => {
            state.isWaitingForResponse = false
            state.objectsPoints = action.payload.payload
        },
    )
    builder.addCase(getObjectPointsByPageId.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //rooms points
    builder.addCase(getRoomsPointsByPageId.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getRoomsPointsByPageId.fulfilled, (state, action: PayloadAction<{ rooms: Array<RoomPoint> }>) => {
        state.isWaitingForResponse = false
        state.roomsPoints = action.payload.rooms

        // const rooms = action.payload.rooms
        //
        // const three = {} as RoomsPointsThree
        // if (rooms && rooms.length > 0) {
        //     action.payload.rooms.forEach((room) => {
        //         three[room.id] = { ...room, points: [] }
        //     })
        //
        //     state.roomsPointsThree = three
        // }
    })
    builder.addCase(getRoomsPointsByPageId.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //create rooms points
    builder.addCase(createRoomPoint.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createRoomPoint.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(createRoomPoint.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //rename floorPlan
    builder.addCase(renameFloorPlan.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(renameFloorPlan.fulfilled, (state, action: PayloadAction<{ name: string }>) => {
        state.isWaitingForResponse = false
        if (state.currentPlan) {
            state.currentPlan.name = action.payload.name
        }
    })
    builder.addCase(renameFloorPlan.rejected, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
}

export default extraReducers
