export enum ROUTES {
    USERS = '/users',
    CUSTOMERS = '/manage-customers',
    BUILDINGS = '/buildings',
    CALENDAR = '/calendar',
    ASSETS = '/assets',
    EMERGENCY = '/emergency',
    WORK_ORDERS = '/work-orders',
    PMs = '/preventative-maintenance',
    SUBCONTRACTORS = '/subcontractors',
    TEAMS = '/teams',
    TENANTS = '/tenants',
    DASHBOARD = '/dashboard',
    SUPPORT = '/support',
    PROFILE = '/profile',
    FLOORS = '/floors',
    PARTS_INVENTORY = '/parts-and-inventory',
    SIGN_OUT = '/sign-out',
}
