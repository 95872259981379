import { Route, Routes, useNavigate } from 'react-router-dom'
import React, { lazy } from 'react'
import { useAppSelector } from '../hooks'
import { permissionsUser } from '../store/selectors'
import { ROUTES } from './const'

const CustomersPage = lazy(() => import('pages/CustomersPage'))
const CustomerProfile = lazy(() => import('../pages/CustomersPage/components/CustomerProfile'))

const CustomersRoutes = () => {
    const navigate = useNavigate()
    const { isSuperAdmin } = useAppSelector(permissionsUser)

    if (!isSuperAdmin) {
        navigate(ROUTES.DASHBOARD)
    }

    return (
        <Routes>
            <Route index element={<CustomersPage />} />
            <Route path="/new-customer" element={<CustomersPage />} />
            <Route path=":id/*" element={<CustomerProfile />} />
        </Routes>
    )
}

export default CustomersRoutes
