export enum LsKeys {
    TOKEN = 'fm-pro.token',
    REFRESH = 'fm-pro.refreshToken',
    IS_AUTH = 'fm-pro.isAuth',
    IS_EXPAND_NAVBAR = 'fm-pro.isExpandNavBar',
    ASSETS_SETTINGS = 'fm-pro.assets-settings',
    WORK_ORDERS_SETTINGS = 'fm-pro.work-orders-settings',
    CUSTOMERS_SETTINGS = 'fm-pro.customers-settings',
    SUBCONTRACTORS_SETTINGS = 'fm-pro.subcontractors-settings',
    TEAMS_SETTINGS = 'fm-pro.teams-settings',
    EMERGENCY_PLAN_SETTINGS = 'fm-pro.emergency-plan-settings',
    EMERGENCY_CONTACTS_SETTINGS = 'fm-pro.emergency-contacts-settings',
    EMERGENCY_INCIDENTS_SETTINGS = 'fm-pro.emergency-incidents-settings',
    PM_SETTINGS = 'fm-pro.preventative-maintenance-settings',
    USER_REQUESTER_SETTINGS = 'fm-pro.user-requester-settings',
    USER_TECHNICIANS_SETTINGS = 'fm-pro.user-technicians-settings',
    USER_SUPERVISORS_SETTINGS = 'fm-pro.user-supervisors-settings',
    USER_ADMINS_SETTINGS = 'fm-pro.user-admins-settings',
    USER_SUPER_ADMINS_SETTINGS = 'fm-pro.user-super-admins-settings',
    TENANTS_SETTINGS = 'fm-pro.tenants-settings',
    INVENTORY_ITEMS_SETTINGS = 'fm-pro.inventory-items-settings',
    VENDORS_SETTINGS = 'fm-pro.vendors-settings',
    PURCHASE_REQUESTS_SETTINGS = 'fm-pro.purchase-requests-settings',
    CALENDAR = 'fm-pro.calendar',
}

export function saveState<T>(key: string, state: T) {
    const stateAsString = JSON.stringify(state)

    localStorage.setItem(key, stateAsString)
}

export function restoreState(key: string) {
    const stateAsString = localStorage.getItem(key)

    if (stateAsString !== undefined && stateAsString !== null) {
        return JSON.parse(stateAsString)
    }

    return null
}

export const dropInLS = <T>(namePage: string, value: T) => {
    const settings: string | null = localStorage.getItem(namePage)

    if (settings) {
        const allSettings = JSON.parse(settings)

        localStorage.setItem(namePage, JSON.stringify({ ...allSettings, ...value }))
    } else {
        localStorage.setItem(namePage, JSON.stringify(value))
    }
}

export const getSettingsFromLS = <T>(key: string, namePage: string, defaultValue: T) => {
    const localSettings: string | null = localStorage.getItem(namePage)

    if (localSettings) {
        const settings = JSON.parse(localSettings)

        if (settings) {
            if (Object.keys(settings).includes(key)) {
                return settings[key]
            }
        }
    }

    return defaultValue
}
