import styles from './styles.module.scss'
import { useAppDispatch } from 'hooks'
import { getNotificationsList, readAllNotifications } from 'api/notifications'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import React, { useCallback, useEffect, useState } from 'react'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { NotificationType } from './types'
import NotificationItem from './NotificationItem'
import { TextButton } from 'ui-kit/components/Button'
import { CircularProgress } from '@mui/material'

type Props = {
    count: number
    open: boolean
    updateCount: () => Promise<void>
    onClose: () => void
}

const Notifications = ({ count, open, updateCount, onClose }: Props) => {
    const dispatch = useAppDispatch()
    const [notifications, setNotifications] = useState<Array<NotificationType>>([])
    const [isLoading, setIsLoading] = useState(false)

    const getNotificationsData = useCallback(async () => {
        setIsLoading(true)
        try {
            const res = await dispatch(getNotificationsList({})).unwrap()
            await updateCount()

            setNotifications(res.payload)
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading(false)
        }
    }, [])

    const handleReadAll = async () => {
        try {
            await dispatch(readAllNotifications()).unwrap()
            await getNotificationsData()
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }

    useEffect(() => {
        if (open) {
            ;(async () => {
                await getNotificationsData()
            })()
        }
    }, [open, getNotificationsData])

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <Paragraph size="md" weight="semi-bold">
                    Notifications
                </Paragraph>
                {count > 0 && (
                    <TextButton
                        text="Read All"
                        colorText="#1B6BC0"
                        onClick={(e) => {
                            e.stopPropagation()
                            void handleReadAll()
                        }}
                    />
                )}
            </div>

            <div className={styles.notificationList}>
                {isLoading && <CircularProgress color="inherit" size={20} className={styles.loading} />}
                {notifications.map((notification) => (
                    <NotificationItem notification={notification} update={getNotificationsData} onClose={onClose} />
                ))}
            </div>
        </div>
    )
}

export default Notifications
