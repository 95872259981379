import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../../../hooks'
import { TeamType } from '../../../../../../slices/team'
import { CardListContainer } from '../../../../../../components/CardsContentView'
import CardItemTeam from './CardItemTeam'

export const TeamCard = () => {
    const navigate = useNavigate()

    const teams = useAppSelector(({ team }) => team.teams)

    const handleClickTeam = (team: TeamType) => {
        const path = generatePath('/teams/:id', { id: team.id })
        navigate(path)
    }

    return (
        <CardListContainer>
            {teams.map((team) => (
                <CardItemTeam team={team} handleClickTeam={handleClickTeam} />
            ))}
        </CardListContainer>
    )
}
