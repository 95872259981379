import { MeType } from '../../slices/AuthSlice'
import { PermissionsUser } from '../../slices/users'
import { USER_ROLE, USER_TYPE } from '../../types'

export const checkRolePermission = (user: MeType): PermissionsUser => {
    const role = user.role
    const type = user.type

    const isAdmin = role === USER_ROLE.ADMIN
    const isSuperAdmin = role === USER_ROLE.SUPERADMIN
    const isTechnician = role === USER_ROLE.TECHNICIAN
    const isRequestor = role === USER_ROLE.REQUESTOR
    const isSupervisor = role === USER_ROLE.SUPERVISOR
    const isFmPro = role === USER_ROLE.FMPRO

    const isMain = type === USER_TYPE.MAIN

    return {
        isAdmin,
        isRequestor,
        isSupervisor,
        isTechnician,
        isSuperAdmin,
        isMain,
        isFmPro,
    }
}
