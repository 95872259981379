import {
    CurrentInventoryDetailType,
    CurrentPurchaseRequestType,
    CurrentVendorType,
    InventoryItemProfileTab,
    InventoryItemsFiltersType,
    InventoryItemsStateType,
    VendorsFiltersType,
} from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { extraReducers } from './extraReducers'
import { dropInLS, getSettingsFromLS, LsKeys } from 'lib/workWithLS'
import {
    initialInventoryColumns,
    initialPurchaseRequestColumns,
    initialVendorColumns,
    InventoryItemsListTab,
} from 'pages/InventoryItemsPage/config'
import { CommonSortType, DirectionEnum, ListViewMode } from 'types'

const initialState: InventoryItemsStateType = {
    currentInventoryItem: {} as CurrentInventoryDetailType,
    inventoryItems: [],

    inventoryItemsListTab: InventoryItemsListTab.ALL_INVENTORY,
    inventoryItemProfileTab: InventoryItemProfileTab.CURRENT_STOCK,
    isWaitingForResponse: false,

    inventoryItemsPaginationPage: 1,
    inventoryItemsCount: 0,
    inventoryItemsSearchValue: '',

    inventoryItemsSettings: {
        filters: getSettingsFromLS('filters', LsKeys.INVENTORY_ITEMS_SETTINGS, { hasQuantity: true }),
        columns: initialInventoryColumns,
        currentViewId: getSettingsFromLS('currentViewId', LsKeys.INVENTORY_ITEMS_SETTINGS, ''),
        currentViewTitle: getSettingsFromLS('currentViewTitle', LsKeys.INVENTORY_ITEMS_SETTINGS, ''),
        listMode: ListViewMode.TABLE,
        isSystemCurrentView: getSettingsFromLS('isSystemCurrentView', LsKeys.INVENTORY_ITEMS_SETTINGS, false),
        sortField: getSettingsFromLS('sortField', LsKeys.INVENTORY_ITEMS_SETTINGS, 'category'),
        sortDirection: getSettingsFromLS('sortDirection', LsKeys.INVENTORY_ITEMS_SETTINGS, DirectionEnum.ASC),
    },

    currentVendor: {} as CurrentVendorType,
    vendors: [],

    vendorsPaginationPage: 1,
    vendorsCount: 0,
    vendorsSearchValue: '',

    vendorsSettings: {
        filters: getSettingsFromLS('filters', LsKeys.VENDORS_SETTINGS, null),
        columns: initialVendorColumns,
        listMode: ListViewMode.TABLE,
        sortField: getSettingsFromLS('sortField', LsKeys.VENDORS_SETTINGS, 'id'),
        sortDirection: getSettingsFromLS('sortDirection', LsKeys.VENDORS_SETTINGS, DirectionEnum.ASC),
    },

    currentPurchaseRequest: {} as CurrentPurchaseRequestType,
    purchaseRequests: [],

    purchaseRequestsPaginationPage: 1,
    purchaseRequestsCount: 0,

    purchaseRequestsSettings: {
        filters: getSettingsFromLS('filters', LsKeys.PURCHASE_REQUESTS_SETTINGS, null),
        columns: initialPurchaseRequestColumns,
        listMode: ListViewMode.TABLE,
        sortField: getSettingsFromLS('sortField', LsKeys.PURCHASE_REQUESTS_SETTINGS, 'id'),
        sortDirection: getSettingsFromLS('sortDirection', LsKeys.PURCHASE_REQUESTS_SETTINGS, DirectionEnum.ASC),
    },
}

export const inventoryItemsSlice = createSlice({
    name: 'inventoryItems',
    initialState,
    reducers: {
        setIsWaitingForResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingForResponse = action.payload
        },

        //Inventory Items
        clearCurrentInventoryItem: (state) => {
            state.currentInventoryItem = initialState.currentInventoryItem
        },
        clearInventoryItemsSettings: (state) => {
            state.inventoryItemsPaginationPage = initialState.inventoryItemsPaginationPage
            state.inventoryItemsSearchValue = initialState.inventoryItemsSearchValue
        },
        setInventoryItemProfileTab: (state, action: PayloadAction<InventoryItemProfileTab>) => {
            state.inventoryItemProfileTab = action.payload
        },
        setInventoryItemsListTab: (state, action: PayloadAction<InventoryItemsListTab>) => {
            state.inventoryItemsListTab = action.payload
        },
        setInventoryItemPaginationPage: (state, action: PayloadAction<number>) => {
            state.inventoryItemsPaginationPage = action.payload
        },
        setInventoryItemsSearchValue: (state, action: PayloadAction<string>) => {
            state.inventoryItemsSearchValue = action.payload
            state.inventoryItemsPaginationPage = 1
        },
        //Inventory Items settings
        setCurrentInventoryItemView: (
            state,
            action: PayloadAction<{ id: string; title: string; isSystemView: boolean }>,
        ) => {
            dropInLS(LsKeys.INVENTORY_ITEMS_SETTINGS, {
                currentViewId: action.payload.id,
                currentViewTitle: action.payload.title,
                isSystemCurrentView: action.payload.isSystemView,
            })
            state.inventoryItemsSettings.currentViewId = action.payload.id
            state.inventoryItemsSettings.currentViewTitle = action.payload.title
            state.inventoryItemsSettings.isSystemCurrentView = action.payload.isSystemView
        },
        setInventoryItemsFilters: (state, action: PayloadAction<InventoryItemsFiltersType | null>) => {
            dropInLS(LsKeys.INVENTORY_ITEMS_SETTINGS, { filters: action.payload })
            state.inventoryItemsSettings.filters = action.payload
            state.inventoryItemsPaginationPage = 1
        },
        setInventoryItemSort: (state, action: PayloadAction<CommonSortType>) => {
            const checkIsNewSortField = state.inventoryItemsSettings.sortField !== action.payload.sortField

            dropInLS(LsKeys.INVENTORY_ITEMS_SETTINGS, {
                sortField: action.payload.sortField,
                sortDirection: checkIsNewSortField ? DirectionEnum.ASC : action.payload.sortDirection,
            })
            state.inventoryItemsSettings.sortField = action.payload.sortField
            state.inventoryItemsSettings.sortDirection = checkIsNewSortField
                ? DirectionEnum.ASC
                : action.payload.sortDirection
        },

        //Vendors
        clearCurrentVendor: (state) => {
            state.currentVendor = initialState.currentVendor
        },
        clearVendorsSettings: (state) => {
            state.vendorsPaginationPage = initialState.vendorsPaginationPage
            state.vendorsSearchValue = initialState.vendorsSearchValue
        },
        setVendorPaginationPage: (state, action: PayloadAction<number>) => {
            state.vendorsPaginationPage = action.payload
        },
        setVendorsSearchValue: (state, action: PayloadAction<string>) => {
            state.vendorsSearchValue = action.payload
            state.vendorsPaginationPage = 1
        },
        //Vendors settings
        setVendorsFilters: (state, action: PayloadAction<VendorsFiltersType | null>) => {
            dropInLS(LsKeys.VENDORS_SETTINGS, { filters: action.payload })
            state.vendorsSettings.filters = action.payload
            state.vendorsPaginationPage = 1
        },
        setVendorSort: (state, action: PayloadAction<CommonSortType>) => {
            const checkIsNewSortField = state.vendorsSettings.sortField !== action.payload.sortField

            dropInLS(LsKeys.VENDORS_SETTINGS, {
                sortField: action.payload.sortField,
                sortDirection: checkIsNewSortField ? DirectionEnum.ASC : action.payload.sortDirection,
            })
            state.vendorsSettings.sortField = action.payload.sortField
            state.vendorsSettings.sortDirection = checkIsNewSortField ? DirectionEnum.ASC : action.payload.sortDirection
        },

        //Purchase Request
        clearCurrentPurchaseRequest: (state) => {
            state.currentPurchaseRequest = initialState.currentPurchaseRequest
        },
        clearPurchaseRequestsSettings: (state) => {
            state.purchaseRequestsPaginationPage = initialState.purchaseRequestsPaginationPage
        },
        setPurchaseRequestPaginationPage: (state, action: PayloadAction<number>) => {
            state.purchaseRequestsPaginationPage = action.payload
        },
        //Purchase Requests settings
        setPurchaseRequestsFilters: (state, action: PayloadAction<VendorsFiltersType | null>) => {
            dropInLS(LsKeys.PURCHASE_REQUESTS_SETTINGS, { filters: action.payload })
            state.purchaseRequestsSettings.filters = action.payload
            state.purchaseRequestsPaginationPage = 1
        },
        setPurchaseRequestSort: (state, action: PayloadAction<CommonSortType>) => {
            const checkIsNewSortField = state.vendorsSettings.sortField !== action.payload.sortField

            dropInLS(LsKeys.PURCHASE_REQUESTS_SETTINGS, {
                sortField: action.payload.sortField,
                sortDirection: checkIsNewSortField ? DirectionEnum.ASC : action.payload.sortDirection,
            })
            state.purchaseRequestsSettings.sortField = action.payload.sortField
            state.purchaseRequestsSettings.sortDirection = checkIsNewSortField
                ? DirectionEnum.ASC
                : action.payload.sortDirection
        },
    },
    extraReducers,
})

export const {
    clearCurrentInventoryItem,
    clearInventoryItemsSettings,
    setInventoryItemProfileTab,
    setInventoryItemsListTab,
    setInventoryItemPaginationPage,
    setIsWaitingForResponse,
    setCurrentInventoryItemView,
    setInventoryItemsFilters,
    setInventoryItemSort,
    setVendorSort,
    clearCurrentVendor,
    clearVendorsSettings,
    setVendorsFilters,
    setVendorPaginationPage,
    setVendorsSearchValue,
    setPurchaseRequestsFilters,
    setPurchaseRequestPaginationPage,
    setPurchaseRequestSort,
    clearPurchaseRequestsSettings,
    clearCurrentPurchaseRequest,
    setInventoryItemsSearchValue,
} = inventoryItemsSlice.actions
export const InventoryItemsReducer = inventoryItemsSlice.reducer
