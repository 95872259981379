import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAllRegions } from 'api/regions'
import { RegionStateType } from './types'

const initialState: RegionStateType = {
    regions: [],
    isWaitingForResponse: false,
    searchRegionValue: '',
    regionPaginationPage: 1,
    regionsCount: 0,
}

export const regionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        setSearchRegionValue: (state, action: PayloadAction<string>) => {
            state.searchRegionValue = action.payload
            state.regionPaginationPage = 1
        },
        setRegionPaginationPage: (state, action: PayloadAction<number>) => {
            state.regionPaginationPage = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllRegions.pending, (state) => {
            state.isWaitingForResponse = true
        })
        builder.addCase(getAllRegions.fulfilled, (state, action) => {
            state.isWaitingForResponse = false
            state.regions = action.payload.rows
        })
        builder.addCase(getAllRegions.rejected, (state, action) => {
            state.isWaitingForResponse = false
        })
    },
})

export const { setSearchRegionValue, setRegionPaginationPage } = regionSlice.actions

export const RegionReducer = regionSlice.reducer
