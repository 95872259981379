import * as T from 'components/Table'

export enum SubcontractorKeysEnum {
    NAME = 'Company Name',
    CITY = 'City/State',
    REGION = 'Region',
    RESPONSIBILITIES = 'Responsibilities',
    AVAILABILITY = 'Availability',
    PHONE = 'Phone',
    ACTIVE_WO = 'Active WOs',
    ADDRESS = 'Address',
    ZIP = 'Zip Code',
    HOURS_OF_OPERATION = 'Hours of Operation',
    EMERGENCY_PHONE = 'Emergency Phone',
    LAST_UPDATED = 'Last Updated',
    TOTAL_WO_PERFORMED = 'Total WOs Performed',
    YTD_COST = 'YTD Cost',
    TOTAL_COST = 'Total Cost',
    APPROVED = 'Approved',
}

export const requiredSubcontractorsColumns: T.TableColumnType[] = [
    { label: SubcontractorKeysEnum.NAME, name: 'name', sort: true },
]
export const initialSubcontractorsColumns: T.TableColumnType[] = [
    { label: SubcontractorKeysEnum.CITY, name: 'city', sort: true },
    { label: SubcontractorKeysEnum.REGION, name: 'region', sort: false },
    { label: SubcontractorKeysEnum.RESPONSIBILITIES, name: 'responsibilities', sort: false },
    { label: SubcontractorKeysEnum.AVAILABILITY, name: 'availability', sort: true },
    { label: SubcontractorKeysEnum.PHONE, name: 'phone', sort: true },
    { label: SubcontractorKeysEnum.ACTIVE_WO, name: 'openWos', sort: false },
]
export const subcontractorsTableColumns: T.TableColumnType[] = initialSubcontractorsColumns.concat([
    { label: SubcontractorKeysEnum.ADDRESS, name: 'address', sort: true },
    { label: SubcontractorKeysEnum.ZIP, name: 'zip', sort: false },
    { label: SubcontractorKeysEnum.HOURS_OF_OPERATION, name: 'hoursOfOperation', sort: true },
    { label: SubcontractorKeysEnum.EMERGENCY_PHONE, name: 'emergencyPhone', sort: false },
    { label: SubcontractorKeysEnum.LAST_UPDATED, name: 'lastUpdate', sort: false },
    { label: SubcontractorKeysEnum.TOTAL_WO_PERFORMED, name: 'totalWoPerformed', sort: false },
    //{ label: SubcontractorKeysEnum.YTD_COST, name: 'ytdCost', sort: false },
    //{ label: SubcontractorKeysEnum.TOTAL_COST, name: 'totalCost', sort: false },
    { label: SubcontractorKeysEnum.APPROVED, name: 'approved', sort: false },
])

export const SUBCONTRACTORS_PER_PAGE = 50
