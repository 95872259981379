import { CompareKeyType, FiltersForReportsType } from 'components/CommonSavedView'
import { useAppSelector } from 'hooks/redux'
import { assetsFiltersSelect } from 'store/selectors'
import { removeBlankFields } from 'lib/common'
import { ItemForAutocompleteType } from 'components/CommonAutoComplete'

export type AssetReportsFiltersType = {
    customerId?: string
    regionId?: string
    buildingId?: string
    cost?: { [key in CompareKeyType]?: number }
    installDate?: { [key in CompareKeyType]?: Date }
    isCritical?: boolean
    hasPlans?: boolean
    paranoid?: boolean
    categoryId?: string[]
    typeId?: string[]
}

export const useAssetFiltersForReports = () => {
    const assetFilters = useAppSelector(assetsFiltersSelect)
    const filters: FiltersForReportsType<AssetReportsFiltersType>[] = []
    let filtersCount = 0

    if (assetFilters) {
        for (const [key, val] of Object.entries(removeBlankFields(assetFilters))) {
            if (key === 'regions' && val?.length) {
                filters.push({ regionId: val?.map((item: ItemForAutocompleteType) => item?.value) })
                filtersCount += 1
            }
            if (key === 'buildings' && val?.length) {
                filters.push({ buildingId: val?.map((item: ItemForAutocompleteType) => item?.value) })
                filtersCount += 1
            }
            if (key === 'minPrice') {
                filters.push({ cost: { '>=': val } })
            }
            if (key === 'maxPrice') {
                filters.push({ cost: { '<=': val } })
                filtersCount += 1
            }
            if (key === 'installDateFrom') {
                filters.push({ installDate: { '>=': val } })
            }
            if (key === 'installDateTo') {
                filters.push({ installDate: { '<=': val } })
                filtersCount += 1
            }
            if (key === 'isCritical') {
                filters.push({ isCritical: val })
                filtersCount += 1
            }
            if (key === 'hasPlan') {
                filters.push({ hasPlans: val })
                filtersCount += 1
            }
            if (key === 'isShowArchived') {
                filters.push({ paranoid: val })
                filtersCount += 1
            }
            if (key === 'assetCategoriesId') {
                filters.push({ categoryId: val })
                filtersCount += 1
            }
            if (key === 'assetTypesId') {
                filters.push({ typeId: val })
                filtersCount += 1
            }
        }
    }

    return { filters, filtersCount }
}
