import * as yup from 'yup'
import { MORE_ZERO, MUST_BE_NUMBER, REQUIRED_FIELD, TOO_LONG } from 'pages/NewAssetPage/config'
import { LengthConstrains, USER_ROLE } from 'types'
import { Asset } from 'slices/floorPlanViever'
import { ItemForAutocompleteType, yupAutocompleteItem } from 'components/CommonAutoComplete'
import { FREQUENCY } from 'pages/EmergencyPage/const'

export enum TypeWO {
    ACCESS_CONTROL = 'Access Control Request',
    AMENITY_SPACE_BOOKING = 'Amenity Space Booking',
    CORRECTIVE_MAINTENANCE = 'Corrective Maintenance',
    EMERGENCY = 'Emergency',
    EVENT_SUPPORT = 'Event Support',
    PROJECT = 'Project/Planning',
    RECURRING = 'Recurring',
    SERVICE_REQUEST = 'Service Request',
    PREVENTIVE_MAINTENANCE = 'Preventative Maintenance',
}

export enum DescriptionTypeWO {
    SERVICE_REQUEST = 'Report a problem, contact maintenance, or request technical assistance.Please make sure to outline the nature of the issue or need, the way in which the issue was discovered, and provide any notes, images or special instructions our technicians may need to take into consideration.',
    AMENITY_SPACE_BOOKING = 'Reserve a specific facility amenity, such as a meeting room or other publicly used space. Please make sure to include details such as the desired date and time, purpose of use, and any specific requirements or arrangements needed for the event or activity.',
    EVENT_SUPPORT = 'Seek assistance with all aspects of hosting an event. This may include requests for equipment setup, technical support, security, catering services, or additional staffing to ensure the smooth running of the event.',
}

export enum TypeAccessControlWO {
    INSTALLATION = 'Installation',
    MAINTENANCE = 'Maintenance',
    REPAIR = 'Repair',
    SETUP = 'Setup',
}

export enum WorkOrderStatus { // order is important
    NEW = 'New',
    IN_PROGRESS = 'In Progress',
    OPEN = 'Open', // this status only for render for not Requestor user role
    COMPLETED = 'Completed',
    CANCELLED = 'Cancelled',
    ON_HOLD = 'On Hold',
    PENDING_REVIEW = 'Pending Review',
}

export enum PmSubtype {
    RESTOCKING = 'Restocking ',
    SAFETY_CHECK = 'Health & Safety Check ',
    TENANT_CHECK = 'Tenant Check ',
    TESTING = 'Testing ',
}
export enum RecurringSubtype {
    ADJUSTMENTS = 'Adjustments/Calibrations',
    CLEANING = 'Cleaning',
    COMPLIANCE_VERIFICATION = 'Compliance Verification',
    EQUIPMENT_SERVICING = 'Equipment Servicing',
    FIRE_SYSTEM = 'Fire System Maintenance',
    INSPECTION = 'Inspection',
    PARTS_REPLACEMENT = 'Parts Replacement',
    PERFORMANCE_CHECK = 'Performance Check',
    RESTOCKING = 'Restocking',
    ROUTINE_SERVICE = 'Routine Maintenance',
    SAFETY_CHECK = 'Health & Safety Check',
    SOFTWARE_UPDATES = 'System/Software Updates',
    TENANT_CHECK = 'Tenant Check',
    TESTING = 'Testing',
    OTHER = 'Other',
}
export enum TypeServicesWO {
    CUSTODIAL = 'Custodial',
    ELECTRICAL = 'Electrical',
    HVAC_COLD = 'HVAC/Too Cold',
    HVAC_HOT = 'HVAC/Too Hot',
    IT_TECHNOLOGY = 'IT/Technology',
    LANDSCAPING = 'Landscaping',
    MAINTENANCE = 'Maintenance ', // need space, because TypeAccessControlWO includes same field
    PLUMBING = 'Plumbing',
    SECURITY = 'Security',
    SPACE_SETUP = 'Space Setup',
    OTHER = 'Other ', // need space, because RecurringSubtype includes same field
}

export enum PriorityWO {
    CRITICAL = 'Critical',
    HIGH = 'High',
    MEDIUM = 'Medium',
    LOW = 'Low',
    SCHEDULED = 'Scheduled',
    EMERGENCY = 'Emergency',
    SCHEDULED_PM = 'Scheduled PM',
}

export enum OnHoldReason {
    REVIEW_OPERATING = 'Asset is Critical – Review Operating Requirements',
    ASSIGN_SUBCONTRACTOR = 'Assign Subcontractor',
    RESOURCES_LACK = 'Lack of Resources',
    ADDITIONAL_INFORMATION = 'Need Additional Information',
    PRIORITIZATION = 'Prioritization of Other Tasks',
    SAFETY_CONCERNS = 'Safety Concerns',
    UNAVAILABLE_EQUIPMENT = 'Unavailable Equipment',
    WAITING_PARTS = 'Waiting for Parts',
    OTHER = 'Other',
}

export type NewWorkOrderType = {
    title: string
    description: string
    type: string
    subType?: string
    priority?: string
    specialInstructions?: string
    startDate?: Date | null
    expectedCompletionDate?: Date | null
    estimatedLaborHours?: number | null
    buildingId: string
    floorId?: string
    roomId?: string
    subcontractorId?: string
    sendDetailsToSubcontractor?: boolean
    assetsId?: string[]
    bucketsId?: string[]
    files?: File[]
    frequencyPM?: FREQUENCY
    plansId?: string[]
    startWorkOrder?: boolean
    //displayOnCalendars?: boolean
}

export type NewWorkOrderFormType = {
    title: string
    description: string
    type: string
    subType?: string
    priority?: string
    specialInstructions?: string
    startDate?: Date | null
    expectedCompletionDate?: Date | null
    estimatedLaborHours?: number | null
    regionId: ItemForAutocompleteType | null
    buildingId: ItemForAutocompleteType | null
    floorId?: ItemForAutocompleteType | null
    roomId?: ItemForAutocompleteType | null
    subcontractorId?: ItemForAutocompleteType | null
    sendDetailsToSubcontractor?: boolean
    assetsId?: Asset[]
    bucketsId?: ItemForAutocompleteType[] | null
    files?: File[]
    frequencyPM?: FREQUENCY
    plansId?: string[]
    mopsId?: string[]
    startWorkOrder?: boolean
    //displayOnCalendars?: boolean
}

export const newWorkOrderInitialValues: NewWorkOrderFormType = {
    title: '',
    description: '',
    type: '',
    subType: undefined,
    priority: PriorityWO.MEDIUM,
    specialInstructions: undefined,
    startDate: undefined,
    expectedCompletionDate: undefined,
    estimatedLaborHours: undefined,
    regionId: null,
    buildingId: null,
    floorId: null,
    roomId: null,
    subcontractorId: null,
    sendDetailsToSubcontractor: undefined,
    assetsId: undefined,
    bucketsId: [],
    files: undefined,
    frequencyPM: undefined,
    plansId: undefined,
    mopsId: undefined,
    startWorkOrder: undefined,
    //displayOnCalendars: undefined,
}

const requiredFields = {
    title: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required(REQUIRED_FIELD),
    description: yup.string().max(LengthConstrains.L_5000, TOO_LONG).trim().required(REQUIRED_FIELD),
    type: yup.string().max(LengthConstrains.L_50, TOO_LONG).required(REQUIRED_FIELD),
    priority: yup.string().max(LengthConstrains.L_50, TOO_LONG).required(REQUIRED_FIELD),
    subType: yup.string().when('type', ([type]) => {
        return [
            TypeWO.ACCESS_CONTROL,
            TypeWO.PREVENTIVE_MAINTENANCE,
            TypeWO.SERVICE_REQUEST,
            TypeWO.RECURRING,
        ].includes(type)
            ? yup.string().max(LengthConstrains.L_100, TOO_LONG).required(REQUIRED_FIELD)
            : yup.string().nullable()
    }),
    specialInstructions: yup.string().max(LengthConstrains.L_500, TOO_LONG).optional(),
}

export const newWorkOrderSchema = (userRole: USER_ROLE, isStartWoNow: boolean, isAddSubcontractor: boolean) => {
    if (userRole === USER_ROLE.REQUESTOR) {
        return yup.object().shape({
            ...requiredFields,
            buildingId: yupAutocompleteItem.required(REQUIRED_FIELD),
            floorId: yupAutocompleteItem.nullable(),
            roomId: yupAutocompleteItem.when('type', ([type]) => {
                return type === TypeWO.AMENITY_SPACE_BOOKING
                    ? yupAutocompleteItem.required(REQUIRED_FIELD)
                    : yupAutocompleteItem.nullable()
            }),
        })
    } else {
        return yup.object().shape({
            ...requiredFields,
            regionId: yupAutocompleteItem.when(() => {
                return userRole === USER_ROLE.ADMIN
                    ? yupAutocompleteItem.required(REQUIRED_FIELD)
                    : yup.string().nullable()
            }),
            buildingId: yupAutocompleteItem.required(REQUIRED_FIELD),
            floorId: yupAutocompleteItem.nullable(),
            roomId: yupAutocompleteItem.when('type', ([type]) => {
                return type === TypeWO.AMENITY_SPACE_BOOKING
                    ? yupAutocompleteItem.required(REQUIRED_FIELD)
                    : yupAutocompleteItem.nullable()
            }),
            frequencyPM: yup.string().when('type', ([type]) => {
                return type === TypeWO.RECURRING && isStartWoNow
                    ? yup.string().required(REQUIRED_FIELD)
                    : yup.string().nullable()
            }),
            startDate: yup.string().when('type', ([type]) => {
                return type === TypeWO.RECURRING && isStartWoNow
                    ? yup.string().max(LengthConstrains.L_100, TOO_LONG).required(REQUIRED_FIELD)
                    : yup.string().nullable()
            }),
            assetsId: yup.array().of(yup.object()).nullable(),
            bucketsId: yup.array().of(yupAutocompleteItem).nullable(),
            plansId: yup.array().of(yup.string()).optional(),
            mopsId: yup.array().of(yup.string()).optional(),
            expectedCompletionDate: yup.string().when('type', ([type]) => {
                return isStartWoNow && type !== TypeWO.RECURRING
                    ? yup.string().max(LengthConstrains.L_100, TOO_LONG).required(REQUIRED_FIELD)
                    : yup.string().nullable()
            }),
            estimatedLaborHours: yup.number().when('type', ([type]) => {
                return isStartWoNow && type !== TypeWO.RECURRING
                    ? yup
                          .number()
                          .typeError(MUST_BE_NUMBER)
                          .min(0, MORE_ZERO)
                          .max(1000, TOO_LONG)
                          .required(REQUIRED_FIELD)
                    : yup.number().nullable()
            }),
            subcontractorId: yupAutocompleteItem.when(() => {
                return isAddSubcontractor ? yupAutocompleteItem.required(REQUIRED_FIELD) : yup.string().nullable()
            }),
            sendDetailsToSubcontractor: yup.boolean().optional(),
        })
    }
}

export const getDescription = (value: TypeWO) => {
    switch (value) {
        case TypeWO.SERVICE_REQUEST: {
            return DescriptionTypeWO.SERVICE_REQUEST
        }
        case TypeWO.AMENITY_SPACE_BOOKING: {
            return DescriptionTypeWO.AMENITY_SPACE_BOOKING
        }
        case TypeWO.EVENT_SUPPORT: {
            return DescriptionTypeWO.EVENT_SUPPORT
        }
        default: {
            return ''
        }
    }
}
