import { Accept, useDropzone } from 'react-dropzone'
import styles from './FilesDropzoneLoader.module.scss'
import Icons from 'assets/icons'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import AddedFilesList from './AddedFilesList'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'

type Props = {
    accept?: Accept
    onLoadFiles: (files: Array<File>) => void
    className?: string
    maxFiles?: number
    maxSize?: number
}

const FilesDropzoneLoader = ({ accept, onLoadFiles, className, maxFiles, maxSize }: Props) => {
    const [files, setFiles] = useState<Array<File>>([])

    const {
        getRootProps,
        getInputProps,
        open: openDropZone,
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: accept,
        maxFiles: maxFiles,
        maxSize: maxSize,
        onDrop: (acceptedFiles: Array<File>, fileRejections) => {
            if (fileRejections?.length) {
                const messages = fileRejections.map((i) => i.errors[0]?.message)
                messages.map((message) => sendNotification(message, NOTIFICATION_STATUS.ERROR))
            }
            setFiles([...files, ...acceptedFiles])
        },
    })

    const handleDelete = (deleteFile: File) => {
        setFiles(files.filter((file) => file.name !== deleteFile.name))
    }

    useEffect(() => {
        onLoadFiles(files)
    }, [files])

    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles.dropZone} {...getRootProps()}>
                <input {...getInputProps()} />

                <div className={styles.dropZoneContent} onClick={openDropZone}>
                    <Icons.ImportFileSVG />
                    Click to Upload or Drag And Drop
                </div>
            </div>

            <AddedFilesList files={files} onDelete={handleDelete} />
        </div>
    )
}

export default FilesDropzoneLoader
