import * as React from 'react'
import Tooltip from '@mui/material/Tooltip'
import s from './CustomErrorTooltip.module.scss'
import { ReactComponent as ValidationErrorIcon } from '../../../assets/icons/validationErrorIcon.svg'

interface CustomErrorTooltipProps {
    text?: string
    open?: boolean
}

const CustomErrorTooltip: React.FC<CustomErrorTooltipProps> = ({ text = '', open = undefined }) => {
    return (
        <div className={s.errorPlaceholder} style={{ visibility: text ? 'visible' : 'hidden' }}>
            <Tooltip
                title={text}
                placement="right"
                open={open}
                enterTouchDelay={200}
                componentsProps={{
                    tooltip: {
                        sx: {
                            userSelect: 'none',
                            padding: '10px',
                            fontSize: '12px',
                            fontFamily: 'Poppins, sans-serif',
                            background: '#DC3545',
                            color: 'white',
                            '& .MuiTooltip-arrow': {
                                color: 'linear-gradient(180deg, #F8F8F8 0%, #F7F7F7 100%);',
                            },
                        },
                    },
                }}
            >
                <ValidationErrorIcon />
            </Tooltip>
        </div>
    )
}

export default CustomErrorTooltip
