import React, { useMemo } from 'react'
import s from './LoginLayout.module.scss'

type LoginLayoutProps = {
    children?: React.ReactNode
}

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
    const img = useMemo(() => {
        return <img src="/assets/images/logo-large.png" alt="logo" />
    }, [])

    return (
        <div className={s.pageContainer}>
            <div className={s.logo}>{img}</div>

            <div className={s.contentContainer}>{children}</div>
        </div>
    )
}

export default LoginLayout
