import styles from './styles.module.scss'
import { Note } from 'slices/building'
import NoteItem from './components/NoteItem'
import { useCallback, useEffect, useRef, useState } from 'react'

import NoteInput from './components/NoteInput'
import sendNotification from '../../lib/notification'
import { NOTIFICATION_STATUS } from '../../types'
import { useAppDispatch, useInfinityScroll } from '../../hooks'
import { createNote, deleteNote, getNotesList } from '../../api/notes'
import ProgressBar from '../ProgressBar'
import { orderBy } from 'lodash'

type Props = {
    id: string
    saveFieldName: string
    height?: string | number
    updateCallback?: () => Promise<void>
    isEditable?: boolean
}
const NotesContainer = ({ id, saveFieldName, height, updateCallback, isEditable = true }: Props) => {
    const dispatch = useAppDispatch()
    const noteContentRef = useRef<HTMLDivElement>(null)

    const [notes, setNotes] = useState<Array<Note>>([])
    const [loading, setLoading] = useState(false)

    const getNotes = useCallback(async () => {
        setLoading(true)
        try {
            const data = await dispatch(getNotesList({ id })).unwrap()
            setNotes(data.notes)
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
        setLoading(false)
    }, [id])

    const handleSave = useCallback(
        async (textNote: string, files: Array<File>) => {
            setLoading(true)
            try {
                const formData = new FormData()
                formData.append(saveFieldName, id)
                formData.append('text', textNote)

                if (files) {
                    files.forEach((file) => {
                        formData.append('noteFiles[]', file)
                    })
                }
                await dispatch(createNote(formData))
                await getNotes()
                updateCallback && (await updateCallback())
            } catch {
                sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
            }
            setLoading(false)
        },
        [id, saveFieldName, getNotes],
    )

    const handleDeleteNote = useCallback(
        async (noteId: string) => {
            setLoading(true)
            try {
                await dispatch(deleteNote(noteId))
                await getNotes()
                updateCallback && (await updateCallback())
            } catch {
                sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
            }
            setLoading(false)
        },
        [getNotes],
    )

    const { setLastElement } = useInfinityScroll({
        fetchNextData: async () => getNotes(),
    })

    useEffect(() => {
        ;(async () => {
            await getNotes()
        })()
    }, [getNotes, id])

    return (
        <div className={styles.root} style={{ height }}>
            <div ref={noteContentRef} className={styles.noteContent}>
                {orderBy(notes, ['creationDate'], ['desc']).map((note, index, { length }) => {
                    const refLast = index === length - 1 ? setLastElement : null
                    return (
                        <NoteItem
                            ref={refLast}
                            key={note.id}
                            note={note}
                            onDeleteNote={handleDeleteNote}
                            isEditable={isEditable}
                        />
                    )
                })}
            </div>

            {isEditable && <NoteInput editTextNote={''} onSave={handleSave} />}

            <ProgressBar show={loading} />
        </div>
    )
}

export default NotesContainer
