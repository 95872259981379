import styles from './FilesDropzoneLoader.module.scss'
import { Popover, Tooltip } from '@mui/material'
import Icons from 'assets/icons'
import { filesize } from 'filesize'
import React, { useEffect, useState } from 'react'
import { CommonFileType } from 'types'
import FileIcon from '../FileIcon'

type Props = {
    files: Array<CommonFileType>
    onDeleteFile?: (files: Array<CommonFileType>) => void
    onDeleteOneFile?: (file: CommonFileType) => void
}
const CurrentFilesList = ({ files, onDeleteFile, onDeleteOneFile }: Props) => {
    const [deletedFiles, setDeletedFiles] = useState<Array<CommonFileType>>([])
    const [currentFiles, setCurrentFiles] = useState<Array<CommonFileType>>([])
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [openViewDialog, setOpenViewDialog] = useState<CommonFileType | null>(null)

    const handleDelete = (file: CommonFileType) => {
        const deleted = [...deletedFiles, file]
        onDeleteFile && onDeleteFile(deleted)
        setDeletedFiles(deleted)
        onDeleteOneFile && onDeleteOneFile(file)

        setCurrentFiles(currentFiles.filter((item) => item.id !== file.id))
    }
    const handleClick = (event: React.MouseEvent<HTMLDivElement>, file: CommonFileType) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
        setOpenViewDialog(file)
    }
    const handleClose = () => {
        setAnchorEl(null)
        setOpenViewDialog(null)
    }
    useEffect(() => {
        setCurrentFiles(files)
    }, [files])

    return (
        <div className={styles.filesWrap}>
            {currentFiles.map((file) => (
                <Tooltip key={file.name} title={file.name}>
                    <div
                        className={styles.fileRow}
                        onClick={(e) =>
                            file.mimetype.includes('image') || file.mimetype.includes('video')
                                ? handleClick(e, file)
                                : undefined
                        }
                    >
                        {file.mimetype.includes('image') || file.mimetype.includes('video') ? (
                            <div className={styles.link}>
                                <FileIcon accept={file.mimetype} size={25} />
                                <span>{file.name}</span>
                            </div>
                        ) : (
                            <a href={file.url} target="_blank" download={file.name} rel="noreferrer">
                                <FileIcon accept={file.mimetype} size={25} />
                                <span>{file.name}</span>
                            </a>
                        )}

                        <Popover
                            open={!!openViewDialog}
                            onClose={() => setOpenViewDialog(null)}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleClose()
                            }}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                horizontal: 0,
                                vertical: 500,
                            }}
                            sx={{
                                padding: 0,
                                '& .MuiPopover-paper': {
                                    padding: 0,
                                    backgroundColor: '#fff',
                                    borderRadius: '10px',
                                    boxShadow: openViewDialog?.mimetype.includes('image')
                                        ? '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 0px rgba(0, 0, 0, 0.12)'
                                        : 'none',
                                },
                            }}
                        >
                            {openViewDialog ? (
                                <div className={styles.flyBox} onClick={() => setOpenViewDialog(file)}>
                                    {openViewDialog.mimetype.includes('image') ? (
                                        <img
                                            src={openViewDialog.url}
                                            alt={openViewDialog.name}
                                            draggable={false}
                                            className={styles.flyImg}
                                        />
                                    ) : (
                                        <video
                                            src={openViewDialog.url}
                                            className={styles.flyImg}
                                            muted
                                            autoPlay
                                            controls
                                        />
                                    )}
                                </div>
                            ) : null}
                        </Popover>

                        <div className={styles.fileSize}>{filesize(file.size)}</div>
                        {(onDeleteFile || onDeleteOneFile) && (
                            <div className={styles.delete} onClick={() => handleDelete(file)}>
                                <Icons.CrossSVG />
                            </div>
                        )}
                    </div>
                </Tooltip>
            ))}
        </div>
    )
}

export default CurrentFilesList
