import { forwardRef, ReactNode } from 'react'
import classNames from 'classnames'
import styles from '../../styles.module.scss'
import { noop } from 'lib/common'
import { TableRow, TableRowProps } from '@mui/material'

type Props = {
    children: ReactNode
    className?: string
    active?: boolean
} & TableRowProps
const TableBodyRow = forwardRef<HTMLTableRowElement, Props>(({ children, className, onClick, active }, ref) => {
    return (
        <TableRow
            ref={ref}
            className={classNames(styles.tableBodyRow, active && styles.active, className)}
            onClick={onClick}
        >
            {children}
        </TableRow>
    )
})

export default TableBodyRow
