import styles from './CollapsedContainerRow.module.scss'
import { ReactNode, useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material'
import Icons from 'assets/icons'

type Props = {
    id?: string
    title: ReactNode
    defaultExpanded?: boolean
    icon?: ReactNode
    children?: ReactNode
    AddButton?: ReactNode
    amount?: number
    onOpen?: (id: string) => void
    isLoading?: boolean
    className?: string
}
const CollapsedContainerRow = ({
    title,
    amount,
    icon,
    defaultExpanded = true,
    children,
    AddButton,
    onOpen,
    id,
    isLoading,
    className,
}: Props) => {
    useEffect(() => {
        if (defaultExpanded && onOpen && id) {
            onOpen(id)
        }
    }, [defaultExpanded])

    return (
        <Accordion
            disableGutters
            elevation={0}
            className={className}
            defaultExpanded={defaultExpanded}
            onChange={(e, expanded) => expanded && onOpen && id && onOpen(id)}
            sx={{
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
                borderBottom: id ? '1px solid #e0dfe8' : 'none',
                '&:before': {
                    display: 'none',
                },
                '&:last-child': {
                    borderBottom: 'none',
                },
            }}
        >
            <AccordionSummary
                expandIcon={<Icons.Next className={styles.accordionIcon} />}
                sx={{
                    padding: '0 8px',

                    '& .MuiAccordionSummary-content': {
                        margin: 0,
                    },
                }}
            >
                <div className={styles.summaryContent}>
                    {icon}
                    <span>
                        {title} {amount ? `(${amount})` : null}
                    </span>
                    {isLoading && <CircularProgress color="inherit" size={20} className={styles.loading} />}
                    {AddButton}
                </div>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    padding: id ? '0' : '10px 0',
                    margin: 0,
                }}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default CollapsedContainerRow
