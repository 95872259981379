import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { CommonOtherResponseType, CommonResponseType, DirectionEnum, USER_ROLE } from 'types'
import { axiosErrorHandler, client } from 'api/setupAxios'
import { AxiosResponse } from 'axios'
import { PmPlansListPageTab } from 'pages/PmPage/config'
import { CurrentPMType, PMType } from 'slices/pm'
import { NewWorkOrderType, TypeWO } from 'components/NewWorkOrder/config'
import { checkIsGoodStatus } from 'lib/common'
import { EditWorkOrderType } from 'pages/WorkOrderProfile/components/WorkOrderProfileForm/config'
import { WORK_ORDERS_PER_PAGE } from 'pages/WorkOrdersPage/config'
import { WorkOrderManyAttributeKeys, WorkOrderManyIncludeKeys } from 'api/workOrders'

export const getAllPMsList = createAppAsyncThunk('pm/getAllPMsList', async (_, { rejectWithValue, getState }) => {
    const page = getState().pm.pMPaginationPage
    const search = getState().pm.pMsSearchValue
    const role = getState().auth?.user?.role
    const regionId = getState().auth?.user?.regionId || ''
    const sortField = getState().pm.pMsSettings.sortField
    const sortDirection = getState().pm.pMsSettings.sortDirection
    const filters = getState().pm.pMsSettings.filters
    const pMPlansListTab = getState().pm.pMPlansListTab

    const params = {
        offset: (page - 1) * WORK_ORDERS_PER_PAGE,
        limit: WORK_ORDERS_PER_PAGE,
        keySearchValue: search !== '' ? search : undefined,
        sortField,
        sortDirection,

        regionIdes: role && ![USER_ROLE.ADMIN, USER_ROLE.REQUESTOR].includes(role) ? [regionId] : undefined,
        //subTypes: filters?.subTypes?.length ? filters?.subTypes : undefined,
        //priorities: filters?.priorities?.length ? filters?.priorities : undefined,
        startDate: filters?.completionDateFrom ? filters?.completionDateFrom : undefined,
        endDate: filters?.completionDateTo ? filters?.completionDateTo : undefined,
        types: pMPlansListTab === PmPlansListPageTab.CONTRACTED ? [TypeWO.PREVENTIVE_MAINTENANCE] : [TypeWO.RECURRING],
        attributeCriteria: Object.values(WorkOrderManyAttributeKeys),
        includeCriteria: Object.values(WorkOrderManyIncludeKeys),
    }

    try {
        const result = await client.get<CommonResponseType<PMType>, AxiosResponse<CommonOtherResponseType<PMType>>>(
            `/api/workOrders/get-many-folders`,
            { params },
        )
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getAllWorkOrdersInPM = createAppAsyncThunk<any, { pmFolderId: string; isShowPresentPms?: boolean }>(
    'workOrder/getAllWorkOrdersInPM',
    async ({ pmFolderId, isShowPresentPms }, { rejectWithValue, getState }) => {
        const pMPlansListTab = getState().pm.pMPlansListTab

        const params = {
            offset: 0,
            limit: 100,
            sortField: 'startDate',
            sortDirection: DirectionEnum.ASC,
            pmFolderId,
            showPM: pMPlansListTab === PmPlansListPageTab.CONTRACTED,
            isShowPresentPms,

            attributeCriteria: Object.values(WorkOrderManyAttributeKeys),
            includeCriteria: Object.values(WorkOrderManyIncludeKeys),
        }

        try {
            const result = await client.get<
                CommonOtherResponseType<CurrentPMType>,
                AxiosResponse<CommonOtherResponseType<CurrentPMType>>
            >(`/api/workOrders/get-many`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getPMById = createAppAsyncThunk('pm/getPMById', async ({ id }: { id: string }, { rejectWithValue }) => {
    const params = { workOrderId: id }
    try {
        const result = await client.get<CurrentPMType, AxiosResponse<CurrentPMType>>(
            `/api/workOrders/get-workOrder-by-id`,
            { params },
        )
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const createPM = createAppAsyncThunk<
    any,
    Omit<NewWorkOrderType, 'type'> & { isPM: boolean; checkResponse?: (id?: string) => void }
>('pm/createPM', async ({ checkResponse, isPM, ...arg }, { rejectWithValue, getState }) => {
    try {
        const result = await client.post<PMType, AxiosResponse<PMType>>(
            `/api/workOrders/create-workOrder`,
            {
                customerId: getState().auth?.user?.customerId ?? '',
                type: isPM ? TypeWO.PREVENTIVE_MAINTENANCE : TypeWO.RECURRING,
                ...arg,
            },
            { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse(result?.data?.id)
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const editPM = createAppAsyncThunk<
    any,
    Partial<EditWorkOrderType> & { workOrderId: string; checkResponse?: () => void }
>('pm/editPM', async ({ checkResponse, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.patch<PMType, AxiosResponse<PMType>>(`/api/workOrders/update-workOrder`, arg, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})
