import styles from './styles.module.scss'
import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material'
import { getAssetsByCategoryAndTypes } from 'api/floors'
import { Asset, AssetCategory, AssetType } from 'slices/floorPlanViever'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import { useAppDispatch } from 'hooks'
import Icons from 'assets/icons'
import PlanTypeIcon from '../PlanTypeIcon/PlanTypeIcon'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import AssetCheckBoxRow from './AssetCheckBoxRow'

type Props = {
    category: AssetCategory
    type: AssetType
    dependBuildingId?: string
    disabledAssetsIds?: Array<string>
    selectedAssetsIds: Array<string>
    onSelectAssetId: (id: string, checked: boolean, asset: Asset) => void
    hiddenAssetId?: string
}
const TypeAccordionRow = ({
    type,
    category,
    dependBuildingId,
    selectedAssetsIds,
    onSelectAssetId,
    disabledAssetsIds,
    hiddenAssetId,
}: Props) => {
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)

    const [assets, setAssets] = useState<Array<Asset>>([])

    const handleOpenType = async (id: string) => {
        setLoading(true)
        try {
            const res = await dispatch(
                getAssetsByCategoryAndTypes({
                    idCategory: category.id,
                    idType: id,
                    buildingId: dependBuildingId,
                }),
            ).unwrap()

            setAssets(res.assets)
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
        setLoading(false)
    }

    return (
        <Accordion
            disableGutters
            elevation={0}
            onChange={(_, expanded) => expanded && handleOpenType(type.id)}
            sx={{
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
                '&:before': {
                    display: 'none',
                },

                '&.Mui-expanded': {
                    background: '#E9EEF3',
                },
            }}
        >
            <AccordionSummary
                expandIcon={<Icons.Next className={styles.accordionIcon} />}
                sx={{
                    '& .MuiAccordionSummary-content': {
                        margin: 0,
                    },
                }}
            >
                <div className={styles.accordionRowWrap} style={{ paddingLeft: 20 }}>
                    <PlanTypeIcon src={category?.file?.url} planType={category.name} color={category.color} size={30} />

                    <div className={styles.detailWrap}>
                        <Paragraph size="s" weight="tiny">
                            {type.name}
                        </Paragraph>
                        <Paragraph size="xs" weight="tiny" color="grey">
                            {type.assetsCount} assets
                        </Paragraph>
                    </div>

                    {loading && <CircularProgress size={20} />}
                </div>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    padding: 0,
                    background: '#E9EEF3',
                }}
            >
                {assets.map((asset) => {
                    const isDisabled = !!disabledAssetsIds?.some((item) => item === asset.id)
                    const isChecked = selectedAssetsIds.some((item) => item === asset.id) || isDisabled
                    const isHidden = asset.id === hiddenAssetId

                    return (
                        <AssetCheckBoxRow
                            key={asset.id}
                            asset={asset}
                            checked={isChecked}
                            disabled={isDisabled}
                            onSelectAsset={onSelectAssetId}
                            isHidden={isHidden}
                        />
                    )
                })}
            </AccordionDetails>
        </Accordion>
    )
}

export default TypeAccordionRow
