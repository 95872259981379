import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AutocompletePropsType, ItemForAutocompleteType } from '.'
import { useAppDispatch, useAppSelector } from 'hooks'
import { currentUserSelect } from 'store/selectors'
import useDebounce from 'hooks/useDebounce'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { Autocomplete } from '@mui/material'
import { getOptions } from 'lib/common'
import { CustomTextField } from 'ui-kit/components/CustomTextField'
import { CustomShowMore } from 'ui-kit/components/CustomShowMore'
import { CustomRemovedBox } from 'ui-kit/components/CustomRemovedBox'
import { getAllAssetsForAutocomplete } from 'api/assets'
import { ASSETS_PER_PAGE } from 'pages/AssetsPage/config'
import PlanTypeIcon from 'components/PlanTypeIcon/PlanTypeIcon'
import { CommonType } from 'types'
import { CategoryInAssetType } from 'slices/asset'
import Icons from 'assets/icons'

type ResponseType = {
    id: string
    name: string
    floor: CommonType
    room: CommonType
    category: CategoryInAssetType
}

export const AssetAutoComplete = ({
    label,
    handleBlur,
    errorText,
    currentValue,
    readOnly = false,
    textFieldClassName,
    isSearch = false,
    disableClearable = false,
    textFieldPlaceholder,
    width = '100%',
    onChangeCallback,
    onDeleteItemClick,
    multiple,
    idDependence,
    filteredValues,
}: AutocompletePropsType & { filteredValues?: Array<string> }) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const [values, setValues] = useState<ResponseType[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 500)

    const onInputChange = (event: any, newInputValue: string) => {
        setSearch(newInputValue)
        setPage(1)
    }

    const disabledCondition = count <= page * ASSETS_PER_PAGE || disableClearable

    const isOptionEqualToValue = (option: ItemForAutocompleteType, value: ItemForAutocompleteType) =>
        option?.value === value?.value

    const onShowMoreClick = (e: React.MouseEvent<HTMLParagraphElement>) => {
        e.preventDefault()
        setPage((prev) => prev + 1)
        getAllValues(page + 1)
    }

    const getAllValues = async (currentPage: number) => {
        setIsLoading(true)
        try {
            const res: { count: number; assets: ResponseType[] } = await dispatch(
                getAllAssetsForAutocomplete({ page: currentPage, search, idDependence }),
            ).unwrap()

            setCount(res?.count)
            setValues((prev) => (currentPage === 1 ? res?.assets : [...res.assets, ...prev]))
        } catch (error: any) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!user || typeof idDependence === 'undefined' || search) {
            return
        }
        getAllValues(page)
    }, [user, search, JSON.stringify(idDependence)])

    useEffect(() => {
        if (typeof idDependence === 'undefined') {
            return
        }
        if (debouncedSearch) {
            getAllValues(page)
        }
    }, [debouncedSearch, JSON.stringify(idDependence)])

    const getOptionLabel = useCallback((option: ItemForAutocompleteType) => {
        return option?.label ?? ''
    }, [])

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, option: ItemForAutocompleteType) => {
            const currentAsset = values.find((value) => value.id === option?.value)

            if (option.value === 'Not found') {
                return (
                    <li style={{ pointerEvents: 'none' }} {...props}>
                        <Icons.NotFoundSVG color="#848a9b" style={{ marginRight: '.4rem' }} />
                        <Paragraph size={'s'} color="grey">
                            Not found
                        </Paragraph>
                    </li>
                )
            }

            return (
                <li {...props}>
                    <PlanTypeIcon
                        src={currentAsset?.category?.file?.url}
                        planType={currentAsset?.category?.name ?? ''}
                        color={currentAsset?.category?.color}
                        size={30}
                    />

                    <Paragraph size={'s'} style={{ margin: '0 .4rem' }}>
                        {option?.label}
                    </Paragraph>
                </li>
            )
        },
        [values],
    )

    const renderTags = useCallback(
        (value: ItemForAutocompleteType[]) => {
            if (onDeleteItemClick) {
                return ''
            }
            const currentAsset = values.find((i) => i.id === value[0]?.value)

            if (value?.length === 1) {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                        <PlanTypeIcon
                            src={currentAsset?.category?.file?.url}
                            planType={currentAsset?.category?.name ?? ''}
                            color={currentAsset?.category?.color}
                            size={30}
                        />
                        <Paragraph size={'s'}>{value[0]?.label}</Paragraph>
                    </div>
                )
            } else {
                return (
                    <Paragraph size={'s'} style={{ display: 'flex', gap: '.5rem' }}>
                        {value?.length}
                        <Paragraph size={'s'} color={'grey'}>
                            (assets)
                        </Paragraph>
                    </Paragraph>
                )
            }
        },
        [onDeleteItemClick],
    )

    const getPlaceholder = useMemo(() => {
        setSearch('')
        if (multiple) {
            return currentValue?.length ? '' : textFieldPlaceholder
        }
        return !Array.isArray(currentValue) && currentValue?.value ? '' : textFieldPlaceholder
    }, [multiple, currentValue])

    const optionsRender = useMemo(() => {
        const filteredOptions = filteredValues
            ? values.filter((itemValue) => !filteredValues.some((item) => item === itemValue.id))
            : values
        return getOptions(filteredOptions, 'id', 'name')
    }, [values])

    return (
        <>
            <Autocomplete
                value={currentValue}
                readOnly={readOnly}
                onChange={(event, newValue) => {
                    onChangeCallback(newValue as ItemForAutocompleteType[] & ItemForAutocompleteType, values)
                    setSearch('')
                }}
                openOnFocus={true}
                multiple={multiple}
                inputValue={search}
                onInputChange={onInputChange}
                options={optionsRender?.length > 0 ? optionsRender : [{ value: 'Not found', label: 'Not found' }]}
                freeSolo
                renderTags={renderTags}
                renderOption={renderOption}
                getOptionLabel={(option) => getOptionLabel(option as ItemForAutocompleteType)}
                renderInput={(params) => (
                    <CustomTextField
                        label={label}
                        onBlur={handleBlur}
                        params={params}
                        errorText={errorText}
                        loading={isLoading}
                        className={textFieldClassName}
                        placeholder={getPlaceholder}
                        isSearch={isSearch}
                    />
                )}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        flexWrap: 'nowrap',
                        padding: 0,
                    },
                }}
                disableCloseOnSelect={multiple}
                filterOptions={(x) => x}
                limitTags={1}
                style={{ width }}
                loading={isLoading}
                isOptionEqualToValue={isOptionEqualToValue}
                disableClearable={disableClearable}
                PaperComponent={({ children }) => (
                    <CustomShowMore
                        onShowMoreClick={onShowMoreClick}
                        children={children}
                        text={'Show more'}
                        disabled={disabledCondition}
                    />
                )}
            />
            {onDeleteItemClick &&
                multiple &&
                currentValue?.map((item) => {
                    const currentAsset = values.find((i) => i.id === item.value)

                    return (
                        <CustomRemovedBox
                            id={item?.value ?? ''}
                            title={item?.label ?? ''}
                            onDeleteClick={onDeleteItemClick}
                            color={currentAsset?.category?.color}
                        />
                    )
                })}
        </>
    )
}
