import React, { FC, useState } from 'react'
import s from './AuthInput.module.scss'
import { Paragraph } from '../Typography/Typography'
import SvgSelector from '../../../assets/SvgSelector'
import CustomErrorTooltip from '../CustomErrorTooltip/CustomErrorTooltip'
import HideShowEye from '../../../components/HideShowEye'
import { noop } from '../../../lib/common'
import cn from 'classnames'

type CustomInputProps = {
    id?: string
    name?: string
    value?: string | number
    title?: string
    width?: string
    error?: string
    placeholder?: string
    type?: 'text' | 'password' | 'number' | 'hidden'
    disabled?: boolean
    labelClassName?: string
    onChange: (e: React.ChangeEvent<any>) => void
    onEnterPress?: () => void
    defaultShowError?: boolean
    className?: string
    containerClassName?: string
}

const AuthInput: FC<CustomInputProps> = ({
    id = '',
    value = '',
    title = '',
    type = 'text',
    placeholder = '',
    disabled = false,
    name = '',
    onChange,
    error,
    onEnterPress = noop,
    defaultShowError,
    className,
    containerClassName,
}) => {
    const color = disabled ? '#656565' : error ? '#b90000' : ''

    const [isHidden, setIsHidden] = useState(true)

    const isPassword = type === 'password'

    const isHiddenPassword = isPassword && isHidden
    const passwordInputStyle = isHiddenPassword
        ? cn(s.passwordInput, s.inputClassName, className)
        : cn(s.inputClassName, className)

    return (
        <div>
            {title && (
                <Paragraph
                    className={disabled ? `${s.inputLabel} opacity` : s.inputLabel}
                    style={{ color: containerClassName ? '#848A9B' : '' }}
                >
                    {title}
                </Paragraph>
            )}

            <div className={s.errorContainer}>
                <div className={cn(s.inputBorder, containerClassName)} style={{ borderColor: color }}>
                    <input
                        id={id}
                        name={name}
                        className={`${passwordInputStyle} ${disabled ? 'opacity' : ''}`}
                        onChange={onChange}
                        value={value}
                        type={isPassword && !isHidden ? 'text' : type}
                        disabled={disabled}
                        placeholder={placeholder}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onEnterPress()
                            }
                        }}
                    />

                    {isPassword && (
                        <HideShowEye
                            isHidden={isHidden}
                            onChangeVisibility={setIsHidden}
                            color={containerClassName ? '#848A9B' : ''}
                        />
                    )}
                </div>
                <CustomErrorTooltip text={error} open={defaultShowError} />
            </div>
        </div>
    )
}

export default AuthInput
