import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { axiosErrorHandler, client } from 'api/setupAxios'
import { DirectionEnum } from 'types'
import { HISTORY_LIMIT } from 'slices/history'

export const getAssetHistory = createAppAsyncThunk(
    'history/getAssetHistory',
    async (
        { assetId, serialNumber, page }: { assetId: string; serialNumber: string; page?: number },
        { rejectWithValue, getState },
    ) => {
        const params = {
            searchString: [assetId, serialNumber],
            orderDirection: DirectionEnum.ASC,
            offset: (page ? page - 1 : getState().history.assetHistoryPaginationPage - 1) * HISTORY_LIMIT,
            limit: HISTORY_LIMIT,
        }
        try {
            const result = await client.get(`/api/history/many`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
