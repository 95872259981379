import { ROUTES } from '../../routes/const'
import { USER_ROLE } from '../../types'

export type NavBarLink = {
    title: string
    icon: string
    link: ROUTES
    roles: Array<USER_ROLE>
}

export const navBarLinks: Array<NavBarLink> = [
    {
        title: 'Dashboard',
        icon: '/assets/icons/dashboard.svg',
        link: ROUTES.DASHBOARD,
        roles: [USER_ROLE.SUPERADMIN, USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR, USER_ROLE.TECHNICIAN, USER_ROLE.REQUESTOR],
    },
    {
        title: 'Buildings',
        icon: '/assets/icons/buildings.svg',
        link: ROUTES.BUILDINGS,
        roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR, USER_ROLE.TECHNICIAN, USER_ROLE.REQUESTOR],
    },
    {
        title: 'Calendar',
        icon: '/assets/icons/calendar.svg',
        link: ROUTES.CALENDAR,
        roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR, USER_ROLE.TECHNICIAN],
    },
    {
        title: 'Users',
        icon: '/assets/icons/users-tenants.svg',
        link: ROUTES.USERS,
        roles: [USER_ROLE.SUPERADMIN, USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR, USER_ROLE.TECHNICIAN, USER_ROLE.REQUESTOR],
    },
    {
        title: 'Tenant Management',
        icon: '/assets/icons/tenants-sidebar.svg',
        link: ROUTES.TENANTS,
        roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR, USER_ROLE.TECHNICIAN, USER_ROLE.REQUESTOR],
    },
    {
        title: 'Subcontractors',
        icon: '/assets/icons/subcontractors.svg',
        link: ROUTES.SUBCONTRACTORS,
        roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR, USER_ROLE.TECHNICIAN],
    },
    {
        title: 'Assets',
        icon: '/assets/icons/assets.svg',
        link: ROUTES.ASSETS,
        roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR, USER_ROLE.TECHNICIAN],
    },
    {
        title: 'Work Orders',
        icon: '/assets/icons/work-orders.svg',
        link: ROUTES.WORK_ORDERS,
        roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR, USER_ROLE.TECHNICIAN, USER_ROLE.REQUESTOR],
    },
    {
        title: 'Preventative Maintenance',
        icon: '/assets/icons/prev-maintenance.svg',
        link: ROUTES.PMs,
        roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR, USER_ROLE.TECHNICIAN],
    },
    {
        title: 'Parts & Inventory',
        icon: '/assets/icons/parts-and-inventory.svg',
        link: ROUTES.PARTS_INVENTORY,
        roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR, USER_ROLE.TECHNICIAN],
    },
    {
        title: 'Upcoming Maintenance',
        icon: '/assets/icons/calendar.svg',
        link: ROUTES.PMs,
        roles: [USER_ROLE.REQUESTOR],
    },
    // {
    //     title: 'Settings',
    //     icon: '/assets/icons/settings.svg',
    //     link: '#',
    // },
    {
        title: 'Support',
        icon: '/assets/icons/support.svg',
        link: ROUTES.SUPPORT,
        roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR, USER_ROLE.TECHNICIAN, USER_ROLE.REQUESTOR],
    },
    {
        title: 'Sign Out',
        icon: '/assets/icons/logout.svg',
        link: ROUTES.SIGN_OUT,
        roles: [USER_ROLE.SUPERADMIN, USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR, USER_ROLE.TECHNICIAN, USER_ROLE.REQUESTOR],
    },
]
