import styles from 'components/WorkOrderCards/MiddleWorkOrderCards/styles.module.scss'
import Button from 'ui-kit/components/Button'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import React from 'react'
import { getSvgImageTypeWo } from 'assets/workOrders-icons'
import { WorkOrderType } from 'slices/workOrder'
import WorkOrderBadge from 'pages/CalendarPage/components/WorkOrderBadge'
import dayjs from 'dayjs'
import { TypeWO } from 'components/NewWorkOrder/config'

type Props = {
    workOrder: WorkOrderType
}

export const MiddleWorkOrderCards = ({ workOrder }: Props) => {
    return (
        <div className={styles.middleCard}>
            <div className={styles.middleCard_title}>
                <div className={styles.middleCard_title_box}>
                    <img src={getSvgImageTypeWo(workOrder.type)} alt="type" />

                    {workOrder.type === TypeWO.RECURRING && (
                        <Button
                            iconOnly={'refresh'}
                            variants={'filled-blue'}
                            className={styles.middleCard_title_box_refresh}
                            disabled
                        />
                    )}

                    <Paragraph size={'s'} weight={'semi-bold'} className={styles.middleCard_title_box_name}>
                        {workOrder.title}
                        <Paragraph size={'s'} weight={'tiny'}>
                            {workOrder.subType}
                        </Paragraph>
                    </Paragraph>
                </div>

                <WorkOrderBadge status={workOrder.status} />
            </div>

            <Paragraph size={'xxs'} weight={'tiny'}>
                Schedule Date: {workOrder.startDate ? dayjs(workOrder.startDate).format('MM/DD/YY') : '-'}
            </Paragraph>
            <div className={styles.middleCard_user}>
                <Paragraph size={'xs'} weight={'tiny'}>
                    Created By: {workOrder.creator.firstName + ' ' + workOrder.creator.lastName}
                </Paragraph>
            </div>
        </div>
    )
}
