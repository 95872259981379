import axios, { AxiosError } from 'axios'
import { clearLS } from 'lib/common'
import { LsKeys, restoreState, saveState } from 'lib/workWithLS'
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore'
import { sendErrorNotification } from 'lib/notification'

const AUTH_URL = process.env.REACT_APP_BASE_URL

export const client = axios.create({
    baseURL: `${AUTH_URL}`,
})

export const authServiceInterceptor = (store: ToolkitStore) => {
    client.interceptors.request.use(
        (config) => {
            const token = restoreState(LsKeys.TOKEN)

            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }

            return config
        },
        (error) => {
            return Promise.reject(error)
        },
    )
    client.interceptors.response.use(
        (response) => response,
        async (e) => {
            const originalConfig = e.config

            if (originalConfig.url !== '/api/auth/sign-in' && e.response) {
                if (originalConfig.url === '/api/auth/refresh' && e.response.status === 401) {
                    //sendErrorNotification('Unauthorized')
                    clearLS(store.dispatch)
                }
                if (e.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true

                    const refreshToken = restoreState(LsKeys.REFRESH)

                    if (refreshToken) {
                        try {
                            const res = await axios.get(`${AUTH_URL}api/auth/refresh`, {
                                headers: { Authorization: `Bearer ${refreshToken}` },
                            })

                            if (res?.data) {
                                saveState(LsKeys.REFRESH, res?.data?.refreshToken)
                                saveState(LsKeys.TOKEN, res?.data?.accessToken)
                                originalConfig.headers.Authorization = `Bearer ${res?.data?.accessToken}`

                                return client(originalConfig)
                            }
                        } catch {
                            sendErrorNotification('Unauthorized')
                        }
                    }
                }

                if (e.response.status === 401 && originalConfig._retry) {
                    clearLS(store.dispatch)
                    //sendErrorNotification('Unauthorized')
                }
            }
            return Promise.reject(e)
        },
    )
}
export const axiosErrorHandler = (e: any) => {
    interface BackendError {
        message: string
    }

    if (axios.isAxiosError(e)) {
        const error = e as AxiosError<BackendError>
        return error?.response?.data?.message
    } else {
        return e
    }
}
