import { PageTabType } from 'components/PageTabPanel'

export const breadCrumbsArr = (name: string) => {
    return [
        {
            name: 'Subcontractors',
            link: `/subcontractors`,
        },
        {
            name: `${name ?? ''}`,
            link: `#`,
        },
    ]
}

export enum SubcontractorProfileTab {
    CONTACTS = 'CONTACTS',
    WO_HISTORY = 'WO_HISTORY',
}
export const tabs: PageTabType[] = [
    {
        id: SubcontractorProfileTab.CONTACTS,
        label: 'Contacts',
    },
    {
        id: SubcontractorProfileTab.WO_HISTORY,
        label: 'WO History',
    },
]
