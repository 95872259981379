import React, { FC } from 'react'
import styles from './styles.module.scss'
import { Input, InputLabel, InputProps, Tooltip } from '@mui/material'
import classNames from 'classnames'

export type InputVariant = 'grey' | 'white' | 'white-grey'

type Props = {
    label?: string
    width?: string
    errorText?: string
    className?: string
    labelClassName?: string
    variant?: InputVariant
} & InputProps

const CustomInput: FC<Props> = ({
    variant,
    label,
    width = '100%',
    className,
    labelClassName,
    multiline,
    errorText,
    ...propsInput
}) => {
    const getVariants = (val?: InputVariant) => {
        switch (val) {
            case 'white':
                return styles.white

            case 'white-grey':
                return styles.whiteGrey

            default:
                return styles.grey
        }
    }

    return (
        <div style={{ width }}>
            {!!label && <InputLabel className={classNames(styles.label, labelClassName)}>{label}</InputLabel>}

            <Tooltip title={errorText ? errorText : ''} placement={'right'}>
                <Input
                    className={classNames(
                        styles.inputContainer,
                        getVariants(variant),
                        multiline && styles.multi,
                        errorText && styles.error,
                        className,
                    )}
                    disableUnderline
                    multiline={multiline}
                    {...propsInput}
                />
            </Tooltip>
        </div>
    )
}

export default CustomInput
