import React, { useEffect, useState } from 'react'
import { ErrorFieldType, ErrorType, ValidatedColumnType } from './config'
import { client } from 'api/setupAxios'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import PureCustomSelect from 'ui-kit/components/PureCustomSelect/PureCustomSelect'
import { getOptions } from 'lib/common'
import Button from 'ui-kit/components/Button'

import s from './styles.module.scss'

type NewRequestType = {
    fetch: { url: string; responseKey: string; requiredParams: string[]; belongsTo?: string | null }[]
    offset?: number
    limit?: number
}

type PropsType = {
    errorField: ErrorFieldType | null
    validatedColumns: ValidatedColumnType
    onSave: (key: string, value: string | ErrorType | null) => void
}

export const CorrectionErrorDialog = ({ errorField, validatedColumns, onSave }: PropsType) => {
    const [data, setData] = useState<any[]>([])
    const [selectedValue, setSelectedValue] = useState<any | null>(null)
    const [errorMessage, setErrorMessage] = useState(errorField?.error?.message ?? '')

    const getData = async ({ fetch, offset = 0, limit = 1000 }: NewRequestType) => {
        try {
            const item = fetch?.at(-1)
            let requiredField = undefined

            if (item) {
                if (item?.belongsTo) {
                    const obj = Object.values(validatedColumns).find((i) => item.belongsTo! in i)

                    if (obj) {
                        requiredField = obj[item.belongsTo]
                    }
                }

                const result = await client.get(`${item?.url}`, {
                    params:
                        item?.requiredParams?.length && requiredField
                            ? {
                                  [item.requiredParams[0]]: requiredField,
                                  offset,
                                  limit,
                                  size: limit,
                                  page: offset / limit + 1,
                              }
                            : { offset, limit, size: limit, page: offset + 1 },
                })
                setData(result.data[item.responseKey])
            }
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }

    useEffect(() => {
        if (errorField?.error?.fetch?.length) {
            getData({
                fetch: errorField?.error?.fetch,
            })
        }
    }, [])

    return (
        <>
            <PureCustomSelect
                values={getOptions(data, 'id', 'name')}
                value={selectedValue}
                onChange={(e) => {
                    setErrorMessage('')
                    setSelectedValue(e.target.value)
                }}
                rootClassName={s.select}
                errorText={errorMessage}
                displayEmpty
            />
            <div className={s.buttonsGroup}>
                <Button
                    variants={'filled-blue'}
                    onClick={() =>
                        !!errorField &&
                        onSave(
                            `${errorField.error.positionX}/${errorField.error.positionY}`,
                            data.find((i) => i.id === selectedValue)?.name,
                        )
                    }
                    disabled={!!errorMessage}
                    className={s.button}
                >
                    Save
                </Button>
            </div>
        </>
    )
}
