import React, { ChangeEvent, useCallback, useRef, useState } from 'react'
import { importDialogSelect } from 'store/selectors'
import { useAppDispatch, useAppSelector } from 'hooks'
import { CommonDialog } from 'components/CommonDialog'
import { ImportModelsEnum, setImportDialog } from 'slices/ToolsetSlice'
import FilesDropzoneLoader from 'components/FilesDropzoneLoader'
import { read, utils } from 'xlsx'
import Icons from 'assets/icons'
import Button, { TextButton } from 'ui-kit/components/Button'
import { capitalized, getOptionsFromEnum } from 'lib/common'
import * as T from 'components/Table'
import { SelectChangeEvent, TableContainer } from '@mui/material'
import ProgressBar from 'components/ProgressBar'
import { createImport } from 'api/views'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import CustomInput from 'ui-kit/components/CustomInput'
import CustomCheckBox from 'ui-kit/components/CustomCheckBox'
import { EmptyDatePicker } from 'ui-kit/components/EmptyDatePicker'
import cn from 'classnames'
import {
    ErrorCodeEnum,
    ErrorFieldType,
    ErrorType,
    ExpectedFieldType,
    EXTENSIONS,
    ResponseImportType,
    ValidatedColumnType,
} from './config'
import { CorrectionErrorDialog } from './CorrectionErrorDialog'
import PureCustomSelect from 'ui-kit/components/PureCustomSelect/PureCustomSelect'
import { Availabilities } from 'components/NewSubcontractor/config'
import ReactInputMask from 'react-input-mask'

import s from './styles.module.scss'

type Props = {
    resolve: () => void
}

export const ImportDialog = ({ resolve }: Props) => {
    const dispatch = useAppDispatch()
    const importDialog = useAppSelector(importDialogSelect)
    const [isLoading, setIsLoading] = useState(false)
    const [openErrorDialog, setOpenErrorDialog] = useState<ErrorFieldType | null>(null)
    const [commonFile, setCommonFile] = useState<File>()
    const [colDefs, setColDefs] = useState<string[]>([])
    const [invalidTitles, setInvalidTitles] = useState<{ [key: string]: boolean }>({})
    const [unexpectedTitles, setUnexpectedTitles] = useState<{ [key: string]: boolean }>({})
    const [validatedColumns, setValidatedColumns] = useState<ValidatedColumnType>({})
    const [data, setData] = useState<any[]>([])
    const inputRefs = useRef<Record<string, { value: string | number | null; error: ErrorType; isValid: boolean }>>({})

    const onCloseClick = () => {
        dispatch(setImportDialog({ isOpen: false, table: null }))
    }

    const onContinueClick = (keyOutside?: string, val?: any) => {
        if (!commonFile) {
            return
        }

        const replacements: Record<string, unknown> = {}
        inputRefs?.current &&
            Object.entries(inputRefs.current).forEach(([key, value]) => {
                if (keyOutside && val && keyOutside === key) {
                    replacements[key] = val
                } else {
                    if (typeof value.value === 'number') {
                        replacements[key] = value.value
                    } else {
                        replacements[key] = value.value?.trim()
                    }
                }
            })

        void importExcel(commonFile, JSON.stringify(replacements))
    }

    const getExtension = (insideFile: File) => {
        const parts = insideFile.name.split('.')
        const extension = parts[parts.length - 1]
        return EXTENSIONS.includes(extension)
    }
    const convertToJson = (headers: string[], innerData: string[][]) => {
        const rows: { [key: string]: string }[] = []
        innerData.forEach((row: string[]) => {
            const rowData: { [key: string]: string } = {}
            row.forEach((element: string, i: number) => {
                rowData[headers[i]] = element
            })
            rows.push(rowData)
        })
        return rows
    }

    const setRefValue = (key: string, value: string | number | ErrorType | null, isValid = false) => {
        if (!inputRefs.current) {
            return
        }

        if (!inputRefs.current[key]) {
            inputRefs.current[key] = { value: null, error: {} as ErrorType, isValid }
        }

        if (value && typeof value === 'object') {
            if (typeof value['received'] !== 'undefined') {
                if (value?.expectedType === 'checkbox') {
                    inputRefs.current[key].value = 0
                } else {
                    inputRefs.current[key].value = value['received']
                }
            }
            inputRefs.current[key].error = value
        } else {
            inputRefs.current[key].value = value as string
        }
        inputRefs.current[key].isValid = isValid
    }

    const importExcel = async (file: File, replacements?: string) => {
        if (!file || !importDialog.table) {
            return
        }

        setIsLoading(true)
        const res: ResponseImportType = await dispatch(
            createImport({ file: file, table: importDialog.table, replacements }),
        ).unwrap()
        const tErrors: ErrorType[] = []

        //@ts-ignore
        if (res?.statusCode >= 400) {
            setIsLoading(false)
            dispatch(setImportDialog({ isOpen: false, table: null }))
            resolve()
            return sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }

        if (res?.isValid) {
            dispatch(setImportDialog({ isOpen: false, table: null }))
            resolve()
            res?.uncreated?.length
                ? sendNotification(
                      `All the assets were successfully imported except of Assets with the following IDs ${res?.uncreated?.map(
                          (i) => 'asset ID' + i?.data?.id,
                      )} as assets with their IDs are already in the system.`,
                      NOTIFICATION_STATUS.WARNING,
                  )
                : sendNotification(`${capitalized(importDialog.table)}s has been imported`, NOTIFICATION_STATUS.SUCCESS)
        } else {
            if (!replacements) {
                const reader = new FileReader()
                reader.onload = (event) => {
                    const buffer = event.target?.result
                    const workBook = read(buffer, { type: 'binary' }) //parse data

                    const workSheetName = workBook.SheetNames[0] //get first sheet
                    const workSheet = workBook.Sheets[workSheetName]

                    const fileData: string[][] = utils.sheet_to_json(workSheet, {
                        header: 1,
                        blankrows: false,
                        raw: false,
                    })

                    const headers: string[] = fileData[0]
                    if (inputRefs.current) {
                        res?.errors?.forEach((item) => {
                            if (item.error_code !== ErrorCodeEnum.UNEXPECTED_COLUMN) {
                                setRefValue(`${item.positionX}/${item.positionY}`, item)
                            }
                        })
                    }
                    setColDefs(headers)
                    fileData.splice(0, 1) //removing header
                    setData(convertToJson(headers, fileData))
                }

                if (file && getExtension(file)) {
                    reader.readAsBinaryString(file)
                }
            }
            setInvalidTitles(res?.invalidTitles)
            setUnexpectedTitles(res?.unexpectedTitles)
            setValidatedColumns(res?.validated)

            if (inputRefs.current) {
                replacements &&
                    Object.entries(JSON.parse(replacements)).forEach(([key, value]) => {
                        setRefValue(key, value as string, true)
                    })

                res?.errors?.forEach((item) => {
                    if (item.error_code === ErrorCodeEnum.UNEXPECTED_COLUMN) {
                        tErrors.push(item)
                    } else {
                        setRefValue(`${item.positionX}/${item.positionY}`, item)
                    }
                })
            }
        }

        tErrors.forEach((err) => sendNotification(err.message, NOTIFICATION_STATUS.ERROR))
        setIsLoading(false)
    }

    const onChangeInput = useCallback(
        (
            { target: { value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            errorField: { value: string | number | null; error: ErrorType },
        ) => {
            if (inputRefs.current) {
                setRefValue(`${errorField.error.positionX}/${errorField.error.positionY}`, value)
            }
        },
        [],
    )

    const onChangeSelect = useCallback(
        (
            { target: { value } }: SelectChangeEvent<unknown>,
            errorField: { value: string | number | null; error: ErrorType },
        ) => {
            if (inputRefs.current) {
                setRefValue(`${errorField.error.positionX}/${errorField.error.positionY}`, value as string)
            }
        },
        [],
    )

    const onChangeChecked = useCallback((checked: boolean, errorField: ErrorFieldType) => {
        if (inputRefs.current) {
            setRefValue(`${errorField.error.positionX}/${errorField.error.positionY}`, checked ? 1 : 0)
        }
    }, [])

    const onChangeDate = useCallback((date: Date | null, errorField: ErrorFieldType) => {
        if (inputRefs.current) {
            setRefValue(`${errorField.error.positionX}/${errorField.error.positionY}`, `${date?.toISOString()}`)
        }
    }, [])

    const switchErrorField = (errorField: ErrorFieldType) => {
        if (errorField?.error.fetch) {
            return (
                <CustomInput
                    value={errorField?.value}
                    readOnly
                    variant={'white-grey'}
                    errorText={errorField.error.message}
                    onClick={() => setOpenErrorDialog(errorField)}
                    className={s.input}
                />
            )
        }

        switch (errorField?.error.expectedType) {
            case ExpectedFieldType.NUMBER:
                return (
                    <CustomInput
                        defaultValue={
                            inputRefs?.current[`${errorField.error.positionX}/${errorField.error.positionY}`].value
                        }
                        id={`${errorField.error.positionX}/${errorField.error.positionY}`}
                        errorText={errorField.isValid ? '' : errorField.error.message}
                        variant={'white-grey'}
                        className={s.input}
                        onChange={(e) => onChangeInput(e, errorField)}
                        readOnly={errorField.isValid}
                        type={'number'}
                    />
                )
            case ExpectedFieldType.DATE:
                return (
                    <EmptyDatePicker
                        dateValue={
                            inputRefs?.current[`${errorField.error.positionX}/${errorField.error.positionY}`]
                                .value as string
                        }
                        onChange={(date) => onChangeDate(date, errorField)}
                        errorText={errorField.error.message}
                        maxDate={new Date()}
                    />
                )
            case ExpectedFieldType.CHECKBOX:
                return (
                    <div className={cn(s.checkBox, !errorField.isValid ? s.error : '')}>
                        <CustomCheckBox
                            disabled={errorField.isValid}
                            onChange={(e) => onChangeChecked(e.target.checked, errorField)}
                        />
                    </div>
                )
            default:
                if (errorField.error.message.includes('availability')) {
                    return (
                        <PureCustomSelect
                            values={getOptionsFromEnum(Availabilities)}
                            rootClassName={s.select}
                            backgroundColor={'transparent'}
                            defaultValue={
                                inputRefs?.current[`${errorField.error.positionX}/${errorField.error.positionY}`]?.value
                            }
                            onChange={(e) => onChangeSelect(e, errorField)}
                            errorText={errorField.isValid ? '' : errorField.error.message}
                            readOnly={errorField.isValid}
                        />
                    )
                }
                if (errorField.error.message.includes('phone')) {
                    return (
                        <ReactInputMask
                            maskPlaceholder={null}
                            alwaysShowMask={true}
                            mask="+1 999 999 9999"
                            onChange={(e) => onChangeInput(e, errorField)}
                        >
                            <CustomInput
                                errorText={errorField.isValid ? '' : errorField.error.message}
                                id="phone"
                                className={s.input}
                                variant={'white-grey'}
                            />
                        </ReactInputMask>
                    )
                }
                return (
                    <CustomInput
                        id={`${errorField.error.positionX}/${errorField.error.positionY}`}
                        defaultValue={
                            inputRefs?.current[`${errorField.error.positionX}/${errorField.error.positionY}`]?.value
                        }
                        errorText={errorField.isValid ? '' : errorField.error.message}
                        variant={'white-grey'}
                        className={s.input}
                        onChange={(e) => onChangeInput(e, errorField)}
                        readOnly={errorField.isValid}
                    />
                )
        }
    }

    return (
        <>
            <CommonDialog
                onClose={onCloseClick}
                isOpen={importDialog.isOpen}
                title={`Import ${
                    importDialog.table !== ImportModelsEnum.INVENTORY_ITEM
                        ? capitalized(importDialog.table?.split('-').at(0) ?? '')
                        : 'Inventory Item'
                }s`}
                dialogStyle={{ overflowY: 'auto', width: 'auto', maxWidth: '100%' }}
            >
                <ProgressBar show={isLoading} />
                {data.length ? (
                    <>
                        <TableContainer sx={{ borderRadius: '10px' }} className={s.table}>
                            <T.Table stickyHeader sx={{ borderCollapse: 'initial!important' }}>
                                <T.TableHead>
                                    <T.TableHeadRow>
                                        {colDefs?.map((column) => (
                                            <T.TableHeadData
                                                align="center"
                                                key={column}
                                                className={cn(
                                                    s.theadTitle,
                                                    invalidTitles[column]
                                                        ? s.errorTitle
                                                        : unexpectedTitles[column]
                                                        ? s.unexpectedTitle
                                                        : '',
                                                )}
                                            >
                                                {column}
                                            </T.TableHeadData>
                                        ))}
                                    </T.TableHeadRow>
                                </T.TableHead>
                                <T.TableBody>
                                    {data?.map((item, rowIndex) => {
                                        return (
                                            <T.TableBodyRow key={item + rowIndex}>
                                                {colDefs?.map((column, columnIndex) => {
                                                    const errorField =
                                                        inputRefs?.current[`${columnIndex}/${rowIndex}`] &&
                                                        !inputRefs?.current[`${columnIndex}/${rowIndex}`]?.isValid
                                                            ? inputRefs.current[`${columnIndex}/${rowIndex}`]
                                                            : null
                                                    return errorField ? (
                                                        <T.TableBodyData align="center" key={columnIndex}>
                                                            {switchErrorField(errorField)}
                                                        </T.TableBodyData>
                                                    ) : (
                                                        <T.TableBodyData align="center" key={column}>
                                                            {(!unexpectedTitles[column] &&
                                                                inputRefs?.current[`${columnIndex}/${rowIndex}`]
                                                                    ?.value) ??
                                                                item[column]}
                                                        </T.TableBodyData>
                                                    )
                                                })}
                                            </T.TableBodyRow>
                                        )
                                    })}
                                </T.TableBody>
                            </T.Table>
                        </TableContainer>
                    </>
                ) : (
                    <>
                        <FilesDropzoneLoader
                            className={s.drop}
                            onLoadFiles={(file) => {
                                setCommonFile(file[0])
                                importExcel(file[0])
                            }}
                            accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [] }}
                        />
                        <div className={s.link}>
                            <TextButton
                                icon={<Icons.BlueDownloadSVG />}
                                text="Download our Template"
                                colorText={'#1B6BC0'}
                                href={`/assets/importTemplates/${importDialog.table?.split('-').at(0)}.xlsx`}
                            />
                        </div>
                    </>
                )}

                {!!data.length && (
                    <Button variants={'filled-blue'} onClick={() => onContinueClick()} className={s.button}>
                        Save
                    </Button>
                )}
            </CommonDialog>

            <CommonDialog
                onClose={() => setOpenErrorDialog(null)}
                isOpen={!!openErrorDialog}
                title={`Edit ${capitalized(importDialog.table ?? '')}`}
            >
                <CorrectionErrorDialog
                    errorField={openErrorDialog}
                    validatedColumns={validatedColumns}
                    onSave={(key, value) => {
                        onContinueClick(key, value)
                        setRefValue(key, value)
                        setOpenErrorDialog(null)
                    }}
                />
            </CommonDialog>
        </>
    )
}
