import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import {
    addAssetToWorkOrder,
    addFilesToWorkOrder,
    createWorkOrder,
    deleteAssetFromWorkOrder,
    deleteWorkOrderFile,
    editWorkOrder,
    getAllWorkOrdersList,
    getInventoryItemsListForWo,
    getWorkOrderById,
    getWorkOrderFilesList,
    joinToWorkOrder,
    updateWOTechnician,
} from 'api/workOrders'
import { CommonOtherResponseType, CommonResponseType, NOTIFICATION_STATUS } from 'types'
import { CurrentWorkOrderType, WorkOrdersState, WorkOrderType } from './types'
import sendNotification from 'lib/notification'
import { InventoryItemType } from 'slices/inventoryItems'

export const extraReducers = (builder: ActionReducerMapBuilder<WorkOrdersState>) => {
    builder.addCase(getAllWorkOrdersList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getAllWorkOrdersList.fulfilled,
        (state, action: PayloadAction<CommonOtherResponseType<WorkOrderType>>) => {
            state.isWaitingForResponse = false
            state.workOrders = action.payload.payload
            state.workOrdersCount = action.payload.count
        },
    )
    builder.addCase(getAllWorkOrdersList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getWorkOrderById.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getWorkOrderById.fulfilled, (state, action: PayloadAction<CurrentWorkOrderType>) => {
        state.isWaitingForResponse = false
        state.currentWorkOrder = action.payload
    })
    builder.addCase(getWorkOrderById.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(createWorkOrder.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createWorkOrder.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Work Order has been created', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(createWorkOrder.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(editWorkOrder.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(editWorkOrder.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Work Order has been edited', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(editWorkOrder.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(addAssetToWorkOrder.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(addAssetToWorkOrder.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Asset has been added', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(addAssetToWorkOrder.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(deleteAssetFromWorkOrder.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteAssetFromWorkOrder.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Asset has been deleted from work order', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(deleteAssetFromWorkOrder.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    //wo files
    builder.addCase(addFilesToWorkOrder.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(addFilesToWorkOrder.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(addFilesToWorkOrder.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getWorkOrderFilesList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getWorkOrderFilesList.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(getWorkOrderFilesList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(deleteWorkOrderFile.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteWorkOrderFile.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(deleteWorkOrderFile.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(joinToWorkOrder.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(joinToWorkOrder.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(joinToWorkOrder.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(updateWOTechnician.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(updateWOTechnician.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(updateWOTechnician.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getInventoryItemsListForWo.pending, (state) => {
        state.inventoryItemsInWo.isLoading = true
    })
    builder.addCase(
        getInventoryItemsListForWo.fulfilled,
        (state, action: PayloadAction<CommonOtherResponseType<InventoryItemType>>) => {
            state.inventoryItemsInWo.count = action.payload.count
            if (state.inventoryItemsInWo.page === 1) {
                state.inventoryItemsInWo.items = action.payload.payload
            } else {
                state.inventoryItemsInWo.items = [...state.inventoryItemsInWo.items, ...action.payload.payload]
            }

            state.inventoryItemsInWo.isLoading = false
        },
    )
    builder.addCase(getInventoryItemsListForWo.rejected, (state) => {
        state.inventoryItemsInWo.isLoading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
}
