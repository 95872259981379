import { axiosErrorHandler, client } from './setupAxios'
import { checkIsGoodStatus } from 'lib/common'
import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'

interface VerifyPasswordData {
    email: string
    password: string
    callback?: () => void
}

interface SendVerificationData {
    email: string
}

interface VerifyCodeData {
    verificationCode: number
    email: string
    password: string
}

interface VerifyEmailData {
    email: string
    callback?: () => void
}

interface VerifyCodeForgot {
    email: string
    verificationCode: number
    callback?: () => void
}

interface ForgotPasswordData {
    email: string
    password: string
    verificationCode: string | number
    callback?: () => void
}

interface AcceptInviteData {
    password: string
    emailToken: string
    callback?: () => void
}

const verifyPassword = createAppAsyncThunk(
    'auth/verify-password',
    async ({ email, password, callback }: VerifyPasswordData, { rejectWithValue, dispatch }) => {
        try {
            const result = await client.post(`/api/auth/verify-password`, {
                email,
                password,
            })
            if (checkIsGoodStatus(result.status)) {
                const resultSendCode = await client.post(`/api/auth/send-verification-code`, {
                    email,
                })

                if (checkIsGoodStatus(resultSendCode.status)) {
                    callback && callback()
                }
            }
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

const sendVerificationCode = createAppAsyncThunk(
    'auth/sendVerificationCode',
    async ({ email, callback }: VerifyEmailData, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/auth/send-verification-code`, {
                email,
            })

            if (checkIsGoodStatus(result.status)) {
                callback && callback()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

const verifyCodeLogin = createAppAsyncThunk(
    'auth/verifyCodeLogin',
    async ({ email, password, verificationCode }: VerifyCodeData, { rejectWithValue, dispatch }) => {
        try {
            const resultVerify = await client.post(`/api/auth/verify-verification-code`, {
                email,
                verificationCode,
            })

            const resultSingIn = await client.patch(`/api/auth/sign-in`, {
                email,
                password,
                verificationCode,
            })

            return { ...resultSingIn.data, ...resultVerify.data }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const verifyEmail = createAppAsyncThunk(
    'auth/verifyEmail',
    async ({ email, callback }: VerifyEmailData, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/user/by-email`, {
                params: {
                    email,
                },
            })
            if (result?.data?.status === 'PENDING') {
                sendNotification(
                    'You cannot log in because you have not yet activated your account.' +
                        ' Please check your email and follow the link we sent there. ' +
                        'Create a password and then you can log in and use the system',
                    NOTIFICATION_STATUS.ERROR,
                )
                return result.data
            }
            if (checkIsGoodStatus(result.status)) {
                await client.post(`/api/auth/send-verification-code`, {
                    email,
                })
            }

            if (checkIsGoodStatus(result.status)) {
                callback && callback()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const verifyCodeForgot = createAppAsyncThunk(
    'auth/verifyEmail',
    async ({ email, verificationCode, callback }: VerifyCodeForgot, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/auth/verify-verification-code`, {
                email,
                verificationCode,
            })

            if (checkIsGoodStatus(result.status)) {
                callback && callback()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getMe = createAppAsyncThunk('user/getMe', async (_, { rejectWithValue, dispatch }) => {
    try {
        const resultUser = await client.get(`/api/user/me-partial`)

        return resultUser.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const logout = createAppAsyncThunk('auth/logout', async (_, { rejectWithValue, dispatch }) => {
    try {
        const resultUser = await client.delete(`/api/auth/logout`)

        return resultUser.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

const getVerifyCode = createAppAsyncThunk(
    'auth/getVerifyCode',
    async ({ email }: SendVerificationData, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/auth/send-verification-code`, {
                email,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const forgotPassword = createAppAsyncThunk(
    'auth/setNewPassword',
    async ({ email, password, verificationCode, callback }: ForgotPasswordData, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/auth/forgot-password`, {
                email,
                password,
                verificationCode,
            })

            if (checkIsGoodStatus(result.status)) {
                callback && callback()
            }
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const acceptInvite = createAppAsyncThunk(
    'auth/acceptInvite',
    async ({ password, emailToken, callback }: AcceptInviteData, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/auth/accept-invitation`, {
                password,
                emailToken,
            })

            if (checkIsGoodStatus(result.status)) {
                sendNotification('Verification code was sent to your e-mail address!', NOTIFICATION_STATUS.SUCCESS)
                callback && callback()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

const updateUserNotificationSettings = createAppAsyncThunk<any, { [key: string]: boolean | string; userId: string }>(
    'users/updateUserNotificationSettings',
    async ({ ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/user/notification-settings`, { ...arg })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export { getVerifyCode, verifyPassword, verifyCodeLogin, sendVerificationCode, updateUserNotificationSettings }
