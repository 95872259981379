import styles from './styles.module.scss'
import { FC } from 'react'
import classNames from 'classnames'
import Icons from '../../assets/icons'
import { noop } from 'lib/common'

type Props = {
    onClose: () => void
    className?: string
}

const CloseButton: FC<Props> = ({ onClose = noop, className }) => {
    return (
        <div
            className={classNames(styles.root, className)}
            onClick={(e) => {
                e.stopPropagation()
                onClose()
            }}
        >
            <Icons.CloseBtn />
        </div>
    )
}

export default CloseButton
