import { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes } from 'react'

import cn from 'classnames'

import './CheckboxInput.scss'
import { Paragraph } from '../Typography/Typography'

type DefaultInputPropsType = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
type RadioInputType = DefaultInputPropsType & {
    onChangeChecked?: (checked: boolean) => void
    className?: string
}
export const CheckboxInput = ({
    type,
    onChange,
    onChangeChecked,
    className,
    children,
    ...restProps
}: RadioInputType) => {
    const onChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e)
        }
        if (onChangeChecked) {
            onChangeChecked(e.currentTarget.checked)
        }
    }

    return (
        <span className={cn('CheckboxInput', className)}>
            {children && <Paragraph size={'s'}>{children}</Paragraph>}
            <input type="checkbox" onChange={onChangeCallback} className="CheckboxInput_Input" {...restProps} />
        </span>
    )
}
