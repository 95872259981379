import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { CommonResponseType, DirectionEnum, USER_ROLE } from 'types'
import { axiosErrorHandler, client } from 'api/setupAxios'
import { AxiosResponse } from 'axios'
import { SUBCONTRACTORS_PER_PAGE } from 'pages/SubcontractorsPage/config'
import { SubcontractorType } from 'slices/subcontractor'
import { checkIsGoodStatus } from 'lib/common'
import { EditSubcontractorType, NewSubcontractorType } from 'components/NewSubcontractor/config'

export const getAllSubcontractorsList = createAppAsyncThunk<any, { assetCategoryId?: string }>(
    'subcontractor/getAllSubcontractorsList',
    async ({ assetCategoryId }, { rejectWithValue, getState }) => {
        const page = getState().subcontractors.subcontractorPaginationPage
        const search = getState().subcontractors.subcontractorsSearchValue
        const role = getState().auth?.user?.role
        const customerId = getState().auth?.user?.customerId || ''
        const regionId = getState().auth?.user?.regionId || ''
        const sortDirection = getState().subcontractors.subcontractorsSettings.sortDirection
        const sortField = getState().subcontractors.subcontractorsSettings.sortField
        const filters = getState().subcontractors.subcontractorsSettings.filters

        const params = {
            page,
            size: SUBCONTRACTORS_PER_PAGE,
            keySearchValue: search !== '' ? search : undefined,
            sortField,
            sortDirection,
            customerId: role === USER_ROLE.ADMIN ? customerId : undefined,
            assetCategoriesId: assetCategoryId
                ? [assetCategoryId]
                : filters?.responsibilities?.length
                ? filters?.responsibilities
                : undefined,
            isEmergency: filters?.hasEmergency ? filters?.hasEmergency : undefined,
            isApprovedByProcurement: filters?.isApproved ? filters?.isApproved : undefined,
            availabilities: filters?.availabilities?.length ? filters?.availabilities : undefined,
            regionIdes:
                role && ![USER_ROLE.ADMIN, USER_ROLE.REQUESTOR].includes(role)
                    ? [regionId]
                    : filters?.regions?.length
                    ? filters?.regions.map((item) => item?.value)
                    : undefined,
            cities: filters?.cities?.length ? filters?.cities : undefined,
            startLastUpdateDate: filters?.lastUpdateFrom ? filters?.lastUpdateFrom : undefined,
            endLastUpdateDate: filters?.lastUpdateTo ? filters?.lastUpdateTo : undefined,
            status: undefined, //TODO: add in filters /[ACTIVE, ARCHIVED]
            isArchived: filters?.isShowArchived ? filters?.isShowArchived : undefined,
        }

        try {
            const result = await client.get<
                CommonResponseType<SubcontractorType>,
                AxiosResponse<CommonResponseType<SubcontractorType>>
            >(`/api/subcontractor/get-subcontractors-list`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetSubcontractorsType = {
    page: number
    keySearchValue?: string
    assetCategoriesId?: string[]
    regionIdes?: string[]
    customerId?: string
}

export const getSubcontractors = createAppAsyncThunk<any, GetSubcontractorsType>(
    'subcontractor/getSubcontractors',
    async (arg, { rejectWithValue, getState }) => {
        const params = {
            size: SUBCONTRACTORS_PER_PAGE,
            ...arg,
        }

        try {
            const result = await client.get<
                CommonResponseType<SubcontractorType>,
                AxiosResponse<CommonResponseType<SubcontractorType>>
            >(`/api/subcontractor/get-subcontractors-list`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetEmergencyContacts = {
    page?: number
    search?: string
    isEmergency?: boolean
    customerId: string
}

export const getEmergencyContactsList = createAppAsyncThunk<any, GetEmergencyContacts>(
    'subcontractor/getEmergencyContactsList',
    async (
        { page = 1, search = '', customerId, isEmergency = false }: GetEmergencyContacts,
        { rejectWithValue, getState },
    ) => {
        const params = {
            page,
            size: SUBCONTRACTORS_PER_PAGE,
            keySearchValue: search !== '' ? search : undefined,
            customerId,
            sortField: 'id',
            sortDirection: DirectionEnum.ASC,
            isEmergency,
        }

        try {
            const result = await client.get<
                CommonResponseType<SubcontractorType>,
                AxiosResponse<CommonResponseType<SubcontractorType>>
            >(`/api/subcontractor/get-subcontractors-list`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getSubcontractorById = createAppAsyncThunk(
    'subcontractor/getSubcontractorById',
    async ({ id }: { id: string }, { rejectWithValue }) => {
        const params = { subcontractorId: id }
        try {
            const result = await client.get<SubcontractorType, AxiosResponse<SubcontractorType>>(
                `/api/subcontractor/get-subcontractor-by-id`,
                { params },
            )
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createSubcontractor = createAppAsyncThunk<
    any,
    NewSubcontractorType & { checkResponse?: (id: string, name?: string) => void }
>('subcontractor/createSubcontractor', async ({ checkResponse, ...arg }, { rejectWithValue, getState }) => {
    try {
        const result = await client.post<SubcontractorType, AxiosResponse<SubcontractorType>>(
            `/api/subcontractor/create-subcontractor`,
            {
                ...arg,
                customerId: getState().auth.user?.customerId ?? '',
            },
            { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse(result.data.id, result.data.name)
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const editSubcontractor = createAppAsyncThunk<any, EditSubcontractorType & { checkResponse?: () => void }>(
    'subcontractor/editSubcontractor',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.patch<SubcontractorType, AxiosResponse<SubcontractorType>>(
                `/api/subcontractor/update-subcontractor`,
                { ...arg },
            )
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteAssetPreferredSubcontractor = createAppAsyncThunk(
    'subcontractor/deleteAssetPreferredSubcontractor',
    async (
        {
            subcontractorId,
            assetId,
            checkResponse,
        }: { subcontractorId: string; assetId: string; checkResponse?: () => void },
        { rejectWithValue },
    ) => {
        try {
            const result = await client.delete(`/api/subcontractor/delete-asset-preferred-contractor`, {
                data: { subcontractorId, assetId },
            })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getSubcontractorsForAutocomplete = createAppAsyncThunk<
    any,
    { page: number; search: string; customerId?: string }
>(
    'subcontractor/getSubcontractorsForAutocomplete',
    async ({ page, search, customerId }, { rejectWithValue, getState }) => {
        const role = getState().auth?.user?.role
        const regionId = getState().auth?.user?.regionId || ''

        const params = {
            page,
            size: SUBCONTRACTORS_PER_PAGE,
            keySearchValue: search !== '' ? search : undefined,
            sortField: 'id',
            sortDirection: DirectionEnum.ASC,
            customerId: role === USER_ROLE.ADMIN ? customerId : undefined,
            regionIdes: role && ![USER_ROLE.ADMIN, USER_ROLE.REQUESTOR].includes(role) ? [regionId] : undefined,
        }

        try {
            const result = await client.get<
                CommonResponseType<SubcontractorType>,
                AxiosResponse<CommonResponseType<SubcontractorType>>
            >(`/api/subcontractor/get-subcontractors-list`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
