import { PageTabType } from 'components/PageTabPanel'
import { TableColumnType } from 'components/Table'
import { FiltersForReportsType } from 'components/CommonSavedView'
import { PMFiltersType } from 'slices/pm'

export enum PMKeysEnum {
    PM_NUMBER = 'PM Number',
    SCHEDULED_DATE = 'Scheduled Date',
    TITLE = 'Title',
    PM_TYPE = 'PM Type',
    ASSET = 'Asset',
    ASSET_IS_CRITICAL = 'Asset Is Critical',
    SERVICER = 'Servicer',
    CREATED_DATE = 'Date Created',
    COMPLETED_DATE = 'Completion Date',
    DESCRIPTION = 'Description',
    LOCATION = 'Location',
    FREQUENCY = 'Frequency',
    COST = 'Cost',
}

export const requiredPMColumns: TableColumnType[] = [
    { label: PMKeysEnum.PM_NUMBER, name: 'PMNumber' },
    { label: PMKeysEnum.SCHEDULED_DATE, name: 'scheduledDate' },
]
export const initialPMColumns: TableColumnType[] = [
    { label: PMKeysEnum.TITLE, name: 'title', sort: true },
    { label: PMKeysEnum.PM_TYPE, name: 'subtype' },
    { label: PMKeysEnum.ASSET, name: 'asset' },
    //{ label: PMKeysEnum.ASSET_IS_CRITICAL, name: 'assetIsCritical' },
    { label: PMKeysEnum.SERVICER, name: 'servicer' },
]
export const pMTableColumns: TableColumnType[] = initialPMColumns.concat([
    { label: PMKeysEnum.CREATED_DATE, name: 'createdDate' },
    { label: PMKeysEnum.COMPLETED_DATE, name: 'completedDate' },
    { label: PMKeysEnum.DESCRIPTION, name: 'description' },
    { label: PMKeysEnum.ASSET_IS_CRITICAL, name: 'assetIsCritical' },
    { label: PMKeysEnum.LOCATION, name: 'location' },
    { label: PMKeysEnum.FREQUENCY, name: 'frequency' },
    //{ label: PMKeysEnum.COST, name: 'cost' },
    /*{ label: PMKeysEnum.PRIORITY, name: 'priority' },
    { label: PMKeysEnum.STATUS, name: 'status' },*/
])

export enum PMsListPageTab {
    PM_PLANS = 'PM_PLANS',
    SAVED_VIEWS = 'SAVED_VIEWS',
}

export enum PmPlansListPageTab {
    CONTRACTED = 'Contracted',
    RECURRING = 'Recurring',
}
export const tabs: PageTabType[] = [
    {
        id: PMsListPageTab.PM_PLANS,
        label: 'Maintenance',
    },
    {
        id: PMsListPageTab.SAVED_VIEWS,
        label: 'Saved Views',
    },
]

export const PM_PER_PAGE = 50

export const getPMColumns = (columns: string[]) => {
    const otherColumns: TableColumnType[] = []
    for (const val of columns.slice(requiredPMColumns.length)) {
        const currentColumn = pMTableColumns.find((item) => item.label === val)

        if (currentColumn) {
            otherColumns.push(currentColumn)
        }
    }
    return otherColumns
}

export const getPMFilters = (filter?: FiltersForReportsType<any>[]) => {
    const pMFilters: PMFiltersType = {}

    if (filter) {
        for (const val of Object.values(filter)) {
            /*if ('buildingId' in val) {
                // @ts-ignore
                workOrderFilters.buildings = val?.buildingId?.map((item: string) => ({ value: item, label: '' }))
            }
            if ('priority' in val) {
                workOrderFilters.priorities = val.priority as string[]
            }
            if ('endDate' in val) {
                // @ts-ignore
                if ('>=' in val.endDate && val.endDate['>=']) {
                    workOrderFilters.completionDateFrom = new Date(val.endDate['>='] as Date)
                }
                // @ts-ignore
                if ('<=' in val.endDate && val.endDate['<=']) {
                    workOrderFilters.completionDateTo = new Date(val.endDate['<='] as Date)
                }
            }*/
        }
    }
    return pMFilters
}
