import { Route, Routes, useNavigate } from 'react-router-dom'
import React, { lazy } from 'react'
import { useAppSelector } from '../hooks'
import { permissionsUser } from '../store/selectors'
import { ROUTES } from './const'

const FloorPlanViewer = lazy(() => import('../pages/FloorPlanViewer'))

const FloorPlanRoutes = () => {
    const navigate = useNavigate()
    const { isSuperAdmin } = useAppSelector(permissionsUser)

    if (isSuperAdmin) {
        navigate(ROUTES.DASHBOARD)
    }
    return (
        <Routes>
            <Route index element={<FloorPlanViewer />} />
            <Route path="/:floorId" element={<FloorPlanViewer />} />
            <Route path="/:floorId/plans/:planId/pages/:pageId" element={<FloorPlanViewer />} />
            <Route path="/:floorId/plans/:planId" element={<FloorPlanViewer />} />
        </Routes>
    )
}

export default FloorPlanRoutes
