import React, { ReactNode } from 'react'
import { Paragraph } from '../Typography/Typography'
import { Paper } from '@mui/material'
import cn from 'classnames'
import s from './styles.module.scss'

type CustomShowMoreType = {
    children: ReactNode
    text: string
    onShowMoreClick: (event: React.MouseEvent<HTMLParagraphElement>) => void
    className?: string
    disabled?: boolean
}
export const CustomShowMore = ({
    onShowMoreClick,
    disabled = false,
    text,
    children,
    className,
}: CustomShowMoreType) => {
    return (
        <Paper className={cn(s.showMore, className)}>
            {children}

            {!disabled && (
                <Paragraph
                    size={'s'}
                    weight={'semi-bold'}
                    className={s.showMore_text}
                    onMouseDown={(e) => onShowMoreClick(e)}
                >
                    {text}
                </Paragraph>
            )}
        </Paper>
    )
}
