import { FocusEventHandler } from 'react'
import * as yup from 'yup'
import { REQUIRED_FIELD, TOO_LONG } from 'pages/NewAssetPage/config'
import { LengthConstrains } from 'types'

export type ItemForAutocompleteType = {
    value: string
    label: string
} // | null

export type AutocompleteType = {
    value: string
    label: string
}

export const yupAutocompleteItem = yup.object().shape({
    value: yup.string().max(LengthConstrains.L_50, TOO_LONG).required(REQUIRED_FIELD),
    label: yup.string().required(REQUIRED_FIELD),
})

export const commonItem = yup.object().shape({
    id: yup.string().max(LengthConstrains.L_50, TOO_LONG).required(REQUIRED_FIELD),
    name: yup.string().max(LengthConstrains.L_100, TOO_LONG).required(REQUIRED_FIELD),
})

type IdDependenceKeyType =
    | 'roomIdes'
    | 'floorIdes'
    | 'buildingId'
    | 'regionId'
    | 'regionIdes'
    | 'assetId'
    | 'categoryId'
    | 'typeIdes'
    | 'categoryIdes'
    | 'buildingIdes'
    | 'isAmenity'
    | 'userId'
    | 'customerId'
    | 'tenantCompanyId'
    | 'types'
    | 'roomId'
    | 'hasActiveLeases'

export type IdDependenceType = { [key in IdDependenceKeyType]?: string | string[] | boolean }

type OnePropsType = {
    currentValue: ItemForAutocompleteType | null
    multiple?: false
    onChangeCallback: (currentValue: ItemForAutocompleteType, values?: any[]) => void
}

type MultiPropsType = {
    currentValue: ItemForAutocompleteType[] | null
    multiple?: true
    onChangeCallback: (currentValues: ItemForAutocompleteType[], values?: any[]) => void
}

export type AutocompletePropsType = {
    errorText?: string
    readOnly?: boolean
    disabled?: boolean
    disableClearable?: boolean
    label?: string
    textFieldClassName?: string
    textFieldPlaceholder?: string
    handleBlur?: FocusEventHandler
    width?: string | number
    isSearch?: boolean
    onDeleteItemClick?: (id: string) => void
    idDependence?: IdDependenceType
} & (MultiPropsType | OnePropsType)
