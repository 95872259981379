import styles from './styles.module.scss'
import { Lease } from '../../../../../../../../slices/users'
import CloseButton from '../../../../../../../../components/CloseButton'
import React, { useCallback, useState } from 'react'
import { Paragraph } from '../../../../../../../../ui-kit/components/Typography/Typography'
import SaveCancelPanel from '../../../../../../../../components/SaveCancelPanel'
import CustomInput from '../../../../../../../../ui-kit/components/CustomInput'
import { CustomDatePicker } from '../../../../../../../../ui-kit/components/CustomDatePicker'
import CustomCheckBox from '../../../../../../../../ui-kit/components/CustomCheckBox'
import { ItemForAutocompleteType, RoomAutoComplete } from '../../../../../../../../components/CommonAutoComplete'
import { useAppDispatch } from '../../../../../../../../hooks'
import { updateTenantLease } from '../../../../../../../../api/tenant'
import sendNotification from '../../../../../../../../lib/notification'
import { NOTIFICATION_STATUS } from '../../../../../../../../types'
import { noop } from '../../../../../../../../lib/common'
import Icons from '../../../../../../../../assets/icons'

type Props = {
    onClose: () => void
    lease: Lease
    tenantCompanyId: string
    update?: () => void
}

const TenantLeaseEnd = ({ onClose, lease, tenantCompanyId, update = noop }: Props) => {
    const dispatch = useAppDispatch()

    const [moveOutDate, setMoveOutDate] = useState<Date | null>(null)
    const [description, setDescription] = useState<string>('')

    const [room, setRoom] = useState<ItemForAutocompleteType | null>(null)

    const [isRelocate, setIsRelocate] = useState(false)

    const onChangeRoom = (item: ItemForAutocompleteType | null, allRooms?: any[]) => {
        setRoom(item)
    }

    const handleEndLease = useCallback(async () => {
        if (!moveOutDate) {
            return
        }

        try {
            await dispatch(
                updateTenantLease({
                    leaseId: lease.id,
                    option: 'END',
                    moveOutDate,
                    leaseType: lease.leaseType,
                    description,
                    relocateUsersToRoomId: room?.value,
                }),
            ).unwrap()

            onClose()
            update()
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }, [moveOutDate, description])

    return (
        <div className={styles.root}>
            <CloseButton onClose={onClose} />

            <div className={styles.formContent}>
                <Paragraph size="l" weight="semi-bold" position="center">
                    End Lease
                </Paragraph>

                <Paragraph size="smd" weight="tiny" position="center" className={styles.text}>
                    {`You are about to end the current lease for ${lease?.room.name}, which will remove the tenant from
                        this room/office and prevent all of their users from accessing the system`}
                </Paragraph>

                <CustomDatePicker
                    minDate={new Date()}
                    className={styles.input}
                    label={'Move out date*'}
                    dateValue={moveOutDate}
                    onChange={setMoveOutDate}
                />

                <Paragraph size="smd" weight="tiny" position="center" className={styles.label}>
                    Please enter forwarding information, lease penalties, direct deposit returns, or any other pertinent
                    information
                </Paragraph>

                <CustomInput
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    rows={4}
                    variant="white-grey"
                />

                <CustomCheckBox
                    className={styles.checkbox}
                    label="Relocate users of this space to a new room/office?"
                    checked={isRelocate}
                    onChange={(e, checked) => setIsRelocate(checked)}
                />

                {isRelocate && (
                    <RoomAutoComplete
                        label="Room/Office"
                        idDependence={{ tenantCompanyId }}
                        currentValue={room}
                        onChangeCallback={onChangeRoom}
                        isBuildingLabel
                        filteredValues={[lease.room.id]}
                    />
                )}

                {!isRelocate && (
                    <div className={styles.rowInfoNotify}>
                        <Icons.InfoCircleSVG />
                        <Paragraph size="s" color="red">
                            Users assigned to this room/office will also be removed from the system.
                        </Paragraph>
                    </div>
                )}
            </div>

            <div className={styles.saveWrap}>
                <SaveCancelPanel
                    disabledConfirm={!moveOutDate || (isRelocate && !room)}
                    onSave={handleEndLease}
                    saveButtonVariant="filled-red"
                    saveButtonText="End Lease"
                />
            </div>
        </div>
    )
}

export default TenantLeaseEnd
