import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import extraReducers from './extraReducers'
import {
    CalendarPickerMode,
    CalendarPickerValue,
    getCalendarValuesByMode,
} from 'pages/CalendarPage/components/CalendarWeekDayPicker'
import { Dayjs } from 'dayjs'
import { CalendarPageTab } from 'slices/calendar/types'
import { dropInLS, getSettingsFromLS, LsKeys } from 'lib/workWithLS'

export interface CalendarState {
    calendarDateValue: CalendarPickerValue
    calendarMode: CalendarPickerMode
    calendarTab: CalendarPageTab

    loading: boolean
    workOrdersSearch: string

    selectedDayOnMonthMode: Dayjs | null
}

const initialState: CalendarState = {
    loading: false,

    calendarMode: 'Day',
    calendarDateValue: getCalendarValuesByMode('Day'),
    workOrdersSearch: '',
    calendarTab: getSettingsFromLS('calendarTab', LsKeys.CALENDAR, CalendarPageTab.WO),

    selectedDayOnMonthMode: null,
}

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setCalendarDateValue: (state, action: PayloadAction<CalendarPickerValue>) => {
            state.workOrdersSearch = ''

            state.calendarDateValue = action.payload
        },
        setCalendarTab: (state, action: PayloadAction<CalendarPageTab>) => {
            dropInLS(LsKeys.CALENDAR, { calendarTab: action.payload })

            state.calendarTab = action.payload
        },

        setWorkOrdersSearch: (state, action: PayloadAction<string>) => {
            state.workOrdersSearch = action.payload
        },

        setCalendarMode: (state, action: PayloadAction<CalendarPickerMode>) => {
            state.selectedDayOnMonthMode = null

            state.calendarMode = action.payload
        },

        setSelectedDayOnMonthMode: (state, action: PayloadAction<Dayjs | null>) => {
            state.selectedDayOnMonthMode = action.payload
        },
    },
    extraReducers,
})

export const calendarActions = calendarSlice.actions
export const CalendarReducer = calendarSlice.reducer
