import { CardColumnItem, CardContainer, CardFlexRow } from '../CardsContentView'
import styles from './styles.module.scss'
import Icons, { UserTableSVG } from '../../assets/icons'
import { Paragraph } from '../../ui-kit/components/Typography/Typography'
import TooltipMenu, { TooltipMenuItem } from '../TooltipMenu'
import React, { useMemo, useState } from 'react'
import { Contact } from './types'
import { CommonDialog } from '../CommonDialog'
import { ContactForm } from '../ContactForm'
import { Avatar, Drawer } from '@mui/material'
import { User } from '../../slices/users'
import UserContactInfoLayout from '../UserContactInfoLayout'
import { useAppDispatch } from '../../hooks'
import { removeEmailContact, updateContact } from '../../api/contacts'
import { noop } from '../../lib/common'
import sendNotification from '../../lib/notification'
import { NOTIFICATION_STATUS } from '../../types'

type Props = {
    contact: Contact
    update?: () => void
    onUpdateContact?: (contact: Contact) => void
    onDeleteContact?: (contact: Contact) => void
    link: string
    types?: Array<string>
    disabled?: boolean
}

const ContactItemCard = ({
    contact,
    link,
    update = noop,
    onUpdateContact,
    onDeleteContact,
    types,
    disabled = false,
}: Props) => {
    const dispatch = useAppDispatch()

    const [isEdit, setIsEdit] = useState(false)
    const [isOpenUser, setIsOpenUser] = useState(false)

    const contactData = useMemo(() => {
        if (contact.user) {
            return contact.user
        } else {
            return contact
        }
    }, [contact])

    const handleDeleteContact = async () => {
        try {
            if (onDeleteContact) {
                onDeleteContact(contact)
            } else {
                await dispatch(
                    removeEmailContact({
                        email: contact.user ? contact.user.email : contact.email,
                        link,
                    }),
                ).unwrap()

                update()
            }
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }

    const handleSaveContact = async (newContact: Partial<Contact>) => {
        try {
            if (onUpdateContact) {
                onUpdateContact(newContact as Contact)
            } else {
                await dispatch(updateContact({ id: contact.id, ...newContact })).unwrap()
                update()
            }
            setIsEdit(false)
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }

    return (
        <CardContainer key={contact.id} height={'10.5rem'}>
            <CardFlexRow justifyContent="space-between" className={styles.row}>
                <CardFlexRow justifyContent="flex-start" className={styles.row}>
                    <Avatar
                        variant="rounded"
                        src={contact.user?.avatar?.url}
                        sx={{
                            width: '30px',
                            height: '30px',
                            background: 'transparent',
                        }}
                    >
                        {!contact.user ? <Icons.UsersSVG /> : <UserTableSVG />}
                    </Avatar>

                    <div>
                        <Paragraph size={'s'} weight="semi-bold">
                            {contactData?.firstName} {contactData?.lastName}
                        </Paragraph>

                        <Paragraph
                            className={styles.italic}
                            size={'s'}
                            weight="semi-bold"
                            color={!contact.user ? 'blue' : 'grey'}
                        >
                            {contact.user ? 'User' : 'Contact'}
                        </Paragraph>
                    </div>
                </CardFlexRow>

                {!disabled && (
                    <CardFlexRow justifyContent="flex-end" className={styles.row}>
                        <TooltipMenu classesRoot={styles.tooltip} widthPopper={'10rem'}>
                            <TooltipMenuItem
                                icon={contact.user ? <Icons.UserIconSVG /> : <Icons.Edit />}
                                title={contact.user ? 'User details' : 'Edit Contact'}
                                onClick={() => (contact.user ? setIsOpenUser(true) : setIsEdit(true))}
                            />
                            <TooltipMenuItem icon={<Icons.Trash />} title="Delete" onClick={handleDeleteContact} />
                        </TooltipMenu>
                    </CardFlexRow>
                )}
            </CardFlexRow>

            <CardFlexRow justifyContent="space-between">
                <CardColumnItem title="Title" value={contactData?.title ?? '-'} />
                <CardColumnItem title="Type" value={contactData?.type?.length > 0 ? contactData.type : '-'} />
            </CardFlexRow>

            <CardFlexRow justifyContent="space-between">
                <CardColumnItem title="Email" value={contactData?.email ?? '-'} />
                <CardColumnItem title="Phone" value={contactData?.phone ?? '-'} />
            </CardFlexRow>

            <CommonDialog
                title={'Edit Contact'}
                onClose={() => setIsEdit(false)}
                isOpen={isEdit}
                dialogClassName={styles.dialog}
            >
                <ContactForm
                    types={types}
                    onClose={() => setIsEdit(false)}
                    onSave={handleSaveContact}
                    contact={contactData}
                />
            </CommonDialog>

            {contact.user && (
                <Drawer anchor="right" open={isOpenUser} onClose={() => setIsOpenUser(false)}>
                    <UserContactInfoLayout user={contact.user as User} onClose={() => setIsOpenUser(false)} />
                </Drawer>
            )}
        </CardContainer>
    )
}

export default ContactItemCard
