import { useAppSelector } from 'hooks/redux'
import { vendorsFiltersSelect } from 'store/selectors'
import { removeBlankFields } from 'lib/common'

export const useVendorsFiltersForReports = () => {
    const vendorsFilters = useAppSelector(vendorsFiltersSelect)
    let filtersCount = 0

    if (vendorsFilters) {
        for (const [key, val] of Object.entries(removeBlankFields(vendorsFilters))) {
            if (key === 'categoryIdes') {
                filtersCount += 1
            }
            if (key === 'typeIdes') {
                filtersCount += 1
            }

            if (key === 'lastOrderDateTo') {
                filtersCount += 1
            }
        }
    }

    return { filtersCount }
}
