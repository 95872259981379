import SearchInput from 'ui-kit/components/SearchInput/SearchInput'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector, useInfinityScroll } from 'hooks'
import { getUsersList, getUsersListByRequesterRole } from 'api/user'
import { NOTIFICATION_STATUS, User } from 'types'
import sendNotification from 'lib/notification'
import * as T from 'components/Table'
import { TableContainer } from '@mui/material'
import SaveCancelPanel from 'components/SaveCancelPanel'
import { currentCustomerId } from 'store/selectors'

import styles from './styles.module.scss'
import UserItemRow from './UserItemRow'

type Props = {
    onAddContacts: (users: Array<User>) => void
    userRole?: string
    customerId?: string
    oldUserIds?: string[]
    tenantCompanyId?: string
    regionIdes?: string[]
}

const UsersCheckBoxList = ({ onAddContacts, userRole, oldUserIds, customerId, tenantCompanyId, regionIdes }: Props) => {
    const dispatch = useAppDispatch()
    const customerIdCurrent = useAppSelector(currentCustomerId)

    const [users, setUsers] = useState<Array<User>>([])
    const [selectedUsers, setSelectedUsers] = useState<Array<User>>([])

    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState('')

    const { setLastElement } = useInfinityScroll({
        fetchNextData: () => setPage(page + 1),
        hasMore: users.length < count,
    })

    const handleCheckUser = (val: User, checked: boolean) => {
        if (checked) {
            setSelectedUsers([...selectedUsers, val])
        } else {
            setSelectedUsers(selectedUsers.filter((item) => item.id !== val.id))
        }
    }

    const getUsers = useCallback(async () => {
        try {
            const res = await dispatch(
                userRole || tenantCompanyId
                    ? getUsersList({
                          value: search,
                          page,
                          userRole,
                          size: 15,
                          customerId: customerId || customerIdCurrent || '',
                          tenantCompanyId,
                          regionIdes,
                      })
                    : getUsersListByRequesterRole({
                          keySearchValue: search,
                          page,
                      }),
            ).unwrap()
            setCount(res.count)

            if (page === 1) {
                setUsers(res.rows)
            } else {
                setUsers([...users, ...res.rows])
            }
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }, [page, search, customerIdCurrent, customerId, tenantCompanyId])

    useEffect(() => {
        ;(async () => {
            await getUsers()
        })()
    }, [getUsers])

    return (
        <div className={styles.root}>
            <SearchInput
                placeholder="Search User"
                searchText={search}
                onChangeSearchText={(text) => {
                    setPage(1)
                    setSearch(text)
                }}
            />

            <TableContainer className={styles.tableContainer}>
                <T.Table stickyHeader>
                    <T.TableHead>
                        <T.TableHeadRow>
                            <T.TableHeadData width={25}>{''}</T.TableHeadData>
                            <T.TableHeadData align="center">User name</T.TableHeadData>
                            <T.TableHeadData align="center">Email</T.TableHeadData>
                            <T.TableHeadData align="center">Role</T.TableHeadData>
                            <T.TableHeadData align="center">Title</T.TableHeadData>
                            <T.TableHeadData align="center">Type</T.TableHeadData>
                        </T.TableHeadRow>
                    </T.TableHead>

                    <T.TableBody>
                        {users.map((user, index, { length }) => {
                            const disabled = oldUserIds?.includes(user.id)
                            const active = selectedUsers.some((item) => item.id === user.id) || disabled
                            const refLast = index === length - 1 ? setLastElement : null

                            return (
                                <T.TableBodyRow
                                    key={user.id}
                                    ref={refLast}
                                    active={active}
                                    className={disabled ? styles.disabled : ''}
                                    onClick={() => handleCheckUser(user, !active)}
                                >
                                    <UserItemRow user={user} active={!!active} disabled={!!disabled} />
                                </T.TableBodyRow>
                            )
                        })}
                    </T.TableBody>
                </T.Table>
            </TableContainer>

            <SaveCancelPanel
                disabledConfirm={selectedUsers.length === 0}
                onSave={() => onAddContacts(selectedUsers)}
                saveButtonText={`Add Users`}
                className={styles.btnWrap}
            />
        </div>
    )
}

export default UsersCheckBoxList
