import styles from './styles.module.scss'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { TextButton } from 'ui-kit/components/Button'
import Icons from 'assets/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks'

import sendNotification from 'lib/notification'
import { CommonFileType, NOTIFICATION_STATUS } from 'types'
import { useDropzone } from 'react-dropzone'

import { FilesList } from 'components/FilesList'
import { userAction } from '../../../../../../slices/users'
import { createTenantFiles, deleteTenantFile, getTenantsFiles } from '../../../../../../api/tenant'

const FilesTenantView = () => {
    const dispatch = useAppDispatch()

    const { open: openDropZone } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: async (acceptedFiles: Array<File>) => {
            await createFiles(acceptedFiles)
        },
    })

    const selectedTenantProfile = useAppSelector(({ users }) => users.selectedTenantProfile)

    const [files, setFiles] = useState<Array<CommonFileType>>([])
    const [filesCount, setFilesCount] = useState(0)
    const [page, setPage] = useState(1)

    const getFiles = useCallback(async () => {
        if (!selectedTenantProfile) {
            return
        }

        dispatch(userAction.setLoading(true))

        try {
            const res = await dispatch(
                getTenantsFiles({
                    page,
                    tenantCompanyId: selectedTenantProfile.id,
                }),
            ).unwrap()
            setFiles(res.rows)
            setFilesCount(res.count)
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
        dispatch(userAction.setLoading(false))
    }, [selectedTenantProfile])

    const createFiles = useCallback(
        async (filesValue: Array<File>) => {
            if (!selectedTenantProfile) {
                return
            }
            dispatch(userAction.setLoading(true))

            try {
                await dispatch(
                    createTenantFiles({
                        tenantCompanyId: selectedTenantProfile.id,
                        files: filesValue,
                    }),
                ).unwrap()
                await getFiles()
            } catch {
                sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
            }
            dispatch(userAction.setLoading(false))
        },
        [selectedTenantProfile, getFiles],
    )

    const handleDeleteFile = async (id: string) => {
        try {
            await dispatch(deleteTenantFile(id))
            await getFiles()
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }

    useEffect(() => {
        ;(async () => {
            await getFiles()
        })()
    }, [getFiles])

    return (
        <div className={styles.root}>
            <div className={styles.headerRow}>
                <Paragraph size="md" weight="semi-bold">
                    Images & Files
                </Paragraph>
                <TextButton
                    icon={<Icons.AddPlusSVG />}
                    text="Add File"
                    colorText="#1B6BC0"
                    onClick={(e) => {
                        e.stopPropagation()
                        openDropZone()
                    }}
                />
            </div>

            <div className={styles.fileList}>
                <FilesList
                    files={files}
                    onDeleteClick={handleDeleteFile}
                    filesCount={filesCount}
                    fetchNextData={() => setPage(page + 1)}
                    className={styles.fileList}
                />
            </div>
        </div>
    )
}

export default FilesTenantView
