import * as T from 'components/Table'
import { PageTabType } from 'components/PageTabPanel'
import { FiltersForReportsType } from 'components/CommonSavedView'
import { AssetReportsFiltersType } from 'hooks/assetsHooks/useAssetFiltersForReports'
import { AssetFiltersType } from 'slices/asset'
import { TableColumnType } from 'components/Table'
import { CommonSortType, DirectionEnum } from 'types'

export enum AssetKeysEnum {
    NAME = 'Asset name',
    CATEGORY = 'Category',
    AGE = 'Asset age',
    IS_CRITICAL = 'Critical',
    DESCRIPTION = 'Description',
    INSTALL_DATE = 'Install Date',
    COST = 'Purchase Price',
    QR_CODE = 'QR Code',
    REGION = 'Region',
    TYPE = 'Type',
    LOCATION = 'Location',
    WO_STATUS = 'WO Status',
    QTY_IN_INVENTORY = 'Qty in Inventory',
    PLANS = 'Plans',
    ACTIVE_WO = 'Active Work Orders',
    NEXT_PM_DATE = 'Next PM Date',
    HAS_EMERGENCY_PLAN = 'Has Emergency Plan',
    LAST_SERVICE = 'Last Service',
    HAS_WO = 'Has WO',
    EQUIPMENT_ID = 'Equipment ID',
}

export const requiredAssetColumns: T.TableColumnType[] = [
    { label: AssetKeysEnum.CATEGORY, name: 'category', sort: false },
    { label: AssetKeysEnum.NAME, name: 'name', sort: true },
]
export const initialAssetColumns: T.TableColumnType[] = [
    { label: AssetKeysEnum.EQUIPMENT_ID, name: 'equipmentId', sort: true },
    { label: AssetKeysEnum.TYPE, name: 'type', sort: false },
    { label: AssetKeysEnum.IS_CRITICAL, name: 'isCritical', sort: true },
    { label: AssetKeysEnum.QR_CODE, name: 'qrCode', sort: false },
    { label: AssetKeysEnum.ACTIVE_WO, name: 'openWo' },
    { label: AssetKeysEnum.PLANS, name: 'plans', sort: false },
    { label: AssetKeysEnum.LOCATION, name: 'location', sort: false },
]

export const assetTableColumns: T.TableColumnType[] = initialAssetColumns.concat([
    { label: AssetKeysEnum.AGE, name: 'age', sort: false },
    { label: AssetKeysEnum.DESCRIPTION, name: 'description', sort: true },
    { label: AssetKeysEnum.HAS_EMERGENCY_PLAN, name: 'hasEmergencyPlan', sort: false },
    //{ label: 'Has WO', name: 'hasWo' },
    { label: AssetKeysEnum.INSTALL_DATE, name: 'installDate', sort: true },
    //{ label: 'Last Service', name: 'lastService' },
    //{ label: 'Next PM Date', name: 'nextPmDate' },
    { label: AssetKeysEnum.COST, name: 'cost', sort: true },
    //{ label: 'Qty In Inventory', name: 'qtyInInventory' },
    { label: AssetKeysEnum.REGION, name: 'region', sort: false },
    //{ label: 'WO Status', name: 'woStatus' },
])

export enum AssetsListPageTab {
    FM_PRO_MANAGEMENT = 'FM_PRO_MANAGEMENT',
    ASSETS = 'ASSETS',
    SAVED_VIEWS = 'SAVED_VIEWS',
}

export const tabs: PageTabType[] = [
    {
        id: AssetsListPageTab.FM_PRO_MANAGEMENT,
        label: 'FMPro Management',
    },
    {
        id: AssetsListPageTab.ASSETS,
        label: 'Assets',
    },
    {
        id: AssetsListPageTab.SAVED_VIEWS,
        label: 'Saved Views',
    },
]

export const ASSETS_PER_PAGE = 50

export const FILES_PER_PAGE = 50

export const getAssetFilters = (filter?: FiltersForReportsType<AssetReportsFiltersType>[]) => {
    const assetFilters: AssetFiltersType = {}

    if (filter) {
        for (const val of Object.values(filter)) {
            if ('regionId' in val) {
                // @ts-ignore
                assetFilters.regions = val?.regionId?.map((item: string) => ({ value: item, label: '' }))
            }
            if ('buildingId' in val) {
                // @ts-ignore
                assetFilters.buildings = val?.buildingId?.map((item: string) => ({ value: item, label: '' }))
            }
            if ('categoryId' in val) {
                assetFilters.assetCategoriesId = val.categoryId as string[]
            }
            if ('typeId' in val) {
                assetFilters.assetTypesId = val.typeId as string[]
            }
            if ('cost' in val) {
                // @ts-ignore
                if ('>=' in val.cost && val.cost['>=']) {
                    assetFilters.minPrice = +val.cost['>=']
                }
                // @ts-ignore
                if ('<=' in val.cost && val.cost['<=']) {
                    assetFilters.maxPrice = +val.cost['<=']
                }
            }
            if ('installDate' in val) {
                // @ts-ignore
                if ('>=' in val.installDate && val.installDate['>=']) {
                    assetFilters.installDateFrom = new Date(val.installDate['>='])
                }
                // @ts-ignore
                if ('<=' in val.installDate && val.installDate['<=']) {
                    assetFilters.installDateTo = new Date(val.installDate['<='])
                }
            }
            if ('paranoid' in val) {
                assetFilters.isShowArchived = val.paranoid as boolean
            }
            if ('isCritical' in val) {
                assetFilters.isCritical = val.isCritical as boolean
            }
            if ('hasPlans' in val) {
                assetFilters.hasPlan = val.hasPlans as boolean
            }
        }
    }
    return assetFilters
}

export const getSearchValueFromView = (filter?: FiltersForReportsType<AssetReportsFiltersType>[]) => {
    if (filter) {
        for (const val of Object.values(filter)) {
            // eslint-disable-next-line no-prototype-builtins
            if (Object.values(val)[0].hasOwnProperty('search')) {
                return Object.values(Object.values(val)[0])[0]
            }
        }
    }
}

export const getAssetColumns = (columns: string[]) => {
    const otherColumns: TableColumnType[] = []
    for (const val of columns.slice(requiredAssetColumns.length)) {
        const currentColumn = assetTableColumns.find((item) => item.label === val)

        if (currentColumn) {
            otherColumns.push(currentColumn)
        }
    }
    return otherColumns
}

export const getSortFromView = (order: { [key: string]: DirectionEnum }): CommonSortType => {
    return {
        sortField: Object.keys(order)[0],
        sortDirection: Object.values(order)[0],
    }
}
