import styles from './CustomSelect.module.scss'
import { MenuItem, Select, Tooltip } from '@mui/material'
import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { getSelectItemIcon, getSelectItemKey } from './helpers'

export type CustomSelectType = {
    value: string
    label: string
    icon?: ReactNode
}

type Props<T> = {
    disabled?: boolean
    readonly?: boolean
    label?: string
    values: Array<T>
    value: string | null
    onChange: (val: string | null) => void
    rootClassName?: string
    background?: string
    errorText?: string
}
const CustomSelect = <T extends CustomSelectType | string>({
    disabled,
    readonly,
    label,
    value,
    values,
    onChange,
    rootClassName,
    errorText,
    background,
}: Props<T>) => {
    if (!value === null) {
        return null
    }

    return (
        <div className={classNames(styles.root, rootClassName)}>
            {label !== undefined && <div className={styles.labelWrap}>{label}</div>}
            <Tooltip title={errorText ? errorText : ''} placement={'right'}>
                <Select
                    disabled={disabled}
                    readOnly={readonly}
                    value={value}
                    onChange={(e) => {
                        e.target.value && onChange(e.target.value)
                    }}
                    MenuProps={{
                        sx: {
                            '& li': {
                                fontSize: '12px !important',
                                fontFamily: "'Poppins', sans-serif !important",
                            },
                        },
                    }}
                    sx={{
                        minWidth: '150px',
                        width: '100%',
                        backgroundColor: background || '#F4F5F7',
                        padding: 0,
                        borderRadius: '8px',
                        overflow: 'hidden',
                        fontSize: '12px !important',
                        fontFamily: "'Poppins', sans-serif !important",

                        '& .MuiInputBase-input': {
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 12px',
                            backgroundColor: 'inherit',
                            height: '38px !important',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: errorText ? '1px solid #F1416C !important' : 'none !important',
                        },
                        '& .MuiOutlinedInput-notchedOutline:hover': {
                            border: errorText ? '1px solid #F1416C !important' : 'none !important',
                        },
                        '& li': {
                            fontSize: '4px !important',
                        },
                    }}
                >
                    {values.map((item) => {
                        const itemValue = getSelectItemKey(item, 'value')
                        const itemLabel = getSelectItemKey(item)
                        const itemIcon = getSelectItemIcon(item)

                        return (
                            <MenuItem key={itemValue} value={itemValue}>
                                {itemIcon && <div className={styles.iconItem}>{itemIcon}</div>}
                                {itemLabel}
                            </MenuItem>
                        )
                    })}
                </Select>
            </Tooltip>
        </div>
    )
}

export default CustomSelect
