import { ReactNode } from 'react'

import styles from './styles.module.scss'
import classNames from 'classnames'

type Props = {
    children: ReactNode
    className?: string
    justifyContent?: string
}
const CardFlexRow = ({ children, className, justifyContent }: Props) => {
    return (
        <div className={classNames(styles.cardFlexRow, className)} style={{ justifyContent }}>
            {children}
        </div>
    )
}

export default CardFlexRow
