import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import { CalendarState } from './slice'
import { getWorkOrdersMonthCalendarList } from 'api/workOrders'
import { CalendarMonthWOList } from '../workOrder'

type Payload = {
    payload: CalendarMonthWOList
}

const extraReducers = (builder: ActionReducerMapBuilder<CalendarState>) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get WO month list
    builder.addCase(getWorkOrdersMonthCalendarList.pending, (state) => {
        state.loading = true
    })
    builder.addCase(
        getWorkOrdersMonthCalendarList.fulfilled,
        (state, action: PayloadAction<{ payload: CalendarMonthWOList }>) => {
            state.loading = false
        },
    )
    builder.addCase(getWorkOrdersMonthCalendarList.rejected, (state, action) => {
        state.loading = false
    })
}

export default extraReducers
