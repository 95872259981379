import { useAppDispatch, useAppSelector } from '../redux'
import { NOTIFICATION_STATUS } from '../../types'
import { useCallback, useEffect, useState } from 'react'
import sendNotification from '../../lib/notification'
import { getUserSettings } from '../../api/user'
import {
    AbilitySettingForm,
    NotificationSettingForm,
} from '../../pages/UsersTenantsPage/components/UserTenantProfile/components/UserSettings/config'
import { checkRolePermission } from './helpers'
import { setNotificationSettings, setPermissionsUser } from '../../slices/AuthSlice'

const useUserCheckRolePermission = (isAllow = true) => {
    const dispatch = useAppDispatch()

    const user = useAppSelector(({ auth }) => auth.user)

    const [abilitySettings, setAbilitySettings] = useState<AbilitySettingForm | null>(null)

    const getSetting = useCallback(async () => {
        if (!user) {
            return
        }
        try {
            const res: { abilitySettings: AbilitySettingForm; notificationSettings: NotificationSettingForm } =
                await dispatch(
                    getUserSettings({
                        userId: user.id,
                    }),
                ).unwrap()

            dispatch(setNotificationSettings(res.notificationSettings))
            setAbilitySettings(res.abilitySettings)
        } catch (e) {
            sendNotification((e as string) || 'The building has been deleted', NOTIFICATION_STATUS.SUCCESS)
        }
    }, [user])

    useEffect(() => {
        if (!isAllow) {
            return
        }

        ;(async () => {
            await getSetting()
        })()
    }, [getSetting, isAllow])

    useEffect(() => {
        if (!user) {
            return
        }

        dispatch(setPermissionsUser(checkRolePermission(user)))
    }, [user, abilitySettings])

    return { getSetting }
}

export default useUserCheckRolePermission
