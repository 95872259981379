import { CategoryNameEnum, TypePlanName } from 'types'
import { FC } from 'react'
import { getSvgImageByType } from 'assets/plans-type-icons'

type Props = {
    src?: string | null
    planType: TypePlanName | string | CategoryNameEnum
    size?: number
    color?: string
    variant?: 'round' | 'square'
}

const PlanTypeIcon: FC<Props> = ({ src, planType, size, color, variant = 'square' }) => {
    const img = src || getSvgImageByType(planType)

    const borderRadius = () => {
        switch (variant) {
            case 'square':
                return '5px'

            case 'round':
                return '50%'
            default:
                return undefined
        }
    }

    return (
        <img
            width={size}
            height={size}
            src={img}
            alt="type"
            draggable={false}
            style={{ backgroundColor: color && src ? color : 'transparent', borderRadius: borderRadius() }}
        />
    )
}

export default PlanTypeIcon
