import React, { FC } from 'react'
import styles from './styles.module.scss'
import { Paragraph } from 'ui-kit/components/Typography/Typography'

type Props = {
    count: number | string
    title: string
    icon: React.ReactNode
}

const ProfileInfoCard = ({ count, title, icon }: Props) => {
    return (
        <div className={styles.card}>
            <div className={styles.column}>
                {icon}
                <Paragraph size={'xl'} weight={'bold'}>
                    {count}
                </Paragraph>
            </div>
            <div className={styles.column}>
                <Paragraph size={'md'} color={'grey'}>
                    {title}
                </Paragraph>
            </div>
        </div>
    )
}

export default ProfileInfoCard
