import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import { EmergencyState } from './slice'
import {
    attachEmergencyProcedure,
    createEmergencyProcedure,
    createEmergencyScenarioReport,
    createUpdateEmergencyScenario,
    decrementPlanEmergency,
    deleteEmergencyPlan,
    deleteEmergencyProcedureFromPlan,
    getEmergencyPlanById,
    getEmergencyPlanList,
    getEmergencyProcedures,
    getMyStepEmergencyPlan,
    incrementPlanEmergency,
    updatePlanEmergencyName,
} from '../../api/emergency'
import { NOTIFICATION_STATUS } from '../../types'
import { EmergencyPlan, EmergencyReport, EmergencyProcedure, EmergencyProcedureType } from './types'
import sendNotification from '../../lib/notification'
import { getEmergencyContactsList } from '../../api/subcontractors'
import { SubcontractorType } from '../subcontractor'
import { addAssetReportEmergency, getMyStepEmergencyReport, incrDecrReportEmergency } from '../../api/emergencyReport'
import { getWorkOrdersList } from '../../api/workOrders'
import { WorkOrderType } from '../workOrder'

const extraReducers = (builder: ActionReducerMapBuilder<EmergencyState>) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get Wo list
    builder.addCase(getWorkOrdersList.pending, (state) => {
        state.loading = true
    })
    builder.addCase(
        getWorkOrdersList.fulfilled,
        (state, action: PayloadAction<{ count: number; rows: Array<WorkOrderType> }>) => {
            state.loading = false
            state.emergencyIncidentsCount = action.payload.count
            state.emergencyIncidents = action.payload.rows
        },
    )
    builder.addCase(getWorkOrdersList.rejected, (state) => {
        state.loading = false
    })

    // get emergency plan list
    builder.addCase(getEmergencyPlanList.pending, (state) => {
        state.loading = true
    })
    builder.addCase(
        getEmergencyPlanList.fulfilled,
        (state, action: PayloadAction<{ count: number; payload: Array<EmergencyPlan> }>) => {
            state.loading = false
            state.emergencyPlansCount = action.payload.count
            state.emergencyPlans = action.payload.payload
        },
    )
    builder.addCase(getEmergencyPlanList.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //create emergency plan
    builder.addCase(createUpdateEmergencyScenario.pending, (state) => {
        state.loading = true
    })
    builder.addCase(createUpdateEmergencyScenario.fulfilled, (state, action: PayloadAction<EmergencyPlan>) => {
        state.loading = false
        state.currentCreatePlanEmergencyId = action.payload.id
    })
    builder.addCase(createUpdateEmergencyScenario.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //create emergency report
    builder.addCase(createEmergencyScenarioReport.pending, (state) => {
        state.loading = true
    })
    builder.addCase(createEmergencyScenarioReport.fulfilled, (state) => {
        state.loading = false
    })
    builder.addCase(createEmergencyScenarioReport.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get my step emergency plan
    builder.addCase(getMyStepEmergencyPlan.pending, (state) => {
        state.loading = true
    })
    builder.addCase(
        getMyStepEmergencyPlan.fulfilled,
        (state, action: PayloadAction<EmergencyPlan & { currentLevel: number }>) => {
            state.loading = false
            if (action.payload) {
                state.planActiveStep = action.payload.currentLevel
                state.currentCreatePlanEmergencyId = action.payload.id
                state.selectedEmergencyContacts = action.payload.subcontractors
            } else {
                state.planActiveStep = 0
            }
        },
    )
    builder.addCase(getMyStepEmergencyPlan.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get emergency procedures
    builder.addCase(getEmergencyProcedures.pending, (state) => {
        state.loading = true
    })
    builder.addCase(
        getEmergencyProcedures.fulfilled,
        (
            state,
            action: PayloadAction<{ count: number; payload: Array<EmergencyProcedure>; type: EmergencyProcedureType }>,
        ) => {
            state.loading = false
            const procedures = action.payload.payload
            const type = action.payload.type

            state.emergencyProcedureCounts = {
                ...state.emergencyProcedureCounts,
                [type]: action.payload.count,
            }

            state.emergencyProcedureThree = {
                ...state.emergencyProcedureThree,
                [type]: procedures,
            }
        },
    )
    builder.addCase(getEmergencyProcedures.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //create emergency procedures
    builder.addCase(createEmergencyProcedure.pending, (state) => {
        state.loading = true
    })
    builder.addCase(createEmergencyProcedure.fulfilled, (state) => {
        state.loading = false
    })
    builder.addCase(createEmergencyProcedure.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //attach emergency procedures
    builder.addCase(attachEmergencyProcedure.pending, (state) => {
        state.loading = true
    })
    builder.addCase(attachEmergencyProcedure.fulfilled, (state) => {
        state.loading = false
    })
    builder.addCase(attachEmergencyProcedure.rejected, (state, action) => {
        state.loading = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //delete emergency procedures from plan
    builder.addCase(deleteEmergencyProcedureFromPlan.pending, (state) => {
        state.loading = true
    })
    builder.addCase(deleteEmergencyProcedureFromPlan.fulfilled, (state) => {
        state.loading = false
    })
    builder.addCase(deleteEmergencyProcedureFromPlan.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get emergency plan
    builder.addCase(getEmergencyPlanById.pending, (state) => {
        state.loading = true
    })
    builder.addCase(getEmergencyPlanById.fulfilled, (state, action: PayloadAction<EmergencyPlan>) => {
        state.loading = false
        state.profilePlanEmergency = action.payload
    })
    builder.addCase(getEmergencyPlanById.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get my step emergency report
    builder.addCase(getMyStepEmergencyReport.pending, (state) => {
        state.loading = true
    })
    builder.addCase(
        getMyStepEmergencyReport.fulfilled,
        (state, action: PayloadAction<EmergencyReport & { currentLevel: number }>) => {
            state.loading = false
            if (action.payload) {
                const { currentLevel, ...report } = action.payload

                state.reportActiveStep = currentLevel
                state.newReportActive = report
            } else {
                state.reportActiveStep = 0
                state.newReportActive = null
            }
        },
    )
    builder.addCase(getMyStepEmergencyReport.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //update name emergency plan
    builder.addCase(updatePlanEmergencyName.pending, (state) => {
        state.loading = true
    })
    builder.addCase(updatePlanEmergencyName.fulfilled, (state) => {
        state.loading = false
    })
    builder.addCase(updatePlanEmergencyName.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //increment emergency plan
    builder.addCase(incrementPlanEmergency.pending, (state) => {
        state.loading = true
    })
    builder.addCase(incrementPlanEmergency.fulfilled, (state, action: PayloadAction<{ currentLevel: number }>) => {
        state.loading = false
        if (action.payload) {
            state.planActiveStep = action.payload.currentLevel
        }
    })
    builder.addCase(incrementPlanEmergency.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //decrement emergency plan
    builder.addCase(decrementPlanEmergency.pending, (state) => {
        state.loading = true
    })
    builder.addCase(decrementPlanEmergency.fulfilled, (state, action: PayloadAction<{ currentLevel: number }>) => {
        state.loading = false
        state.planActiveStep = action.payload.currentLevel
    })
    builder.addCase(decrementPlanEmergency.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //decrement emergency plan
    builder.addCase(incrDecrReportEmergency.pending, (state) => {
        state.loading = true
    })
    builder.addCase(
        incrDecrReportEmergency.fulfilled,
        (
            state,
            action: PayloadAction<{
                updated: {
                    currentLevel: number
                }
            }>,
        ) => {
            state.loading = false
            state.reportActiveStep = action.payload.updated.currentLevel
        },
    )
    builder.addCase(incrDecrReportEmergency.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //emergency contact list
    builder.addCase(getEmergencyContactsList.pending, (state) => {
        state.loading = true
    })
    builder.addCase(
        getEmergencyContactsList.fulfilled,
        (state, action: PayloadAction<{ rows: Array<SubcontractorType>; count: number }>) => {
            state.loading = false
            state.emergencyContacts = action.payload.rows
            state.emergencyContactsCount = action.payload.count
        },
    )
    builder.addCase(getEmergencyContactsList.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //add Asset Report Emergency
    builder.addCase(addAssetReportEmergency.pending, (state) => {
        state.loading = true
    })
    builder.addCase(addAssetReportEmergency.fulfilled, (state) => {
        state.loading = false
    })
    builder.addCase(addAssetReportEmergency.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //delete emergency plan
    builder.addCase(deleteEmergencyPlan.pending, (state) => {
        state.loading = true
    })
    builder.addCase(deleteEmergencyPlan.fulfilled, (state) => {
        state.loading = false
    })
    builder.addCase(deleteEmergencyPlan.rejected, (state) => {
        state.loading = false
        sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
}

export default extraReducers
