import React, { FC, ReactNode } from 'react'
import styles from './styles.module.scss'
import { Avatar } from '@mui/material'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import classNames from 'classnames'

interface Props {
    image: string | ReactNode
    name: string
    description?: string
    registered?: string
    className?: string
}

const CardHeader = ({ image, name, description, registered, className }: Props) => {
    return (
        <>
            <div className={classNames(styles.cardHeader, className)}>
                {typeof image === 'string' ? <Avatar variant={'rounded'} alt="" src={image} /> : image}
                <div>
                    <Paragraph size={'s'}>{name}</Paragraph>
                    {description && (
                        <Paragraph size={'xs'} color={'grey'}>
                            {description}
                        </Paragraph>
                    )}
                </div>
            </div>
            {registered && (
                <Paragraph size={'xxs'} color={'grey'}>
                    Created: {registered}
                </Paragraph>
            )}
        </>
    )
}

export default CardHeader
