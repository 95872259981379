import { TableColumnType } from '../../components/Table'
import { PageTabType, RoutesTabPanelType } from '../../components/PageTabPanel'
import { TenantProfileRoutes, UserProfilePageTab } from './types'
import { USER_ROLE } from '../../types'

export const techniciansVisibleColumns: TableColumnType[] = [
    { label: 'Type', name: 'type' },
    { label: 'Status', name: 'status' },
    { label: 'Date Added', name: 'creationDate' },
    { label: 'Email', name: 'email' },
    { label: 'Phone', name: 'phone' },
    { label: 'Active WOs', name: 'openWo' },
    { label: 'Past Due WOs', name: 'pastDueWo' },
    { label: 'Last Activity', name: 'lastActivity' },
]

export const techniciansColumns: TableColumnType[] = techniciansVisibleColumns.concat([
    { label: 'Last Login', name: 'lastLogin' },
    { label: 'Title', name: 'title' },
    { label: 'WO Teams(s)', name: 'teams' },
    { label: 'Total WOs', name: 'totalWo' },
    { label: 'Files', name: 'title' },
    { label: 'Hourly rate', name: 'hourlyRate' },
])

export const techniciansRequiredColumns: TableColumnType[] = [
    { label: 'Technician Name', name: 'name' },
    { label: 'Region', name: 'region' },
]

export const requestersVisibleColumns: TableColumnType[] = [
    { label: 'Type', name: 'type' },
    { label: 'Status', name: 'status' },
    { label: 'Title', name: 'title' },
    { label: 'Buildings Assigned', name: 'assignedBuildings' },
    { label: 'Email', name: 'email' },
    { label: 'Phone', name: 'phone' },
    { label: 'Active WOs', name: 'openWo' },
]

export const requestersColumns: TableColumnType[] = requestersVisibleColumns.concat([
    { label: 'Date Added', name: 'creationDate' },
    { label: 'Files', name: 'files' },
    { label: 'Last Login', name: 'lastLogin' },
    { label: 'Region', name: 'region' },
    { label: 'Total WOs', name: 'totalWo' },
])

export const requestersRequiredColumns: TableColumnType[] = [{ label: 'Requestor Name', name: 'name' }]

//

export const supervisorsVisibleColumns: TableColumnType[] = [
    { label: 'Type', name: 'type' },
    { label: 'Status', name: 'status' },
    { label: 'Date Added', name: 'creationDate' },
    { label: 'Region', name: 'region' },
    { label: 'Email', name: 'email' },
    { label: 'Phone', name: 'phone' },
    { label: 'Past Due WOs', name: 'pastDueWo' },
    { label: 'Last Activity', name: 'lastActivity' },
]

export const supervisorsColumns: TableColumnType[] = supervisorsVisibleColumns.concat([
    { label: 'Last Login', name: 'lastLogin' },
    { label: 'Buildings Assigned', name: 'assignedBuildings' },
    { label: 'Title', name: 'title' },
    { label: 'WO Teams(s)', name: 'teams' },
    { label: 'Total WOs', name: 'totalWo' },
    { label: 'Active WOs', name: 'openWo' },
    { label: 'Files', name: 'files' },
    { label: '# Assets Managed', name: 'assetsManaged' },
])

export const supervisorsRequiredColumns: TableColumnType[] = [{ label: 'Supervisor Name', name: 'name' }]

export const adminsVisibleColumns: TableColumnType[] = [
    { label: 'Type', name: 'type' },
    { label: 'Status', name: 'status' },
    { label: 'Date Added', name: 'creationDate' },
    { label: 'Title', name: 'title' },
    { label: 'Email', name: 'email' },
    { label: 'Phone', name: 'phone' },
    { label: 'Last Activity', name: 'lastActivity' },
]

export const adminsColumns: TableColumnType[] = adminsVisibleColumns.concat([
    { label: 'Last Login', name: 'lastLogin' },
    { label: 'Files', name: 'files' },
])

export const adminsRequiredColumns: TableColumnType[] = [{ label: 'Administrator Name', name: 'name' }]

export const tenantsVisibleColumns: TableColumnType[] = [
    { label: 'Website', name: 'website' },
    { label: 'Date Added', name: 'dateAdded' },
    { label: 'Status', name: 'status' },
    { label: '# of Users', name: 'users' },
]

export const tenantsColumns: TableColumnType[] = tenantsVisibleColumns.concat([
    { label: 'Regions', name: 'region' },
    { label: 'Buildings', name: 'building' },
    { label: 'LeaseFees', name: 'leaseFees' },
    { label: 'Total Sq Ft', name: 'area' },
])

export const tenantsRequiredColumns: TableColumnType[] = [{ label: 'Company Name', name: 'name' }]

export const TechnicianProfileTabs: Array<PageTabType> = [
    {
        id: UserProfilePageTab.PROFILE,
        label: 'Profile',
    },
    {
        id: UserProfilePageTab.WO_HISTORY,
        label: 'Work Order History',
    },
    {
        id: UserProfilePageTab.FILES_NOTES,
        label: 'Files & Notes',
    },
    {
        id: UserProfilePageTab.LOGIN_HISTORY,
        label: 'Login History',
    },
    {
        id: UserProfilePageTab.ASSIGNMENTS,
        label: 'Team Assignments',
    },
    {
        id: UserProfilePageTab.SETTINGS,
        label: 'Settings',
    },
]

export const RequestorProfileTabs: Array<PageTabType> = [
    {
        id: UserProfilePageTab.PROFILE,
        label: 'Profile',
    },
    {
        id: UserProfilePageTab.WO_HISTORY,
        label: 'Work Order History',
    },
    {
        id: UserProfilePageTab.FILES_NOTES,
        label: 'Files & Notes',
    },
    {
        id: UserProfilePageTab.LOGIN_HISTORY,
        label: 'Login History',
    },
    {
        id: UserProfilePageTab.ASSIGNMENTS,
        label: 'Building Assignments',
    },
    {
        id: UserProfilePageTab.SETTINGS,
        label: 'Settings',
    },
]

export const AdminProfileTabs: Array<PageTabType> = [
    {
        id: UserProfilePageTab.PROFILE,
        label: 'Profile',
    },
    {
        id: UserProfilePageTab.FILES_NOTES,
        label: 'Files & Notes',
    },
    {
        id: UserProfilePageTab.LOGIN_HISTORY,
        label: 'Login History',
    },
    {
        id: UserProfilePageTab.SETTINGS,
        label: 'Settings',
    },
]

export const SupervisorProfileTabs: Array<PageTabType> = [
    {
        id: UserProfilePageTab.PROFILE,
        label: 'Profile',
    },
    {
        id: UserProfilePageTab.FILES_NOTES,
        label: 'Files & Notes',
    },
    {
        id: UserProfilePageTab.LOGIN_HISTORY,
        label: 'Login History',
    },
    {
        id: UserProfilePageTab.ASSIGNMENTS,
        label: 'Assignments ',
    },
    {
        id: UserProfilePageTab.SETTINGS,
        label: 'Settings',
    },
]

export const TenantProfileTabs: Array<RoutesTabPanelType> = [
    {
        link: TenantProfileRoutes.PROFILE,
        label: 'Profile',
    },
    {
        link: TenantProfileRoutes.USERS,
        label: 'Users',
    },
    {
        link: TenantProfileRoutes.CONTACTS,
        label: 'Contacts',
    },
    {
        link: TenantProfileRoutes.LEASES,
        label: 'Lease Information',
    },
    {
        link: TenantProfileRoutes.NOTES,
        label: 'Notes',
    },
]

export const getTenantProfileTabs = (isTechnicians: boolean) => {
    if (isTechnicians) {
        return TenantProfileTabs.filter((item) => item.link !== TenantProfileRoutes.LEASES)
    } else {
        return TenantProfileTabs
    }
}

export const requiredUserColumns: { [key: string]: TableColumnType[] } = {
    [USER_ROLE.ADMIN]: adminsRequiredColumns,
    [USER_ROLE.SUPERADMIN]: adminsRequiredColumns,
    [USER_ROLE.TECHNICIAN]: techniciansRequiredColumns,
    [USER_ROLE.REQUESTOR]: requestersRequiredColumns,
    [USER_ROLE.SUPERVISOR]: supervisorsRequiredColumns,
}

export const userColumns: { [key: string]: TableColumnType[] } = {
    [USER_ROLE.ADMIN]: adminsColumns,
    [USER_ROLE.SUPERADMIN]: adminsColumns,
    [USER_ROLE.TECHNICIAN]: techniciansColumns,
    [USER_ROLE.REQUESTOR]: requestersColumns,
    [USER_ROLE.SUPERVISOR]: supervisorsColumns,
}
