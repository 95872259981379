import { useEffect, useState } from 'react'
import { useAppDispatch } from 'hooks/redux'
import { getPropsAssetByTypeList } from 'api/assets'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import { useInfinityScroll } from 'hooks/index'
import { ResponsePropsType } from 'components/AddPropsDialog/AddPropsDialog'
import useDebounce from 'hooks/useDebounce'

type UseAssetCategoriesType = {
    isAllow?: boolean
    categoryId?: string
    typeId?: string
    setIsLoading?: (value: boolean) => void
    limit?: number
    search?: string
    getActiveProperties?: boolean
}

export const useAssetProps = ({
    isAllow = true,
    categoryId,
    typeId,
    limit = 100,
    getActiveProperties,
}: UseAssetCategoriesType) => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [props, setProps] = useState<ResponsePropsType[]>([])
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 500)

    const { setLastElement } = useInfinityScroll({
        fetchNextData: () => setPage(page + 1),
        hasMore: props.length < count,
    })

    const getProps = async () => {
        setIsLoading(true)
        try {
            const res: { count: number; props: ResponsePropsType[] } = await dispatch(
                getPropsAssetByTypeList({
                    categoryId,
                    typeId,
                    limit,
                    offset: limit * (page - 1),
                    searchString: search ? search : undefined,
                    isActive: getActiveProperties,
                }),
            ).unwrap()

            if (res) {
                setProps(page === 1 ? res?.props : [...props, ...res.props])
                setCount(res?.count)
            }
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!isAllow || (!categoryId && !typeId) || search) {
            return
        }

        getProps()
    }, [isAllow, categoryId, typeId, search, page])

    useEffect(() => {
        if (debouncedSearch && isAllow) {
            getProps()
        }
    }, [debouncedSearch, page])

    const setSearchByProps = (value: string) => {
        setSearch(value)
        setPage(1)
    }

    return { props, isLoading, count, setLastElement, getProps, search, setSearchByProps }
}
