import { CommonFileType, User } from '../../types'
import { Floor, FloorPlan, Room } from '../floorPlanViever'
import { RegionType } from '../region'

export type Note = {
    id: string
    text: string
    creationDate: string
    creator: User
    noteFiles: Array<CommonFileType>
}

export type UtilityInsurance = {
    id: string
    providerName: string
    type: string
    name: string
    number: string
    email: string
    account: string
    file: Array<File>
    buildingId: string
    utilitiesOrInsuranceFiles: Array<CommonFileType>
}

export interface Building {
    id: string
    name: string
    description: string
    type: string
    creator: User
    address: string
    zipCode: string
    state: string
    city: string
    country: string
    location: string
    coordinates: string
    area: number
    floorsNumber: number
    height: number
    websiteURL: string
    contactInfo: string
    buildingAccessInformation: string
    yearBuilt: number
    userId: number
    imageUrl: string
    avatar: CommonFileType | null
    region: RegionType | null
    regionId: string
    lat: number
    long: number
    creationDate: string
    pastDuePMCount: number
    pastDueWOCount: number
    totalAssetsCount: number
    totalWOCount: number
    openWOCount: number
    buidingAccessInformation: string
}

export enum BuildingRoutes {
    MAP = 'v/map',
    TABLE = 'v/table',
    MANAGE_REGIONS = 'v/manage-regions',
}

export enum BuildingProfileTab {
    FLOORS = 'FLOORS',
    TENANTS = 'TENANTS',
    NOTES = 'NOTES',
    DETAILS = 'DETAILS',
    FILES = 'FILES',
    EMERGENCY = 'EMERGENCY',
}

export type PlansThree = {
    [key: string]: Array<FloorPlan>
}

export type FloorsThree = {
    [key: string]: Array<Floor>
}

export type RoomsThree = {
    [key: string]: Array<Room>
}
