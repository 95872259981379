import React from 'react'
import { Contact } from 'components/ContactsList/types'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import CustomCheckBox from 'ui-kit/components/CustomCheckBox'
import { ContactInSubcontractorType } from 'slices/subcontractor'
import { User } from 'types'

import s from './styles.module.scss'

type Props = {
    contact: Contact | ContactInSubcontractorType | User
    checked: boolean
    onChange: () => void
}

export const SmallContactCard = ({ contact, checked, onChange }: Props) => {
    return (
        <CustomCheckBox
            label={
                <div className={s.details}>
                    <div className={s.row}>
                        <Paragraph size={'s'}>{contact.firstName + ' ' + contact.lastName}</Paragraph>
                        <div className={s.dot} />
                        <Paragraph size={'s'} color={'grey'}>
                            {contact.type}
                        </Paragraph>
                    </div>

                    <Paragraph size={'s'} color={'grey'}>
                        {contact.email}
                    </Paragraph>
                </div>
            }
            className={s.checkBox}
            checked={checked}
            onChange={onChange}
        />
    )
}
