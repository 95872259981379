import { useAppSelector } from 'hooks/redux'
import { purchaseRequestsFiltersSelect } from 'store/selectors'
import { removeBlankFields } from 'lib/common'

export const usePurchaseRequestsFiltersForReports = () => {
    const purchaseRequestsFilters = useAppSelector(purchaseRequestsFiltersSelect)
    let filtersCount = 0

    if (purchaseRequestsFilters) {
        for (const [key, val] of Object.entries(removeBlankFields(purchaseRequestsFilters))) {
            if (key === 'categoryIdes') {
                filtersCount += 1
            }
            if (key === 'typeIdes') {
                filtersCount += 1
            }
            if (key === 'orderDateTo') {
                filtersCount += 1
            }
            if (key === 'isForWOs') {
                filtersCount += 1
            }
            if (key === 'statuses') {
                filtersCount += 1
            }
        }
    }

    return { filtersCount }
}
