import { ReactComponent as TotalArea } from './totalArea.svg'
import { ReactComponent as Buildings } from './buildings.svg'
import { ReactComponent as ItemsInventory } from './items-inventory.svg'
import { ReactComponent as TotalUsers } from './total-users.svg'
import { ReactComponent as TotalAssets } from './total-assets.svg'
import { ReactComponent as Contact } from './contact.svg'
import { ReactComponent as Assets } from './assets.svg'
export { ReactComponent as CallEmailSVG } from './call-email.svg'
export { ReactComponent as CallPhoneSVG } from './call-phone.svg'

const CustomerIcons = {
    TotalArea,
    Buildings,
    ItemsInventory,
    TotalUsers,
    TotalAssets,
    Contact,
    Assets,
}

export default CustomerIcons
