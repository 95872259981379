import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { client, axiosErrorHandler } from './setupAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const createNote = createAppAsyncThunk('notes/createNote', async (data: FormData, { rejectWithValue }) => {
    try {
        const result = await client.post(`/api/notes/create-note`, data)

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const editNote = createAppAsyncThunk('notes/editNote', async (data: FormData, { rejectWithValue }) => {
    try {
        const result = await client.patch(`/api/notes/update-note`, data)

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const deleteNote = createAppAsyncThunk('notes/deleteNote', async (noteId: string, { rejectWithValue }) => {
    try {
        const result = await client.delete(`/api/notes/delete-note`, { params: { noteId } })

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

type GetNotesByBuildingData = {
    offset?: number
    limit?: number
    id: string
}

export const getNotesList = createAsyncThunk(
    'notes/getNotesListByBuildingId',
    async ({ id, offset = 0, limit = 25 }: GetNotesByBuildingData, { rejectWithValue }) => {
        try {
            const result = await client.get(`api/notes/many`, {
                params: {
                    offset,
                    limit,
                    searchId: id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
