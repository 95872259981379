import { client, axiosErrorHandler } from './setupAxios'
import {
    AddFilesToAssetRequestType,
    CreateAssetPreferredSubcontractorsType,
    CreateFeedForAssetType,
    NewAffectedAreaType,
    NewAssetFormType,
} from 'pages/NewAssetPage/config'
import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import dayjs from 'dayjs'
import {
    AssetGetByEntityAttributes,
    AssetGetByEntityInclude,
    EditAffectedAreaType,
    GetAssetByIdType,
    GetAssetFilesType,
    GetAssetsByEntityType,
    GetFeedByAssetType,
} from 'slices/asset'
import { checkIsGoodStatus } from 'lib/common'
import {
    EditAssetType,
    EditAssignmentFolderType,
    EditPanelType,
    NewAssignmentFolderType,
} from 'pages/AssetProfile/config'
import { ASSETS_PER_PAGE } from 'pages/AssetsPage/config'
import { IdDependenceType } from 'components/CommonAutoComplete'
import { USER_ROLE } from 'types'

export const getAllAssetsList = createAppAsyncThunk('assets/getAllAssets', async (_, { rejectWithValue, getState }) => {
    const page = getState().assets.assetPaginationPage
    const search = getState().assets.assetsSearchValue
    const filters = getState().assets.assetsSettings.filters
    const sortDirection = getState().assets.assetsSettings.sortDirection
    const sortField = getState().assets.assetsSettings.sortField

    const params = {
        offset: (page - 1) * ASSETS_PER_PAGE,
        limit: ASSETS_PER_PAGE,
        searchString: search !== '' ? search : undefined,
        attributeCriteria: Object.values(AssetGetByEntityAttributes),
        includeCriteria: [
            AssetGetByEntityInclude.CATEGORY,
            AssetGetByEntityInclude.TYPE,
            AssetGetByEntityInclude.QR_CODE,
            AssetGetByEntityInclude.BUILDING,
            AssetGetByEntityInclude.FLOOR,
            AssetGetByEntityInclude.REGION,
            AssetGetByEntityInclude.WORK_ORDER,
        ],
        installDateFrom: filters?.installDateFrom ? filters?.installDateFrom : undefined,
        installDateTo: filters?.installDateTo ? filters?.installDateTo : undefined,
        minCost: filters?.minPrice ? filters?.minPrice : undefined,
        maxCost: filters?.maxPrice ? filters?.maxPrice : undefined,
        isCritical: filters?.isCritical ? filters?.isCritical : undefined,
        hasPlans: filters?.hasPlan ? filters?.hasPlan : undefined,
        isArchive: filters?.isShowArchived ? filters?.isShowArchived : undefined,
        categoryIdes: filters?.assetCategoriesId?.length ? filters?.assetCategoriesId : undefined,
        typeIdes: filters?.assetTypesId?.length ? filters?.assetTypesId : undefined,
        // @ts-ignore
        regionIdes: filters?.regions?.length ? filters?.regions.map(({ value }) => value) : undefined,
        // @ts-ignore
        buildingIdes: filters?.buildings?.length ? filters?.buildings.map(({ value }) => value) : undefined,
        sortField,
        sortDirection,
    }

    try {
        const result = await client.get(`/api/asset/by-entity`, { params })
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getAssetsListByCategory = createAppAsyncThunk(
    'assets/getAssetsListByCategory',
    async (
        { categoryId, page, search }: { categoryId: string; page: number; search: string },
        { rejectWithValue, getState },
    ) => {
        const params = {
            offset: (page - 1) * ASSETS_PER_PAGE,
            limit: ASSETS_PER_PAGE,
            searchString: search !== '' ? search : undefined,
            categoryIdes: [categoryId],
            includeCriteria: [AssetGetByEntityInclude.CATEGORY, AssetGetByEntityInclude.TYPE],
        }

        try {
            const result = await client.get(`/api/asset/by-entity`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAssignedAssetsListByPlanId = createAppAsyncThunk(
    'assets/getAssignedAssetsListByPlanId',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.get(`/assets/get-assigned-assets-by-plan-id/${id}`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAssetByIdCommon = createAppAsyncThunk(
    'assets/get-asset-by-id-common',
    async ({ id }: GetAssetByIdType, { rejectWithValue }) => {
        const params = {
            attributeCriteria: Object.values(AssetGetByEntityAttributes),
            includeCriteria: [
                AssetGetByEntityInclude.CATEGORY,
                AssetGetByEntityInclude.TYPE,
                AssetGetByEntityInclude.QR_CODE,
                AssetGetByEntityInclude.AVATAR,
            ],
        }
        try {
            const result = await client.get(`/api/asset/${id}`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAssetByIdWithoutStore = createAppAsyncThunk(
    'assets/getAssetByIdWithoutStore',
    async ({ id }: GetAssetByIdType, { rejectWithValue }) => {
        const params = {
            attributeCriteria: Object.values(AssetGetByEntityAttributes),
            includeCriteria: [
                AssetGetByEntityInclude.CATEGORY,
                AssetGetByEntityInclude.TYPE,
                AssetGetByEntityInclude.QR_CODE,
                AssetGetByEntityInclude.PROPS,
                AssetGetByEntityInclude.AVATAR,
            ],
        }
        try {
            const result = await client.get(`/api/asset/${id}`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getPropsAssetById = createAppAsyncThunk(
    'assets/get-props-asset-by-id',
    async ({ id }: GetAssetByIdType, { rejectWithValue }) => {
        const params = {
            includeCriteria: [AssetGetByEntityInclude.PROPS],
        }
        try {
            const result = await client.get(`/api/asset/${id}`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getLocationAssetById = createAppAsyncThunk(
    'assets/get-location-asset-by-id',
    async ({ id }: GetAssetByIdType, { rejectWithValue }) => {
        const params = {
            includeCriteria: [
                AssetGetByEntityInclude.REGION,
                AssetGetByEntityInclude.BUILDING,
                AssetGetByEntityInclude.FLOOR,
                AssetGetByEntityInclude.ROOM,
            ],
        }
        try {
            const result = await client.get(`/api/asset/${id}`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getLocationAssetByIdWithoutStore = createAppAsyncThunk(
    'assets/getLocationAssetByIdWithoutStore',
    async ({ id }: GetAssetByIdType, { rejectWithValue }) => {
        const params = {
            includeCriteria: [
                AssetGetByEntityInclude.REGION,
                AssetGetByEntityInclude.BUILDING,
                AssetGetByEntityInclude.FLOOR,
                AssetGetByEntityInclude.ROOM,
            ],
        }
        try {
            const result = await client.get(`/api/asset/${id}`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAffectedAreaAssetById = createAppAsyncThunk<any, { id: string }>(
    'assets/get-affected-area-asset-by-id',
    async ({ id }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/affected-area/many/${id}`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAssetPagesByAssetId = createAppAsyncThunk<any, { id: string; limit?: number; offset?: number }>(
    'assets/getAssetPagesByAssetId',
    async ({ id, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/points/pages/${id}`, { params: { ...arg } })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAssetPagesByAssetIdWithoutStore = createAppAsyncThunk<
    any,
    { id: string; limit?: number; offset?: number }
>('assets/getAssetPagesByAssetIdWithoutStore', async ({ id, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.get(`/api/asset/points/pages/${id}`, { params: { ...arg } })
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getOneAffectedArea = createAppAsyncThunk<any, { id: string }>(
    'assets/getOneAffectedArea',
    async ({ id }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/affected-area/${id}`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAssetFeeds = createAppAsyncThunk<any, GetFeedByAssetType & { id: string }>(
    'assets/get-feeds-by-asset',
    async ({ id, page, limit, fedType }, { rejectWithValue }) => {
        const params = {
            offset: (page - 1) * limit,
            limit,
            fedType,
        }

        try {
            const result = await client.get(`/api/asset/fed-from/${id}`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createAsset = createAppAsyncThunk<
    any,
    NewAssetFormType & { checkResponse?: (id?: string) => Promise<void> }
>('assets/create-asset', async ({ checkResponse, ...arg }, { rejectWithValue }) => {
    const data = {
        ...arg,
        cost: +arg.cost!,
        laborValue: +arg.laborValue!,
        installDate: dayjs(arg.installDate),
    }

    try {
        const result = await client.post(`/api/asset`, data)

        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse(result?.data?.id)
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const addAssetAvatar = createAppAsyncThunk<any, { assetId: string; file: File; checkResponse?: () => void }>(
    'assets/addAssetAvatar',
    async ({ checkResponse, file, assetId }, { rejectWithValue }) => {
        try {
            const result = await client.post(
                `/api/asset/files/avatar/${assetId}`,
                { file },
                { headers: { 'Content-Type': 'multipart/form-data' } },
            )
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteAssetAvatar = createAppAsyncThunk<any, { assetId: string; checkResponse?: () => void }>(
    'assets/deleteAssetAvatar',
    async ({ checkResponse, assetId }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/asset/files/avatar/${assetId}`)
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getCategoriesForAssetList = createAppAsyncThunk<any, { offset?: number; limit?: number }>(
    'assets/getCategoriesAssets',
    async ({ offset = 0, limit = 100 }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/category/many/`, {
                params: {
                    offset,
                    limit,
                    checkType: 'AND',
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetPropsAssetByTypeListType = {
    typeId?: string
    categoryId?: string
    offset?: number
    limit?: number
    searchString?: string
    isActive?: boolean
}
export const getPropsAssetByTypeList = createAppAsyncThunk<any, GetPropsAssetByTypeListType>(
    'assets/get-props-asset-by-type-id-list',
    async ({ offset = 0, limit = 100, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/props/many`, {
                params: { offset, limit, ...arg },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAllAssetsForAutocomplete = createAppAsyncThunk<
    any,
    { page: number; search?: string; idDependence?: IdDependenceType }
>('assets/get-all-assets-for-autocomplete', async ({ page, search, idDependence }, { rejectWithValue, getState }) => {
    const role = getState().auth?.user?.role
    const regionId = getState().auth?.user?.regionId || ''

    const params = {
        limit: ASSETS_PER_PAGE,
        offset: (page - 1) * ASSETS_PER_PAGE,
        searchString: search ? search : undefined,
        includeCriteria: [
            AssetGetByEntityInclude.FLOOR,
            AssetGetByEntityInclude.ROOM,
            AssetGetByEntityInclude.CATEGORY,
        ],
        regionIdes: role && ![USER_ROLE.ADMIN, USER_ROLE.REQUESTOR].includes(role) ? [regionId] : undefined,
        ...idDependence,
    }

    try {
        const result = await client.get(`/api/asset/by-entity`, { params })
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const deleteAsset = createAppAsyncThunk(
    'asset/delete',
    async ({ id, checkResponse }: { id: string; checkResponse: (status: number) => void }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/asset/${id}`)

            checkResponse(result?.status)
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const editAsset = createAppAsyncThunk<any, EditAssetType & { checkResponse?: () => void } & { id: string }>(
    'assets/edit-asset',
    async ({ id, checkResponse, ...arg }, { rejectWithValue }) => {
        const data = {
            ...arg,
            cost: arg.cost ? +arg.cost : undefined,
            laborValue: arg.laborValue ? +arg.laborValue : undefined,
            installDate: arg.installDate ? dayjs(arg.installDate) : undefined,
        }

        try {
            const result = await client.put(`/api/asset/${id}`, data)
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createAffectedArea = createAppAsyncThunk<any, NewAffectedAreaType & { checkResponse?: () => void }>(
    'assets/create-affected-area',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/asset/affected-area`, { ...arg })
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteAffectedArea = createAppAsyncThunk(
    'asset/deleteAffectedArea',
    async ({ id, checkResponse }: { id: string; checkResponse?: () => void }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/asset/affected-area/${id}`)

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteRoomsAffectedArea = createAppAsyncThunk(
    'asset/deleteRoomsAffectedArea',
    async ({ id, roomIdes }: { id: string; roomIdes: string[] }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/asset/affected-area/room/${id}`, {
                params: { roomIdes },
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const editAffectedArea = createAppAsyncThunk(
    'asset/editAffectedArea',
    async (
        { id, checkResponse, ...arg }: EditAffectedAreaType & { checkResponse?: () => void } & { id: string },
        { rejectWithValue },
    ) => {
        try {
            const result = await client.put(`/api/asset/affected-area/${id}`, { ...arg })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const addFilesToAsset = createAppAsyncThunk<
    any,
    AddFilesToAssetRequestType & { checkResponse?: () => void } & { assetId: string }
>('assets/add-files-to-asset', async ({ checkResponse, assetId, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.post(
            `/api/asset/files/${assetId}`,
            { ...arg },
            { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getAssetFilesList = createAppAsyncThunk<any, GetAssetFilesType>(
    'assets/getAssetFilesList',
    async ({ id, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/files/many/${id}`, { params: { ...arg } })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAssetFilesListWithoutStore = createAppAsyncThunk<any, GetAssetFilesType>(
    'assets/getAssetFilesListWithoutStore',
    async ({ id, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/files/many/${id}`, { params: { ...arg } })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteAssetFile = createAppAsyncThunk(
    'assets/deleteAssetFile',
    async ({ fileIdes, checkResponse }: { fileIdes: string[]; checkResponse?: () => void }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/asset/files/v2`, { params: { fileIdes } })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createAssetFeed = createAppAsyncThunk<any, CreateFeedForAssetType & { checkResponse?: () => void }>(
    'assets/create-asset-feed',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/asset/fed-from`, { ...arg })
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteAssetFeed = createAppAsyncThunk(
    'assets/deleteAssetFeed',
    async ({ id, checkResponse }: { id: string; checkResponse?: () => void }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/asset/fed-from/${id}`)

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createAssetPreferredSubcontractor = createAppAsyncThunk<
    any,
    CreateAssetPreferredSubcontractorsType & { checkResponse?: () => void }
>('assets/create-asset-preferred-contractors', async ({ checkResponse, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.post(`/api/subcontractor/create-asset-preferred-contractors`, { ...arg })
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const createAssignmentFolder = createAppAsyncThunk<
    any,
    NewAssignmentFolderType & { checkResponse?: () => void }
>('assets/createAssignmentFolder', async ({ checkResponse, ...arg }, { rejectWithValue }) => {
    try {
        const result = await client.post(`/api/asset/assignment-folder`, { ...arg })
        if (checkIsGoodStatus(result?.status)) {
            checkResponse && checkResponse()
        }

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const editAssignmentFolder = createAppAsyncThunk<any, EditAssignmentFolderType & { checkResponse?: () => void }>(
    'assets/editAssignmentFolder',
    async ({ checkResponse, id, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/asset/assignment-folder/${id}`, { ...arg })
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAssignmentsList = createAppAsyncThunk<any, { id: string; limit?: number; offset?: number }>(
    'assets/getAssignments',
    async ({ id, limit = 50, offset = 0 }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/assignment-folder/many/${id}`, { params: { limit, offset } })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteAssignment = createAppAsyncThunk(
    'assets/deleteAssignment',
    async ({ id, checkResponse }: { id: string; checkResponse?: () => void }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/asset/assignment-folder/${id}`)

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getPanelsList = createAppAsyncThunk<any, { id: string; limit?: number; offset?: number; pageId?: string }>(
    'assets/getPanelsList',
    async ({ id, limit = 100, offset = 0, pageId }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/assignment-panel/many/${id}`, {
                params: { limit, offset, pageId },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const editPanel = createAppAsyncThunk<any, EditPanelType & { checkResponse?: () => void }>(
    'assets/editAssignmentFolder',
    async ({ checkResponse, folderId, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/asset/assignment-panel/${folderId}`, { ...arg })
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const clearPanel = createAppAsyncThunk(
    'assets/clearPanel',
    async ({ id, checkResponse }: { id: string; checkResponse?: () => void }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/asset/assignment-panel/${id}`)

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getMinMaxAssetCost = createAppAsyncThunk('assets/getMinMaxAssetCost', async (_, { rejectWithValue }) => {
    try {
        const result = await client.get(`/api/asset/min-max-cost`)
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})
type NewAssetForReplaceType = {
    name: string
    model: string
    manufacturer: string
    installDate: Date
    isCritical: boolean
    laborValue?: number
    cost: number
    categoryId: string
    typeId: string
    buildingId: string
    serialNumber: string
    description?: string
    floorId?: string
    roomId?: string
}

export type ReplaceAssetRequestType = {
    oldAssetId: string
    name?: string
    assetId?: string
    itemId?: string
    serialNumber?: string
    isPutToInventory?: boolean
    isDestroyAsset?: boolean
    isRetainRelationships?: boolean
    isRetainFiles?: boolean
    isRetainPreferredContractors?: boolean

    buildingId?: string
    roomId?: string
    shelf?: string
    bin?: string

    linkKey?: string
    newAsset?: NewAssetForReplaceType
}
export const replaceAsset = createAppAsyncThunk<any, ReplaceAssetRequestType & { checkResponse?: () => Promise<void> }>(
    'assets/replaceAsset',
    async ({ oldAssetId, checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/asset/replace/${oldAssetId}`, { ...arg })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
