import React from 'react'
import SideNavBar from '../SideNavBar/SideNavBar'
import s from './AppLayout.module.scss'
import { InternalHeader } from '../InternalHeader'
import { useSelector } from 'react-redux'
import { currentUserSelect } from '../../store/selectors'
import ProgressBar from '../ProgressBar'

type AppLayoutProps = {
    children?: React.ReactNode
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
    const user = useSelector(currentUserSelect)

    if (!user) {
        return <ProgressBar show={true} />
    }

    return (
        <div className={s.pageContainer}>
            <SideNavBar />
            <div className={s.childrenContainer}>
                <InternalHeader />
                <div className={s.child}>{children}</div>
            </div>
        </div>
    )
}

export default AppLayout
