import { ReactNode } from 'react'

import styles from './styles.module.scss'
import classNames from 'classnames'
import { noop } from 'lib/common'

type Props = {
    children: ReactNode
    onClick?: () => void
    className?: string
    height?: string | number
    checked?: boolean
    disabled?: boolean
}
const CardContainer = ({ children, className, height, onClick = noop, checked, disabled }: Props) => {
    return (
        <div
            className={classNames(
                styles.cardContainer,
                checked && styles.checked,
                disabled && styles.disabled,
                className,
            )}
            style={{ height }}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default CardContainer
