import { useEffect, useState } from 'react'
import { debounce } from 'lodash'

const useResizeObserver = () => {
    const [element, setElement] = useState<HTMLDivElement | null>(null)
    const [width, setWidth] = useState<number | null>(null)

    const handleResize = debounce((target: Element) => {
        setWidth(target.clientWidth)
    }, 100)

    const resizeObserver = new ResizeObserver((entries) => {
        const first = entries[0]
        if (first.target) {
            handleResize(first.target)
        }
    })

    useEffect(() => {
        if (element) {
            resizeObserver.observe(element)
        }

        return () => {
            if (element) {
                resizeObserver.unobserve(element)
            }
        }
    }, [element])

    return { width, element, setElement }
}

export default useResizeObserver
