import * as T from 'components/Table'
import { PageTabType } from 'components/PageTabPanel'

export enum InventoryKeysEnum {
    CATEGORY = 'Category',
    ITEM_TYPE = 'Item Type',
    QTY = 'Qty',
    AVG_COST = 'Avg Cost',
    AVG_STOCK_AGE = 'Avg Stock Age',
}

export const requiredInventoryColumns: T.TableColumnType[] = [
    { label: InventoryKeysEnum.CATEGORY, name: 'category', sort: true },
    { label: InventoryKeysEnum.ITEM_TYPE, name: 'type', sort: true },
]

export const initialInventoryColumns: T.TableColumnType[] = [
    { label: InventoryKeysEnum.QTY, name: 'qty', sort: false },
    { label: InventoryKeysEnum.AVG_COST, name: 'avgCost', sort: false },
    { label: InventoryKeysEnum.AVG_STOCK_AGE, name: 'avgStockAge', sort: false },
]

export enum InventoryItemsListTab {
    ALL_INVENTORY = 'ALL_INVENTORY',
    VENDORS = 'VENDORS',
    PURCHASE_REQUESTS = 'PURCHASE_REQUESTS',
    SAVED_VIEWS = 'SAVED_VIEWS',
}

export const tabs: PageTabType[] = [
    {
        id: InventoryItemsListTab.ALL_INVENTORY,
        label: 'All Inventory',
    },
    {
        id: InventoryItemsListTab.VENDORS,
        label: 'Vendors',
    },
    {
        id: InventoryItemsListTab.PURCHASE_REQUESTS,
        label: 'Purchase Requests',
    },
    {
        id: InventoryItemsListTab.SAVED_VIEWS,
        label: 'Saved Views',
    },
]

export enum VendorKeysEnum {
    VENDOR_NAME = 'Vendor Name',
    LOCATION = 'Location',
    WEBSITE = 'Website',
    PARTS_SERVICES = 'Parts Services',
    LAST_ORDER = 'Last Order',
}

export const requiredVendorsColumns: T.TableColumnType[] = [
    { label: VendorKeysEnum.VENDOR_NAME, name: 'name', sort: false },
]

export const initialVendorColumns: T.TableColumnType[] = [
    { label: VendorKeysEnum.LOCATION, name: 'location', sort: false },
    { label: VendorKeysEnum.PARTS_SERVICES, name: 'partsServices', sort: false },
    { label: VendorKeysEnum.LAST_ORDER, name: 'lastOrder', sort: false },
]

export enum PurchaseRequestKeysEnum {
    REQUESTED_DATE = 'Requested Date',
    CATEGORY = 'Category',
    TYPE = 'Type',
    QTY = 'Qty',
    REQUESTED_BY = 'Requested By',
    FOR_WO = 'For WO',
    ORDER_STATUS = 'Order Status',
}

export const requiredPurchaseRequestsColumns: T.TableColumnType[] = [
    { label: PurchaseRequestKeysEnum.REQUESTED_DATE, name: 'requestedDate', sort: false },
]

export const initialPurchaseRequestColumns: T.TableColumnType[] = [
    { label: PurchaseRequestKeysEnum.CATEGORY, name: 'category', sort: false },
    { label: PurchaseRequestKeysEnum.TYPE, name: 'type', sort: false },
    { label: PurchaseRequestKeysEnum.QTY, name: 'qty', sort: false },
    { label: PurchaseRequestKeysEnum.REQUESTED_BY, name: 'requestedBy', sort: false },
    { label: PurchaseRequestKeysEnum.FOR_WO, name: 'forWo', sort: false },
    { label: PurchaseRequestKeysEnum.ORDER_STATUS, name: 'orderStatus', sort: false },
]
