import { Navigate, Route, Routes } from 'react-router-dom'
import { PlaceHolderScreenEnum } from 'components/PlaceHolderScreen'
import React, { lazy } from 'react'
import { ROUTES } from './const'

import UsersRoutes from './UsersRoutes'
import CustomersRoutes from './CustomersRoutes'
import BuildingsRoutes from './BuildingsRoutes'
import AssetsRoutes from './AssetsRoutes'
import EmergencyRoutes from './EmergencyRoutes'
import PMsRoutes from './PMsRoutes'
import SubcontractorsRoutes from './SubcontractorsRoutes'
import WorkOrdersRoutes from './WorkOrdersRoutes'
import { InventoryItemsRoutes } from './InventoryItemsRoutes'
import TenantRoutes from './TenantRoutes'
import { FloorPlanRoutes } from './index'

const CalendarWOPage = lazy(() => import('pages/CalendarPage/CalendarPage'))
const TeamProfile = lazy(() => import('pages/TeamProfile'))
const SupportPage = lazy(() => import('pages/SupportPage'))
const PlaceHolderScreen = lazy(() => import('components/PlaceHolderScreen'))
const ProfilePage = lazy(() => import('pages/ProfilePage'))

const InternalRoutes = () => {
    return (
        <Routes>
            <Route path={`${ROUTES.USERS}/*`} element={<UsersRoutes />} />
            <Route path={`${ROUTES.CUSTOMERS}/*`} element={<CustomersRoutes />} />
            <Route path={`${ROUTES.BUILDINGS}/*`} element={<BuildingsRoutes />} />

            <Route path={`${ROUTES.CALENDAR}/*`} element={<CalendarWOPage />} />

            <Route path={`${ROUTES.PARTS_INVENTORY}/*`} element={<InventoryItemsRoutes />} />

            <Route path={`${ROUTES.ASSETS}/*`} element={<AssetsRoutes />} />
            <Route path={`${ROUTES.EMERGENCY}/*`} element={<EmergencyRoutes />} />

            <Route path={`${ROUTES.WORK_ORDERS}/*`} element={<WorkOrdersRoutes />} />
            <Route path={`${ROUTES.PMs}/*`} element={<PMsRoutes />} />
            <Route path={`${ROUTES.SUBCONTRACTORS}/*`} element={<SubcontractorsRoutes />} />

            <Route path={`${ROUTES.TEAMS}/:id`} element={<TeamProfile />} />

            <Route path={`${ROUTES.TENANTS}/*`} element={<TenantRoutes />} />

            <Route path={`${ROUTES.SUPPORT}`} element={<SupportPage />} />

            <Route path={`${ROUTES.PROFILE}`} element={<ProfilePage />} />
            {/*placeholder screens*/}
            <Route
                path={`${ROUTES.DASHBOARD}/*`}
                element={<PlaceHolderScreen screen={PlaceHolderScreenEnum.DASHBOARD} />}
            />
            {/*<Route path="/calendar" element={<PlaceHolderScreen screen={PlaceHolderScreenEnum.WO_TIMELINE} />} />*/}

            <Route path={`${ROUTES.FLOORS}/*`} element={<FloorPlanRoutes />} />

            <Route path="*" element={<Navigate to={ROUTES.DASHBOARD} />} />
        </Routes>
    )
}

export default InternalRoutes
