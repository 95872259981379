import styles from './styles.module.scss'
import Icons, { DatePickerSVG } from '../../../../assets/icons'
import React, { forwardRef, useMemo } from 'react'
import classNames from 'classnames'
import { Paragraph } from '../../../../ui-kit/components/Typography/Typography'
import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'
import { TextButton } from '../../../../ui-kit/components/Button'
import { getCalendarValuesByMode, getFirstDayOfMontBetweenDates } from '../../helpers'
import DatePicker from 'react-datepicker'
dayjs.extend(utc)
dayjs.extend(isoWeek)

export type CalendarPickerMode = 'Week' | 'Day' | 'Month'
export type CalendarPickerValue = [string, string]

type Props = {
    mode: CalendarPickerMode

    value: CalendarPickerValue

    onChange: (val: CalendarPickerValue) => void
}

const CalendarWeekDayPicker = ({ value, mode, onChange }: Props) => {
    const handlePrev = () => {
        if (mode === 'Day') {
            const day = dayjs(value[0]).subtract(1, 'day')
            onChange([day.startOf('day').toISOString(), day.endOf('day').toISOString()])
        }
        if (mode === 'Week') {
            const day = dayjs(value[0]).subtract(1, 'week')
            onChange([day.startOf('isoWeek').toISOString(), day.endOf('isoWeek').toISOString()])
        }
        if (mode === 'Month') {
            const day = getFirstDayOfMontBetweenDates(value[0], value[1]).subtract(1, 'month')
            onChange(getCalendarValuesByMode(mode, undefined, day))
        }
    }

    const handleNext = () => {
        if (mode === 'Day') {
            const day = dayjs(value[0]).add(1, 'day')
            onChange([day.startOf('day').toISOString(), day.endOf('day').toISOString()])
        }
        if (mode === 'Week') {
            const day = dayjs(value[0]).add(1, 'week')
            onChange([day.startOf('isoWeek').toISOString(), day.endOf('isoWeek').toISOString()])
        }

        if (mode === 'Month') {
            const day = getFirstDayOfMontBetweenDates(value[0], value[1]).add(1, 'month')
            onChange(getCalendarValuesByMode(mode, undefined, day))
        }
    }

    const handleToday = () => {
        onChange(getCalendarValuesByMode(mode, undefined, dayjs()))
    }

    const handlePickerChange = (date: Date) => {
        const day = dayjs(date)

        if (mode === 'Day') {
            onChange([day.startOf('day').toISOString(), day.endOf('day').toISOString()])
        }
        if (mode === 'Week') {
            onChange([day.startOf('isoWeek').toISOString(), day.endOf('isoWeek').toISOString()])
        }

        if (mode === 'Month') {
            onChange(getCalendarValuesByMode(mode, undefined, day))
        }
    }

    const ExampleCustomInput = forwardRef(({ onClick }: any, ref: React.ForwardedRef<HTMLParagraphElement>) => (
        <Paragraph ref={ref} size="md" weight="semi-bold" className={styles.date} onClick={onClick} position="center">
            {mode === 'Day' && dayjs(value[0]).format('MMM D, YYYY')}
            {mode === 'Week' && `${dayjs(value[0]).format('MMM D')} - ${dayjs(value[1]).format('MMM D, YYYY')}`}
            {mode === 'Month' && getFirstDayOfMontBetweenDates(value[0], value[1]).format('MMMM, YYYY')}
        </Paragraph>
    ))

    const selected = useMemo(() => {
        const date = dayjs(value[0]).format('MM-DD-YYYY')
        return mode === 'Month' ? getFirstDayOfMontBetweenDates(value[0], value[1]).toDate() : dayjs(date).toDate()
    }, [value, mode])

    return (
        <div className={styles.root}>
            <div className={classNames(styles.btn, styles.prev)} onClick={handlePrev}>
                <Icons.Next />
            </div>

            <div className={styles.pickerWrap}>
                <DatePicker
                    popperClassName={classNames(styles.picker, mode === 'Week' && styles.selectWeek)}
                    selected={selected}
                    onChange={(date) => date && handlePickerChange(date)}
                    customInput={<ExampleCustomInput />}
                    showMonthDropdown
                    showYearDropdown
                    useShortMonthInDropdown
                    showMonthYearPicker={mode === 'Month'}
                    showFullMonthYearPicker={mode === 'Month'}
                    calendarStartDay={1}
                    showWeekPicker={mode === 'Week'}
                />
            </div>

            <div className={styles.btn} onClick={handleNext}>
                <Icons.Next />
            </div>

            <TextButton
                className={styles.today}
                icon={<DatePickerSVG />}
                text="Today"
                onClick={handleToday}
                colorText="#1B6BC0"
            />
        </div>
    )
}

export default CalendarWeekDayPicker
