import React, { useCallback, useEffect, useState } from 'react'
import CollapsedContainerRow from 'components/CollapsedContainerRow'
import { AssetPageType, QrCodeInAssetType, SaveKeyAddingFile } from 'slices/asset'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { CheckboxInput } from 'ui-kit/components/CheckboxInput'
import { getAssetFilesList, getAssetPagesByAssetIdWithoutStore } from 'api/assets'
import { useAppDispatch } from 'hooks'
import { ItemForAutocompleteType } from 'components/CommonAutoComplete'
import FileIcon from 'components/FileIcon'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'

import s from './styles.module.scss'

type AssetPlansMopsCollapsedType = {
    assets: ItemForAutocompleteType[]
    onAttachPlan: (id: string) => void
    onAttachMop: (id: string) => void
    attachedPlans?: string[]
    attachedMops?: string[]
    className?: string
}

type AssetContentType = {
    assetId: string
    assetName: string
    plans?: AssetPageType[]
    mops?: QrCodeInAssetType[]
}

export const AssetPlansMopsCollapsed = ({
    className,
    assets,
    onAttachPlan,
    onAttachMop,
    attachedPlans,
    attachedMops,
}: AssetPlansMopsCollapsedType) => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState('')
    const [assetContent, setAssetContent] = useState<AssetContentType[]>([])

    const handleChangePlansMops = async (assetId: string) => {
        setIsLoading(assetId)
        try {
            const resMops: { count: number; files: QrCodeInAssetType[] } = await dispatch(
                getAssetFilesList({
                    id: assetId,
                    limit: 100,
                    offset: 0,
                    getKey: SaveKeyAddingFile.ASSET_MOP,
                }),
            ).unwrap()
            const resPlans: { assetId: string; count: number; pages: AssetPageType[] } = await dispatch(
                getAssetPagesByAssetIdWithoutStore({
                    id: assetId,
                    limit: 100,
                    offset: 0,
                }),
            ).unwrap()

            setAssetContent((prev) =>
                prev.map((asset) =>
                    asset.assetId === assetId ? { ...asset, plans: resPlans?.pages, mops: resMops?.files } : asset,
                ),
            )
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading('')
        }
    }

    useEffect(() => {
        setAssetContent(assets?.map((item) => ({ assetId: item?.value ?? '', assetName: item?.label ?? '' })))
    }, [JSON.stringify(assets)])

    return (
        <section className={className}>
            {assetContent?.map((asset) => {
                return (
                    <CollapsedContainerRow
                        defaultExpanded={false}
                        title={asset?.assetName}
                        key={asset?.assetId}
                        id={asset?.assetId}
                        onOpen={handleChangePlansMops}
                        isLoading={isLoading === asset?.assetId}
                        className={s.box}
                    >
                        {!!asset?.plans?.length && (
                            <div className={s.container}>
                                <Paragraph size={'s'} color={'grey'} weight={'semi-bold'}>
                                    Plans
                                </Paragraph>
                                {asset?.plans?.map((plan, index, { length }) => {
                                    return (
                                        <CheckboxInput
                                            checked={attachedPlans?.includes(plan.id)}
                                            onChangeChecked={() => onAttachPlan(plan.id)}
                                            className={s.checkBox}
                                            key={'plan' + plan.id}
                                        >
                                            <a
                                                href={plan?.rootFile ? plan.rootFile?.url : plan.childFile?.url}
                                                target="_blank"
                                                download={plan?.plan ? plan.plan.name : ''}
                                                rel="noreferrer"
                                                className={s.link}
                                            >
                                                <FileIcon accept={''} size={25} />
                                                <span>{plan?.plan ? plan.plan?.name : ''}</span>
                                            </a>
                                        </CheckboxInput>
                                    )
                                })}
                            </div>
                        )}

                        {!!asset?.mops?.length && (
                            <div className={s.container}>
                                <Paragraph size={'s'} color={'grey'} weight={'semi-bold'}>
                                    MOPs
                                </Paragraph>
                                {asset?.mops?.map((mop, index, { length }) => {
                                    return (
                                        <CheckboxInput
                                            checked={attachedMops?.includes(mop.id)}
                                            onChangeChecked={() => onAttachMop(mop.id)}
                                            className={s.checkBox}
                                            key={'mop' + mop.id}
                                        >
                                            <a
                                                href={mop?.url}
                                                target="_blank"
                                                download={mop?.name ? mop.name : ''}
                                                rel="noreferrer"
                                                className={s.link}
                                            >
                                                <FileIcon accept={''} size={25} />
                                                <span>{mop?.name ? mop.name : ''}</span>
                                            </a>
                                        </CheckboxInput>
                                    )
                                })}
                            </div>
                        )}
                    </CollapsedContainerRow>
                )
            })}
        </section>
    )
}
