import { WorkOrderType } from 'slices/workOrder'
import { getSvgImageTypeWo } from 'assets/workOrders-icons'
import { useMemo, useRef } from 'react'
import { PriorityWO } from 'components/NewWorkOrder/config'
import classNames from 'classnames'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import WorkOrderBadge from 'pages/CalendarPage/components/WorkOrderBadge'

import styles from './styles.module.scss'

type Props = {
    workOrder: WorkOrderType
    onClose?: () => void
}

export const LargeWorkOrderCard = ({ workOrder }: Props) => {
    const navigate = useNavigate()
    const refContainer = useRef<HTMLDivElement>(null)

    const styleCard = useMemo(() => {
        switch (workOrder.priority) {
            case PriorityWO.CRITICAL:
                return styles.critical
            case PriorityWO.HIGH:
                return styles.high
            case PriorityWO.MEDIUM:
                return styles.medium
            case PriorityWO.LOW:
                return styles.low
            case PriorityWO.EMERGENCY:
                return styles.critical
            case PriorityWO.SCHEDULED:
                return styles.scheduled
            case PriorityWO.SCHEDULED_PM:
                return styles.scheduled

            default:
                return styles.scheduled
        }
    }, [workOrder])

    const handleViewMore = () => {
        navigate(`/work-orders/${workOrder.id}`)
    }

    return (
        <div ref={refContainer} className={classNames(styles.root, styleCard)}>
            <div className={styles.headerCard}>
                <img src={getSvgImageTypeWo(workOrder.type)} alt="type" />
                <div className={styles.cardNameWrap}>
                    <span>#{workOrder.number} </span>
                    <span>{workOrder.title}</span>
                    <div className={styles.priority}>
                        <WorkOrderBadge status={workOrder.priority} size={12} />
                    </div>
                </div>

                <WorkOrderBadge status={workOrder.status} size={12} />
            </div>

            {workOrder.description && (
                <Paragraph size="xs" weight="tiny" className={styles.description}>
                    {workOrder.description}
                </Paragraph>
            )}

            <div className={styles.flexRow}>
                <div className={styles.flexItem}>
                    <Paragraph size="xs" weight="tiny" color="grey">
                        Building:
                    </Paragraph>
                    <Paragraph size="xs" weight="tiny" color="black">
                        {workOrder.building.name}
                    </Paragraph>
                </div>

                {!!workOrder.buckets?.length && (
                    <div className={styles.flexItem}>
                        <Paragraph size="xs" weight="tiny" color="grey">
                            Assigned team:
                        </Paragraph>
                        <Paragraph size="xs" weight="tiny" color="black">
                            {workOrder.buckets.map((i) => (
                                <span key={i.id} className={styles.buckets}>
                                    {i.name}
                                </span>
                            ))}
                        </Paragraph>
                    </div>
                )}
            </div>

            <div className={styles.flexRow}>
                <div className={styles.flexItem}>
                    <Paragraph size="xs" weight="tiny" color="grey">
                        Start time:
                    </Paragraph>
                    <Paragraph size="xs" weight="tiny" color="black">
                        {dayjs(workOrder.expectedCompletionDate).format('h:mm A')}
                    </Paragraph>
                </div>

                <div className={styles.flexItem}>
                    <Paragraph size="xs" weight="tiny" color="grey">
                        Due Date:
                    </Paragraph>
                    <Paragraph size="xs" weight="tiny" color="black">
                        {dayjs(workOrder.expectedCompletionDate).format('MM/DD/YYYY, h:mm A')}
                    </Paragraph>
                </div>
            </div>

            <Paragraph size="xs" weight="semi-bold" color={'grey'}>{`
                Created by ${workOrder?.creator?.firstName} ${workOrder?.creator?.lastName} - ${dayjs(
                workOrder?.creationDate,
            ).format('MM/DD/YYYY, h:mm A')}
            `}</Paragraph>

            <div className={styles.viewMoreWrap} onClick={handleViewMore}>
                <Paragraph size="s" weight="semi-bold" color="white">
                    View more
                </Paragraph>
            </div>
        </div>
    )
}
