import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Building, BuildingProfileTab, FloorsThree, PlansThree, RoomsThree, UtilityInsurance } from './types'
import { Floor } from '../floorPlanViever'

import extraReducers from './extraReducers'
import { RegionType } from '../region'
import { DirectionEnum } from '../../types'
import { Contact } from '../../components/ContactsList/types'

export interface BuildingsState {
    currentBuilding: Building | null
    floorsTableThree: FloorsThree
    floorsThree: Array<Floor>
    plansThree: PlansThree
    roomsThree: RoomsThree
    buildings: Building[]

    sortBuildingsColumn: string
    sortDirection: DirectionEnum

    buildingsForMap: Building[]
    hoverBuildingIdMap: string | null
    boundsMap: GeoJSON.BBox
    zoomBuildingMap: number
    centerBuildingMap: { lat: number; lng: number }

    buildingPaginationPage: number
    buildingsSearchValue: string
    buildingsCount: number
    isWaitingForResponse: boolean
    openFloorIdLoading: string | null
    buildingProfileTab: BuildingProfileTab
    editFloor: Floor | null
    contactsBuilding: Array<Contact>
    utilitiesBuilding: Array<UtilityInsurance>
    insuranceBuilding: Array<UtilityInsurance>

    regionsList: Array<RegionType>
}

const initialState: BuildingsState = {
    currentBuilding: null,
    floorsTableThree: {},
    floorsThree: [],
    plansThree: {},
    roomsThree: {},
    buildingPaginationPage: 1,
    buildingsCount: 0,
    buildings: [],

    sortBuildingsColumn: 'name',
    sortDirection: DirectionEnum.ASC,

    buildingsForMap: [],
    hoverBuildingIdMap: null,
    boundsMap: [-158.8673730242412, 23.01406913451879, -43.73065427424122, 68.72643972759737],
    zoomBuildingMap: 4,
    centerBuildingMap: { lat: 40.766666, lng: -74.433333 },

    isWaitingForResponse: false,
    openFloorIdLoading: null,
    buildingProfileTab: BuildingProfileTab.FLOORS,
    editFloor: null,
    buildingsSearchValue: '',
    contactsBuilding: [],
    utilitiesBuilding: [],
    insuranceBuilding: [],

    regionsList: [],
}

export const buildingSlice = createSlice({
    name: 'building',
    initialState,
    reducers: {
        setSortBuildingColumn: (state, action: PayloadAction<{ column: string; direction: DirectionEnum }>) => {
            if (state.sortBuildingsColumn !== action.payload.column) {
                state.sortDirection = DirectionEnum.ASC
            } else {
                state.sortDirection = action.payload.direction
            }

            state.sortBuildingsColumn = action.payload.column
        },

        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isWaitingForResponse = action.payload
        },
        setBuildingPaginationPage: (state, action: PayloadAction<number>) => {
            state.buildingPaginationPage = action.payload
        },
        setBuildingProfileTab: (state, action: PayloadAction<BuildingProfileTab>) => {
            state.buildingProfileTab = action.payload
        },
        setEditFloor: (state, action: PayloadAction<Floor | null>) => {
            state.editFloor = action.payload
        },
        setBuildingsSearchValue: (state, action: PayloadAction<string>) => {
            state.buildingsSearchValue = action.payload
            state.buildingPaginationPage = 1
        },
        setOpenFloorIdLoading: (state, action: PayloadAction<string | null>) => {
            state.openFloorIdLoading = action.payload
        },
        setHoverBuildingIdMap: (state, action: PayloadAction<string | null>) => {
            state.hoverBuildingIdMap = action.payload
        },
        setZoomBuildingMap: (state, action: PayloadAction<number>) => {
            state.zoomBuildingMap = action.payload
        },
        setCenterBuildingMap: (state, action: PayloadAction<{ lat: number; lng: number }>) => {
            state.centerBuildingMap = action.payload
        },
        setBoundsMap: (state, action: PayloadAction<GeoJSON.BBox>) => {
            state.boundsMap = action.payload
        },
        clearBuilding: (state) => {
            state.currentBuilding = null
        },
    },
    extraReducers: extraReducers,
})

export const {
    setBuildingPaginationPage,
    setEditFloor,
    clearBuilding,
    setBuildingProfileTab,
    setBuildingsSearchValue,
    setOpenFloorIdLoading,
    setHoverBuildingIdMap,
    setBoundsMap,
    setZoomBuildingMap,
    setCenterBuildingMap,
    setLoading,
    setSortBuildingColumn,
} = buildingSlice.actions
export const BuildingReducer = buildingSlice.reducer
