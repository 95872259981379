import * as yup from 'yup'
import { emailRegExp, MUST_BE_EMAIL, REQUIRED_FIELD, TOO_LONG } from 'pages/NewAssetPage/config'
import { Contact } from '../ContactsList/types'
import { LengthConstrains } from 'types'

export const initContact: Partial<Contact> = {
    firstName: '',
    lastName: '',
    title: '',
    phone: '',
    email: '',
    type: '',
}

export const Schema = yup.object().shape({
    firstName: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required(REQUIRED_FIELD),
    lastName: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required(REQUIRED_FIELD),
    email: yup.string().matches(emailRegExp, MUST_BE_EMAIL).required(REQUIRED_FIELD),
})
