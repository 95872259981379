import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import s from './Typography.module.scss'

export const Paragraph: FC<TParagraph> = ({
    color = 'black',
    size = 'md',
    weight = 'tiny',
    className,
    position = 'left',
    ...restProps
}) => {
    return <p className={`${s.p} ${s[size]} ${s[weight]} ${s[color]} ${s[position]} ${className}`} {...restProps} />
}

type TParagraph = {
    weight?: 'tiny' | 'bold' | 'semi-bold'
    color?: 'grey' | 'black' | 'white' | 'blue' | 'red' | 'light-blue'
    size?: 'xxs' | 'xs' | 's' | 'smd' | 'md' | 'l' | 'xl' | 'xxl'
    position?: 'left' | 'center' | 'right'
} & DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>
