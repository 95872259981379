import { Tenant, User } from '../../slices/users'
import { LengthConstrains, USER_ROLE, USER_TYPE } from '../../types'
import * as yup from 'yup'
import { boolean } from 'yup'
import { AutocompleteType, yupAutocompleteItem } from '../../components/CommonAutoComplete'
import {
    emailRegExp,
    MUST_BE_EMAIL,
    MUST_BE_PHONE,
    phoneV2RegExp,
    REQUIRED_FIELD,
    TOO_LONG,
} from '../NewAssetPage/config'
import { LeaseForm, LeaseType } from './types'

export type UserForm = Partial<User> & {
    isHourlyRate: boolean
    avatarFile: File | null
    avatarUrl?: string
    newRegionId: string | null
    isRegion: boolean
}

export type CreateUserForm = Omit<UserForm, 'buildingAssignments'> & {
    isBuckets: boolean
    bucketsId: AutocompleteType[] | null

    isBuildings: boolean
    isRoom: boolean

    buildingsIds: AutocompleteType[] | null
    buildingAssignments: Array<string> | null

    room: AutocompleteType | null
    roomId: string | null

    buckets: Array<string> | null

    noteText: string
    documents: Array<File> | null
}

export const initUserForm: UserForm = {
    firstName: '',
    lastName: '',
    title: '',
    role: USER_ROLE.TECHNICIAN,
    type: undefined,
    phone: '',
    email: '',
    isRegion: true,
    newRegionId: null,
    isHourlyRate: false,
    avatarFile: null,
    hourlyRate: '',
}

export const UserProfileSchema = () =>
    yup.object().shape({
        firstName: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required('First name is required'),
        lastName: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required('Last name is required'),
        title: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required('Title is required'),
        role: yup.string().required('Role is required'),
        type: yup.string().required('Type is required'),
        email: yup.string().matches(emailRegExp, MUST_BE_EMAIL).required('Email is required'),
        phone: yup
            .string()
            .matches(phoneV2RegExp, MUST_BE_PHONE)
            .max(LengthConstrains.L_50, TOO_LONG)
            .required(REQUIRED_FIELD),

        isRegion: boolean(),
        regionId: yup.string().when('isRegion', {
            is: true,
            then: (schema) => schema.required('Region is required'),
            otherwise: (schema) => schema.nullable(),
        }),

        isHourlyRate: yup.boolean(),
        hourlyRate: yup.number().when('isHourlyRate', {
            is: true,
            then: (schema) => schema.required('Hourly Rate is required'),
            otherwise: (schema) => schema.nullable(),
        }),
    })

export const initUserCreateForm: CreateUserForm = {
    ...initUserForm,
    isBuckets: false,
    bucketsId: [],
    isBuildings: true,
    isRoom: false,
    roomId: null,
    room: null,
    buildingsIds: [],
    buildingAssignments: null,
    buckets: null,

    noteText: '',
    documents: null,
}

export const UserCreateSchema = () =>
    yup.object().shape({
        firstName: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required('First name is required'),
        lastName: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required('Last name is required'),
        title: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required('Title is required'),
        role: yup.string().required('Role is required'),
        type: yup.string().required('Type is required'),
        email: yup.string().matches(emailRegExp, MUST_BE_EMAIL).required('Email is required'),
        phone: yup
            .string()
            .matches(phoneV2RegExp, MUST_BE_PHONE)
            .max(LengthConstrains.L_50, TOO_LONG)
            .required(REQUIRED_FIELD),

        isRegion: boolean(),
        regionId: yup.string().when('isRegion', {
            is: true,
            then: (schema) => schema.required('Region is required'),
            otherwise: (schema) => schema.nullable(),
        }),
        isHourlyRate: yup.boolean(),
        hourlyRate: yup.number().when('isHourlyRate', {
            is: true,
            then: (schema) => schema.required('Hourly Rate is required'),
            otherwise: (schema) => schema.nullable(),
        }),

        isBuckets: yup.boolean(),

        isBuildings: yup.boolean(),
        buildingsIds: yup
            .array()
            .of(yupAutocompleteItem)
            .when('isBuildings', {
                is: true,
                then: (schema) => schema.min(1, REQUIRED_FIELD),
                otherwise: (schema) => schema.nullable(),
            }),

        isRoom: yup.boolean(),
        room: yup.object().when('isRoom', {
            is: true,
            then: (schema) => schema.required(REQUIRED_FIELD),
            otherwise: (schema) => schema.nullable(),
        }),
    })

export const tenantFormSchema = yup.object().shape({
    name: yup.string().max(LengthConstrains.L_100, 'Too Long!').trim().required('Is required'),
    website: yup.string().max(LengthConstrains.L_100, 'Too Long!').trim().required('Is required'),
})

export const leaseFormSchema = yup.object().shape({
    region: yup.object().required(REQUIRED_FIELD),
    building: yup.object().required(REQUIRED_FIELD),
    room: yup.object().required(REQUIRED_FIELD),
    moveInDate: yup.date().required(REQUIRED_FIELD),
    leaseExpirationDate: yup.date().required(REQUIRED_FIELD),
})

export type TenantForm = Partial<Tenant> & {
    name: string
    website: string
    note: string

    avatarFile: File | null
    avatarUrl?: string
    files: Array<File> | null
}

export const initTenantForm: TenantForm = {
    name: '',
    website: '',
    note: '',
    avatarFile: null,
    files: null,
}

export const selectTypeData: { [key: string]: Array<{ label: string; value: string }> } = {
    [USER_ROLE.SUPERADMIN]: [
        {
            value: USER_TYPE.MAIN,
            label: 'Main',
        },
        {
            value: USER_TYPE.SUPPORT,
            label: 'Support',
        },
        {
            value: USER_TYPE.SALES,
            label: 'Sales',
        },
        {
            value: USER_TYPE.VENDOR,
            label: 'Vendor',
        },
    ],
    [USER_ROLE.ADMIN]: [
        {
            value: USER_TYPE.MAIN,
            label: 'Main',
        },
        {
            value: USER_TYPE.SUPPORT,
            label: 'Support',
        },
        {
            value: USER_TYPE.EMERGENCY,
            label: 'Emergency',
        },
        {
            value: USER_TYPE.MAINTENANCE,
            label: 'Maintenance',
        },
        {
            value: USER_TYPE.BILLING,
            label: 'Billing',
        },
        {
            value: USER_TYPE.ITTECHNOLOGY,
            label: 'IT/Technology',
        },
        {
            value: USER_TYPE.LEGAL,
            label: 'Legal',
        },
    ],
    [USER_ROLE.SUPERVISOR]: [
        {
            value: USER_TYPE.MAIN,
            label: 'Main',
        },
        {
            value: USER_TYPE.EMERGENCY,
            label: 'Emergency ',
        },
        {
            value: USER_TYPE.MAINTENANCE,
            label: 'Maintenance',
        },
        {
            value: USER_TYPE.BILLING,
            label: 'Billing',
        },
        {
            value: USER_TYPE.ITTECHNOLOGY,
            label: 'IT/Technology',
        },
        {
            value: USER_TYPE.SECURITY,
            label: 'Security',
        },
        {
            value: USER_TYPE.OTHER,
            label: 'Other',
        },
    ],
    [USER_ROLE.TECHNICIAN]: [
        {
            value: USER_TYPE.MAIN,
            label: 'Main',
        },
        {
            value: USER_TYPE.EMERGENCY,
            label: 'Emergency',
        },
        {
            value: USER_TYPE.MAINTENANCE,
            label: 'Maintenance',
        },
        {
            value: USER_TYPE.TRADE,
            label: 'Trade',
        },
        {
            value: USER_TYPE.OTHER,
            label: 'Other',
        },
    ],
    [USER_ROLE.REQUESTOR]: [
        {
            value: USER_TYPE.MAIN,
            label: 'Main',
        },
        {
            value: USER_TYPE.MANAGER,
            label: 'Manager',
        },
        {
            value: USER_TYPE.CONTRACTOR,
            label: 'Contractor',
        },
        {
            value: USER_TYPE.SECURITY,
            label: 'Security',
        },
        {
            value: USER_TYPE.LEASING,
            label: 'Leasing',
        },
        {
            value: USER_TYPE.CLEANING,
            label: 'Cleaning',
        },
        {
            value: USER_TYPE.GROUNDS_CREW,
            label: 'Grounds Crew',
        },
        {
            value: USER_TYPE.JANITORIAL,
            label: 'Janitorial',
        },
        {
            value: USER_TYPE.RECEPTION,
            label: 'Reception',
        },
        {
            value: USER_TYPE.OTHER,
            label: 'Other',
        },
    ],
    ['TENANT']: [
        {
            value: USER_TYPE.MAIN,
            label: 'Main',
        },
        {
            value: USER_TYPE.OTHER,
            label: 'Other',
        },
    ],
}

export const initLeaseForm: LeaseForm = {
    region: null,
    regionId: null,
    building: null,
    buildingId: null,
    room: null,
    roomId: null,
    leaseAmount: 0,
    moveInDate: null,
    leaseExpirationDate: null,
    description: '',
    leaseFiles: null,
    files: null,
    leaseType: LeaseType.GROSS,
    hasNewLeaseInformation: false,
}
