import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector, useInfinityScroll } from 'hooks/index'
import { INVENTORY_PER_PAGE, InventoryItemStatus, InventoryItemType } from 'slices/inventoryItems'
import { CommonOtherResponseType, NOTIFICATION_STATUS } from 'types'
import { getInventoryItemsList } from 'api/partsInventory'
import sendNotification from 'lib/notification'
import { currentUserSelect } from 'store/selectors'

type UseInventoryType = {
    assetTypeIdes?: string[]
    status?: InventoryItemStatus
    workOrderId?: string
    isAllow?: boolean
    limit?: number
}

export const useInventory = ({
    assetTypeIdes,
    status,
    workOrderId,
    isAllow = true,
    limit = INVENTORY_PER_PAGE,
}: UseInventoryType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const [isLoading, setIsLoading] = useState(false)
    const [inventoryItems, setInventoryItems] = useState<InventoryItemType[]>([])
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)

    const { setLastElement } = useInfinityScroll({
        fetchNextData: () => setPage(page + 1),
        hasMore: inventoryItems.length < count,
    })

    const getInventory = async () => {
        setIsLoading(true)
        try {
            const res: CommonOtherResponseType<InventoryItemType> = await dispatch(
                getInventoryItemsList({
                    typeIdes: assetTypeIdes,
                    limit,
                    offset: limit * (page - 1),
                    status,
                    allocatedToWorkOrderId: workOrderId,
                }),
            ).unwrap()

            if (res) {
                setInventoryItems(page === 1 ? res?.payload : [...inventoryItems, ...res.payload])
                setCount(res?.count)
            }
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!user || !isAllow) {
            return
        }
        getInventory()
    }, [user, page, JSON.stringify(assetTypeIdes), workOrderId, isAllow])

    return { inventoryItems, isLoading, count, setLastElement, getInventory }
}
