import { useAppSelector } from '../../../hooks'
import { USER_ROLE } from '../../../types'

const useGetUserSettings = (userRole: USER_ROLE) => {
    const userAdminsSettings = useAppSelector(({ users }) => users.userAdminsSettings)
    const userRequesterSettings = useAppSelector(({ users }) => users.userRequesterSettings)
    const userTechniciansSettings = useAppSelector(({ users }) => users.userTechniciansSettings)
    const userSupervisorsSettings = useAppSelector(({ users }) => users.userSupervisorsSettings)
    const userSuperAdminsSettings = useAppSelector(({ users }) => users.userSuperAdminsSettings)

    switch (userRole) {
        case USER_ROLE.ADMIN:
            return userAdminsSettings
        case USER_ROLE.SUPERADMIN:
            return userSuperAdminsSettings
        case USER_ROLE.REQUESTOR:
            return userRequesterSettings
        case USER_ROLE.TECHNICIAN:
            return userTechniciansSettings
        case USER_ROLE.SUPERVISOR:
            return userSupervisorsSettings
        default:
            return null
    }
}

export default useGetUserSettings
