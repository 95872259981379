import * as T from '../../components/Table'
import { RoutesTabPanelType } from '../../components/PageTabPanel'
import { BuildingRoutes } from '../../slices/building'

export const BUILDS_PER_PAGE = 25

export const tableColumnsInitial: Array<T.TableColumnType> = [
    { name: 'description', label: 'Description', sort: true },
    { name: 'address', label: 'Address' },
    { name: 'type', label: 'Building type', sort: true },
    { name: 'region', label: 'Region' },
    { name: 'area', label: 'Square Footage' },
    { name: 'totalAssetsCount', label: 'Assets' },
    { name: 'pastDueWOCount', label: 'Past Due WOs' },
    { name: 'pastDuePMCount', label: 'Past Due PMs' },
    // { name: 'plans', label: 'Plans' },
    { name: 'openWOCount', label: 'Open WOs' },
    { name: 'totalWOCount', label: 'Total WOs' },
    // { name: 'numberTenant', label: '# of Tenants' },
    { name: 'year', label: 'Year Built' },
    // { name: 'emergency', label: 'Emergency Plans' },
    // { name: 'rooms', label: 'Available Rooms/Offices' },
]

export const visibleTableColumnsInitial: Array<T.TableColumnType> = [
    { name: 'description', label: 'Description', sort: true },
    { name: 'address', label: 'Address' },
    { name: 'type', label: 'Building type', sort: true },
    { name: 'region', label: 'Region' },
    { name: 'area', label: 'Square Footage' },
    { name: 'totalAssetsCount', label: 'Assets' },
    { name: 'pastDueWOCount', label: 'Past Due WOs' },
    // { name: 'plans', label: 'Plans' },
]

export const getBuildingsTabs = (isRegion: boolean): Array<RoutesTabPanelType> => {
    const tabs: Array<RoutesTabPanelType> = [
        {
            link: BuildingRoutes.TABLE,
            label: 'Table',
        },
        {
            link: BuildingRoutes.MAP,
            label: 'Map',
        },
    ]

    isRegion &&
        tabs.push({
            link: BuildingRoutes.MANAGE_REGIONS,
            label: 'Manage Regions',
        })

    return tabs
}
