import { CalendarPickerMode, CalendarPickerValue } from './components/CalendarWeekDayPicker'
import dayjs, { Dayjs } from 'dayjs'

import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'
import { CalendarMonthWOList } from '../../slices/workOrder'
import { ColorVariant } from '../../components/StatusBadge'
import { WorkOrderStatus } from '../../components/NewWorkOrder/config'
dayjs.extend(utc)
dayjs.extend(isoWeek)

export const getCalendarValuesByMode = (
    mode: CalendarPickerMode,
    value?: CalendarPickerValue,
    dayValue?: Dayjs,
): CalendarPickerValue => {
    const day = value ? dayjs(value[0]) : dayValue ? dayjs(dayValue) : dayjs()

    if (mode === 'Week') {
        const firstWeekDay = day.startOf('isoWeek')
        const lastWeekDay = day.endOf('isoWeek')

        return [firstWeekDay.toISOString(), lastWeekDay.toISOString()]
    }

    if (mode === 'Month') {
        const firstMonthDay = day.startOf('month')
        const lastMonthDay = day.endOf('month')

        const firstWeekDay = firstMonthDay.startOf('isoWeek')
        const lastWeekDay = lastMonthDay.endOf('isoWeek')

        return [firstWeekDay.toISOString(), lastWeekDay.toISOString()]
    }

    return [day.startOf('day').toISOString(), day.endOf('day').toISOString()]
}

export const getArrayDates = (from: string, to: string) => {
    const dayFrom = dayjs(from)
    const dayTo = dayjs(to)

    const daysArr = [dayjs(dayFrom)]
    let tempDate = dayjs(dayFrom)

    while (tempDate.isBefore(dayTo, 'day')) {
        tempDate = dayjs(tempDate.add(1, 'days'))
        daysArr.push(dayjs(tempDate).startOf('day'))
    }

    return daysArr
}

export const getFirstDayOfMontFromArray = (arr: Array<Dayjs>) => {
    return arr.find((day) => day.date() === 1) || dayjs()
}

export const getFirstDayOfMontBetweenDates = (from: string, to: string) => {
    const arr = getArrayDates(from, to)
    return getFirstDayOfMontFromArray(arr)
}

export const filterCalendarDayWoList = (woList: CalendarMonthWOList, date: Dayjs) => {
    return woList.filter((item) =>
        date.isAfter(dayjs())
            ? dayjs(item.expectedCompletionDate).isAfter(dayjs()) && !dayjs(date).isAfter(item.expectedCompletionDate)
            : true,
    )
}

export const getDurationString = (minutes: number) => {
    const hours = Math.trunc(minutes / 60)
    const restMinutes = minutes % 60

    return `${hours}:${String(restMinutes).padStart(2, '0')}`
}

export const getColorCalendarStatus = (option: WorkOrderStatus): ColorVariant => {
    switch (option) {
        case WorkOrderStatus.COMPLETED:
        case WorkOrderStatus.PENDING_REVIEW:
            return ColorVariant.green

        case WorkOrderStatus.NEW:
            return ColorVariant.yellow

        case WorkOrderStatus.IN_PROGRESS:
            return ColorVariant.lightBlue

        case WorkOrderStatus.CANCELLED:
        case WorkOrderStatus.ON_HOLD:
            return ColorVariant.grey

        default:
            return ColorVariant.grey
    }
}

export const getTextCalendarStatus = (option: WorkOrderStatus) => {
    switch (option) {
        case WorkOrderStatus.IN_PROGRESS:
            return 'Open'

        default:
            return option
    }
}
