import { client, axiosErrorHandler } from './setupAxios'
import { DirectionEnum } from '../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from '../common/createAppAsyncThunk'
import { BUILDS_PER_PAGE } from '../pages/BuildingsPage/helpers'
import { IdDependenceType } from 'components/CommonAutoComplete'

interface CreateBuildingData {
    name: string
    type?: string
    description?: string
    creator?: string
    address?: string
    zipCode?: string
    state?: string
    country?: string
    city?: string
    location?: string
    lat?: number
    long?: number
    area?: number
    floorsNumber?: number
    height?: number
    websiteURL?: string
    contactInfo?: string
    buidingAccessInformation?: string
    yearBuilt?: number
    userId?: number
    customerId?: string
    regionId?: string
}

interface getBuildingsListParams {
    page: number
    keySearchValue: string
    size?: number
    sortField?: string
    sortDirection?: DirectionEnum
    customerId?: string
    tenantCompanyId?: string
}

interface GetBuildingsListDataByCoords {
    minLat: number
    maxLat: number
    minLong: number
    maxLong: number
    customerId: string
    value: string
}

export const createBuilding = createAppAsyncThunk(
    'buildings/createBuilding',
    async (data: FormData, { rejectWithValue }) => {
        try {
            const result = await client.post(`api/buildings/create-building`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getBuildingById = createAsyncThunk('buildings/getById', async (id: string, { rejectWithValue }) => {
    try {
        const result = await client.get(`api/buildings/get-building-by-id?buildingId=${id}`, {})
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getBuildingsList = createAppAsyncThunk(
    'buildings/getList',
    async (params: getBuildingsListParams, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/buildings/get-buildings-list`, {
                params: {
                    ...params,
                    size: params.size || BUILDS_PER_PAGE,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
export const getBuildingsListByCoords = createAppAsyncThunk(
    'buildings/getListByMap',
    async (params: GetBuildingsListDataByCoords, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/buildings/get-buildings-list-by-coordinates`, {
                params: {
                    ...params,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const updateBuilding = createAppAsyncThunk(
    'buildings/update',
    async (data: Partial<CreateBuildingData> & { buildingId: string }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`api/buildings/update-building`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type UploadImageBuilding = {
    buildingId: string
    image: File
}

export const uploadImageBuilding = createAppAsyncThunk(
    'buildings/uploadImageBuilding',
    async (data: UploadImageBuilding, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/buildings/upload-building-image`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteBuilding = createAsyncThunk('buildings/delete', async (id: string, { rejectWithValue }) => {
    try {
        const result = await client.post(`/buildings/delete-building?buildingId=${id}`, {
            id,
        })
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

type CreateBuildingFiles = {
    files: Array<File>
    buildingId: string
}

export const createBuildingFiles = createAsyncThunk(
    'buildings/createBuildingFiles',
    async (data: CreateBuildingFiles, { rejectWithValue }) => {
        try {
            const result = await client.post(`api/buildings/files/create-building-file`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createUtilitiesInsuranceFiles = createAsyncThunk(
    'buildings/createUtilitiesInsuranceFiles',
    async (data: FormData, { rejectWithValue }) => {
        try {
            const result = await client.post(`api/buildings/utilities/files/create-utilities-file`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteUtilitiesInsuranceFile = createAsyncThunk(
    'buildings/deleteUtilitiesInsuranceFile',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`api/buildings/utilities/files/delete-utilities-file`, {
                params: {
                    fileId: id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteBuildingFile = createAsyncThunk(
    'buildings/deleteBuildingFile',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/buildings/files/delete-building-file`, {
                params: {
                    id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getBuildingsListForAutocomplete = createAppAsyncThunk<
    any,
    { page: number; search: string; customerId?: string; idDependence?: IdDependenceType; size?: number }
>(
    'buildings/get-buildings-list-for-autocomplete',
    async ({ page, search, idDependence, customerId, size = BUILDS_PER_PAGE }, { getState, rejectWithValue }) => {
        try {
            const result = await client.get(
                idDependence?.regionId || idDependence?.regionIdes
                    ? `/api/buildings/get-buildings-list-by-region`
                    : `/api/buildings/get-buildings-list`,
                {
                    params: {
                        page,
                        keySearchValue: search,
                        size,
                        sortField: 'name',
                        sortDirection: DirectionEnum.ASC,
                        customerId: customerId ?? (getState().auth.user?.customerId || ''),
                        ...idDependence,
                    },
                },
            )
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getBuildingsListByCustomer = createAppAsyncThunk(
    'buildings/getBuildingsListByCustomer',
    async (params: getBuildingsListParams, { getState, rejectWithValue }) => {
        try {
            const result = await client.get(`/api/buildings/get-buildings-list`, {
                params: {
                    ...params,
                    size: BUILDS_PER_PAGE,
                    sortField: 'name',
                    sortDirection: DirectionEnum.ASC,
                    customerId: getState().auth.user?.customerId || '',
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetBuildingsFiles = {
    page: number
    buildingId: string
}

export const getBuildingsFiles = createAppAsyncThunk(
    'buildings/getBuildingsFiles',
    async (params: GetBuildingsFiles, { rejectWithValue }) => {
        try {
            const result = await client.get(`api/buildings/files/get-building-files-by-building-id`, {
                params: {
                    ...params,
                    size: BUILDS_PER_PAGE,
                    sortField: 'id',
                    sortDirection: DirectionEnum.ASC,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
