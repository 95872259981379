import * as T from 'components/Table'

export const emergencyPlanTableColumns: T.TableColumnType[] = [
    { name: 'createdAt', label: 'Created Date' },
    { name: 'affectedArea', label: 'Affected Area' },
    { name: 'lastReviewed', label: 'Last Reviewed' },
    { name: 'procedures', label: 'Procedures' },
]

export const visibleEmergencyPlanTableColumns: T.TableColumnType[] = [
    { name: 'createdAt', label: 'Created Date' },
    { name: 'affectedArea', label: 'Affected Area' },
    { name: 'lastReviewed', label: 'Last Reviewed' },
]

export const requiredEmergencyPlanTableColumns: T.TableColumnType[] = [
    { label: 'Plan Name', name: 'name' },
    { label: 'Scenario', name: 'scenario' },
]

export const emergencyContactsTableColumns: T.TableColumnType[] = [
    { label: 'Last Updated', name: 'lastUpdateDate' },
    { label: 'Region', name: 'region' },
    { label: 'Phone', name: 'phone' },
    { label: 'Hours of Operation', name: 'hoursOfOperation' },
    { label: 'Zip Code', name: 'zipCode' },
    { label: 'Address', name: 'address' },

    { label: 'Responsibilities', name: 'responsibilities' },
    { label: 'Availability', name: 'availability' },
    { label: 'City/State', name: 'city' },
    { label: 'Approved Through Procurement', name: 'approvedByProcurement' },
    { label: 'After hours/emergency phone', name: 'afterHoursPhone' },
    { label: '# of Contacts', name: 'contacts' },
]

export const visibleEmergencyContactsTableColumns: T.TableColumnType[] = [
    { label: 'Responsibilities', name: 'responsibilities' },
    { label: 'Availability', name: 'availability' },
    { label: 'City/State', name: 'city' },
    { label: 'Approved Through Procurement', name: 'approvedByProcurement' },
    { label: 'After hours/emergency phone', name: 'afterHoursPhone' },
    { label: '# of Contacts', name: 'contacts' },
]

export const requiredEmergencyContactsTableColumns: T.TableColumnType[] = [{ label: 'Name', name: 'name' }]

export const emergencyIncidentsTableColumns: T.TableColumnType[] = [
    { name: 'createdAt', label: 'Incident Date' },
    { name: 'buildingName', label: 'Building name' },
    { name: 'documents', label: 'Documents' },
    { name: 'affectedAssets', label: ' Affected assets' },
]

export const visibleEmergencyIncidentsTableColumns: T.TableColumnType[] = [
    { name: 'createdAt', label: 'Incident Date' },
    { name: 'buildingName', label: 'Building name' },
    { name: 'documents', label: 'Documents' },
    { name: 'affectedAssets', label: ' Affected assets' },
]

export const requiredEmergencyIncidentsTableColumns: T.TableColumnType[] = [{ label: 'Incidents', name: 'name' }]

export const COUNT_PAGE = 25
