import React from 'react'
import ReactDOM from 'react-dom/client'
import './fonts/DM/static/DMSans-Regular.ttf'
import './fonts/Poppins/Poppins-Medium.ttf'
import './fonts/Poppins/Poppins-Regular.ttf'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import { App } from 'App'
import { store } from 'store'
import { authServiceInterceptor } from 'api/setupAxios'

import './index.scss'

authServiceInterceptor(store)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
)
