import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import styles from '../../styles.module.scss'
import { TableRow } from '@mui/material'

type Props = {
    children: ReactNode
    className?: string
}
const TableHeadRow: FC<Props> = ({ children, className }) => {
    return <TableRow className={classNames(styles.tableHeadRow, className)}>{children}</TableRow>
}

export default TableHeadRow
