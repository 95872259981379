import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AssetProfileTab } from 'pages/AssetProfile/config'
import { AssetFiltersType, AssetsState, CurrentAssetType, LocationAssetType } from './types'
import { AssetsListPageTab, initialAssetColumns } from 'pages/AssetsPage/config'
import { extraReducers } from './extraReducers'
import { dropInLS, getSettingsFromLS, LsKeys } from 'lib/workWithLS'
import { CommonSortType, DirectionEnum, ListViewMode } from 'types'
import { TableColumnType } from 'components/Table'

const initialState: AssetsState = {
    currentAsset: {} as CurrentAssetType,
    assets: [],
    isWaitingForResponse: false,
    isWaitingFloorPlan: true,
    assetProfileTab: AssetProfileTab.DETAILS,
    notesCurrentAsset: [],
    propsCurrentAsset: [],
    locationCurrentAsset: {} as LocationAssetType,
    affectedAreaCurrentAsset: [],
    feedToCurrentAsset: [],
    feedFromCurrentAsset: [],
    assetListTab: AssetsListPageTab.ASSETS,
    assetPaginationPage: 1,
    assetsCount: 0,
    assetsSearchValue: '',
    assetPages: [],
    selectedPlanPage: null,
    assetsPointsOnPlan: [],
    isNeedUpdatePoints: false,
    assetsSettings: {
        filters: getSettingsFromLS('filters', LsKeys.ASSETS_SETTINGS, null),
        columns: getSettingsFromLS('columns', LsKeys.ASSETS_SETTINGS, initialAssetColumns),
        currentViewId: getSettingsFromLS('currentViewId', LsKeys.ASSETS_SETTINGS, ''),
        currentViewTitle: getSettingsFromLS('currentViewTitle', LsKeys.ASSETS_SETTINGS, ''),
        listMode: getSettingsFromLS('listMode', LsKeys.ASSETS_SETTINGS, ListViewMode.TABLE),
        isSystemCurrentView: getSettingsFromLS('isSystemCurrentView', LsKeys.ASSETS_SETTINGS, false),
        sortField: getSettingsFromLS('sortField', LsKeys.ASSETS_SETTINGS, 'id'),
        sortDirection: getSettingsFromLS('sortDirection', LsKeys.ASSETS_SETTINGS, DirectionEnum.ASC),
    },

    categoriesThree: [],
}

export const assetSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        clearCurrentAsset: (state) => {
            state.currentAsset = initialState.currentAsset
            state.notesCurrentAsset = initialState.notesCurrentAsset
            state.propsCurrentAsset = initialState.propsCurrentAsset
            state.locationCurrentAsset = initialState.locationCurrentAsset
            state.assetPages = initialState.assetPages
            state.selectedPlanPage = initialState.selectedPlanPage
            state.assetsPointsOnPlan = initialState.assetsPointsOnPlan
        },
        clearAssetsSettings: (state) => {
            state.assetPaginationPage = initialState.assetPaginationPage
            state.assetsSearchValue = initialState.assetsSearchValue
        },
        clearPlanPoints: (state) => {
            state.assetsPointsOnPlan = initialState.assetsPointsOnPlan
        },
        needToUpdatePoints: (state) => {
            state.isNeedUpdatePoints = true
        },
        setAssetProfileTab: (state, action: PayloadAction<AssetProfileTab>) => {
            state.assetProfileTab = action.payload
        },
        setAssetListTab: (state, action: PayloadAction<AssetsListPageTab>) => {
            state.assetListTab = action.payload
        },
        setAssetPaginationPage: (state, action: PayloadAction<number>) => {
            state.assetPaginationPage = action.payload
        },
        setAssetsSearchValue: (state, action: PayloadAction<string>) => {
            state.assetsSearchValue = action.payload
            state.assetPaginationPage = 1
        },
        setSelectedPlanPage: (state, action: PayloadAction<string>) => {
            state.isWaitingFloorPlan = true
            state.selectedPlanPage = state.assetPages.find((item) => item.id === action.payload) ?? null
        },
        setIsWaitingForResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingForResponse = action.payload
        },
        setIsWaitingFloorPlan: (state, action: PayloadAction<boolean>) => {
            state.isWaitingFloorPlan = action.payload
        },
        //settings
        setAssetsColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.ASSETS_SETTINGS, {
                columns: action.payload,
            })

            state.assetsSettings.columns = action.payload
        },
        setCurrentAssetView: (state, action: PayloadAction<{ id: string; title: string; isSystemView: boolean }>) => {
            dropInLS(LsKeys.ASSETS_SETTINGS, {
                currentViewId: action.payload.id,
                currentViewTitle: action.payload.title,
                isSystemCurrentView: action.payload.isSystemView,
            })
            state.assetsSettings.currentViewId = action.payload.id
            state.assetsSettings.currentViewTitle = action.payload.title
            state.assetsSettings.isSystemCurrentView = action.payload.isSystemView
        },
        setAssetsFilters: (state, action: PayloadAction<AssetFiltersType | null>) => {
            dropInLS(LsKeys.ASSETS_SETTINGS, { filters: action.payload })
            state.assetsSettings.filters = action.payload
        },
        setAssetListMode: (state, action: PayloadAction<ListViewMode>) => {
            dropInLS(LsKeys.ASSETS_SETTINGS, { listMode: action.payload })
            state.assetsSettings.listMode = action.payload
        },
        setAssetSort: (state, action: PayloadAction<CommonSortType>) => {
            const checkIsNewSortField = state.assetsSettings.sortField !== action.payload.sortField

            dropInLS(LsKeys.ASSETS_SETTINGS, {
                sortField: action.payload.sortField,
                sortDirection: checkIsNewSortField ? DirectionEnum.ASC : action.payload.sortDirection,
            })
            state.assetsSettings.sortField = action.payload.sortField
            state.assetsSettings.sortDirection = checkIsNewSortField ? DirectionEnum.ASC : action.payload.sortDirection
        },
    },
    extraReducers,
})

export const {
    clearCurrentAsset,
    setSelectedPlanPage,
    setAssetPaginationPage,
    setAssetsSearchValue,
    setAssetListTab,
    setAssetProfileTab,
    clearPlanPoints,
    needToUpdatePoints,
    clearAssetsSettings,
    setIsWaitingForResponse,
    setAssetsColumns,
    setCurrentAssetView,
    setAssetsFilters,
    setAssetListMode,
    setAssetSort,
    setIsWaitingFloorPlan,
} = assetSlice.actions
export const AssetReducer = assetSlice.reducer
