import React, { useCallback, useEffect, useState } from 'react'

import styles from './styles.module.scss'
import { Drawer } from '@mui/material'
import TableToolbar from './components/TableToolbar'
import TableView from './components/TableView'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { ListViewMode, USER_ROLE } from '../../../../types'
import CardView from './components/CardView'
import { getUsersList } from '../../../../api/user'
import { userAction } from '../../../../slices/users'
import UserCreateForm from '../UserCreateForm'
import UserContactInfoLayout from '../../../../components/UserContactInfoLayout'
import useGetUserSettings from '../../hooks/useGetUserSettings'
import WorkOrdersListLayout from '../../../../components/WorkOrdersListLayout'
import TeamsListLayout from '../../../../components/TeamsListLayout'
import { importDialogSelect } from '../../../../store/selectors'
import { ImportDialog } from '../../../../components/ImportDialog'

type Props = {
    tenantCompanyId?: string
    customerId?: string
    userRole: USER_ROLE
    hideSaveView?: boolean
}

const UsersView = ({ tenantCompanyId, customerId, userRole, hideSaveView }: Props) => {
    const dispatch = useAppDispatch()

    const settings = useGetUserSettings(userRole)

    const usersData = useAppSelector(({ users }) => users.users)
    const selectedUserCard = useAppSelector(({ users }) => users.selectedUserCard)

    const page = useAppSelector(({ users }) => users.usersPage)
    const search = useAppSelector(({ users }) => users.usersSearch)
    const usersListMode = useAppSelector(({ users }) => users.usersListMode)

    const workOrdersLayout = useAppSelector(({ users }) => users.workOrdersLayout)
    const teamsLayout = useAppSelector(({ users }) => users.teamsLayout)

    const importDialog = useAppSelector(importDialogSelect)

    const [openAddNew, setOpenAddNew] = useState(false)

    const getData = useCallback(async () => {
        if (!settings) {
            return
        }

        await dispatch(
            getUsersList({
                page,
                value: search,
                customerId,
                userRole: userRole,
                statuses: settings.filters?.statuses,
                regionIdes: settings.filters?.regions?.map((item) => item.value),
                types: settings.filters?.types,
                startDateAdded: settings.filters?.startDateAdded,
                endDateAdded: settings.filters?.endDateAdded,
                startLastLogin: settings.filters?.startLastLogin,
                endLastLogin: settings.filters?.endLastLogin,
                startLastActivity: settings.filters?.startLastActivity,
                endLastActivity: settings.filters?.endLastActivity,
                isArchived: settings.filters?.isArchived,
                tenantCompanyId,
            }),
        )
    }, [page, search, userRole, settings, tenantCompanyId, customerId])

    useEffect(() => {
        ;(async () => {
            await getData()
        })()
    }, [getData])

    useEffect(() => {
        return () => {
            dispatch(userAction.setSelectedUserCard(null))
        }
    }, [])

    return (
        <div className={styles.container}>
            <Drawer
                anchor="right"
                open={openAddNew}
                onClose={(_, reason) => reason !== 'backdropClick' && setOpenAddNew(false)}
            >
                <UserCreateForm
                    customerId={customerId}
                    tenantCompanyId={tenantCompanyId}
                    role={userRole}
                    onClose={() => setOpenAddNew(false)}
                    update={getData}
                />
            </Drawer>

            <Drawer
                anchor="right"
                open={!!selectedUserCard}
                onClose={() => dispatch(userAction.setSelectedUserCard(null))}
            >
                <UserContactInfoLayout
                    user={selectedUserCard}
                    onClose={() => dispatch(userAction.setSelectedUserCard(null))}
                />
            </Drawer>

            <TableToolbar hideSaveView={hideSaveView} userRole={userRole} onAddUserClick={() => setOpenAddNew(true)} />

            <div className={styles.tableContainer}>
                {usersListMode === ListViewMode.TABLE && <TableView userRole={userRole} />}
                {usersListMode === ListViewMode.CARD && <CardView users={usersData} />}
            </div>

            <Drawer
                anchor="right"
                open={!!workOrdersLayout}
                onClose={() => dispatch(userAction.setWorkOrdersLayout(null))}
            >
                {workOrdersLayout && (
                    <WorkOrdersListLayout
                        onClose={() => dispatch(userAction.setWorkOrdersLayout(null))}
                        {...workOrdersLayout}
                    />
                )}
            </Drawer>

            <Drawer anchor="right" open={!!teamsLayout} onClose={() => dispatch(userAction.setTeamsLayout(null))}>
                {teamsLayout && (
                    <TeamsListLayout onClose={() => dispatch(userAction.setTeamsLayout(null))} {...teamsLayout} />
                )}
            </Drawer>

            {importDialog.isOpen && <ImportDialog resolve={getData} />}
        </div>
    )
}

export default UsersView
