import React, { ReactNode } from 'react'
import CloseButton from '../CloseButton'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { Dialog } from '@mui/material'
import cn from 'classnames'
import SaveCancelPanel from '../SaveCancelPanel'
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx'

import s from './styles.module.scss'

type CommonDialogType = {
    onClose: () => void
    isOpen: boolean
    title?: string
    dialogClassName?: string
    children: ReactNode
    width?: string
    onSaveClick?: () => void
    saveButtonText?: string
    cancelButtonText?: string
    onClick?: (e: React.MouseEvent) => void
    disabledConfirm?: boolean
    saveButtonVariant?:
        | 'text'
        | 'filled-grey'
        | 'outline'
        | 'filled-blue'
        | 'filled-red'
        | 'filled-yellow'
        | 'poor'
        | undefined
    dialogStyle?: SystemStyleObject
}
export const CommonDialog = ({
    isOpen,
    onClose,
    dialogClassName,
    title,
    children,
    width = '480px',
    onSaveClick,
    saveButtonText,
    onClick,
    disabledConfirm = false,
    saveButtonVariant = 'filled-blue',
    dialogStyle,
    cancelButtonText,
}: CommonDialogType) => {
    return (
        <Dialog
            onClose={onClose}
            open={isOpen}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '10px',
                    width,
                    maxWidth: width,
                    overflowY: 'visible',
                    ...dialogStyle,
                },
            }}
            onClick={(e) => onClick && onClick(e)}
        >
            <div className={cn(s.dialog, dialogClassName)}>
                <CloseButton onClose={onClose} className={s.close} />

                {!!title && (
                    <Paragraph size={'l'} weight={'semi-bold'} className={s.title} position="center">
                        {title}
                    </Paragraph>
                )}

                <section className={s.content}>{children}</section>

                {onSaveClick && (
                    <SaveCancelPanel
                        onCancel={() => onClose()}
                        onSave={onSaveClick}
                        saveButtonText={saveButtonText}
                        disabledConfirm={disabledConfirm}
                        saveButtonVariant={saveButtonVariant}
                        cancelButtonText={cancelButtonText}
                    />
                )}
            </div>
        </Dialog>
    )
}
