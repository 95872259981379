import { useAppDispatch } from 'hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { Asset, AssetCategory } from 'slices/floorPlanViever'
import { getAssetsByCategoryAndTypes, getCategoriesList } from 'api/floors'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import { orderBy } from 'lodash'
import CategoryAccordionRow from './CategoryAccordionRow'
import SaveCancelPanel from '../SaveCancelPanel'
import AssetCheckBoxRow from './AssetCheckBoxRow'
import SearchInput from 'ui-kit/components/SearchInput/SearchInput'
import ProgressBar from '../ProgressBar'

import styles from './styles.module.scss'

type Props = {
    onSaveSelectedAssetsIds?: (ids: Array<string>, assets: Asset[]) => void
    disabledAssetsIds?: Array<string>
    dependBuildingId?: string
    totalAssets?: number
    selectedAssetsLimit?: number
    warningText?: string
    hiddenAssetId?: string
}

const AssetsCheckBoxList = ({
    onSaveSelectedAssetsIds,
    dependBuildingId,
    disabledAssetsIds,
    selectedAssetsLimit,
    totalAssets = 0,
    warningText,
    hiddenAssetId,
}: Props) => {
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)

    const [categories, setCategories] = useState<Array<AssetCategory>>([])
    const [selectedAssetsIds, setSelectedAssetsIds] = useState<Array<string>>([])
    const [selectedAssets, setSelectedAssets] = useState<Array<Asset>>([])
    const [searchAssets, setSearchAssets] = useState<Array<Asset>>([])

    const [searchString, setSearchString] = useState('')

    const handleSelectAssetId = (id: string, checked: boolean, asset: Asset) => {
        if (checked) {
            if (selectedAssetsLimit && warningText && totalAssets + selectedAssetsIds?.length >= selectedAssetsLimit) {
                return sendNotification(warningText, NOTIFICATION_STATUS.WARNING)
            }

            setSelectedAssetsIds([...selectedAssetsIds, id])
            setSelectedAssets([...selectedAssets, asset])
        } else {
            setSelectedAssetsIds(selectedAssetsIds.filter((item) => item !== id))
            setSelectedAssets(selectedAssets.filter((item) => item.id !== id))
        }
    }

    const getData = useCallback(async () => {
        setLoading(true)
        try {
            const res = await dispatch(getCategoriesList({ buildingId: dependBuildingId })).unwrap()

            setCategories(res.categories)
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
        setLoading(false)
    }, [])

    const getSearchAssets = useCallback(async () => {
        if (searchString.length === 0) {
            return
        }

        setLoading(true)

        try {
            const res = await dispatch(
                getAssetsByCategoryAndTypes({
                    buildingId: dependBuildingId,
                    searchString,
                }),
            ).unwrap()

            setSearchAssets(res.assets)
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }

        setLoading(false)
    }, [searchString, dependBuildingId])

    useEffect(() => {
        ;(async () => {
            await getData()
        })()
    }, [getData])

    useEffect(() => {
        ;(async () => {
            await getSearchAssets()
        })()
    }, [getSearchAssets])

    return (
        <div className={styles.root}>
            <ProgressBar show={loading} className={styles.progress} />
            <SearchInput searchText={searchString} onChangeSearchText={setSearchString} />

            <div className={styles.threeWrap}>
                {searchString.length === 0
                    ? orderBy(categories, 'name').map((category) => (
                          <CategoryAccordionRow
                              key={category.id}
                              category={category}
                              selectedAssetsIds={selectedAssetsIds}
                              onSelectAssetId={handleSelectAssetId}
                              disabledAssetsIds={disabledAssetsIds}
                              dependBuildingId={dependBuildingId}
                              hiddenAssetId={hiddenAssetId}
                          />
                      ))
                    : searchAssets.map((asset) => {
                          const isDisabled = !!disabledAssetsIds?.some((item) => item === asset.id)
                          const isChecked = selectedAssetsIds.some((item) => item === asset.id) || isDisabled
                          const isHidden = asset.id === hiddenAssetId

                          return (
                              <AssetCheckBoxRow
                                  key={asset.id}
                                  asset={asset}
                                  disabled={isDisabled}
                                  checked={isChecked}
                                  onSelectAsset={handleSelectAssetId}
                                  isSearch
                                  isHidden={isHidden}
                              />
                          )
                      })}
            </div>
            {onSaveSelectedAssetsIds && (
                <div className={styles.saveWrap}>
                    <SaveCancelPanel
                        saveButtonText="Add"
                        onSave={() => onSaveSelectedAssetsIds(selectedAssetsIds, selectedAssets)}
                    />
                </div>
            )}
        </div>
    )
}

export default AssetsCheckBoxList
