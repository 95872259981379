import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    getVerifyCode,
    verifyPassword,
    verifyCodeForgot,
    verifyCodeLogin,
    getMe,
    logout,
    forgotPassword,
    acceptInvite,
} from 'api/auth'
import { CommonFileType, CommonType, NOTIFICATION_STATUS, USER_ROLE, USER_TYPE } from 'types'
import sendNotification from 'lib/notification'
import { LsKeys, restoreState, saveState } from 'lib/workWithLS'
import { initialPermissions, PermissionsUser } from './users'
import { NotificationSettingForm } from 'pages/UsersTenantsPage/components/UserTenantProfile/components/UserSettings/config'

export type MeType = {
    id: string
    email: string
    role: USER_ROLE
    type: USER_TYPE
    status: string
    customerId: string
    regionId: string
    lastActivity: Date
    region: CommonType
    firstName: string
    lastName: string
    phone: string
    avatar: CommonFileType | null
}

export interface AuthState {
    isAuth: boolean
    id: string
    role: USER_ROLE | null
    isPasswordChanged: boolean
    isWaitingForResponse: boolean
    verificationToken: string
    user: MeType | null
    emailToLog: string

    permissionsUser: PermissionsUser
    notificationSettings: Partial<NotificationSettingForm>
}

const initialState: AuthState = {
    isAuth: restoreState(LsKeys.IS_AUTH) === 'true',
    id: '',
    role: null,
    isPasswordChanged: false,
    isWaitingForResponse: false,
    verificationToken: '',
    user: null,
    emailToLog: '',

    permissionsUser: initialPermissions,
    notificationSettings: {},
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearState(state) {
            state.verificationToken = ''
            state.isPasswordChanged = false
            state.isWaitingForResponse = false
        },
        clearToken(state) {
            state.role = null
            state.id = ''
            state.isAuth = false
        },
        setRole(state, action) {
            state.role = action.payload
        },
        setIsLoading(state, action) {
            state.isWaitingForResponse = action.payload
        },

        setPermissionsUser: (state, action: PayloadAction<PermissionsUser>) => {
            state.permissionsUser = action.payload
        },
        setNotificationSettings: (state, action: PayloadAction<Partial<NotificationSettingForm>>) => {
            state.notificationSettings = action.payload
        },
    },

    extraReducers: (builder) => {
        builder.addCase(verifyPassword.pending, (state) => {
            state.isWaitingForResponse = true
        })
        builder.addCase(verifyPassword.fulfilled, (state) => {
            state.isWaitingForResponse = false
        })
        builder.addCase(verifyPassword.rejected, (state, action) => {
            state.isWaitingForResponse = false
            sendNotification(
                action?.payload ?? 'Cannot sign in! Please, check your email and password!',
                NOTIFICATION_STATUS.ERROR,
            )
        })

        //logout reducer
        builder.addCase(logout.pending, (state) => {
            state.isWaitingForResponse = true
        })
        builder.addCase(logout.fulfilled, (state) => {
            state.isWaitingForResponse = false
            state.isAuth = false
            state.role = null
            state.id = ''
            state.user = null

            localStorage.removeItem(LsKeys.REFRESH)
            localStorage.removeItem(LsKeys.TOKEN)
            localStorage.removeItem(LsKeys.IS_AUTH)
        })
        builder.addCase(logout.rejected, (state, action) => {
            state.isWaitingForResponse = false
            state.isAuth = false

            localStorage.removeItem(LsKeys.REFRESH)
            localStorage.removeItem(LsKeys.TOKEN)
            localStorage.removeItem(LsKeys.IS_AUTH)

            sendNotification('Cannot logout!', NOTIFICATION_STATUS.ERROR)
        })

        builder.addCase(getMe.pending, (state) => {
            state.isWaitingForResponse = true
        })
        builder.addCase(getMe.fulfilled, (state, { payload }: PayloadAction<MeType>) => {
            state.isWaitingForResponse = false

            if (payload) {
                state.role = payload.role
                state.id = payload.id
                state.user = payload
            }
        })
        builder.addCase(getMe.rejected, (state, action) => {
            state.isWaitingForResponse = false
            sendNotification(action?.payload ?? 'Not found me', NOTIFICATION_STATUS.ERROR)
        })

        builder.addCase(verifyCodeLogin.pending, (state) => {
            state.isWaitingForResponse = true
        })
        builder.addCase(verifyCodeLogin.fulfilled, (state, { payload }) => {
            state.isWaitingForResponse = false
            state.isAuth = true

            saveState(LsKeys.IS_AUTH, JSON.stringify(true))
            saveState(LsKeys.TOKEN, payload.accessToken)
            saveState(LsKeys.REFRESH, payload.refreshToken)

            sendNotification('You have successfully logged in', NOTIFICATION_STATUS.SUCCESS)
        })
        builder.addCase(verifyCodeLogin.rejected, (state, action) => {
            state.isWaitingForResponse = false
            sendNotification(
                action?.payload ?? 'Verification code is not correct! Please, check verification code on your e-mail',
                NOTIFICATION_STATUS.ERROR,
            )
        })

        builder.addCase(getVerifyCode.pending, (state) => {
            state.isWaitingForResponse = true
        })
        builder.addCase(getVerifyCode.fulfilled, (state) => {
            state.isWaitingForResponse = false
            sendNotification('Verification code was sent to your e-mail address!', NOTIFICATION_STATUS.SUCCESS)
        })
        builder.addCase(getVerifyCode.rejected, (state, action) => {
            state.isWaitingForResponse = false
            sendNotification(
                action?.payload ?? 'Email is not found! Please, check spelling of e-mail address',
                NOTIFICATION_STATUS.ERROR,
            )
        })

        builder.addCase(verifyCodeForgot.pending, (state) => {
            state.isWaitingForResponse = true
        })
        builder.addCase(verifyCodeForgot.fulfilled, (state, { payload }) => {
            state.isWaitingForResponse = false
            state.verificationToken = payload.verificationToken
        })
        builder.addCase(verifyCodeForgot.rejected, (state, action) => {
            state.isWaitingForResponse = false
            sendNotification(
                action?.payload ?? 'Verification code is not correct! Please, check verification code on your e-mail',
                NOTIFICATION_STATUS.ERROR,
            )
        })

        //forgot Password
        builder.addCase(forgotPassword.pending, (state) => {
            state.isWaitingForResponse = true
        })
        builder.addCase(forgotPassword.fulfilled, (state) => {
            state.isWaitingForResponse = false
            sendNotification('Password changed successfully! You can try to log in again', NOTIFICATION_STATUS.SUCCESS)
        })
        builder.addCase(forgotPassword.rejected, (state, action) => {
            state.isWaitingForResponse = false
            sendNotification(
                action?.payload ?? 'Error with password update! Please, try again later',
                NOTIFICATION_STATUS.ERROR,
            )
        })

        //accept invite
        builder.addCase(acceptInvite.pending, (state) => {
            state.isWaitingForResponse = true
        })
        builder.addCase(acceptInvite.fulfilled, (state) => {
            state.isWaitingForResponse = false
            sendNotification(
                'User password created successfully! You can try to log in now',
                NOTIFICATION_STATUS.SUCCESS,
            )
        })
        builder.addCase(acceptInvite.rejected, (state, action) => {
            state.isWaitingForResponse = false
            sendNotification(
                action?.payload ?? 'Error with password create! Please, try again later',
                NOTIFICATION_STATUS.ERROR,
            )
        })

        /*builder.addMatcher(
            (action) => action.type.endsWith('/rejected'),
            (state, action) => {
                state.error = action?.payload ?? 'Something wrong. Please try again'
            },
        )*/
    },
})

export const { clearState, clearToken, setPermissionsUser, setIsLoading, setNotificationSettings } = authSlice.actions
export const AuthReducer = authSlice.reducer
