import styles from './NoteInput.module.scss'
import CustomInput from '../../../../ui-kit/components/CustomInput'
import Icons from '../../../../assets/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import AddedFilesList from '../../../FilesDropzoneLoader/AddedFilesList'
import { ClipAttachSVG } from '../../../../assets/building-icons'

type Props = {
    editTextNote: string
    onSave: (textNote: string, files: Array<File>) => void
}

const NoteInput = ({ onSave }: Props) => {
    const [files, setFiles] = useState<Array<File>>([])

    const {
        getRootProps,
        getInputProps,
        open: openDropZone,
        acceptedFiles,
        isDragActive,
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        //accept: accept,
    })
    const [textNote, setTextNote] = useState('')

    const handleClickSave = useCallback(() => {
        onSave(textNote, files)

        setTextNote('')
        setFiles([])
    }, [files, textNote, setTextNote])

    const handleDelete = (deleteFile: File) => {
        setFiles(files.filter((file) => file.name !== deleteFile.name))
    }

    useEffect(() => {
        setFiles([...files, ...acceptedFiles])
    }, [acceptedFiles])

    return (
        <form className={styles.noteEditWrap} {...getRootProps()}>
            <input {...getInputProps()} />

            {isDragActive ? (
                <div className={styles.dropFileZone}>Drop files</div>
            ) : (
                <>
                    <div className={styles.attachFileWrap}>
                        <div className={styles.btn} onClick={openDropZone}>
                            <ClipAttachSVG />
                            Attach file
                        </div>
                    </div>
                    <CustomInput
                        startAdornment={
                            <div className={styles.inputIconWrap}>
                                <Icons.NoteEnterSVG />
                            </div>
                        }
                        endAdornment={
                            <div className={styles.sendIconWrap} onClick={handleClickSave}>
                                <Icons.NoteSendSVG />
                            </div>
                        }
                        label={''}
                        multiline
                        rows={3}
                        placeholder="Enter your note"
                        value={textNote}
                        onChange={(e) => setTextNote(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13 && !e.shiftKey) {
                                e.preventDefault()
                                handleClickSave()
                            }
                        }}
                    />
                </>
            )}

            <AddedFilesList files={files} onDelete={handleDelete} />
        </form>
    )
}

export default NoteInput
