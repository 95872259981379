import React from 'react'

import styles from './styles.module.scss'
import classNames from 'classnames'
import { Paragraph } from '../../ui-kit/components/Typography/Typography'

type Props = {
    title: string
    value?: string | number

    className?: string
}
const CardColumnItem = ({ className, title, value }: Props) => {
    return (
        <div className={classNames(styles.cardColumnItem, className)}>
            <Paragraph color={'grey'} size={'xs'}>
                {title}
            </Paragraph>
            <Paragraph size={'s'}>{value}</Paragraph>
        </div>
    )
}

export default CardColumnItem
