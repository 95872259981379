import { PathBreadcrumbsType } from 'ui-kit/components/PathBreadcrumbs/PathBreadcrumbs'
import * as yup from 'yup'
import { FedType, SaveKeyAddingFile } from 'slices/asset'
import { ItemForAutocompleteType } from 'components/CommonAutoComplete'
import { LengthConstrains } from 'types'

export type PropertiesType = {
    id: string
    name: string
    type: string
    value: any
    isBasic?: boolean
}

export type NewAssetFormType = {
    isCritical?: boolean
    name: string
    equipmentId?: string
    description?: string
    categoryId: string
    typeId: string
    manufacturer: string
    serialNumber: string
    model: string
    installDate: Date | null
    cost: number | null
    laborValue: number | null
    props?: PropertiesType[]
    regionId: string
    floorId?: string
    buildingId: string
    roomId?: string
}

export type NewAffectedAreaType = {
    assetId: string
    buildingId?: string
    floorIdes?: string[]
    roomIdes?: string[]
}

export type AddFilesToAssetRequestType = {
    files: File[]
    saveKey: SaveKeyAddingFile
}

export type CreateFeedForAssetType = {
    fromId: string
    toId: string
    fedType?: FedType
}

export type CreateAssetPreferredSubcontractorsType = {
    subcontractorsId: string[]
    assetId: string
}

export const breadCrumbsArr: Array<PathBreadcrumbsType> = [
    {
        name: 'Asset page',
        link: '/assets',
    },
    {
        name: 'New asset',
        link: '/assets/new-asset',
    },
]

export const newAssetInitialValues: NewAssetFormType = {
    isCritical: false,
    name: '',
    equipmentId: '',
    description: undefined,
    categoryId: '',
    typeId: '',
    manufacturer: '',
    serialNumber: '',
    model: '',
    installDate: null,
    cost: null,
    laborValue: null,
    regionId: '',
    floorId: '',
    buildingId: '',
    roomId: '',
    props: [],
}
export const REQUIRED_FIELD = 'Required field'
export const TOO_LONG = 'Too Long!'
export const MORE_50 = 'Field must be longer than or equal to 50 characters'
export const MAX_12_CHARACTERS = 'Field should not be more than 12 characters'
export const MUST_BE_EMAIL = 'Field must be a valid email'
export const MUST_BE_NUMBER = 'Must be a number'
export const MORE_ZERO = 'Field must be greater than or equal to zero'
export const MORE_ONE = 'Field must be greater than or equal to one'
export const MUST_BE_PHONE = 'Phone number is not valid'

export const phoneV2RegExp = /^[+][0-9] [0-9]{3}-[0-9]{3}-[0-9]{4}?$/

export const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i

export const newAssetFormSchema = yup.object().shape({
    name: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required(REQUIRED_FIELD),
    equipmentId: yup.string().max(LengthConstrains.L_12, MAX_12_CHARACTERS).trim().nullable(),
    description: yup.string().max(LengthConstrains.L_5000, TOO_LONG).trim().nullable(),
    categoryId: yup.string().required(REQUIRED_FIELD),
    typeId: yup.string().max(LengthConstrains.L_50, TOO_LONG).required(REQUIRED_FIELD),
    manufacturer: yup.string().max(LengthConstrains.L_50, TOO_LONG).trim().required(REQUIRED_FIELD),
    serialNumber: yup.string().max(LengthConstrains.L_50, TOO_LONG).trim().required(REQUIRED_FIELD),
    model: yup.string().max(LengthConstrains.L_50, TOO_LONG).trim().required(REQUIRED_FIELD),
    installDate: yup.string().max(LengthConstrains.L_100, TOO_LONG).required(REQUIRED_FIELD),
    cost: yup.number().typeError(MUST_BE_NUMBER).min(0).required(REQUIRED_FIELD),
    laborValue: yup.number().typeError(MUST_BE_NUMBER).min(0).required(REQUIRED_FIELD),
    regionId: yup.string().max(LengthConstrains.L_50, TOO_LONG).required(REQUIRED_FIELD),
    floorId: yup.string().max(LengthConstrains.L_50, TOO_LONG),
    buildingId: yup.string().max(LengthConstrains.L_50, TOO_LONG).required(REQUIRED_FIELD),
    roomId: yup.string().max(LengthConstrains.L_50, TOO_LONG),
})

export type AffectedAreaStateType = {
    building: ItemForAutocompleteType
    floors: ItemForAutocompleteType[] | null
    rooms: (ItemForAutocompleteType & { floorId: string })[] | null
    assets?: (ItemForAutocompleteType & { floorId: string | null; roomId: string | null })[] | null
}
