import styles from './styles.module.scss'
import ProgressBar from '../../../../components/ProgressBar'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import PathBreadcrumbs from '../../../../ui-kit/components/PathBreadcrumbs'
import { PathBreadcrumbsType } from '../../../../ui-kit/components/PathBreadcrumbs/PathBreadcrumbs'
import { TenantProfileRoutes } from '../../../../slices/users'
import { getTenantById } from '../../../../api/tenant'
import TenantProfileForm from './components/TenantProfileForm'
import { RoutesTabPanel } from '../../../../components/PageTabPanel'
import { getTenantProfileTabs } from '../../../../slices/users/const'
import InfoTenantProfile from './components/InfoTenantProfile'
import UsersView from '../UsersView'
import { ROUTES } from '../../../../routes/const'
import TenantFilesNotes from './components/TenantFilesNotes'
import { USER_ROLE } from '../../../../types'
import ContactsList from '../../../../components/ContactsList'
import TenantLeasesList from './components/TenantLeasesList'
import { permissionsUser } from 'store/selectors'

const breadCrumbsArr = (id?: string, name?: string): Array<PathBreadcrumbsType> => {
    return [
        {
            name: 'Tenants',
            link: ROUTES.TENANTS,
        },
        {
            name: `${name ?? ''}`,
            link: `${ROUTES.TENANTS}/${id}`,
        },
    ]
}

const TenantProfile = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { isTechnician } = useAppSelector(permissionsUser)

    const loading = useAppSelector(({ users }) => users.loading)
    const selectedTenantProfile = useAppSelector(({ users }) => users.selectedTenantProfile)

    useEffect(() => {
        if (id) {
            dispatch(getTenantById(id))
        }
    }, [id])

    if (!selectedTenantProfile) {
        return null
    }

    return (
        <div className={styles.root}>
            <ProgressBar show={loading} />

            <div className={styles.breadcrumbs}>
                <PathBreadcrumbs
                    breadCrumbs={breadCrumbsArr(id, selectedTenantProfile?.name)}
                    onClickBreadCrumb={({ link }) => navigate(link)}
                />
            </div>

            <RoutesTabPanel tabs={getTenantProfileTabs(isTechnician)} />

            <div className={styles.content}>
                <TenantProfileForm />

                <div className={styles.container}>
                    <Routes>
                        <Route path={TenantProfileRoutes.PROFILE} element={<InfoTenantProfile />} />
                        <Route
                            path={TenantProfileRoutes.USERS}
                            element={
                                <UsersView
                                    tenantCompanyId={selectedTenantProfile.id}
                                    userRole={USER_ROLE.REQUESTOR}
                                    hideSaveView
                                />
                            }
                        />
                        <Route
                            path={TenantProfileRoutes.CONTACTS}
                            element={
                                <ContactsList
                                    link={selectedTenantProfile.id}
                                    tenantCompanyId={selectedTenantProfile.id}
                                />
                            }
                        />
                        {!isTechnician && <Route path={TenantProfileRoutes.LEASES} element={<TenantLeasesList />} />}{' '}
                        <Route path={TenantProfileRoutes.NOTES} element={<TenantFilesNotes />} />
                        <Route path={TenantProfileRoutes.PROFILE} element={<InfoTenantProfile />} />
                        <Route path="*" element={<Navigate to={TenantProfileRoutes.PROFILE} />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default TenantProfile
