import { createAppAsyncThunk } from '../common/createAppAsyncThunk'
import { DirectionEnum } from '../types'
import { axiosErrorHandler, client } from './setupAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { TenantForm } from '../pages/UsersTenantsPage/const'
import { LeaseForm } from '../pages/UsersTenantsPage/types'
import { HISTORY_LIMIT } from '../slices/history'
import { BUILDS_PER_PAGE } from '../pages/BuildingsPage/helpers'

type GetTenantsList = {
    search?: string
    page?: number
    size?: number
    customerId?: string
}

export const getTenantsList = createAppAsyncThunk(
    'tenants/getTenantsList',
    async ({ search, size = 25, ...restParams }: GetTenantsList, { rejectWithValue }) => {
        const params = {
            sortField: 'name',
            sortDirection: DirectionEnum.ASC,
            size,
            keySearchValue: search,
            ...restParams,
        }
        try {
            const result = await client.get(`/api/tenantCompanies/get-tenantCompanies-list`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getTenantById = createAsyncThunk(
    'tenants/getTenantById',
    async (tenantCompanyId: string, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/tenantCompanies/get-tenantCompany-by-id`, {
                params: { tenantCompanyId },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createTenantCompany = createAsyncThunk(
    'tenant/createTenantCompany',
    async (data: Partial<TenantForm & LeaseForm> | { avatar: File | null }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/tenantCompanies/create-tenantCompany`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const updateTenantCompany = createAsyncThunk(
    'tenant/updateTenantCompany',
    async (data: Partial<TenantForm> | { tenantCompanyId: string }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/tenantCompanies/update-tenantCompany`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const uploadTenantAvatar = createAsyncThunk(
    'tenant/uploadTenantAvatar',
    async (data: { tenantCompanyId: string; avatar: File | null }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/tenantCompanies/upload-tenantCompany-avatar`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetTLeaseList = {
    search?: string
    page?: number
    size?: number
    tenantCompanyId?: string
}

export const getLeaseList = createAppAsyncThunk(
    'tenants/getLeaseList',
    async ({ search, size = 25, ...restParams }: GetTLeaseList, { rejectWithValue }) => {
        const params = {
            sortField: 'id',
            sortDirection: DirectionEnum.ASC,
            size,
            keySearchValue: search,
            ...restParams,
        }
        try {
            const result = await client.get(`/api/tenantCompanies/leases/get-leases-list`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createTenantLease = createAsyncThunk(
    'tenant/createTenantLease',
    async (data: Partial<LeaseForm> | { tenantCompanyId: string }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/tenantCompanies/leases/create-lease`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type UpdateTenantLease = {
    leaseId: string
    hasNewLeaseInformation?: boolean
    relocateUsersToRoomId?: string
    moveOutDate?: Date
    option: 'END' | 'RENEW' | 'EDIT' | 'RELOCATE'
} & Partial<LeaseForm>

export const updateTenantLease = createAsyncThunk(
    'tenant/createTenantLease',
    async (data: UpdateTenantLease, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/tenantCompanies/leases/update-lease`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getLeaseHistory = createAppAsyncThunk(
    'tenant/getLeaseHistory',
    async ({ leaseId, offset }: { leaseId: string; offset?: number }, { rejectWithValue }) => {
        const params = {
            searchString: [leaseId],
            orderDirection: DirectionEnum.ASC,
            offset,
            limit: HISTORY_LIMIT,
        }
        try {
            const result = await client.get(`/api/history/many`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreateLeaseFiles = {
    leaseId: string
    files: Array<File>
}

export const createLeaseFiles = createAsyncThunk(
    'tenant/createLeaseFiles',
    async (data: CreateLeaseFiles, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/tenantCompanies/leases/files/create-lease-file`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteLeaseFile = createAsyncThunk('tenant/deleteLeaseFile', async (id: string, { rejectWithValue }) => {
    try {
        const result = await client.delete(`/api/tenantCompanies/leases/files/delete-lease-file`, {
            params: {
                id,
            },
        })
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

type GetTenantsFiles = {
    page: number
    tenantCompanyId: string
}

export const getTenantsFiles = createAppAsyncThunk(
    'tenant/getTenantsFiles',
    async (params: GetTenantsFiles, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/tenantCompanies/files/get-tenantCompany-files`, {
                params: {
                    ...params,
                    size: BUILDS_PER_PAGE,
                    sortField: 'id',
                    sortDirection: DirectionEnum.ASC,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreateTenantFiles = {
    files: Array<File>
    tenantCompanyId: string
}

export const createTenantFiles = createAsyncThunk(
    'tenant/createTenantFiles',
    async (data: CreateTenantFiles, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/tenantCompanies/files/create-tenantCompany-file`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteTenantFile = createAsyncThunk('tenant/deleteTenantFile', async (id: string, { rejectWithValue }) => {
    try {
        const result = await client.delete(`/api/tenantCompanies/files/delete-tenantCompany-file`, {
            params: {
                id,
            },
        })
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})
