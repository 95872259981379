import React, { ReactNode } from 'react'

import styles from './styles.module.scss'
import classNames from 'classnames'
import { Paragraph } from '../../ui-kit/components/Typography/Typography'

type Props = {
    title: string
    value?: string | number
    valueNode?: ReactNode
    className?: string
}
const CardRowItem = ({ className, title, value, valueNode }: Props) => {
    return (
        <div className={classNames(styles.cardRowItem, className)}>
            <Paragraph color={'grey'} size={'xs'}>
                {title}
            </Paragraph>
            {!!value && <Paragraph size={'s'}>{value}</Paragraph>}
            {valueNode}
        </div>
    )
}

export default CardRowItem
