import React, { ReactNode, useEffect } from 'react'
import { Popover } from '@mui/material'
import Icons from 'assets/icons'
import classNames from 'classnames'

import styles from './TooltipMenu.module.scss'

type Props = {
    children: ReactNode
    button?: ReactNode
    classesRoot?: string
    closeByClick?: boolean
    widthPopper?: number | string
    heightPopper?: number | string
    onClose?: () => void
    onOpen?: () => void
    isClosed?: boolean
}

const TooltipMenu: React.FC<Props> = ({
    children,
    button,
    classesRoot,
    closeByClick = true,
    widthPopper,
    heightPopper,
    onClose,
    onOpen,
    isClosed = false,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
        onOpen && onOpen()
    }
    const handleClose = () => {
        setAnchorEl(null)
        onClose && onClose()
    }

    useEffect(() => {
        if (isClosed) {
            setAnchorEl(null)
        }
    }, [isClosed])

    return (
        <div className={classNames(styles.root, classesRoot, open && styles.show)}>
            <div className={styles.buttonWrap} onClick={handleClick}>
                {button || <Icons.DotsSVG width={24} />}
            </div>
            <Popover
                open={open}
                onClose={handleClose}
                onClick={(e) => {
                    e.stopPropagation()
                    closeByClick && handleClose()
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    horizontal: 0,
                    vertical: -0,
                }}
                sx={{
                    padding: 0,
                    '& .MuiPopover-paper': {
                        padding: 0,
                        backgroundColor: '#ffffff',
                        borderRadius: '10px',
                        width: widthPopper,
                        maxHeight: heightPopper,
                    },
                }}
            >
                {children}
            </Popover>
        </div>
    )
}

export default TooltipMenu
