import { CompareKeyType, FiltersForReportsType } from 'components/CommonSavedView'
import { useAppSelector } from 'hooks/redux'
import { teamsFiltersSelect } from 'store/selectors'
import { removeBlankFields } from 'lib/common'
import { Availabilities } from 'components/NewSubcontractor/config'

export type TeamReportsFiltersType = {
    customerId?: string

    availabilities?: Availabilities[]
    requiresApproval?: boolean
    type?: string[]
    subType?: string[]
    avgWoCost?: { [key in CompareKeyType]?: number }
    avgHourlyRate?: { [key in CompareKeyType]?: number }
}

export const useTeamFiltersForReports = () => {
    const teamFilters = useAppSelector(teamsFiltersSelect)
    const filters: FiltersForReportsType<TeamReportsFiltersType>[] = []
    let filtersCount = 0

    if (teamFilters) {
        for (const [key, val] of Object.entries(removeBlankFields(teamFilters))) {
            if (key === 'availabilities') {
                filters.push({ availabilities: val })
                filtersCount += 1
            }
            /*if (key === 'requiresApproval') {
                filters.push({ requiresApproval: val })
                filtersCount += 1
            }*/
            if (key === 'type') {
                filters.push({ type: val })
                filtersCount += 1
            }
            if (key === 'subType') {
                filters.push({ subType: val })
            }
            if (key === 'minAvgWoCost') {
                filters.push({ avgWoCost: { '>=': val } })
            }
            if (key === 'maxAvgWoCost') {
                filters.push({ avgWoCost: { '<=': val } })
                filtersCount += 1
            }
            if (key === 'minAvgHourlyRate') {
                filters.push({ avgHourlyRate: { '>=': val } })
            }
            if (key === 'maxAvgHourlyRate') {
                filters.push({ avgHourlyRate: { '<=': val } })
                filtersCount += 1
            }
        }
    }

    return { filters, filtersCount }
}
