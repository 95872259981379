import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { axiosErrorHandler, client } from 'api/setupAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { checkIsGoodStatus } from 'lib/common'
import { AssetPropsType } from 'pages/AssetProfile/components/CurrentAssetAdditionalProperty/CurrentAssetAdditionalProperty'

export const getAssetCategoriesList = createAppAsyncThunk<any, { offset?: number; limit?: number }>(
    'assets/getAssetCategoriesList',
    async ({ offset = 0, limit = 100 }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/category/many`, {
                params: {
                    offset,
                    limit,
                    checkType: 'AND',
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getAssetTypesList = createAsyncThunk<any, { categoryId: string; offset?: number; limit?: number }>(
    'assets/getAssetTypesList',
    async ({ categoryId, offset = 0, limit = 100 }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/type/many`, {
                params: {
                    categoryId,
                    offset,
                    limit,
                    checkType: 'AND',
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export type NewAssetCategoryType = {
    file: File
    color: string
    name: string
    checkResponse?: () => void
}

export const createAssetCategory = createAppAsyncThunk<any, NewAssetCategoryType>(
    'assets/createAssetCategory',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/asset/category`, arg, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const editAssetCategory = createAppAsyncThunk<any, Partial<NewAssetCategoryType> & { id?: string }>(
    'assets/editAssetCategory',
    async ({ checkResponse, id, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/asset/category/${id}`, arg, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export type TypeForNewAssetType = {
    name?: string
    categoryId?: string
    checkResponse?: () => void
}

export const createAssetType = createAppAsyncThunk<any, TypeForNewAssetType>(
    'assets/createAssetType',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/asset/type`, arg)
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const editAssetType = createAppAsyncThunk<any, TypeForNewAssetType & { id: string }>(
    'assets/editAssetType',
    async ({ checkResponse, id, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/asset/type/${id}`, arg)
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export type NewAssetPropertyType = {
    name?: string
    type?: AssetPropsType
    assetCategoryId?: string
    assetTypeIdes?: string[]
    isAddToAllCategories?: boolean
    isAddToAllTypesOfCategory?: boolean
    isBasic?: boolean
    checkResponse?: () => void
}

export const createAssetProperty = createAppAsyncThunk<any, NewAssetPropertyType>(
    'assets/createAssetProperty',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/asset/props`, arg)
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const editAssetProperty = createAppAsyncThunk<any, NewAssetPropertyType & { id: string }>(
    'assets/editAssetProperty',
    async ({ checkResponse, id, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/asset/props/${id}`, arg)
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const toggleProperty = createAppAsyncThunk<any, { propId: string; destinationId: string }>(
    'assets/toggleProperty',
    async ({ propId, destinationId }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/asset/props/toggle-active/${propId}/${destinationId}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const attachPropertyToType = createAppAsyncThunk<any, { typeId: string; assetPropIdes: string[] }>(
    'assets/attachPropertyToType',
    async ({ typeId, assetPropIdes }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/asset/type/attach-props/${typeId}`, { assetPropIdes })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const detachPropertyFromType = createAppAsyncThunk<any, { typeId: string; assetPropIdes: string[] }>(
    'assets/detachPropertyFromType',
    async ({ typeId, assetPropIdes }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/asset/type/detach-props/${typeId}`, { assetPropIdes })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const detachPropertyFromCategory = createAppAsyncThunk<any, { categoryId: string; assetPropIdes: string[] }>(
    'assets/detachPropertyFromType',
    async ({ categoryId, assetPropIdes }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/asset/type/detach-by-category/${categoryId}`, { assetPropIdes })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getOneProperty = createAppAsyncThunk<any, { id: string }>(
    'assets/getOneProperty',
    async ({ id }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/props/${id}`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
