import { StoreRootState } from '..'
import { MeType } from 'slices/AuthSlice'
import { PermissionsUser } from '../../slices/users'
import { NotificationSettingForm } from 'pages/UsersTenantsPage/components/UserTenantProfile/components/UserSettings/config'

export const currentUserSelect = (state: StoreRootState): MeType | null => state.auth.user
export const currentCustomerId = (state: StoreRootState): string | null => state.auth.user?.customerId || null
export const permissionsUser = (state: StoreRootState): PermissionsUser => state.auth.permissionsUser
export const isWaitingForResponse = (state: StoreRootState): boolean => state.auth.isWaitingForResponse
export const notificationSettings = (state: StoreRootState): Partial<NotificationSettingForm> =>
    state.auth.notificationSettings
