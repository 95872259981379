import React, { FC } from 'react'
import { Breadcrumbs } from '@mui/material'
import Icons from '../../../assets/icons'
import { Paragraph } from '../Typography/Typography'
import s from './styles.module.scss'
import { useLocation } from 'react-router-dom'

export type PathBreadcrumbsType = {
    name: string
    link: string
}

type Props = {
    breadCrumbs: Array<PathBreadcrumbsType>
    onClickBreadCrumb: (bc: PathBreadcrumbsType) => void
}
const PathBreadcrumbs: FC<Props> = ({ breadCrumbs, onClickBreadCrumb }) => {
    const { pathname } = useLocation()

    return (
        <Breadcrumbs separator={<Icons.Next />} aria-label="breadcrumb" sx={{ paddingInline: '8px' }}>
            {breadCrumbs.map((breadCrumb) => {
                const isActive = pathname === breadCrumb.link
                return (
                    <Paragraph
                        className={s.link}
                        onClick={() => onClickBreadCrumb(breadCrumb)}
                        size={'s'}
                        key={breadCrumb.link}
                        color={isActive ? 'black' : 'grey'}
                    >
                        {breadCrumb.name}
                    </Paragraph>
                )
            })}
        </Breadcrumbs>
    )
}

export default PathBreadcrumbs
