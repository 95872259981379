import styles from './styles.module.scss'
import classNames from 'classnames'
import { useMemo } from 'react'
import { PriorityWO, WorkOrderStatus } from '../../../../components/NewWorkOrder/config'
import { useAppSelector } from 'hooks'
import { currentUserSelect } from 'store/selectors'
import { USER_ROLE } from 'types'

type Props = {
    status: WorkOrderStatus | PriorityWO
    size?: string | number
}

const WorkOrderBadge = ({ status, size }: Props) => {
    const user = useAppSelector(currentUserSelect)

    const styleStatus = useMemo(() => {
        switch (status) {
            case WorkOrderStatus.NEW:
            case PriorityWO.HIGH: {
                return styles.newHigh
            }
            case WorkOrderStatus.IN_PROGRESS:
            case PriorityWO.MEDIUM:
            case PriorityWO.LOW: {
                return styles.inProgressMediumLow
            }
            case WorkOrderStatus.ON_HOLD:
            case WorkOrderStatus.CANCELLED: {
                return styles.onHoldCanceled
            }
            case WorkOrderStatus.COMPLETED:
            case WorkOrderStatus.PENDING_REVIEW:
            case PriorityWO.SCHEDULED_PM:
            case PriorityWO.SCHEDULED: {
                return styles.completedReviewScheduled
            }

            case PriorityWO.CRITICAL: {
                return styles.critical
            }
        }
    }, [status])

    const getStatus = useMemo(() => {
        if (user?.role !== USER_ROLE.REQUESTOR && status === WorkOrderStatus.IN_PROGRESS) {
            return WorkOrderStatus.OPEN
        }

        return status
    }, [user, status])

    return (
        <div
            className={classNames(styles.root, styleStatus)}
            style={{
                fontSize: size,
            }}
        >
            {getStatus}
        </div>
    )
}

export default WorkOrderBadge
