import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AutocompletePropsType, ItemForAutocompleteType } from '.'
import { useAppDispatch, useAppSelector } from 'hooks'
import { currentUserSelect } from 'store/selectors'
import useDebounce from 'hooks/useDebounce'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { Autocomplete } from '@mui/material'
import { getOptions } from 'lib/common'
import { CustomTextField } from 'ui-kit/components/CustomTextField'
import { CustomShowMore } from 'ui-kit/components/CustomShowMore'
import { CustomRemovedBox } from 'ui-kit/components/CustomRemovedBox'
import { VENDOR_PER_PAGE, VendorType } from 'slices/inventoryItems'
import { getVendorsForAutocomplete } from 'api/partsInventory'
import { CommonOtherResponseType } from 'types'

export const VendorAutoComplete = ({
    label,
    handleBlur,
    errorText,
    currentValue,
    readOnly = false,
    textFieldClassName,
    isSearch = false,
    disableClearable = false,
    textFieldPlaceholder,
    width = '100%',
    onChangeCallback,
    onDeleteItemClick,
    multiple,
    idDependence,
}: AutocompletePropsType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const [values, setValues] = useState<{ id: string; name: string }[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 500)

    const onInputChange = (event: any, newInputValue: string) => {
        setSearch(newInputValue)
        setPage(1)
    }

    const disabledCondition = count <= page * VENDOR_PER_PAGE || disableClearable

    const isOptionEqualToValue = (option: ItemForAutocompleteType, value: ItemForAutocompleteType) =>
        option?.value === value?.value

    const onShowMoreClick = (e: React.MouseEvent<HTMLParagraphElement>) => {
        e.preventDefault()
        setPage((prev) => prev + 1)
        getAllValues(page + 1)
    }

    const getAllValues = async (currentPage: number) => {
        setIsLoading(true)
        try {
            const res: CommonOtherResponseType<VendorType> = await dispatch(
                getVendorsForAutocomplete({ page: currentPage, search, idDependence }),
            ).unwrap()

            setCount(res?.count)
            setValues((prev) => (currentPage === 1 ? res?.payload : [...res.payload, ...prev]))
        } catch (error: any) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!user || typeof idDependence === 'undefined' || search) {
            return
        }
        getAllValues(page)
    }, [user, search, JSON.stringify(idDependence)])

    useEffect(() => {
        if (typeof idDependence === 'undefined') {
            return
        }
        if (debouncedSearch) {
            getAllValues(page)
        }
    }, [debouncedSearch, JSON.stringify(idDependence)])

    const getOptionLabel = useCallback((option: ItemForAutocompleteType) => {
        return option?.label ?? ''
    }, [])

    const renderTags = useCallback(
        (value: ItemForAutocompleteType[]) => {
            if (onDeleteItemClick) {
                return ''
            }

            if (value?.length === 1) {
                return (
                    <Paragraph
                        size={'s'}
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {value[0]?.label}
                    </Paragraph>
                )
            } else {
                return (
                    <Paragraph size={'s'} style={{ display: 'flex', gap: '.4rem' }}>
                        {value?.length}
                        <Paragraph size={'s'} color={'grey'}>
                            (vendors)
                        </Paragraph>
                    </Paragraph>
                )
            }
        },
        [onDeleteItemClick],
    )

    const getPlaceholder = useMemo(() => {
        setSearch('')
        if (multiple) {
            return currentValue?.length ? '' : textFieldPlaceholder
        }
        return !Array.isArray(currentValue) && currentValue?.value ? '' : textFieldPlaceholder
    }, [multiple, currentValue])

    return (
        <>
            <Autocomplete
                value={currentValue}
                readOnly={readOnly}
                onChange={(event, newValue) => {
                    onChangeCallback(newValue as ItemForAutocompleteType[] & ItemForAutocompleteType, values)
                    setSearch('')
                }}
                openOnFocus={true}
                multiple={multiple}
                inputValue={search}
                onInputChange={onInputChange}
                options={getOptions(values, 'id', 'name')}
                freeSolo
                renderTags={renderTags}
                getOptionLabel={(option) => getOptionLabel(option as ItemForAutocompleteType)}
                renderInput={(params) => (
                    <CustomTextField
                        label={label}
                        onBlur={handleBlur}
                        params={params}
                        errorText={errorText}
                        loading={isLoading}
                        className={textFieldClassName}
                        placeholder={getPlaceholder}
                        isSearch={isSearch}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props}>
                        <Paragraph size={'s'}>{option.label}</Paragraph>
                    </li>
                )}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        flexWrap: 'nowrap',
                        padding: 0,
                    },
                }}
                disableCloseOnSelect={multiple}
                filterOptions={(x) => x}
                limitTags={1}
                style={{ width }}
                loading={isLoading}
                isOptionEqualToValue={isOptionEqualToValue}
                disableClearable={disableClearable}
                PaperComponent={({ children }) => (
                    <CustomShowMore
                        onShowMoreClick={onShowMoreClick}
                        children={children}
                        text={'Show more'}
                        disabled={disabledCondition}
                    />
                )}
            />
            {onDeleteItemClick &&
                multiple &&
                currentValue?.map((item) => {
                    return (
                        <CustomRemovedBox
                            id={item?.value ?? ''}
                            title={item?.label ?? ''}
                            onDeleteClick={onDeleteItemClick}
                        />
                    )
                })}
        </>
    )
}
