import { dropInLS, getSettingsFromLS, LsKeys } from 'lib/workWithLS'
import { CommonSortType, DirectionEnum, ListViewMode } from 'types'
import { PMProfileTab } from 'pages/PMProfile/config'
import { initialPMColumns, PmPlansListPageTab, PMsListPageTab } from 'pages/PmPage/config'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { extraReducers } from './extraReducers'
import { CurrentPMType, PMFiltersType, PMsState } from './types'
import { TableColumnType } from 'components/Table'

const initialState: PMsState = {
    currentPM: null,
    allWOsInPm: [],
    pMs: [],
    isWaitingForResponse: false,
    pMProfileTab: PMProfileTab.DETAILS,
    pMListTab: PMsListPageTab.PM_PLANS,
    pMPlansListTab: getSettingsFromLS('pMPlansListTab', LsKeys.PM_SETTINGS, PmPlansListPageTab.CONTRACTED),
    pMPaginationPage: 1,
    pMsCount: 0,
    pMsSearchValue: '',
    pMsSettings: {
        filters: getSettingsFromLS('filters', LsKeys.PM_SETTINGS, null),
        columns: getSettingsFromLS('columns', LsKeys.PM_SETTINGS, initialPMColumns),
        currentViewId: getSettingsFromLS('currentViewId', LsKeys.PM_SETTINGS, ''),
        currentViewTitle: getSettingsFromLS('currentViewTitle', LsKeys.PM_SETTINGS, ''),
        listMode: getSettingsFromLS('listMode', LsKeys.PM_SETTINGS, ListViewMode.TABLE),
        isSystemCurrentView: getSettingsFromLS('isSystemCurrentView', LsKeys.PM_SETTINGS, false),
        sortField: getSettingsFromLS('sortField', LsKeys.PM_SETTINGS, 'id'),
        sortDirection: getSettingsFromLS('sortDirection', LsKeys.PM_SETTINGS, DirectionEnum.ASC),
    },
}

export const pMSlice = createSlice({
    name: 'PMs',
    initialState,
    reducers: {
        clearCurrentPM: (state) => {
            state.currentPM = initialState.currentPM
            state.allWOsInPm = initialState.allWOsInPm
        },
        clearPMSettings: (state) => {
            state.pMPaginationPage = initialState.pMPaginationPage
            state.pMsSearchValue = initialState.pMsSearchValue
        },
        setPMProfileTab: (state, action: PayloadAction<PMProfileTab>) => {
            state.pMProfileTab = action.payload
        },
        setPMListTab: (state, action: PayloadAction<PMsListPageTab>) => {
            state.pMListTab = action.payload
        },
        setPMPaginationPage: (state, action: PayloadAction<number>) => {
            state.pMPaginationPage = action.payload
        },
        setPMsSearchValue: (state, action: PayloadAction<string>) => {
            state.pMsSearchValue = action.payload
        },
        setIsWaitingForResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingForResponse = action.payload
        },
        setPMPlansListTab: (state, action: PayloadAction<PmPlansListPageTab>) => {
            dropInLS(LsKeys.PM_SETTINGS, { pMPlansListTab: action.payload })
            state.pMPlansListTab = action.payload
        },

        //settings
        setPMsColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.PM_SETTINGS, {
                columns: action.payload,
            })

            state.pMsSettings.columns = action.payload
        },
        setCurrentPMView: (state, action: PayloadAction<{ id: string; title: string; isSystemView: boolean }>) => {
            dropInLS(LsKeys.PM_SETTINGS, {
                currentViewId: action.payload.id,
                currentViewTitle: action.payload.title,
                isSystemCurrentView: action.payload.isSystemView,
            })
            state.pMsSettings.currentViewId = action.payload.id
            state.pMsSettings.currentViewTitle = action.payload.title
            state.pMsSettings.isSystemCurrentView = action.payload.isSystemView
        },
        setPMFilters: (state, action: PayloadAction<PMFiltersType | null>) => {
            dropInLS(LsKeys.PM_SETTINGS, { filters: action.payload })
            state.pMsSettings.filters = action.payload
        },
        setPMListMode: (state, action: PayloadAction<ListViewMode>) => {
            dropInLS(LsKeys.PM_SETTINGS, { listMode: action.payload })
            state.pMsSettings.listMode = action.payload
        },
        setPMSort: (state, action: PayloadAction<CommonSortType>) => {
            const checkIsNewSortField = state.pMsSettings.sortField !== action.payload.sortField

            dropInLS(LsKeys.PM_SETTINGS, {
                sortField: action.payload.sortField,
                sortDirection: checkIsNewSortField ? DirectionEnum.ASC : action.payload.sortDirection,
            })
            state.pMsSettings.sortField = action.payload.sortField
            state.pMsSettings.sortDirection = checkIsNewSortField ? DirectionEnum.ASC : action.payload.sortDirection
        },
    },
    extraReducers,
})

export const {
    setIsWaitingForResponse,
    setPMsSearchValue,
    setPMPaginationPage,
    setPMListTab,
    setPMProfileTab,
    clearCurrentPM,
    clearPMSettings,
    setPMSort,
    setPMListMode,
    setPMFilters,
    setPMsColumns,
    setCurrentPMView,
    setPMPlansListTab,
} = pMSlice.actions

export const PMReducer = pMSlice.reducer
