import styles from './styles.module.scss'
import { ChangeEvent, CSSProperties, FC, useEffect, useState } from 'react'
import Icons from '../../assets/icons'
import classNames from 'classnames'

type Props = {
    readOnly?: boolean
    imgSrc?: string | null
    onChange: (file: File, url: string) => void
    label?: string
    className?: string
    style?: CSSProperties
    accept?: string
}
const ImageLoader: FC<Props> = ({
    imgSrc,
    onChange,
    style,
    accept = 'image/gif, image/jpeg, image/png',
    label = 'Add Image',
    className,
    readOnly = false,
}) => {
    const [imgUrl, setImgUrl] = useState<string | null>(null)

    const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.item(0)
        if (!file) {
            return
        }

        const img = URL.createObjectURL(file)
        setImgUrl(img)

        onChange(file, img)
    }

    useEffect(() => {
        setImgUrl(imgSrc || null)
    }, [imgSrc])

    return (
        <div className={classNames(styles.container, className)}>
            <input
                readOnly={readOnly}
                id="imgSelect"
                className={styles.input}
                type="file"
                accept={accept}
                onChange={handleSelectFile}
            />

            {!imgUrl && (
                <label htmlFor={!readOnly ? 'imgSelect' : undefined} className={styles.labelWrapper}>
                    <Icons.ImagePlaceHolder />
                    {!readOnly && <span>{label}</span>}
                </label>
            )}

            {imgUrl && (
                <div className={styles.imageWrapper} style={style}>
                    <img src={imgUrl} alt={''} />
                    <label htmlFor="imgSelect" className={classNames(styles.change_label, !readOnly && styles.hover)}>
                        Change Image
                    </label>
                </div>
            )}
        </div>
    )
}

export default ImageLoader
