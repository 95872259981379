import React, { FC } from 'react'
import styles from './styles.module.scss'
import { InputLabel, Tooltip } from '@mui/material'
import classNames from 'classnames'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { DatePickerSVG, TimePickerSVG } from 'assets/icons'

type Props = {
    label?: string
    timeLabel?: string
    width?: string
    errorText?: string
    className?: string
    dateValue?: Date | null
    withTime?: boolean
} & ReactDatePickerProps
export const CustomDatePicker: FC<Props> = ({
    label,
    width = '100%',
    className,
    dateValue,
    errorText,
    onChange,
    withTime,
    timeLabel,
    ...rest
}) => {
    return (
        <div style={{ width }} className={styles.root}>
            <div style={{ width: !withTime ? width : '' }}>
                <InputLabel className={styles.label}>{label}</InputLabel>
                <Tooltip title={errorText ? errorText : ''} placement={'right'}>
                    <div>
                        <DatePicker
                            showIcon
                            className={classNames(styles.datePickerContainer, errorText && styles.error, className)}
                            selected={dateValue ? new Date(dateValue) : null}
                            onChange={onChange}
                            popperProps={{ strategy: 'fixed' }}
                            popperPlacement="top-start"
                            dateFormat={'M/d/yyyy'}
                            icon={
                                <DatePickerSVG
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                    }}
                                />
                            }
                            {...rest}
                        />
                    </div>
                </Tooltip>
            </div>

            {withTime && (
                <div>
                    <InputLabel className={styles.label}>{timeLabel}</InputLabel>
                    <DatePicker
                        showIcon
                        className={classNames(styles.datePickerContainer, errorText && styles.error, className)}
                        selected={dateValue ? new Date(dateValue) : null}
                        onChange={onChange}
                        dateFormat={'hh:mm a'}
                        showTimeSelect
                        showTimeSelectOnly
                        icon={
                            <TimePickerSVG
                                style={{
                                    width: '24px',
                                    height: '24px',
                                }}
                            />
                        }
                        {...rest}
                    />
                </div>
            )}
        </div>
    )
}
