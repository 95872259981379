import { Button, ButtonProps } from '@mui/material'
import { FC, ReactNode } from 'react'
import styles from './Button.module.scss'

type Props = {
    colorText?: string
    icon?: ReactNode
    text: string | ReactNode
} & ButtonProps

const TextButton: FC<Props> = ({ icon, text, colorText = '#202534', ...props }) => {
    return (
        <Button
            {...props}
            sx={{
                textTransform: 'none',
                color: colorText,
                gap: '5px',
                fontSize: '12px',
                fontWeight: '500',
                fontFamily: 'PoppinsMedium',
                padding: '5px 14px',
            }}
        >
            {icon && (
                <div className={styles.iconWrap} style={{ color: colorText }}>
                    {icon}
                </div>
            )}

            {text}
        </Button>
    )
}

export default TextButton
