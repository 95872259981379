import { useAppSelector } from '../../../hooks'
import { permissionsUser } from '../../../store/selectors'
import { useEffect, useState } from 'react'
import { USER_ROLE } from '../../../types'

const useCrudUserPermission = (role?: USER_ROLE) => {
    const { isMain, isRequestor, isSuperAdmin, isTechnician, isAdmin, isSupervisor, isFmPro } =
        useAppSelector(permissionsUser)

    const [isCrud, setIsCrud] = useState(false)

    useEffect(() => {
        switch (role) {
            case USER_ROLE.ADMIN: {
                setIsCrud(isSuperAdmin || (isAdmin && isMain))
                break
            }

            case USER_ROLE.SUPERADMIN: {
                setIsCrud(isSuperAdmin && isMain)
                break
            }
            case USER_ROLE.REQUESTOR: {
                setIsCrud(isAdmin || isSupervisor || (isRequestor && isMain))
                break
            }
            case USER_ROLE.SUPERVISOR: {
                setIsCrud(isAdmin || (isSupervisor && isMain))
                break
            }

            case USER_ROLE.TECHNICIAN: {
                setIsCrud(isAdmin || isSupervisor || (isTechnician && isMain))
                break
            }

            default:
                break
        }
    }, [isSuperAdmin, isAdmin, isSupervisor, isFmPro, isRequestor, role])

    return isCrud
}

export default useCrudUserPermission
