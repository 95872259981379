import React, { useEffect, useMemo, useState } from 'react'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useFormik } from 'formik'
import {
    getDescription,
    NewWorkOrderFormType,
    newWorkOrderInitialValues,
    newWorkOrderSchema,
    NewWorkOrderType,
    PriorityWO,
    RecurringSubtype,
    TypeAccessControlWO,
    TypeServicesWO,
    TypeWO,
    WorkOrderStatus,
} from './config'
import Button, { TextButton } from 'ui-kit/components/Button'
import CustomInput from 'ui-kit/components/CustomInput'
import { filterPassedTime, getOptionsFromEnum, removeBlankFields } from 'lib/common'
import PureCustomSelect from 'ui-kit/components/PureCustomSelect/PureCustomSelect'
import { CheckboxInput } from 'ui-kit/components/CheckboxInput'
import {
    BuildingAutoComplete,
    FloorAutoComplete,
    ItemForAutocompleteType,
    RegionAutoComplete,
    RoomAutoComplete,
    SubcontractorsAutoComplete,
    TeamAutoComplete,
} from 'components/CommonAutoComplete'
import { CurrentAssetType } from 'slices/asset'
import { currentUserSelect, isLoadingWorkOrderSelect, locationCurrentAssetSelect } from 'store/selectors'
import { CustomDatePicker } from 'ui-kit/components/CustomDatePicker'
import {
    createWorkOrder,
    getAllWorkOrdersList,
    getWorkOrdersListForAutocomplete,
    joinToWorkOrder,
    sendWoToSubcontractor,
} from 'api/workOrders'
import ProgressBar from 'components/ProgressBar'
import { CommonOtherResponseType, NOTIFICATION_STATUS, USER_ROLE } from 'types'
import { AssetPlansMopsCollapsed } from 'components/AssetPlansMopsCollapsed'
import AssetsCheckBoxList from 'components/AssetsCheckBoxList'
import { CommonDialog } from 'components/CommonDialog'
import { CustomRemovedBox } from 'ui-kit/components/CustomRemovedBox'
import { Asset } from 'slices/floorPlanViever'
import { getSvgImageTypeWo } from 'assets/workOrders-icons'
import { SelectChangeEvent } from '@mui/material'
import FilesDropzoneLoader from 'components/FilesDropzoneLoader'
import Icons from 'assets/icons'
import { Contact } from 'components/ContactsList/types'
import { SendWoDetailsToSubDialog } from 'components/SendWoDetailsToSubDialog'
import sendNotification from 'lib/notification'
import { setIsWaitingForResponse, WorkOrderType } from 'slices/workOrder'
import { LargeWorkOrderCard } from 'components/WorkOrderCards'
import { frequencyValues } from 'pages/EmergencyPage/const'
import { GetWoDetailsComponent } from 'components/GetWoDetailsComponent'
import WorkOrderBadge from 'pages/CalendarPage/components/WorkOrderBadge'

import s from './styles.module.scss'

type NewWorkOrderPropsType = {
    onCancel: () => void
    currentAsset?: CurrentAssetType
}

export const NewWorkOrder = ({ onCancel, currentAsset }: NewWorkOrderPropsType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const isLoading = useAppSelector(isLoadingWorkOrderSelect)
    const locationCurrentAsset = useAppSelector(locationCurrentAssetSelect)
    const [isTechFromTeam, setIsTechFromTeam] = useState(false)
    const [isStartWoNow, setIsStartWoNow] = useState(false)
    const [isAddSubcontractor, setIsAddSubcontractor] = useState(false)
    const [openAddAsset, setOpenAddAsset] = useState(false)
    const [selectedContacts, setSelectedContacts] = useState<Contact[]>([])
    const [duplicatedWo, setDuplicatedWo] = useState<WorkOrderType[]>([])
    const [file, setFile] = useState<File | null>(null)
    const [creationWorkOrderId, setCreationWorkOrderId] = useState('')

    const formik = useFormik<NewWorkOrderFormType>({
        initialValues: newWorkOrderInitialValues,
        onSubmit: async ({ expectedCompletionDate, estimatedLaborHours, startWorkOrder, ...values }) => {
            const fullValues = removeBlankFields({ ...values })
            const data = {
                ...fullValues,
                title: fullValues?.title?.trim(),
                type: fullValues?.type === TypeWO.PROJECT ? 'PROJECT_PLANING' : fullValues?.type,
                description: fullValues?.description?.trim(),
                specialInstructions: fullValues?.specialInstructions?.trim()
                    ? fullValues?.specialInstructions?.trim()
                    : undefined,
                startDate: isStartWoNow && values.type === TypeWO.RECURRING ? values.startDate : undefined,
                //frequencyPM: isStartWoNow && values.type === TypeWO.RECURRING ? values.frequencyPM : undefined,
                regionId: values.regionId?.value ?? undefined,
                buildingId: values?.buildingId?.value,
                floorId: values?.floorId?.value ?? undefined,
                roomId: values?.roomId?.value ?? undefined,
                subcontractorId: isAddSubcontractor ? values?.subcontractorId?.value : undefined,
                assetsId: values?.assetsId?.map((item) => item.id),
                bucketsId: values?.bucketsId?.map((item) => item!.value),
                priority: values.priority,
            }

            dispatch(
                createWorkOrder({
                    ...(data as NewWorkOrderType),
                    checkResponse: (id?: string) => {
                        if (!!values.sendDetailsToSubcontractor && id && selectedContacts?.length) {
                            setCreationWorkOrderId(id)
                        }
                        if (!currentAsset && !values.sendDetailsToSubcontractor && !selectedContacts?.length) {
                            dispatch(getAllWorkOrdersList())
                        }
                        if (isStartWoNow && values.type !== TypeWO.RECURRING && id) {
                            dispatch(
                                joinToWorkOrder({
                                    workOrderId: id,
                                    estimatedLaborHoursTech: estimatedLaborHours ?? 0,
                                    expectedCompletionDateTech: expectedCompletionDate ?? new Date(),
                                }),
                            )
                        }
                        !values.sendDetailsToSubcontractor && !selectedContacts?.length && onCancel()
                    },
                }),
            )
        },
        validationSchema: user && newWorkOrderSchema(user.role, isStartWoNow, isAddSubcontractor),
        validateOnChange: false,
        enableReinitialize: true,
    })

    const onChangeRegion = (item: ItemForAutocompleteType) => {
        formik.setFieldValue('regionId', item)
        formik.setFieldValue('buildingId', null)
        formik.setFieldValue('floorId', null)
        formik.setFieldValue('roomId', null)
        formik.setFieldValue('assetsId', [])
    }

    const onChangeBuilding = (item: ItemForAutocompleteType) => {
        formik.setFieldValue('buildingId', item)
        formik.setFieldValue('floorId', null)
        formik.setFieldValue('roomId', null)
        formik.setFieldValue('assetsId', [])
    }

    const onChangeFloor = (item: ItemForAutocompleteType) => {
        formik.setFieldValue('floorId', item)
        formik.setFieldValue('roomId', null)
        formik.setFieldValue('assetsId', [])
    }

    const onChangeRoom = (item: ItemForAutocompleteType) => {
        formik.setFieldValue('roomId', item)
        formik.setFieldValue('assetsId', [])
    }

    const handleAddAssets = (ids: Array<string>, assets: Asset[]) => {
        formik.setFieldValue('assetsId', formik.values?.assetsId?.concat(assets))
        setOpenAddAsset(false)
    }
    const onDeleteAssetClick = (id: string) => {
        if (currentAsset?.id === id) {
            return
        }

        formik.setFieldValue(
            'assetsId',
            formik.values.assetsId?.filter((asset) => asset.id !== id),
        )
    }

    const onChangeTeams = (items: ItemForAutocompleteType[] | null, values: any) => {
        const technicianFromSelectedTeams = values
            ?.filter((item: any) => items?.map((i) => i?.value)?.includes(item.id))
            ?.filter((o: any) => o?.users?.map((currUser: any) => currUser?.id)?.includes(user?.id))

        formik.setFieldValue('bucketsId', items)
        if (technicianFromSelectedTeams.length) {
            setIsTechFromTeam(true)
        } else {
            setIsTechFromTeam(false)
            setIsStartWoNow(false)
        }
    }

    const onAttachPlan = (id: string) => {
        const value = formik.values?.plansId?.includes(id)
            ? formik.values.plansId?.filter((item) => item !== id)
            : formik.values.plansId?.length
            ? [...formik.values.plansId, id]
            : [id]

        formik.setFieldValue('plansId', value)
    }
    const onAttachMop = (id: string) => {
        const value = formik.values?.mopsId?.includes(id)
            ? formik.values.mopsId?.filter((item) => item !== id)
            : formik.values.mopsId?.length
            ? [...formik.values.mopsId, id]
            : [id]

        formik.setFieldValue('mopsId', value)
    }

    const onChangeTypeWo = ({ target: { value } }: SelectChangeEvent<unknown>) => {
        formik.setFieldValue('type', value as TypeWO)
        formik.setFieldValue('subType', undefined)

        if (value === TypeWO.RECURRING) {
            return formik.setFieldValue('priority', PriorityWO.SCHEDULED)
        }
        if (formik.values.priority === PriorityWO.SCHEDULED) {
            formik.setFieldValue('priority', '')
        }
    }
    const onCreateWoClick = async () => {
        const errors = await formik.validateForm()

        if (!Object.keys(errors)?.length && formik.values.buildingId?.value && formik.values.type) {
            dispatch(setIsWaitingForResponse(true))
            try {
                const res: CommonOtherResponseType<WorkOrderType> = await dispatch(
                    getWorkOrdersListForAutocomplete({
                        offset: 0,
                        limit: 100,
                        statuses: [WorkOrderStatus.NEW, WorkOrderStatus.IN_PROGRESS, WorkOrderStatus.ON_HOLD],
                        idDependence: {
                            types: formik.values.type === TypeWO.PROJECT ? ['PROJECT_PLANING'] : [formik.values.type],
                            buildingId: formik.values.buildingId?.value,
                            roomId: formik.values.roomId?.value ? formik.values.roomId?.value : undefined,
                        },
                    }),
                ).unwrap()

                if (res?.payload?.length) {
                    setDuplicatedWo(res?.payload)
                } else {
                    formik.handleSubmit()
                }
            } catch (e) {
                sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
            } finally {
                dispatch(setIsWaitingForResponse(false))
            }
        } else {
            formik.setErrors(errors)
        }
    }

    const buttonsGroup = useMemo(() => {
        return (
            <div className={s.buttonsGroup}>
                <Button variants={'filled-grey'} type={'button'} onClick={onCancel} className={s.button}>
                    Cancel
                </Button>
                <Button variants={'filled-blue'} onClick={onCreateWoClick} className={s.button}>
                    {isStartWoNow ? 'Begin Work Order' : 'Create Work Order'}
                </Button>
            </div>
        )
    }, [isStartWoNow, formik])

    useEffect(() => {
        if (currentAsset?.id) {
            formik.setFieldValue('regionId', {
                value: locationCurrentAsset.region?.id,
                label: locationCurrentAsset.region?.name,
            })
            formik.setFieldValue('buildingId', {
                value: locationCurrentAsset.building?.id,
                label: locationCurrentAsset.building?.name,
            })
            formik.setFieldValue(
                'floorId',
                locationCurrentAsset?.floor?.id
                    ? {
                          value: locationCurrentAsset.floor?.id,
                          label: locationCurrentAsset.floor?.name,
                      }
                    : null,
            )
            formik.setFieldValue(
                'roomId',
                locationCurrentAsset?.room?.id
                    ? {
                          value: locationCurrentAsset.room?.id,
                          label: locationCurrentAsset.room?.name,
                      }
                    : null,
            )
            formik.setFieldValue('assetsId', [currentAsset])
        }
    }, [currentAsset])

    useEffect(() => {
        if (file) {
            ;(async () => {
                dispatch(setIsWaitingForResponse(true))
                await dispatch(
                    sendWoToSubcontractor({
                        id: creationWorkOrderId,
                        emails: selectedContacts?.map((i) => i.user?.email || i.email),
                        file,
                        checkResponse: () => {
                            setSelectedContacts([])
                            sendNotification(
                                'Details of the work order were successfully sent to the subcontractor',
                                NOTIFICATION_STATUS.SUCCESS,
                            )
                        },
                    }),
                )
                dispatch(getAllWorkOrdersList())
                dispatch(setIsWaitingForResponse(false))
                onCancel()
            })()
        }
    }, [file])

    const getSubTypeByType = useMemo(() => {
        switch (formik.values.type) {
            case TypeWO.ACCESS_CONTROL: {
                return getOptionsFromEnum(TypeAccessControlWO)
            }
            case TypeWO.SERVICE_REQUEST: {
                return getOptionsFromEnum(TypeServicesWO)
            }
            /*case TypeWO.RECURRING: {
                return getOptionsFromEnum(RecurringSubtype)
            }*/
            default:
                return undefined
        }
    }, [formik.values.type])

    const buildingIdDependence = useMemo(() => {
        if (currentAsset) {
            return undefined
        } else {
            if (user?.role === USER_ROLE.ADMIN) {
                return formik.values?.regionId?.value ? { regionIdes: [formik.values?.regionId?.value] } : undefined
            } else {
                return user?.region?.id ? { regionIdes: [user?.region?.id] } : {}
            }
        }
    }, [currentAsset, formik.values.regionId])

    const roomIdDependence = useMemo(() => {
        if (currentAsset) {
            return undefined
        }

        if (formik.values.type === TypeWO.AMENITY_SPACE_BOOKING && formik.values.buildingId?.value) {
            return { buildingId: formik.values.buildingId?.value, isAmenity: true }
        }
        return formik.values.floorId?.value ? { floorIdes: [formik.values.floorId?.value] } : undefined
    }, [currentAsset, formik.values.floorId, formik.values.buildingId, formik.values.type])

    const teamsIdDependence = useMemo(() => {
        if (user?.role === USER_ROLE.ADMIN) {
            return formik.values?.regionId?.value ? { regionId: formik.values?.regionId?.value } : undefined
        } else {
            return {}
        }
    }, [formik.values.regionId])

    const typeWoValues = useMemo(() => {
        if (user?.role === USER_ROLE.REQUESTOR) {
            // TODO: add check tenant role || user?.role === USER_ROLE.TENANT
            return [TypeWO.SERVICE_REQUEST, TypeWO.AMENITY_SPACE_BOOKING, TypeWO.EVENT_SUPPORT].map((value) => {
                return {
                    value,
                    label: value,
                    icon: <img src={getSvgImageTypeWo(value)} alt="type" />,
                    description: getDescription(value),
                }
            })
        }

        return Object.values(TypeWO)
            .filter((value) => ![TypeWO.PREVENTIVE_MAINTENANCE, TypeWO.EMERGENCY, TypeWO.RECURRING].includes(value))
            .map((value) => {
                return { value, label: value, icon: <img src={getSvgImageTypeWo(value)} alt="type" /> }
            })
    }, [user?.role])

    return (
        <>
            <form onSubmit={formik.handleSubmit} className={s.form}>
                <ProgressBar show={isLoading} />
                <div className={s.container}>
                    <Paragraph size={'l'} className={s.title} weight={'semi-bold'}>
                        Add Work Order
                    </Paragraph>
                    <PureCustomSelect
                        values={typeWoValues}
                        rootClassName={s.select}
                        label={'Work Order Type'}
                        backgroundColor={'#F4F5F7'}
                        value={formik.values.type}
                        onChange={onChangeTypeWo}
                        onBlur={formik.handleBlur}
                        name={'type'}
                        errorText={formik.errors.type ?? ''}
                    />
                    {getSubTypeByType && (
                        <PureCustomSelect
                            values={getSubTypeByType}
                            rootClassName={s.select}
                            label={'Work Order Subtype'}
                            backgroundColor={'#F4F5F7'}
                            {...formik.getFieldProps('subType')}
                            errorText={formik.errors.subType ?? ''}
                        />
                    )}
                    <CustomInput
                        label={'Title'}
                        {...formik.getFieldProps('title')}
                        errorText={formik.errors.title ?? ''}
                        variant={'white-grey'}
                    />
                    <CustomInput
                        label={
                            formik.values.type === TypeWO.AMENITY_SPACE_BOOKING
                                ? 'Include dates, times, and additional details about your booking request'
                                : 'Description'
                        }
                        {...formik.getFieldProps('description')}
                        errorText={formik.errors.description ?? ''}
                        multiline
                        className={s.note}
                        variant={'white-grey'}
                        labelClassName={s.description}
                    />
                    <div className={s.empty} />

                    {formik.values.type !== TypeWO.RECURRING && (
                        <PureCustomSelect
                            values={getOptionsFromEnum(PriorityWO)
                                .slice(0, -3)
                                .map(({ value, label }) => ({
                                    value,
                                    label: <WorkOrderBadge status={label} size={12} />,
                                }))}
                            rootClassName={s.select}
                            label={'Priority'}
                            backgroundColor={'#F4F5F7'}
                            {...formik.getFieldProps('priority')}
                            errorText={formik.errors.priority ?? ''}
                        />
                    )}

                    <Paragraph size={'s'} weight={'semi-bold'} className={s.locationTitle}>
                        Location
                    </Paragraph>
                    {user?.role === USER_ROLE.ADMIN && (
                        <RegionAutoComplete
                            currentValue={formik.values?.regionId ?? null}
                            onChangeCallback={onChangeRegion}
                            label={'Region'}
                            errorText={formik.errors.regionId ?? ''}
                            textFieldClassName={s.autoComplete}
                            readOnly={!!currentAsset}
                        />
                    )}
                    <BuildingAutoComplete
                        currentValue={formik.values?.buildingId ?? null}
                        onChangeCallback={onChangeBuilding}
                        handleBlur={formik.handleBlur}
                        errorText={formik.errors.buildingId ?? ''}
                        textFieldClassName={s.autoComplete}
                        idDependence={buildingIdDependence}
                        label={'Building'}
                        readOnly={!!currentAsset}
                    />
                    {formik.values.type !== TypeWO.AMENITY_SPACE_BOOKING && (
                        <FloorAutoComplete
                            currentValue={formik.values?.floorId ?? null}
                            onChangeCallback={onChangeFloor}
                            handleBlur={formik.handleBlur}
                            errorText={formik.errors.floorId ?? ''}
                            textFieldClassName={s.autoComplete}
                            idDependence={
                                currentAsset
                                    ? undefined
                                    : formik.values.buildingId?.value
                                    ? { buildingId: formik.values.buildingId?.value }
                                    : undefined
                            }
                            label={'Floor (Optional)'}
                            readOnly={!!currentAsset}
                        />
                    )}
                    <RoomAutoComplete
                        currentValue={formik.values?.roomId ?? null}
                        onChangeCallback={onChangeRoom}
                        handleBlur={formik.handleBlur}
                        errorText={formik.errors.roomId ?? ''}
                        textFieldClassName={s.autoComplete}
                        idDependence={roomIdDependence}
                        label={
                            formik.values.type === TypeWO.AMENITY_SPACE_BOOKING ? 'Amenity Space' : 'Room (Optional)'
                        }
                        readOnly={!!currentAsset}
                    />

                    {user?.role !== USER_ROLE.REQUESTOR && (
                        <div>
                            {user?.role === USER_ROLE.TECHNICIAN && (
                                <div className={s.row}>
                                    <Icons.InfoCircleSVG className={s.info} />
                                    <Paragraph size={'s'} color={'grey'}>
                                        If you want to start working on this work order now - please select your team
                                        from the list below
                                    </Paragraph>
                                </div>
                            )}
                            <TeamAutoComplete
                                currentValue={formik.values?.bucketsId ?? []}
                                onChangeCallback={onChangeTeams}
                                textFieldClassName={s.autoComplete}
                                label={'WO Team(s)'}
                                errorText={formik.errors.bucketsId ?? ''}
                                idDependence={teamsIdDependence}
                                handleBlur={formik.handleBlur}
                                multiple
                            />
                        </div>
                    )}
                    {user?.role === USER_ROLE.TECHNICIAN && isTechFromTeam && (
                        <CheckboxInput checked={isStartWoNow} onChangeChecked={setIsStartWoNow} className={s.checkBox}>
                            <Paragraph size={'s'} weight={'semi-bold'}>
                                Would you like to begin this work order now?
                            </Paragraph>
                        </CheckboxInput>
                    )}
                    {isStartWoNow && formik.values.type !== TypeWO.RECURRING && (
                        <>
                            <CustomDatePicker
                                label={'Expected Completion Date'}
                                timeLabel={'Expected Completion Time'}
                                dateValue={formik.values.expectedCompletionDate}
                                onChange={(date) => formik.setFieldValue('expectedCompletionDate', date)}
                                onBlur={formik.handleBlur}
                                errorText={
                                    formik.touched.expectedCompletionDate && formik.errors.expectedCompletionDate
                                        ? formik.errors.expectedCompletionDate
                                        : ''
                                }
                                withTime
                                minDate={new Date()}
                                filterTime={(date: Date) => filterPassedTime(new Date(), date)}
                                className={s.date}
                            />
                            <CustomInput
                                label={'Estimated Labor Hours'}
                                {...formik.getFieldProps('estimatedLaborHours')}
                                errorText={formik.errors.estimatedLaborHours ?? ''}
                                variant={'white-grey'}
                            />
                        </>
                    )}
                    <div className={s.empty} />

                    {/*{isStartWoNow && formik.values.type === TypeWO.RECURRING && (
                        <>
                            <CustomDatePicker
                                label={'Start Date'}
                                dateValue={formik.values.startDate}
                                onChange={(date) => formik.setFieldValue('startDate', date)}
                                onBlur={formik.handleBlur}
                                errorText={formik.errors.startDate ?? ''}
                                className={s.date}
                            />
                            <PureCustomSelect
                                values={frequencyValues}
                                rootClassName={s.select}
                                label={'Frequency'}
                                backgroundColor={'#F4F5F7'}
                                {...formik.getFieldProps('frequencyPM')}
                                errorText={formik.errors.frequencyPM ?? ''}
                            />
                        </>
                    )}*/}

                    {user?.role !== USER_ROLE.REQUESTOR && (
                        <>
                            {!!formik.values?.assetsId?.length && (
                                <div>
                                    {formik.values?.assetsId.map((asset) => {
                                        return (
                                            <CustomRemovedBox
                                                title={asset.name}
                                                id={asset.id}
                                                onDeleteClick={onDeleteAssetClick}
                                                key={asset.id}
                                                color={asset?.category?.color}
                                            />
                                        )
                                    })}
                                </div>
                            )}
                            {!!formik.values?.buildingId?.value &&
                                formik.values.type !== TypeWO.AMENITY_SPACE_BOOKING && (
                                    <TextButton
                                        text="+ Add Asset"
                                        colorText="#1B6BC0"
                                        onClick={() => setOpenAddAsset(true)}
                                        className={s.textButton}
                                    />
                                )}

                            {formik.values.type !== TypeWO.AMENITY_SPACE_BOOKING && (
                                <CheckboxInput
                                    checked={isAddSubcontractor}
                                    onChangeChecked={setIsAddSubcontractor}
                                    className={s.checkBox}
                                >
                                    <Paragraph size={'s'} weight={'semi-bold'}>
                                        Add Subcontractor?
                                    </Paragraph>
                                </CheckboxInput>
                            )}

                            {isAddSubcontractor && (
                                <>
                                    <SubcontractorsAutoComplete
                                        currentValue={formik.values?.subcontractorId ?? null}
                                        onChangeCallback={(item: ItemForAutocompleteType) =>
                                            formik.setFieldValue('subcontractorId', item)
                                        }
                                        textFieldClassName={s.autoComplete}
                                        label={'Subcontractor'}
                                        errorText={(formik.errors.subcontractorId as string) ?? ''}
                                        handleBlur={formik.handleBlur}
                                    />
                                    <CheckboxInput
                                        {...formik.getFieldProps('sendDetailsToSubcontractor')}
                                        className={s.checkBox}
                                    >
                                        <Paragraph size={'s'} weight={'semi-bold'}>
                                            Send WO details to subcontractor?
                                        </Paragraph>
                                    </CheckboxInput>

                                    {!!formik.values.sendDetailsToSubcontractor && (
                                        <SendWoDetailsToSubDialog
                                            onClose={() => setSelectedContacts([])}
                                            subcontractorId={formik.values?.subcontractorId?.value ?? null}
                                            onChangeSelectedContacts={setSelectedContacts}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}

                    <CustomInput
                        label={'Special instructions for technicians'}
                        {...formik.getFieldProps('specialInstructions')}
                        errorText={formik.errors.specialInstructions ?? ''}
                        multiline
                        variant={'white-grey'}
                        className={s.note}
                    />

                    <Paragraph size={'s'} weight={'semi-bold'} className={s.locationTitle}>
                        Attach Files
                    </Paragraph>

                    <FilesDropzoneLoader
                        onLoadFiles={(files) => formik.setFieldValue('files', files)}
                        accept={{
                            'application/pdf': [],
                            'image/png': [],
                            'image/jpeg': [],
                        }}
                    />

                    {!!formik.values?.assetsId?.length && user?.role !== USER_ROLE.REQUESTOR && (
                        <>
                            <Paragraph size={'s'} weight={'semi-bold'} className={s.locationTitle}>
                                Would you like to attach any of these asset plans or MOPs to the work order?
                            </Paragraph>
                            <AssetPlansMopsCollapsed
                                assets={formik.values.assetsId?.map((asset) => ({
                                    value: asset.id,
                                    label: asset.name,
                                }))}
                                onAttachPlan={onAttachPlan}
                                attachedPlans={formik.values?.plansId}
                                attachedMops={formik.values?.mopsId}
                                onAttachMop={onAttachMop}
                            />
                        </>
                    )}
                </div>

                <CommonDialog onClose={() => setOpenAddAsset(false)} isOpen={openAddAsset} title="Add Asset">
                    <AssetsCheckBoxList
                        onSaveSelectedAssetsIds={handleAddAssets}
                        disabledAssetsIds={formik.values?.assetsId?.map((asset) => asset.id)}
                        dependBuildingId={formik.values?.buildingId?.value}
                    />
                </CommonDialog>

                <CommonDialog
                    onClose={() => setDuplicatedWo([])}
                    isOpen={!!duplicatedWo.length}
                    title="A similar WO was recently created"
                    onSaveClick={formik.handleSubmit}
                    saveButtonText={'Yes'}
                    width={'900px'}
                >
                    <Paragraph size={'smd'} weight={'semi-bold'} className={s.duplicateTitle}>
                        Would you still like to create this WO?
                    </Paragraph>
                    <div className={s.duplicateBox}>
                        {duplicatedWo?.map((workOrder) => (
                            <LargeWorkOrderCard key={workOrder.id} workOrder={workOrder} />
                        ))}
                    </div>
                </CommonDialog>

                {buttonsGroup}
            </form>

            {!!creationWorkOrderId && <GetWoDetailsComponent workOrderId={creationWorkOrderId} setFile={setFile} />}
        </>
    )
}
