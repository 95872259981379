import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import {
    createSubcontractor,
    deleteAssetPreferredSubcontractor,
    editSubcontractor,
    getAllSubcontractorsList,
    getSubcontractorById,
} from 'api/subcontractors'
import { CommonResponseType, NOTIFICATION_STATUS } from 'types'
import { SubcontractorsState, SubcontractorType } from 'slices/subcontractor/types'
import sendNotification from 'lib/notification'
import { createAssetPreferredSubcontractor } from 'api/assets'

export const extraReducers = (builder: ActionReducerMapBuilder<SubcontractorsState>) => {
    builder.addCase(getAllSubcontractorsList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getAllSubcontractorsList.fulfilled,
        (state, action: PayloadAction<CommonResponseType<SubcontractorType>>) => {
            state.isWaitingForResponse = false
            state.subcontractors = action.payload.rows
            state.subcontractorsCount = action.payload.count
        },
    )
    builder.addCase(getAllSubcontractorsList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getSubcontractorById.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getSubcontractorById.fulfilled, (state, action: PayloadAction<SubcontractorType>) => {
        state.isWaitingForResponse = false
        state.currentSubcontractor = action.payload
    })
    builder.addCase(getSubcontractorById.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(createSubcontractor.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createSubcontractor.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Subcontractor has been created', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(createSubcontractor.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(editSubcontractor.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(editSubcontractor.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('Subcontractor has been edited', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(editSubcontractor.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(createAssetPreferredSubcontractor.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createAssetPreferredSubcontractor.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(createAssetPreferredSubcontractor.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(deleteAssetPreferredSubcontractor.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(deleteAssetPreferredSubcontractor.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(deleteAssetPreferredSubcontractor.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
}
