import styles from './styles.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { getLeaseList } from '../../../../../../api/tenant'
import sendNotification from '../../../../../../lib/notification'
import { NOTIFICATION_STATUS } from '../../../../../../types'
import TenantLeasesTable from './components/TenantLeasesTable'
import { Lease, LeaseOptions, userAction } from '../../../../../../slices/users'
import { Paragraph } from '../../../../../../ui-kit/components/Typography/Typography'
import { TextButton } from '../../../../../../ui-kit/components/Button'
import { Drawer } from '@mui/material'
import TenantLeaseEditContainer from './components/TenantLeaseEditContainer'
import TenantLeaseHistory from './components/TenantLeaseHistory'
import TenantLeaseEnd from './components/TenantLeaseEnd'

const TenantLeasesList = () => {
    const dispatch = useAppDispatch()

    const selectedTenantProfile = useAppSelector(({ users }) => users.selectedTenantProfile)

    const [typeLeaseContainer, setTypeLeaseContainer] = useState<LeaseOptions | null>(null)
    const [selectedLease, setSelectedLease] = useState<Lease | null>(null)
    const [selectedLeaseHistory, setSelectedLeaseHistory] = useState<Lease | null>(null)
    const [selectedEndLease, setSelectedEndLease] = useState<Lease | null>(null)

    const [count, setCount] = useState(0)
    const [leases, setLeases] = useState<Array<Lease>>([])

    const handleCloseAddEdit = () => {
        setTypeLeaseContainer(null)
        setSelectedLease(null)
    }

    const handleSelectLease = (lease: Lease, option: LeaseOptions) => {
        setSelectedLease(lease)
        setTypeLeaseContainer(option)
    }

    const getLeasesData = useCallback(async () => {
        dispatch(userAction.setLoading(true))
        if (!selectedTenantProfile) {
            return
        }

        try {
            const res = await dispatch(getLeaseList({ tenantCompanyId: selectedTenantProfile.id, page: 1 })).unwrap()

            setLeases(res.rows)
            setCount(res.count)
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
        dispatch(userAction.setLoading(false))
    }, [selectedTenantProfile])

    useEffect(() => {
        ;(async () => {
            await getLeasesData()
        })()
    }, [getLeasesData])

    if (!selectedTenantProfile) {
        return null
    }

    return (
        <div className={styles.root}>
            <div className={styles.headerRow}>
                <Paragraph size={'md'} weight={'semi-bold'}>
                    Leases
                </Paragraph>
                <TextButton text="+Add New Lease" onClick={() => setTypeLeaseContainer('ADD')} colorText={'#1B6BC0'} />
            </div>
            <TenantLeasesTable
                leases={leases}
                countLeases={count}
                fetchNextData={getLeasesData}
                onSelectLease={handleSelectLease}
                onClickHistory={setSelectedLeaseHistory}
                onClickEnd={setSelectedEndLease}
            />

            <Drawer
                anchor="right"
                open={!!typeLeaseContainer || !!selectedLease}
                onClose={(_, reason) => reason !== 'backdropClick' && handleCloseAddEdit()}
            >
                <TenantLeaseEditContainer
                    tenantCompanyId={selectedTenantProfile.id}
                    onClose={() => handleCloseAddEdit()}
                    lease={selectedLease}
                    option={typeLeaseContainer}
                    update={getLeasesData}
                />
            </Drawer>

            <Drawer
                anchor="right"
                open={!!selectedLeaseHistory}
                onClose={(_, reason) => reason !== 'backdropClick' && setSelectedLeaseHistory(null)}
            >
                {selectedLeaseHistory && (
                    <TenantLeaseHistory
                        onClose={() => setSelectedLeaseHistory(null)}
                        leaseId={selectedLeaseHistory.id}
                    />
                )}
            </Drawer>

            <Drawer
                anchor="right"
                open={!!selectedEndLease}
                onClose={(_, reason) => reason !== 'backdropClick' && setSelectedEndLease(null)}
            >
                {selectedEndLease && (
                    <TenantLeaseEnd
                        onClose={() => setSelectedEndLease(null)}
                        lease={selectedEndLease}
                        tenantCompanyId={selectedTenantProfile.id}
                        update={getLeasesData}
                    />
                )}
            </Drawer>
        </div>
    )
}

export default TenantLeasesList
