import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import styles from '../../styles.module.scss'
import { TableBody as MuiTableBody } from '@mui/material'

type Props = {
    children: ReactNode
    className?: string
}
const TableBody: FC<Props> = ({ children, className }) => {
    return <MuiTableBody className={classNames(styles.tableBody, className)}>{children}</MuiTableBody>
}

export default TableBody
