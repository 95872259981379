import { Route, Routes } from 'react-router-dom'
import React, { lazy } from 'react'
import TenantProfile from '../pages/UsersTenantsPage/components/TenantProfile'
import { SavedViews } from '../pages/AssetsPage/Views'

const UsersTenantsPage = lazy(() => import('pages/UsersTenantsPage'))
const UserTenantProfile = lazy(() => import('../pages/UsersTenantsPage/components/UserTenantProfile'))

const UsersRoutes = () => {
    return (
        <Routes>
            <Route path="/:role?/user/:id" element={<UserTenantProfile />} />
            <Route path="/tenant/:id" element={<TenantProfile />} />

            <Route path="*" element={<UsersTenantsPage />} />
        </Routes>
    )
}

export default UsersRoutes
