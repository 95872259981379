import { createAsyncThunk } from '@reduxjs/toolkit'
import { client, axiosErrorHandler } from './setupAxios'
import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { BUILDS_PER_PAGE } from 'pages/BuildingsPage/helpers'
import { DirectionEnum } from 'types'
import { IdDependenceType } from 'components/CommonAutoComplete'

interface GetFloorsListData {
    page: number
    size?: number
    sortField?: string
    direction?: string
    customerId?: string
    buildingId: string
    value?: string
}

type CreateFloorData = {
    name: string
    label?: string
    description?: string
    buildingId: string
    area: number
}

export const createFloor = createAppAsyncThunk(
    'floors/createFloor',
    async (data: CreateFloorData, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/floors/create-floor`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const updateFloor = createAppAsyncThunk(
    'floors/updateFloor',
    async (data: CreateFloorData & { floorId: string }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/floors/update-floor`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteFloor = createAppAsyncThunk('floors/delete', async (id: string, { rejectWithValue }) => {
    try {
        const result = await client.delete(`/api/floors/delete-floor?floorId=${id}`)

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getFloorsListByBuildingId = createAsyncThunk(
    'floors/getList',
    async (
        {
            page,
            size = 50,
            sortField = 'name',
            direction = 'asc',
            customerId,
            value = '',
            buildingId,
        }: GetFloorsListData,
        { rejectWithValue },
    ) => {
        try {
            const result = await client.get(`/api/floors/get-floors-by-building-id`, {
                params: {
                    page,
                    size,
                    sortField,
                    sortDirection: direction,
                    customerId,
                    keySearchValue: value,
                    buildingId,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

interface GetRoomsListData {
    sortField?: string
    direction?: string
    customerId: string
    floorIdes: Array<string>
    value?: string
}

export const getRoomsListByFloorId = createAsyncThunk(
    'rooms/getRoomsListByFloor',
    async (
        { sortField = 'name', direction = DirectionEnum.ASC, customerId, value = '', floorIdes }: GetRoomsListData,
        { rejectWithValue },
    ) => {
        try {
            const result = await client.get(`/api/rooms/get-rooms-by-entity`, {
                params: {
                    sortField,
                    sortDirection: direction,
                    customerId,
                    keySearchValue: value,
                    'floorIdes[]': floorIdes,
                    page: 1,
                    size: 100,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetCategoriesListData = {
    offset?: number
    limit?: number
    buildingId?: string
    floorId?: string
    assetPropId?: string
}

export const getCategoriesList = createAsyncThunk(
    'asset/getCategoriesList',
    async (
        { buildingId, floorId, assetPropId, offset = 0, limit = 100 }: GetCategoriesListData,
        { rejectWithValue },
    ) => {
        try {
            const result = await client.get(`/api/asset/category/many/`, {
                params: {
                    offset,
                    limit,
                    buildingId,
                    floorId,
                    assetPropId,
                    checkType: 'AND',
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetCategoriesCountAssets = {
    categoryId: string
    pageId: string
}

export const getCategoriesCountAssets = createAsyncThunk(
    'asset/getCategoriesCountAssets',
    async ({ categoryId, pageId }: GetCategoriesCountAssets, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/category/count/${pageId}/${categoryId}`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetTypesCountAssets = {
    typeId: string
    pageId: string
}

export const getTypesCountAssets = createAsyncThunk(
    'asset/getTypesCountAssets',
    async ({ typeId, pageId }: GetTypesCountAssets, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/type/count/${pageId}/${typeId}`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetAssetTypesListData = {
    offset?: number
    limit?: number
    buildingId?: string
    floorId?: string
    categoryIdes?: string[]
    categoryId?: string
    assetPropId?: string
}

export const getAssetTypesListByCategory = createAsyncThunk(
    'asset/getAssetTypesListByCategory',
    async (
        { categoryIdes, categoryId, buildingId, floorId, assetPropId, offset = 0, limit = 100 }: GetAssetTypesListData,
        { rejectWithValue },
    ) => {
        try {
            const result = await client.get(`/api/asset/type/many`, {
                params: {
                    categoryIdes,
                    categoryId,
                    offset,
                    limit,
                    buildingId,
                    floorId,
                    assetPropId,
                    checkType: 'AND',
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetAssetsByCategoryAndTypesData = {
    idCategory?: string
    idType?: string
    offset?: number
    limit?: number
    buildingId?: string
    floorId?: string
    assignedForPageId?: string
    searchString?: string
}

export const getAssetsByCategoryAndTypes = createAsyncThunk(
    'asset/getAssetsByCategoryAndTypes',
    async (
        {
            idCategory,
            buildingId,
            floorId,
            searchString = '',
            idType,
            offset = 0,
            limit = 100,
            assignedForPageId,
        }: GetAssetsByCategoryAndTypesData,
        { rejectWithValue },
    ) => {
        try {
            const isSearch = searchString.length > 0

            const result = await client.get(`/api/asset/by-entity`, {
                params: {
                    offset,
                    limit,
                    'categoryIdes[]': isSearch ? undefined : idCategory,
                    'typeIdes[]': isSearch ? undefined : idType,
                    'buildingIdes[]': buildingId,
                    'floorIdes[]': floorId,
                    'includeCriteria[]': ['TYPE', 'CATEGORY'],
                    'attributeCriteria[]': 'IS_NEW',
                    assignedForPageId,
                    searchString: !isSearch ? undefined : searchString,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getFloorsListForAutocomplete = createAppAsyncThunk<
    any,
    { page: number; search: string; idDependence?: IdDependenceType }
>('floors/get-list-for-autocomplete', async ({ page, search, idDependence }, { rejectWithValue }) => {
    const params = {
        size: BUILDS_PER_PAGE,
        page: page,
        keySearchValue: search,
        sortField: 'name',
        sortDirection: DirectionEnum.ASC,
        ...idDependence,
    }

    try {
        const result = await client.get(`/api/floors/get-floors-by-building-id`, { params })
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})
