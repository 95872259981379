import styles from './styles.module.scss'
import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material'
import { getAssetTypesListByCategory } from 'api/floors'
import { Asset, AssetCategory, AssetType } from 'slices/floorPlanViever'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import { useAppDispatch } from 'hooks'
import Icons from 'assets/icons'
import TypeAccordionRow from './TypeAccordionRow'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import PlanTypeIcon from '../PlanTypeIcon/PlanTypeIcon'

type Props = {
    category: AssetCategory
    dependBuildingId?: string
    disabledAssetsIds?: Array<string>
    selectedAssetsIds: Array<string>
    onSelectAssetId: (id: string, checked: boolean, asset: Asset) => void
    hiddenAssetId?: string
}
const CategoryAccordionRow = ({
    category,
    dependBuildingId,
    selectedAssetsIds,
    onSelectAssetId,
    disabledAssetsIds,
    hiddenAssetId,
}: Props) => {
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)

    const [types, setTypes] = useState<Array<AssetType>>([])
    const handleOpenCategory = async () => {
        setLoading(true)
        try {
            const res = await dispatch(
                getAssetTypesListByCategory({ categoryId: category.id, buildingId: dependBuildingId }),
            ).unwrap()

            setTypes(res.types)
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
        setLoading(false)
    }

    return (
        <Accordion
            disableGutters
            elevation={0}
            expanded={category.assetsCount === 0 ? false : undefined}
            onChange={(_, expanded) => expanded && handleOpenCategory()}
            sx={{
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
                '&:before': {
                    display: 'none',
                },

                '&.Mui-expanded': {
                    background: '#E9EEF3',
                },
            }}
        >
            <AccordionSummary
                expandIcon={<Icons.Next className={styles.accordionIcon} />}
                sx={{
                    '& .MuiAccordionSummary-content': {
                        margin: 0,
                    },
                }}
            >
                <div className={styles.accordionRowWrap}>
                    <PlanTypeIcon src={category?.file?.url} planType={category.name} color={category.color} size={30} />

                    <div className={styles.detailWrap}>
                        <Paragraph size="s" weight="tiny">
                            {category.name}
                        </Paragraph>
                        <Paragraph size="xs" weight="tiny" color="grey">
                            {category.assetsCount} assets
                        </Paragraph>
                    </div>

                    {loading && <CircularProgress size={20} />}
                </div>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    padding: 0,
                    background: '#E9EEF3',
                }}
            >
                {types.map((type) =>
                    type.assetsCount !== 0 ? (
                        <TypeAccordionRow
                            key={type.id}
                            category={category}
                            type={type}
                            selectedAssetsIds={selectedAssetsIds}
                            onSelectAssetId={onSelectAssetId}
                            disabledAssetsIds={disabledAssetsIds}
                            dependBuildingId={dependBuildingId}
                            hiddenAssetId={hiddenAssetId}
                        />
                    ) : null,
                )}
            </AccordionDetails>
        </Accordion>
    )
}

export default CategoryAccordionRow
