import { Lease, LeaseOptions } from '../../../../../../../../slices/users'
import styles from './styles.module.scss'
import { Paragraph } from '../../../../../../../../ui-kit/components/Typography/Typography'
import StatusBadge from '../../../../../../../../components/StatusBadge'
import { getColorLeaseHistory } from './types'
import dayjs from 'dayjs'
import { capitalized } from '../../../../../../../../lib/common'
import React, { useState } from 'react'
import { Drawer } from '@mui/material'
import TenantLeaseEditContainer from '../TenantLeaseEditContainer'

type Props = {
    date: Date
    option: LeaseOptions
    lease: Lease
}

const LeaseHistoryCard = ({ date, lease, option }: Props) => {
    const [openLease, setOpenLease] = useState(false)

    return (
        <div className={styles.historyCardRoot} onClick={() => setOpenLease(true)}>
            <div className={styles.historyRow}>
                <Paragraph size="s" weight="tiny" color="grey" className={styles.historyRowTitle}>
                    Lease amount:
                </Paragraph>
                <Paragraph size="s" weight="tiny" className={styles.historyRowTitle}>
                    $ {lease.leaseAmount}
                </Paragraph>
            </div>

            <div className={styles.historyRow}>
                <Paragraph size="s" weight="tiny" color="grey" className={styles.historyRowTitle}>
                    Status:
                </Paragraph>
                <Paragraph size="s" weight="tiny" className={styles.historyRowTitle}>
                    <StatusBadge color={getColorLeaseHistory(option)} text={option} />
                </Paragraph>
            </div>

            <div className={styles.historyRow}>
                <Paragraph size="s" weight="tiny" color="grey" className={styles.historyRowTitle}>
                    {capitalized(option)} Date:
                </Paragraph>
                <Paragraph size="s" weight="tiny" className={styles.historyRowTitle}>
                    {dayjs(date).format('MM/DD/YYYY')}
                </Paragraph>
            </div>

            <div className={styles.historyRow}>
                <Paragraph size="s" weight="tiny" color="grey" className={styles.historyRowTitle}>
                    Start Date:
                </Paragraph>
                <Paragraph size="s" weight="tiny" className={styles.historyRowTitle}>
                    {dayjs(lease.moveInDate).format('MM/DD/YYYY')}
                </Paragraph>
            </div>

            <div className={styles.historyRow}>
                <Paragraph size="s" weight="tiny" color="grey" className={styles.historyRowTitle}>
                    Lease end date:
                </Paragraph>
                <Paragraph size="s" weight="tiny" className={styles.historyRowTitle}>
                    {dayjs(lease.leaseExpirationDate).format('MM/DD/YYYY')}
                </Paragraph>
            </div>

            <Drawer
                anchor="right"
                open={openLease}
                onClose={(_, reason) => reason !== 'backdropClick' && setOpenLease(false)}
            >
                <TenantLeaseEditContainer onClose={() => setOpenLease(false)} lease={lease} option="VERSION" />
            </Drawer>
        </div>
    )
}

export default LeaseHistoryCard
