import { Availabilities } from 'components/NewSubcontractor/config'
import { TeamAssignmentMethod } from 'slices/team'
import * as yup from 'yup'
import { REQUIRED_FIELD, TOO_LONG } from 'pages/NewAssetPage/config'
import { ItemForAutocompleteType, yupAutocompleteItem } from 'components/CommonAutoComplete'
import { PageTabType } from 'components/PageTabPanel'
import { LengthConstrains } from 'types'

export const breadCrumbsArr = (name: string) => {
    return [
        {
            name: 'All Teams',
            link: `/work-orders?tab=TEAMS`,
        },
        {
            name: `${name ?? ''}`,
            link: `#`,
        },
    ]
}

export enum TeamProfileTab {
    DETAILS = 'DETAILS',
    NOTES = 'NOTES',
}
export const tabs: PageTabType[] = [
    {
        id: TeamProfileTab.DETAILS,
        label: 'Details',
    },
    {
        id: TeamProfileTab.NOTES,
        label: 'Notes',
    },
]

export type EditTeamType = {
    name: string
    assignmentMethod: TeamAssignmentMethod | null
    availability: Availabilities | null
    workOrderTypes: string[]
    workOrderSubtypes?: string[]
    requiresApproval: boolean
    regionId: string
    buildingAssignments?: string[]
}

export type EditTeamFormType = {
    name: string
    regionId: ItemForAutocompleteType | null
    buildingAssignments?: ItemForAutocompleteType[]
    availability: Availabilities | null
    workOrderTypes: string[]
    workOrderSubtypes?: string[]
    requiresApproval: boolean
    assignmentMethod: TeamAssignmentMethod | null
}

export const editTeamInit: EditTeamFormType = {
    name: '',
    regionId: null,
    buildingAssignments: undefined,
    availability: null,
    workOrderTypes: [],
    workOrderSubtypes: undefined,
    requiresApproval: false,
    assignmentMethod: null,
}

export const editTeamSchema = yup.object().shape({
    name: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required(REQUIRED_FIELD),
    regionId: yupAutocompleteItem.required(REQUIRED_FIELD),
    buildingAssignments: yup.array().of(yupAutocompleteItem).nullable(),
    availability: yup.string().max(LengthConstrains.L_50, TOO_LONG).required(REQUIRED_FIELD),
    workOrderTypes: yup.array().of(yup.string()).min(1, REQUIRED_FIELD),
    workOrderSubtypes: yup.array().of(yup.string()).nullable(),
    assignmentMethod: yup.string().max(LengthConstrains.L_50, TOO_LONG).required(REQUIRED_FIELD),
})
