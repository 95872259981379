import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { currentUserSelect } from 'store/selectors'
import { useEffect, useState } from 'react'
import { WorkOrderType } from 'slices/workOrder'
import { useInfinityScroll } from 'hooks/index'
import { CommonOtherResponseType, NOTIFICATION_STATUS } from 'types'
import sendNotification from 'lib/notification'
import { getWorkOrdersListByAsset } from 'api/workOrders'
import { WORK_ORDERS_PER_PAGE } from 'pages/WorkOrdersPage/config'
import { TypeWO } from 'components/NewWorkOrder/config'

type PropsType = {
    assetId?: string
    isPm?: boolean
    subcontractorId?: string
    isAllow?: boolean
}

export const useWoHistory = ({ assetId, isPm = false, subcontractorId, isAllow = true }: PropsType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const [isLoading, setIsLoading] = useState(false)
    const [history, setHistory] = useState<WorkOrderType[]>([])
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)

    const { setLastElement } = useInfinityScroll({
        fetchNextData: () => setPage(page + 1),
        hasMore: history?.length < count,
    })

    const getHistory = async () => {
        setIsLoading(true)
        try {
            const res: CommonOtherResponseType<WorkOrderType> = await dispatch(
                getWorkOrdersListByAsset({
                    assetId,
                    limit: WORK_ORDERS_PER_PAGE,
                    offset: WORK_ORDERS_PER_PAGE * (page - 1),
                    showPM: isPm || undefined,
                    types: isPm ? [TypeWO.PREVENTIVE_MAINTENANCE] : undefined,
                    subcontractorId,
                }),
            ).unwrap()

            if (res) {
                setHistory(page === 1 ? res?.payload : [...history, ...res.payload])
                setCount(res?.count)
            }
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!user || !isAllow) {
            return
        }
        getHistory()
    }, [user, page, isAllow])

    return { history, isLoading, setLastElement }
}
