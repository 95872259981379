import styles from './styles.module.scss'
import { Paragraph } from '../../../../../../ui-kit/components/Typography/Typography'
import React, { useMemo, useState } from 'react'
import { statusSelect } from './config'
import PureCustomSelect from '../../../../../../ui-kit/components/PureCustomSelect/PureCustomSelect'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks'
import { userAction, UserStatus } from '../../../../../../slices/users'
import { USER_ROLE, USER_TYPE } from '../../../../../../types'
import Button from '../../../../../../ui-kit/components/Button'
import useGetUserSettings from '../../../../hooks/useGetUserSettings'
import {
    AutocompleteType,
    ItemForAutocompleteType,
    RegionAutoComplete,
} from '../../../../../../components/CommonAutoComplete'
import { selectTypeData } from '../../../../const'
import { CustomRangeDatePicker } from '../../../../../../ui-kit/components/CustomRangeDatePicker'
import { ClearButton } from '../../../../../../ui-kit/components/ClearButton'
import CustomCheckBox from '../../../../../../ui-kit/components/CustomCheckBox'

type Props = {
    userRole: USER_ROLE
}

const UserFilters = ({ userRole }: Props) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.auth.user)

    const settings = useGetUserSettings(userRole)

    const [localFilters, setLocalFilters] = useState(settings?.filters)

    const selectTypeValues = useMemo(() => {
        const typeValues = selectTypeData[userRole]
        return typeValues || []
    }, [userRole])

    const statusRenderValue = useMemo(() => {
        if (!localFilters?.statuses?.length) {
            return () => (
                <Paragraph size={'s'} style={{ opacity: 0.4 }}>
                    Select Status
                </Paragraph>
            )
        }
        if (localFilters?.statuses?.length === 1) {
            return undefined
        } else {
            return () => (
                <Paragraph size={'s'} style={{ display: 'flex', gap: '.4rem' }}>
                    {localFilters?.statuses?.length}
                    <Paragraph size={'s'} color={'grey'}>
                        (statuses)
                    </Paragraph>
                </Paragraph>
            )
        }
    }, [localFilters?.statuses?.length])

    const typeRenderValue = useMemo(() => {
        if (!localFilters?.types?.length) {
            return () => (
                <Paragraph size={'s'} style={{ opacity: 0.4 }}>
                    Select Type
                </Paragraph>
            )
        }
        if (localFilters?.types?.length === 1) {
            return undefined
        } else {
            return () => (
                <Paragraph size={'s'} style={{ display: 'flex', gap: '.4rem' }}>
                    {localFilters?.types?.length}
                    <Paragraph size={'s'} color={'grey'}>
                        (types)
                    </Paragraph>
                </Paragraph>
            )
        }
    }, [localFilters?.types?.length])

    const handleChangeDateAdded = (dates: [Date | null, Date | null]) => {
        const [startDateAdded, endDateAdded] = dates
        setLocalFilters((prev) => ({ ...prev, startDateAdded, endDateAdded }))
    }

    const handleChangeLastLogin = (dates: [Date | null, Date | null]) => {
        const [startLastLogin, endLastLogin] = dates
        setLocalFilters((prev) => ({ ...prev, startLastLogin, endLastLogin }))
    }

    const handleChangeLastActivity = (dates: [Date | null, Date | null]) => {
        const [startLastActivity, endLastActivity] = dates
        setLocalFilters((prev) => ({ ...prev, startLastActivity, endLastActivity }))
    }

    const handleChangeStatus = (val: UserStatus[]) => {
        setLocalFilters((prev) => ({ ...prev, statuses: val }))
    }

    const handleChangeType = (val: USER_TYPE[]) => {
        setLocalFilters((prev) => ({ ...prev, types: val }))
    }

    const handleChangeRegion = (item: ItemForAutocompleteType[]) => {
        setLocalFilters((prev) => ({ ...prev, regions: item as AutocompleteType[] }))
    }

    const handleResetClick = async () => {
        if (userRole === USER_ROLE.ADMIN) {
            dispatch(userAction.setAdminsFilters(null))
        }
        if (userRole === USER_ROLE.SUPERADMIN) {
            dispatch(userAction.setSuperAdminsFilters(null))
        }
        if (userRole === USER_ROLE.TECHNICIAN) {
            dispatch(userAction.setTechniciansFilters(null))
        }
        if (userRole === USER_ROLE.SUPERVISOR) {
            dispatch(userAction.setSupervisorsFilters(null))
        }
        if (userRole === USER_ROLE.REQUESTOR) {
            dispatch(userAction.setRequestorsFiltes(null))
        }
        setLocalFilters(null)
        //dispatch(setCurrentAssetView({ id: '', title: '', isSystemView: false }))
    }

    const onApplyClick = () => {
        if (userRole === USER_ROLE.ADMIN) {
            dispatch(userAction.setAdminsFilters({ ...localFilters }))
        }
        if (userRole === USER_ROLE.SUPERADMIN) {
            dispatch(userAction.setSuperAdminsFilters({ ...localFilters }))
        }
        if (userRole === USER_ROLE.TECHNICIAN) {
            dispatch(userAction.setTechniciansFilters({ ...localFilters }))
        }
        if (userRole === USER_ROLE.SUPERVISOR) {
            dispatch(userAction.setSupervisorsFilters({ ...localFilters }))
        }
        if (userRole === USER_ROLE.REQUESTOR) {
            dispatch(userAction.setRequestorsFiltes({ ...localFilters }))
        }
    }

    return (
        <div className={styles.root}>
            <Paragraph position={'left'} weight={'semi-bold'} size={'md'}>
                Filter{!!settings?.currentViewTitle && ` / Saved view: ${settings.currentViewTitle}`}
            </Paragraph>

            <div className={styles.container}>
                <PureCustomSelect
                    label="Status"
                    values={statusSelect}
                    value={localFilters?.statuses || []}
                    onChange={(e) => handleChangeStatus(e.target.value as UserStatus[])}
                    multiple
                    displayEmpty
                    rootClassName={styles.select}
                    renderValue={statusRenderValue}
                    endAdornment={
                        !!localFilters?.statuses?.length && <ClearButton onClick={() => handleChangeStatus([])} />
                    }
                />

                <PureCustomSelect
                    label="Type"
                    values={selectTypeValues}
                    value={localFilters?.types || []}
                    onChange={(e) => handleChangeType(e.target.value as USER_TYPE[])}
                    multiple
                    displayEmpty
                    rootClassName={styles.select}
                    renderValue={typeRenderValue}
                    endAdornment={!!localFilters?.types?.length && <ClearButton onClick={() => handleChangeType([])} />}
                />

                {userRole !== USER_ROLE.ADMIN && userRole !== USER_ROLE.SUPERADMIN && (
                    <RegionAutoComplete
                        width={'250px'}
                        textFieldClassName={styles.autocomplete}
                        label="Region"
                        textFieldPlaceholder="Select Region"
                        currentValue={localFilters?.regions?.length ? localFilters.regions : []}
                        onChangeCallback={handleChangeRegion}
                        disabled={user?.role !== USER_ROLE.ADMIN && user?.role !== USER_ROLE.SUPERADMIN}
                        multiple
                    />
                )}

                <CustomRangeDatePicker
                    className={styles.datePicker}
                    dateFrom={localFilters?.startDateAdded}
                    dateTo={localFilters?.endDateAdded}
                    onChange={handleChangeDateAdded}
                    monthsShown={2}
                    width={'250px'}
                    isClearable
                    placeholderText={'Date Added'}
                />
                <CustomRangeDatePicker
                    className={styles.datePicker}
                    dateFrom={localFilters?.startLastLogin}
                    dateTo={localFilters?.endLastLogin}
                    onChange={handleChangeLastLogin}
                    monthsShown={2}
                    width={'250px'}
                    isClearable
                    placeholderText={'Last Login'}
                />
                <CustomRangeDatePicker
                    className={styles.datePicker}
                    dateFrom={localFilters?.startLastActivity}
                    dateTo={localFilters?.endLastActivity}
                    onChange={handleChangeLastActivity}
                    monthsShown={2}
                    width={'250px'}
                    isClearable
                    placeholderText={'Last Activity'}
                />

                <div className={styles.checkboxWrap}>
                    <CustomCheckBox
                        label="Is Archived users"
                        checked={localFilters?.isArchived ?? false}
                        onChange={() =>
                            setLocalFilters((prev) => ({
                                ...prev,
                                isArchived: !localFilters?.isArchived,
                            }))
                        }
                    />
                </div>
            </div>

            <div className={styles.buttonsGroup}>
                <div className={styles.button}>
                    <Button
                        variants={'filled-grey'}
                        type={'button'}
                        onClick={handleResetClick}
                        disabled={!localFilters}
                    >
                        Reset
                    </Button>
                </div>
                <div className={styles.button}>
                    <Button variants={'filled-blue'} type={'button'} onClick={onApplyClick} disabled={!localFilters}>
                        Apply
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default UserFilters
