import { AppDispatch, StoreRootState } from '../store'
import { createAsyncThunk } from '@reduxjs/toolkit'

type ThunkApiConfig = {
    state: StoreRootState
    dispatch: AppDispatch
    rejectValue: any
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<ThunkApiConfig>()
