import React, { ReactNode } from 'react'
import { NOTIFICATION_STATUS } from '../../types'
import styles from './ToastNotification.module.scss'
import Icons from '../../assets/icons'

type ToastProps = {
    text: string | ReactNode
    type: NOTIFICATION_STATUS
}

const ToastNotification: React.FC<ToastProps> = ({ text, type }) => {
    const getNotificationIcon = () => {
        switch (type) {
            case NOTIFICATION_STATUS.SUCCESS: {
                return <Icons.NotificationSuccess className={styles.notificationIcon} />
            }
            case NOTIFICATION_STATUS.WARNING: {
                return <Icons.NotificationWarning className={styles.notificationIcon} />
            }
            case NOTIFICATION_STATUS.INFO: {
                return <Icons.NotificationInfo className={styles.notificationIcon} />
            }
            case NOTIFICATION_STATUS.ERROR: {
                return <Icons.NotificationError className={styles.notificationIcon} />
            }
            default:
                return <Icons.NotificationInfo className={styles.notificationIcon} />
        }
    }

    const getBackgroundColor = () => {
        switch (type) {
            case NOTIFICATION_STATUS.SUCCESS: {
                return '#28A745'
            }
            case NOTIFICATION_STATUS.WARNING: {
                return '#FFC107'
            }
            case NOTIFICATION_STATUS.INFO: {
                return '#1B6BC0'
            }
            case NOTIFICATION_STATUS.ERROR: {
                return '#DC3545'
            }
            default: {
                return '#1B6BC0'
            }
        }
    }

    const icon = getNotificationIcon()
    const backgroundColor = getBackgroundColor()

    return (
        <div className={styles.notificationContainer} style={{ borderLeft: `8px solid ${backgroundColor}` }}>
            {icon}
            <div className={styles.notificationBody}>{text}</div>
        </div>
    )
}

export default ToastNotification
