import { createAsyncThunk } from '@reduxjs/toolkit'
import { CommonType } from 'types'
import { axiosErrorHandler, client } from 'api/setupAxios'
import { createAppAsyncThunk } from 'common/createAppAsyncThunk'

export const GROUP_PER_PAGE = 50

type GetObjectGroupsListDataType = {
    offset?: number
    limit?: number
    typeId?: string
    categoryId?: string
    searchString?: string
    pageIdes?: string[]
}

export type ObjectGroupType = CommonType & {
    creator: {
        id: string
        firstName: string
        lastName: string
        avatar: null
    }
    category: CommonType & {
        color: string
        file: { url: string } | null
    }
    type: CommonType
}

export const getGroupsList = createAsyncThunk(
    'objects/getGroupsList',
    async ({ offset = 0, limit = GROUP_PER_PAGE, ...params }: GetObjectGroupsListDataType, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/objects/groups/many`, {
                params: {
                    offset,
                    limit,
                    isIncludeCounts: true,
                    ...params,
                },
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetObjectsTypesList = {
    offset?: number
    limit?: number
}

export const getObjectsTypesList = createAppAsyncThunk(
    'objects/getObjectsTypesList',
    async ({ offset = 0, limit = GROUP_PER_PAGE }: GetObjectsTypesList, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/type/many`, {
                params: {
                    offset,
                    variant: 'OBJECT',
                    limit,
                },
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreateGroupObject = {
    typeId: string
    qty: number
    name?: string
    pageId: string
    objectGroupId?: string
}

export const createGroupObject = createAppAsyncThunk(
    'objects/createGroupObject',
    async (data: CreateGroupObject, { rejectWithValue }) => {
        try {
            const result = await client.post(`api/objects`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetObjectsList = {
    offset?: number
    limit?: number
    objectGroupId: string
}

export const getObjectsList = createAppAsyncThunk(
    'objects/GetObjectsList',
    async ({ offset = 0, limit = GROUP_PER_PAGE, ...params }: GetObjectsList, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/objects/many`, {
                params: {
                    offset,
                    limit,
                    ...params,
                },
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteObjectOrGroup = createAppAsyncThunk(
    'objects/deleteObjectOrGroup',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/objects/${id}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type RenameObjectGroup = {
    id: string
    name: string
}

export const renameObjectGroup = createAppAsyncThunk(
    'objects/renameObjectGroup',
    async ({ id, name }: RenameObjectGroup, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/objects/${id}`, {
                name,
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
