import styles from './styles.module.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { getWorkOrdersListByEntity } from '../../../../api/user'
import sendNotification from '../../../../lib/notification'
import { NOTIFICATION_STATUS } from '../../../../types'
import { useAppDispatch, useAppSelector, useInfinityScroll } from '../../../../hooks'
import { WorkOrderType } from '../../../../slices/workOrder'
import { Paragraph } from '../../../../ui-kit/components/Typography/Typography'
import { TableContainer } from '@mui/material'
import classNames from 'classnames'
import * as T from '../../../../components/Table'
import dayjs from 'dayjs'
import { WorkOrderStatus } from '../../../../components/NewWorkOrder/config'
import WorkOrderBadge from '../../../CalendarPage/components/WorkOrderBadge'

type Props = {
    userId?: string
    tenantCompanyId?: string
}

const ActiveWorkOrdersTable = ({ userId, tenantCompanyId }: Props) => {
    const dispatch = useAppDispatch()

    const [openWorkOrders, setOpenWorkOrders] = useState<Array<WorkOrderType>>([])

    const [offset, setOffset] = useState(0)
    const [count, setCount] = useState(0)

    const { setLastElement } = useInfinityScroll({
        fetchNextData: () => setOffset(openWorkOrders.length),
        hasMore: openWorkOrders.length < count,
    })
    const getOpenWorkOrders = useCallback(async () => {
        if (!userId && !tenantCompanyId) {
            return
        }

        try {
            const res = await dispatch(
                getWorkOrdersListByEntity({
                    offset,
                    userId,
                    tenantCompanyId,
                    statuses: [
                        WorkOrderStatus.IN_PROGRESS,
                        WorkOrderStatus.ON_HOLD,
                        WorkOrderStatus.NEW,
                        WorkOrderStatus.PENDING_REVIEW,
                    ],
                }),
            ).unwrap()

            if (!res) {
                return
            }

            setCount(res.count)
            if (offset === 0) {
                setOpenWorkOrders(res.rows)
            } else {
                setOpenWorkOrders([...openWorkOrders, ...res.rows])
            }
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }, [userId, tenantCompanyId, offset])

    useEffect(() => {
        ;(async () => {
            await getOpenWorkOrders()
        })()
    }, [getOpenWorkOrders])

    return (
        <div className={styles.root}>
            <Paragraph size="md" weight="semi-bold">
                Active Work Orders
            </Paragraph>

            <TableContainer className={classNames(styles.historyTable)}>
                <T.Table stickyHeader>
                    <T.TableHead>
                        <T.TableHeadRow>
                            <T.TableHeadData width={200} align="center">
                                WO#
                            </T.TableHeadData>
                            <T.TableHeadData width={200} align="center">
                                Date
                            </T.TableHeadData>
                            <T.TableHeadData align="center">Type</T.TableHeadData>
                            <T.TableHeadData align="center">Status</T.TableHeadData>
                        </T.TableHeadRow>
                    </T.TableHead>

                    <T.TableBody>
                        {openWorkOrders.map((wo, index, { length }) => (
                            <T.TableBodyRow ref={index === length - 1 ? setLastElement : null} key={wo.id}>
                                <T.TableBodyData align="center">
                                    <Paragraph size="xs" weight="tiny">
                                        {wo.title}
                                    </Paragraph>
                                </T.TableBodyData>
                                <T.TableBodyData width={200} align="center">
                                    <Paragraph size="xs" weight="tiny">
                                        {dayjs(wo.creationDate).format('MM/DD/YY, hh:mm A')}
                                    </Paragraph>
                                </T.TableBodyData>
                                <T.TableBodyData align="center">
                                    <Paragraph size="xs" weight="tiny">
                                        {wo.type}/{wo.subType}
                                    </Paragraph>
                                </T.TableBodyData>

                                <T.TableBodyData align="center">
                                    <div className={styles.status}>
                                        <WorkOrderBadge status={wo.status} />
                                    </div>
                                </T.TableBodyData>
                            </T.TableBodyRow>
                        ))}
                    </T.TableBody>
                </T.Table>
            </TableContainer>
        </div>
    )
}

export default ActiveWorkOrdersTable
