import { ReactComponent as NotificationError } from './notificationError.svg'
import { ReactComponent as NotificationSuccess } from './notificationSuccess.svg'
import { ReactComponent as NotificationWarning } from './notificationWarning.svg'
import { ReactComponent as NotificationInfo } from './notificationInfo.svg'
import { ReactComponent as CheckBoxTrue } from './checkBoxTrue.svg'
import { ReactComponent as CheckBoxFalse } from './checkBoxFalse.svg'
import { ReactComponent as CloseBtn } from './closeBtn.svg'
import { ReactComponent as ImagePlaceHolder } from './imagePlaceHolder.svg'
import { ReactComponent as CardsView } from './cardsView.svg'
import { ReactComponent as TableView } from './tableView.svg'
import { ReactComponent as TableExport } from './tableExport.svg'
import { ReactComponent as TablePrint } from './tablePrint.svg'
import { ReactComponent as TableSettings } from './tableSettings.svg'
import { ReactComponent as ViewGoldStar } from './viewGoldStar.svg'
import { ReactComponent as ViewGreyStar } from './viewGreyStar.svg'
import { ReactComponent as Download } from './download.svg'
import { ReactComponent as OpenWorkOrder } from './openWorkOrder.svg'
import { ReactComponent as CompletedWorkOrder } from './completedWorkOrders.svg'
import { ReactComponent as PastDueWorkOrder } from './pastDueWorkOrder.svg'
import { ReactComponent as PendingWorkOrder } from './pendingWorkOrder.svg'
import { ReactComponent as AssignedPM } from './assignedPM.svg'
import { ReactComponent as BurgerMenu } from './burgerMenu.svg'
import { ReactComponent as Edit } from './edit.svg'
import { ReactComponent as Trash } from './trash.svg'
import { ReactComponent as RedTrash } from './red-trash.svg'
import { ReactComponent as BurgerFile } from './burgerFile.svg'
import { ReactComponent as Building } from './building.svg'
import { ReactComponent as Message } from './message.svg'
import { ReactComponent as Next } from './navigateNext.svg'
import { ReactComponent as navDoc } from './navbarDoc.svg'
import { ReactComponent as Emergency } from './emergency.svg'
import { ReactComponent as RedTools } from './redTools.svg'
import { ReactComponent as RedCalendar } from './redCalendar.svg'
import { ReactComponent as DotsSVG } from './option.svg'
import { ReactComponent as SaveSVG } from './save.svg'
import { ReactComponent as EyeViewSVG } from './eye-view.svg'
import { ReactComponent as PrintSVG } from './print.svg'
import { ReactComponent as BuildSVG } from './build.svg'
import { ReactComponent as InfoCircleSVG } from './info-circle.svg'
import { ReactComponent as AddPlusSVG } from './add-plus.svg'
import { ReactComponent as NoteSVG } from './note.svg'
import { ReactComponent as TwoNotesSVG } from './two-notes.svg'
import { ReactComponent as ImportFileSVG } from './import-file.svg'
import { ReactComponent as SearchSVG } from './search.svg'
import { ReactComponent as FilterSVG } from './filter.svg'
import { ReactComponent as sidebarArrowLeft } from './sideBarArrowsLeft.svg'
import { ReactComponent as sidebarArrowRight } from './sideBarArrowsRight.svg'
import { ReactComponent as PDFDocListSVG } from './docs.svg'
import { ReactComponent as PDFileSVG } from './pdf.svg'
import { ReactComponent as RoomListSVG } from './room.svg'
import { ReactComponent as FloorListSVG } from './floor.svg'
import { ReactComponent as DetailsSVG } from './details.svg'
import { ReactComponent as RenameSVG } from './rename.svg'
import { ReactComponent as AddLayerSVG } from './addRoom.svg'
import { ReactComponent as NoteEnterSVG } from './note-enter.svg'
import { ReactComponent as NoteSendSVG } from './note-send.svg'
import { ReactComponent as NotFoundSVG } from './not_found.svg'
import { ReactComponent as DoneSVG } from './done.svg'
import { ReactComponent as AlertSVG } from './create-system-alert.svg'
import { ReactComponent as BellSVG } from './bell.svg'
import { ReactComponent as UserSVG } from './user.svg'
import { ReactComponent as UserIconSVG } from './user-icon.svg'
import { ReactComponent as CrossSVG } from './cross.svg'
import { ReactComponent as CriticalIconSVG } from './critical.svg'
import { ReactComponent as QrCodeIconSVG } from './QrCode.svg'
import { ReactComponent as EmptyDataSmileSVG } from './empty-smile.svg'
import { ReactComponent as CalendarSVG } from './calendar.svg'
import { ReactComponent as ContactUserSVG } from './contact-user.svg'
import { ReactComponent as ApplySVG } from './apply.svg'
import { ReactComponent as ActivateSVG } from './activate.svg'
import { ReactComponent as DeactivateSVG } from './deactivate.svg'
import { ReactComponent as UsersSVG } from './users.svg'
import { ReactComponent as TimeBlueSVG } from './time-blue.svg'
import { ReactComponent as BlueDownloadSVG } from './blue-download.svg'
import { ReactComponent as UsdSVG } from './usd-icon.svg'
import { ReactComponent as TimeSVG } from './time.svg'
import { ReactComponent as DoubleTickBlueSVG } from './double-tick-blue.svg'
import { ReactComponent as SendToEmailSVG } from './send-to-email.svg'
import { ReactComponent as AllocatedInventorySVG } from './allocated-inventory.svg'
import { ReactComponent as AllocatedInventorySmallSVG } from './allocated-inventory-small.svg'
import { ReactComponent as RedSendSVG } from './red-send.svg'
import { ReactComponent as PercentSVG } from './percent.svg'
import { ReactComponent as PauseSVG } from './pause.svg'
import { ReactComponent as DeliverySVG } from './delivery.svg'
import { ReactComponent as LocationSVG } from './location.svg'

export { ReactComponent as DatePickerSVG } from './date-picker.svg'
export { ReactComponent as TimePickerSVG } from './time-picker.svg'
export { ReactComponent as AddTeamSVG } from './add-team.svg'
export { ReactComponent as ArchiveSVG } from './archive.svg'
export { ReactComponent as RoomMenuAddSVG } from './room-menu-add.svg'
export { ReactComponent as OneRoomMenuAddSVG } from './one-room-menu-add.svg'
export { ReactComponent as UserTableSVG } from './user-table.svg'
export { ReactComponent as BlueUserSVG } from './blue-user.svg'
export { ReactComponent as FolderCrossSVG } from './folder-cross.svg'
export { ReactComponent as RedBellSVG } from './red-bell.svg'
export { ReactComponent as ReplaceAssetSVG } from './replace-asset.svg'

const Icons = {
    ActivateSVG,
    DeactivateSVG,
    CrossSVG,
    DoneSVG,
    NotFoundSVG,
    NoteSendSVG,
    NoteEnterSVG,
    PDFileSVG,
    AddLayerSVG,
    RenameSVG,
    DetailsSVG,
    PDFDocListSVG,
    RoomListSVG,
    FloorListSVG,
    NotificationError,
    NotificationSuccess,
    NotificationWarning,
    NotificationInfo,
    CheckBoxTrue,
    CheckBoxFalse,
    CloseBtn,
    ImagePlaceHolder,
    CardsView,
    TableView,
    TablePrint,
    TableExport,
    TableSettings,
    ViewGoldStar,
    ViewGreyStar,
    Download,
    OpenWorkOrder,
    CompletedWorkOrder,
    PastDueWorkOrder,
    PendingWorkOrder,
    AssignedPM,
    BurgerMenu,
    Edit,
    Trash,
    BurgerFile,
    Building,
    Message,
    Next,
    navDoc,
    Emergency,
    RedTools,
    RedCalendar,
    DotsSVG,
    SaveSVG,
    EyeViewSVG,
    PrintSVG,
    BuildSVG,
    InfoCircleSVG,
    AddPlusSVG,
    NoteSVG,
    TwoNotesSVG,
    ImportFileSVG,
    SearchSVG,
    FilterSVG,
    sidebarArrowLeft,
    sidebarArrowRight,
    AlertSVG,
    BellSVG,
    UserSVG,
    UserIconSVG,
    CriticalIconSVG,
    QrCodeIconSVG,
    EmptyDataSmileSVG,
    CalendarSVG,
    RedTrash,
    ContactUserSVG,
    ApplySVG,
    UsersSVG,
    TimeBlueSVG,
    BlueDownloadSVG,
    UsdSVG,
    TimeSVG,
    DoubleTickBlueSVG,
    SendToEmailSVG,
    AllocatedInventorySVG,
    AllocatedInventorySmallSVG,
    RedSendSVG,
    PercentSVG,
    PauseSVG,
    DeliverySVG,
    LocationSVG,
}

export default Icons
