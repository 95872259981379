import { StoreRootState } from '..'
import { WorkOrderProfileTab } from 'pages/WorkOrderProfile/config'
import { WorkOrdersListPageTab } from 'pages/WorkOrdersPage/config'
import { CurrentWorkOrderType, InventoryInWoType, WorkOrderFiltersType, WorkOrderType } from 'slices/workOrder'
import { CommonSortType, ListViewMode } from 'types'
import { TableColumnType } from 'components/Table'
import { CommonCurrentViewType } from 'components/CommonSavedView'

export const currentWorkOrderSelect = (state: StoreRootState): CurrentWorkOrderType => state.workOrders.currentWorkOrder
export const isLoadingWorkOrderSelect = (state: StoreRootState): boolean => state.workOrders.isWaitingForResponse
export const workOrderProfileTabSelect = (state: StoreRootState): WorkOrderProfileTab =>
    state.workOrders.workOrderProfileTab
export const workOrderSearchValueSelect = (state: StoreRootState): string => state.workOrders.workOrdersSearchValue
export const workOrderCurrentPageSelect = (state: StoreRootState): number => state.workOrders.workOrderPaginationPage
export const workOrdersCountSelect = (state: StoreRootState): number => state.workOrders.workOrdersCount
export const workOrderListTabSelect = (state: StoreRootState): WorkOrdersListPageTab =>
    state.workOrders.workOrderListTab
export const workOrdersSelect = (state: StoreRootState): WorkOrderType[] => state.workOrders.workOrders
export const workOrderListModeSelect = (state: StoreRootState): ListViewMode =>
    state.workOrders.workOrdersSettings.listMode
export const workOrdersColumnsSelect = (state: StoreRootState): TableColumnType[] =>
    state.workOrders.workOrdersSettings.columns
export const workOrdersFiltersSelect = (state: StoreRootState): WorkOrderFiltersType | null =>
    state.workOrders.workOrdersSettings.filters
export const workOrdersCurrentViewSelect = (state: StoreRootState): CommonCurrentViewType => ({
    id: state.workOrders.workOrdersSettings.currentViewId!,
    currentViewTitle: state.workOrders.workOrdersSettings.currentViewTitle!,
    isSystemView: state.workOrders.workOrdersSettings.isSystemCurrentView!,
})

export const workOrdersSortSelect = (state: StoreRootState): CommonSortType => ({
    sortField: state.workOrders.workOrdersSettings.sortField,
    sortDirection: state.workOrders.workOrdersSettings.sortDirection,
})
export const workOrdersInventoryItems = (state: StoreRootState): InventoryInWoType =>
    state.workOrders.inventoryItemsInWo
