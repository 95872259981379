import { Slide, toast, ToastOptions } from 'react-toastify'
import ToastNotification from './../../components/ToastNotification/ToastNotification'
import { NOTIFICATION_STATUS } from '../../types'

const sendNotification = (message: string, type: NOTIFICATION_STATUS, autoClose = 5000 as number | false) => {
    const toastOptions: ToastOptions = {
        position: 'top-center',
        autoClose: autoClose,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        progress: undefined,
        transition: Slide,
        rtl: false,
        closeButton: true,
    }

    const item = <ToastNotification text={message} type={type} />
    toast(item, toastOptions)
    toast.clearWaitingQueue()
}

export const sendErrorNotification = (e: unknown) => {
    sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
}

export const clearAllNotifications = () => {
    toast.dismiss()
}

export default sendNotification
