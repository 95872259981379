import styles from './styles.module.scss'
import TenantLeaseForm from '../../../../../TenantLeaseForm'
import { useFormik } from 'formik'
import { LeaseForm } from '../../../../../../types'
import { initLeaseForm, leaseFormSchema } from '../../../../../../const'
import CloseButton from '../../../../../../../../components/CloseButton'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Paragraph } from '../../../../../../../../ui-kit/components/Typography/Typography'
import SaveCancelPanel from '../../../../../../../../components/SaveCancelPanel'
import { Lease, LeaseOptions, userAction } from '../../../../../../../../slices/users'
import { useAppDispatch } from '../../../../../../../../hooks'
import {
    createLeaseFiles,
    createTenantLease,
    deleteLeaseFile,
    updateTenantLease,
} from '../../../../../../../../api/tenant'
import sendNotification from '../../../../../../../../lib/notification'
import { CommonFileType, NOTIFICATION_STATUS } from '../../../../../../../../types'
import { noop } from '../../../../../../../../lib/common'
import StatusBadge from '../../../../../../../../components/StatusBadge'
import { getColorLeaseStatus } from '../TenantLeaseHistory/types'

type Props = {
    onClose: () => void
    lease: Lease | null
    tenantCompanyId?: string
    option: LeaseOptions | null
    update?: () => void
}

const TenantLeaseEditContainer = ({ onClose, lease, option, tenantCompanyId, update = noop }: Props) => {
    const dispatch = useAppDispatch()

    const [editOption, setEditOption] = useState<LeaseOptions | null>()
    const [filesLoaded, setFilesLoaded] = useState<Array<File>>([])
    const [filesDeleted, setFilesDeleted] = useState<Array<CommonFileType>>([])

    const leaseForm = useFormik<LeaseForm>({
        initialValues: initLeaseForm,
        onSubmit: async (valuesForm) => {
            const optionSubmit = editOption || option

            dispatch(userAction.setLoading(true))

            switch (optionSubmit) {
                case 'ADD': {
                    await createLease(valuesForm)
                    return
                }
                case 'RENEW': {
                    await updateLease(valuesForm, 'RENEW')
                    return
                }

                case 'EDIT': {
                    await updateLease(valuesForm, 'EDIT')
                    return
                }

                case 'RELOCATE': {
                    await updateLease(valuesForm, 'RELOCATE')
                    return
                }

                default:
                    return
            }
        },
        validationSchema: leaseFormSchema,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
    })

    const createLease = async (valuesForm: LeaseForm) => {
        const { building, region, room, ...sendLeaseValues } = valuesForm

        try {
            await dispatch(
                createTenantLease({
                    ...sendLeaseValues,
                    regionId: region?.value || null,
                    buildingId: building?.value || null,
                    roomId: room?.value || null,
                    tenantCompanyId,
                    files: filesLoaded,
                }),
            ).unwrap()

            onClose()
            update()
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
        dispatch(userAction.setLoading(false))
    }

    const updateLease = useCallback(
        async (valuesForm: LeaseForm, optionUpdate: 'END' | 'RENEW' | 'EDIT' | 'RELOCATE') => {
            if (!lease) {
                return
            }
            const { building, region, room, regionId, roomId, buildingId, ...sendLeaseValues } = valuesForm

            try {
                await dispatch(
                    updateTenantLease({
                        option: optionUpdate,
                        regionId: region?.value || null,
                        buildingId: building?.value || null,
                        roomId: room?.value || null,
                        leaseId: lease.id,
                        ...sendLeaseValues,
                    }),
                ).unwrap()

                if (filesLoaded.length > 0) {
                    await dispatch(
                        createLeaseFiles({
                            leaseId: lease.id,
                            files: filesLoaded,
                        }),
                    )
                }

                if (filesDeleted.length > 0) {
                    for (const file of filesDeleted) {
                        await dispatch(deleteLeaseFile(file.id))
                    }
                }

                onClose()
                update()
            } catch (e) {
                sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
            }
            dispatch(userAction.setLoading(false))
        },
        [lease, filesDeleted, filesLoaded],
    )

    const handleClose = () => {
        setEditOption(null)
        onClose()
    }

    const title = useMemo(() => {
        if (editOption === 'EDIT') {
            return 'Edit Lease'
        }

        switch (option) {
            case 'RENEW':
                return 'Renew Lease'
            case 'ADD':
                return 'Add New Lease'

            default:
                return 'View Lease'
        }
    }, [option, editOption])

    useEffect(() => {
        if (!lease) {
            return
        }

        leaseForm.setValues({
            region: {
                value: lease.region.id,
                label: lease.region.name,
            },
            regionId: lease.region.id,
            building: {
                value: lease.building.id,
                label: lease.building.name,
            },
            buildingId: lease.building.id,
            room: {
                value: lease.room.id,
                label: lease.room.name,
            },
            roomId: lease.room.id,
            leaseAmount: lease.leaseAmount,
            moveInDate: lease.moveInDate,
            leaseExpirationDate: lease.leaseExpirationDate,
            description: '',
            leaseFiles: null,
            files: null,
            leaseType: lease.leaseType,
            hasNewLeaseInformation: false,
        })
    }, [lease, option])

    return (
        <div className={styles.root}>
            <CloseButton onClose={handleClose} />

            <div className={styles.formContent}>
                <div className={styles.header}>
                    <Paragraph size="md" weight="semi-bold" position="center">
                        {title}
                    </Paragraph>

                    {lease && <StatusBadge color={getColorLeaseStatus(lease.leaseStatus)} text={lease.leaseStatus} />}
                </div>

                <TenantLeaseForm
                    form={leaseForm}
                    option={editOption || option}
                    setFilesLoaded={setFilesLoaded}
                    setFilesDeleted={setFilesDeleted}
                    filesLease={lease?.files}
                />
            </div>

            <div className={styles.saveWrap}>
                {(editOption === 'EDIT' || (option !== 'VIEW' && option !== 'VERSION')) && (
                    <SaveCancelPanel onSave={() => leaseForm.handleSubmit()} onCancel={handleClose} />
                )}
                {option === 'VIEW' && !editOption && (
                    <SaveCancelPanel saveButtonText="Edit" onSave={() => setEditOption('EDIT')} />
                )}
            </div>
        </div>
    )
}

export default TenantLeaseEditContainer
