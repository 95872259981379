import styles from './styles.module.scss'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'

export type RoutesTabPanelType = {
    label: string
    link: string
}

type Props = {
    tabs: Array<RoutesTabPanelType>
}

const RoutesTabPanel = ({ tabs }: Props) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    return (
        <div className={styles.container}>
            <div className={styles.tabPanelRoot}>
                {tabs.map((tab) => (
                    <div
                        key={tab.link}
                        className={classNames(styles.tabPanelButton, pathname.includes(tab.link) && styles.active)}
                        onClick={() => navigate('./' + tab.link)}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RoutesTabPanel
