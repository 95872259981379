import { createAsyncThunk } from '@reduxjs/toolkit'
import { axiosErrorHandler, client } from './setupAxios'
import { EmergencyReportGetPlanEnum } from '../slices/emergency'

export const getMyStepEmergencyReport = createAsyncThunk(
    'emergency/getMyStepEmergencyReport',
    async (_, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/emergency-report/my-unfilled`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const incrDecrReportEmergency = createAsyncThunk(
    'emergency-report/incrDecrReportEmergency',
    async (type: 'increment' | 'decrement', { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-report/increment-decrement`, {
                type,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const changeRegionReportEmergency = createAsyncThunk(
    'emergency-report/changeRegionReportEmergency',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-report/change-region`, {
                regionId: id,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const changeBuildingReportEmergency = createAsyncThunk(
    'emergency-report/changeBuildingReportEmergency',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-report/change-building`, {
                buildingId: id,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const changeFloorReportEmergency = createAsyncThunk(
    'emergency-report/changeFloorReportEmergency',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-report/change-floor`, {
                floorId: id,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const changeRoomReportEmergency = createAsyncThunk(
    'emergency-report/changeRoomReportEmergency',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-report/change-room`, {
                roomId: id,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const addAssetReportEmergency = createAsyncThunk(
    'emergency-report/addAssetReportEmergency',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-report/add-asset`, {
                assetId: id,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const detachAssetReportEmergency = createAsyncThunk(
    'emergency-report/detachAssetReportEmergency',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-report/detach-asset`, {
                assetId: id,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type ChangeTitleDescriptionEmergency = {
    title: string
    description: string
}

export const changeTitleDescriptionEmergency = createAsyncThunk(
    'emergency-report/changeTitleDescriptionEmergency',
    async (data: ChangeTitleDescriptionEmergency, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-report/change-title-description`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getEmergencyReportAssets = createAsyncThunk(
    'emergency/getEmergencyReportAssets',
    async (_, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/emergency-report/assets`, {
                params: {
                    offset: 0,
                    limit: 100,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getEmergencyReportPlans = createAsyncThunk(
    'emergency/getEmergencyReportPlans',
    async (status: EmergencyReportGetPlanEnum, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/emergency-report/plans`, {
                params: {
                    offset: 0,
                    limit: 100,
                    status,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
