import styles from './styles.module.scss'
import { NOTIFICATION_STATUS, USER_ROLE } from '../../../../types'
import { useFormik } from 'formik'
import { CreateUserForm, initUserCreateForm, selectTypeData, UserCreateSchema } from '../../const'
import { userAction } from '../../../../slices/users'
import sendNotification from '../../../../lib/notification'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { Paragraph } from '../../../../ui-kit/components/Typography/Typography'
import CloseButton from '../../../../components/CloseButton'
import ImageLoader from '../../../../components/ImageLoader'
import React, { useEffect, useMemo, useState } from 'react'
import CustomSelect from '../../../../ui-kit/components/CustomSelect'
import { selectRoleData } from '../../helpers'
import CustomInput from '../../../../ui-kit/components/CustomInput'
import ReactInputMask from 'react-input-mask'
import {
    BuildingAutoComplete,
    CommonAutoComplete,
    ItemForAutocompleteType,
    RegionAutoComplete,
    RoomAutoComplete,
    TeamAutoComplete,
} from '../../../../components/CommonAutoComplete'
import { getAllRegionsForAutocomplete } from '../../../../api/regions'
import SaveCancelPanel from '../../../../components/SaveCancelPanel'
import { createInvitation } from '../../../../api/user'
import FilesDropzoneLoader from '../../../../components/FilesDropzoneLoader'
import { currentCustomerId, permissionsUser } from '../../../../store/selectors'

type Props = {
    role: USER_ROLE
    onClose: () => void
    update: () => Promise<void>
    customerId?: string
    tenantCompanyId?: string
}
const UserCreateForm = ({ role, onClose, update, customerId, tenantCompanyId }: Props) => {
    const dispatch = useAppDispatch()
    const customerIdCurrent = useAppSelector(currentCustomerId)

    const { errors, getFieldProps, values, setFieldValue, handleSubmit } = useFormik<CreateUserForm>({
        initialValues: initUserCreateForm,
        onSubmit: async (valuesForm) => {
            const { avatarFile, buildingsIds, bucketsId, ...sendValues } = valuesForm
            dispatch(userAction.setLoading(true))

            try {
                await dispatch(
                    createInvitation({
                        ...sendValues,
                        buildingAssignments: buildingsIds ? buildingsIds?.map((item) => item.value) : null,
                        buckets: bucketsId ? bucketsId?.map((item) => item.value) : null,
                        avatar: avatarFile,
                        isTenant: false,
                        initialSetup: false,
                        documents: filesLoaded,
                        customerId: customerId || customerIdCurrent || undefined,
                        tenantCompanyId,
                    }),
                ).unwrap()

                onClose()
                await update()
            } catch (e) {
                sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
            }
            dispatch(userAction.setLoading(false))
        },
        validationSchema: UserCreateSchema,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
    })

    const [filesLoaded, setFilesLoaded] = useState<Array<File>>([])

    const [regionOutside, setRegionOutside] = useState<ItemForAutocompleteType | null>(null)

    const selectTypeValues = useMemo(() => {
        const typeValues = selectTypeData[tenantCompanyId ? 'TENANT' : role]
        return typeValues || []
    }, [role])

    const onChangeRegion = (item: ItemForAutocompleteType) => {
        setRegionOutside(item)
        setFieldValue('regionId', item?.value)
    }

    const handleDeleteTeamsClick = (id: string) => {
        setFieldValue(
            'bucketsId',
            values.bucketsId?.filter((item) => item?.value !== id),
        )
    }

    const handleDeleteBuildingsClick = (id: string) => {
        setFieldValue(
            'buildingsIds',
            values.buildingsIds?.filter((item) => item?.value !== id),
        )
    }

    useEffect(() => {
        setFieldValue('role', role)
    }, [role])

    useEffect(() => {
        if (role === USER_ROLE.TECHNICIAN) {
            setFieldValue('isHourlyRate', true)
            setFieldValue('isBuildings', false)
            setFieldValue('isBuckets', true)
        }

        if (role === USER_ROLE.SUPERVISOR) {
            setFieldValue('isBuildings', false)
            setFieldValue('isBuckets', true)
        }

        if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPERADMIN) {
            setFieldValue('isRegion', false)
            setFieldValue('regionId', null)

            setFieldValue('isBuildings', false)
            setFieldValue('buildingsIds', undefined)
        }

        if (tenantCompanyId) {
            setFieldValue('isRoom', true)
        }
    }, [role])

    useEffect(() => {
        if (selectTypeValues.length > 0) {
            setFieldValue('type', selectTypeValues[0].value)
        }
    }, [selectTypeValues, tenantCompanyId])

    return (
        <div className={styles.root}>
            <CloseButton onClose={onClose} />

            <div className={styles.formContent}>
                <Paragraph size="md" weight="semi-bold" position="center">
                    Add User
                </Paragraph>

                <ImageLoader
                    onChange={(file, url) => {
                        setFieldValue('avatarUrl', url)
                        setFieldValue('avatarFile', file)
                    }}
                    imgSrc={values.avatarUrl}
                />

                {!tenantCompanyId && (
                    <CustomSelect
                        errorText={errors.role}
                        disabled={true}
                        label="User Role"
                        values={selectRoleData}
                        value={values.role || ''}
                        onChange={(val) => setFieldValue('role', val)}
                        background="#E2E6EB"
                    />
                )}

                <CustomSelect
                    errorText={errors.type}
                    label="User Type"
                    values={selectTypeValues}
                    value={values.type || ''}
                    onChange={(val) => setFieldValue('type', val)}
                    background="#E2E6EB"
                />

                <div className={styles.flexInputRow}>
                    <CustomInput
                        errorText={errors.firstName}
                        label={'First name'}
                        id="firstName"
                        {...getFieldProps('firstName')}
                    />
                    <CustomInput
                        errorText={errors.lastName}
                        label={'Last name'}
                        id="lastName"
                        {...getFieldProps('lastName')}
                    />
                </div>
                <CustomInput errorText={errors.title} label={'Title'} id="title" {...getFieldProps('title')} />
                <CustomInput
                    errorText={errors.email}
                    label={'Email Address'}
                    id="email"
                    type="email"
                    {...getFieldProps('email')}
                />

                <ReactInputMask
                    maskPlaceholder={null}
                    alwaysShowMask={true}
                    mask="+1 999-999-9999"
                    value={values.phone}
                    onChange={(e) => setFieldValue('phone', e.target.value)}
                >
                    <CustomInput errorText={errors.phone} label={'Phone'} id="phone" />
                </ReactInputMask>
                {values.isHourlyRate && (
                    <CustomInput
                        endAdornment={
                            <Paragraph size="s" weight="semi-bold" color="grey">
                                USD/Hour
                            </Paragraph>
                        }
                        errorText={errors.hourlyRate}
                        label={'Hourly Rate'}
                        id="hourlyRate"
                        {...getFieldProps('hourlyRate')}
                    />
                )}

                {values.isRegion && (
                    <RegionAutoComplete
                        onChangeCallback={onChangeRegion}
                        errorText={errors.regionId}
                        label={'Region'}
                        currentValue={regionOutside}
                        idDependence={{ tenantCompanyId, hasActiveLeases: tenantCompanyId ? true : undefined }}
                        textFieldClassName={styles.autocomplete}
                    />
                )}

                {values.isBuckets && (
                    <TeamAutoComplete
                        currentValue={values?.bucketsId ?? []}
                        onChangeCallback={(items: ItemForAutocompleteType[]) => setFieldValue('bucketsId', items)}
                        textFieldClassName={styles.autocomplete}
                        label={'WO team(s)'}
                        errorText={errors.bucketsId}
                        multiple
                        idDependence={values.regionId ? { regionId: values?.regionId } : undefined}
                        onDeleteItemClick={handleDeleteTeamsClick}
                    />
                )}

                {(values.isBuildings || role === 'SUPERVISOR') && (
                    <>
                        {!tenantCompanyId ? (
                            <BuildingAutoComplete
                                currentValue={values?.buildingsIds ?? []}
                                onChangeCallback={(items: ItemForAutocompleteType[]) =>
                                    setFieldValue('buildingsIds', items)
                                }
                                textFieldClassName={styles.autocomplete}
                                label={'Building Assignments'}
                                errorText={errors.buildingsIds}
                                multiple
                                idDependence={values.regionId ? { regionIdes: [values?.regionId] } : undefined}
                                onDeleteItemClick={handleDeleteBuildingsClick}
                            />
                        ) : (
                            <BuildingAutoComplete
                                currentValue={values?.buildingsIds?.at(0) ?? null}
                                onChangeCallback={(item: ItemForAutocompleteType) =>
                                    setFieldValue('buildingsIds', item ? [item] : [])
                                }
                                textFieldClassName={styles.autocomplete}
                                label={'Building Assignments'}
                                errorText={errors.buildingsIds}
                                idDependence={
                                    values.regionId
                                        ? { regionIdes: [values?.regionId], tenantCompanyId, hasActiveLeases: true }
                                        : undefined
                                }
                            />
                        )}
                    </>
                )}

                {values.isRoom && (
                    <RoomAutoComplete
                        currentValue={values?.room ?? null}
                        onChangeCallback={(items: ItemForAutocompleteType) => setFieldValue('room', items)}
                        textFieldClassName={styles.autocomplete}
                        label={'Room'}
                        errorText={errors.room}
                        idDependence={
                            values.buildingsIds
                                ? {
                                      tenantCompanyId,
                                      buildingIdes: values.buildingsIds.map((item) => item.value),
                                      hasActiveLeases: tenantCompanyId ? true : undefined,
                                  }
                                : undefined
                        }
                    />
                )}

                <Paragraph className={styles.documentsTitle} size="s" weight="semi-bold">
                    Documents
                </Paragraph>
                <FilesDropzoneLoader onLoadFiles={setFilesLoaded} />

                <CustomInput label={'Note'} id="noteText" {...getFieldProps('noteText')} multiline rows={4} />
            </div>

            <div className={styles.saveWrap}>
                <SaveCancelPanel onSave={() => handleSubmit()} onCancel={onClose} />
            </div>
        </div>
    )
}

export default UserCreateForm
