import { AssetProfileTab } from 'pages/AssetProfile/config'
import { Note } from 'slices/building'
import { AssetsListPageTab } from 'pages/AssetsPage/config'
import { AssetPoint } from 'slices/floorPlanViever'
import { ItemForAutocompleteType } from 'components/CommonAutoComplete'
import { CommonType, TableSettingsType } from 'types'
import { PriorityWO, TypeWO, WorkOrderStatus } from 'components/NewWorkOrder/config'

export enum AssetGetByEntityAttributes {
    SERIAL_NUMBER = 'SERIAL_NUMBER',
    EQUIPMENT_ID = 'EQUIPMENT_ID',
    MODEL = 'MODEL',
    IS_CRITICAL = 'IS_CRITICAL',
    LABOR_VALUE = 'LABOR_VALUE',
    COST = 'COST',
    IS_NEW = 'IS_NEW',
    MANUFACTURER = 'MANUFACTURER',
    INSTALL_DATE = 'INSTALL_DATE',
    ASSET_AGE = 'ASSET_AGE',
    DESCRIPTION = 'DESCRIPTION',
    PAGES_COUNT = 'PAGES_COUNT',
    EMERGENCY_PLANS_COUNT = 'EMERGENCY_PLANS_COUNT',
    HAS_PLANS = 'HAS_PLANS',
    HAS_EMERGENCY_PLANS = 'HAS_EMERGENCY_PLANS',
}

export enum AssetGetByEntityInclude {
    QR_CODE = 'QR_CODE',
    FEEDS_TO = 'FEEDS_TO',
    FED_FROM = 'FED_FROM',
    PROPS = 'PROPS',
    PURCHASES = 'PURCHASES',
    NOTES = 'NOTES',
    FILES = 'FILES',
    TYPE = 'TYPE',
    CATEGORY = 'CATEGORY',
    ROOM = 'ROOM',
    FLOOR = 'FLOOR',
    BUILDING = 'BUILDING',
    REGION = 'REGION',
    WORK_ORDER = 'WORK_ORDER',
    CREATOR = 'CREATOR',
    AVATAR = 'AVATAR',
}

export enum SaveKeyAddingFile {
    ASSET_FILE = 'assetFileId',
    ASSET_MOP = 'assetMopId',
}

export enum FedType {
    FROM = 'from',
    TO = 'to',
}

export type GetAssetsByEntityType = {
    floorIdes?: string[]
    roomIdes?: string[]
    buildingIdes?: string[]
    regionIdes?: string[]
    categoryIdes?: string[]
    typeIdes?: string[]
    offset?: number
    limit?: number
    searchString?: string
    isReturnAll?: boolean
    includeCriteria?: AssetGetByEntityInclude[]
    isRequiredInclude?: boolean
    attributeCriteria?: AssetGetByEntityAttributes[]
    unassignedForPage?: string
}
export type CommonPaginationType = {
    page: number
    limit: number
}

export type CommonAssetType = CommonType & {
    category: CommonType & {
        file: string | null
    }
}

export type CategoryInAssetType = CommonType & {
    color: string
    customerId: string
    inventoryItemsCount: number
    file: QrCodeInAssetType | null
}

export type AdditionalPropsType = CommonType & {
    type: string
    isBasic: boolean
}

export type PropInAssetType = {
    assetId: string
    assetPropId: string
    id: string
    value: string
    assetProp: AdditionalPropsType
}

export type QrCodeInAssetType = CommonType & {
    url: string
    mimetype: string
    size: number
    width: number | null
    height: number | null
    createdAt: Date
}

export type TypesAssetType = CommonType & {
    categoryId: string
    customerId: string
    inventoryItemsCount: number
}

export type RegionInAssetType = CommonType & {
    description: null
    customerId: string
    creationDate: Date
    lastUpdateDate: Date
    deletedAt: null
}

export type BuildingInAssetType = CommonType & {
    type: string
    address: string
    avatar: QrCodeInAssetType | null
}

export type FloorInAssetType = CommonType & {
    type: null
    label: string
    descriptions: string
    buildingId: string
    creationDate: Date
    lastUpdateDate: Date
    deletedAt: null
}

export type RoomInAssetType = CommonType & {
    creationDate: Date
    deletedAt: null
    descriptions: string
    floorId: string
    lastUpdateDate: Date
}

export type LocationAssetType = {
    building: BuildingInAssetType | null
    floor: FloorInAssetType | null
    room: RoomInAssetType | null
    region: RegionInAssetType | null
}

export type PropsAssetType = CommonType & {
    assetPropsAnswers: PropInAssetType[]
}

export type FeedFromType = {
    id: string
    to: CommonType & {
        category: CategoryInAssetType
    }
}

export type FeedToType = {
    id: string
    from: CommonType & {
        category: CategoryInAssetType
    }
}
export type IAsset = CommonType & {
    assetAge: number
    hasPlans: boolean
    hasEmergencyPlans: boolean
    serialNumber: string
    model: string
    isCritical: boolean
    laborValue: number
    cost: number
    isNew: boolean
    manufacturer: string
    installDate: Date
    description: string
    pagesCount: number
    emergencyPlansCount: number
    equipmentId: string
    category?: CategoryInAssetType
    types?: TypesAssetType
    qrCode?: QrCodeInAssetType
    building?: BuildingInAssetType
    floor?: FloorInAssetType
    region: RegionInAssetType
    workOrders: WorkOrderInAssetType[]
}

export type WorkOrderInAssetType = {
    title: string
    number: number
    id: string
    type: TypeWO
    priority: PriorityWO
    status: WorkOrderStatus
    WorkOrderAsset: {
        id: string
        workOrderId: string
        assetId: string
        creationDate: Date
        lastUpdateDate: Date
    }
}

export type CurrentAssetType = IAsset & {
    category: CategoryInAssetType
    types: TypesAssetType
    qrCode: QrCodeInAssetType
    avatar: QrCodeInAssetType | null
}

export type AffectedAreaInAssetType = {
    id: string
    assetId: string
    createdAt: Date
    building: CommonType
}

export type PlanInAssetPageType = CommonType & {
    floor: CommonType
    planTypes: CommonType
}

export type AssetPageType = {
    childFile: QrCodeInAssetType
    id: string
    page: number
    plan: PlanInAssetPageType
    rootFile: QrCodeInAssetType
}

export type PointOnPageInAssetType = {
    color: '#FFC107'
    from: CommonType & {
        category: CategoryInAssetType & { file: null }
        types: TypesAssetType
    }
    fromId: string
    fromX: number
    fromY: number
    id: string
    pageId: string
    to: number | null
    toId: string | null
    toX: number | null
    toY: number | null
    type: string
}

export type SelectedPlanPageType = string

export type AssetFiltersType = {
    assetCategoriesId?: string[]
    assetTypesId?: string[]
    regions?: ItemForAutocompleteType[]
    buildings?: ItemForAutocompleteType[]
    installDateFrom?: Date | null
    installDateTo?: Date | null
    minPrice?: number
    maxPrice?: number
    isShowArchived?: boolean
    hasInventory?: boolean // TODO: add to reports and fetching from saved view
    isCritical?: boolean
    hasPlan?: boolean // TODO: add to reports and fetching from saved view
}

export type AssetsState = {
    currentAsset: CurrentAssetType
    assets: IAsset[]
    isWaitingForResponse: boolean
    isWaitingFloorPlan: boolean
    assetProfileTab: AssetProfileTab
    notesCurrentAsset: Note[]
    propsCurrentAsset: PropInAssetType[]
    locationCurrentAsset: LocationAssetType
    affectedAreaCurrentAsset: AffectedAreaInAssetType[]
    feedToCurrentAsset: FeedToType[]
    feedFromCurrentAsset: FeedFromType[]
    assetListTab: AssetsListPageTab
    assetPaginationPage: number
    assetsCount: number
    assetsSearchValue: string
    assetPages: AssetPageType[]
    selectedPlanPage: AssetPageType | null
    assetsPointsOnPlan: AssetPoint[]
    isNeedUpdatePoints: boolean
    assetsSettings: TableSettingsType<AssetFiltersType>
    categoriesThree: CategoryThreeType[]
}

export type GetAssetByIdType = {
    id: string
    includeCriteria?: AssetGetByEntityInclude[]
    attributeCriteria?: AssetGetByEntityAttributes[]
}

export type GetFeedByAssetType = CommonPaginationType & {
    fedType: FedType
}

export type CurrentAffectedAreaType = AffectedAreaInAssetType & {
    floors: { floor: CommonType; id: string }[]
    rooms: { room: CommonType; id: string }[]
    assets?: { asset: CommonAssetType | null; id: string | null }[]
}

export type EditAffectedAreaType = {
    buildingId: string
    floorIdes?: string[]
    roomIdes?: string[]
    assetIdes?: string[]
}

export type GetAssetFilesType = {
    id: string
    getKey: SaveKeyAddingFile
    limit: number
    offset: number
}

export type CategoryThreeType = CategoryInAssetType & { types: TypeInCategoryType[] }

export type TypeInCategoryType = CommonType & {
    inventoryItemsCount: number
    assetsCount: number
    newCount: number
    customerId: string | null
}
