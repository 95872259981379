import styles from './styles.module.scss'
import classNames from 'classnames'
import { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export type PageTabType = {
    id: string
    label: string
}

type Props = {
    tabs: Array<PageTabType>
    activeTab: string
    onChangeTab: (val: string) => void
}
const PageTabPanel: FC<Props> = ({ tabs, activeTab, onChangeTab }) => {
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (searchParams.get('tab') === null) {
            searchParams.set('tab', activeTab)
            setSearchParams(searchParams, { replace: true })
            return
        }

        if (searchParams.get('tab') !== activeTab) {
            onChangeTab(searchParams.get('tab') as string)
            return
        }
    }, [])

    useEffect(() => {
        searchParams.set('tab', activeTab)
        setSearchParams(searchParams, { replace: true })
    }, [activeTab])

    return (
        <div className={styles.container}>
            <div className={styles.tabPanelRoot}>
                {tabs.map((tab) => (
                    <div
                        key={tab.id}
                        className={classNames(styles.tabPanelButton, activeTab === tab.id && styles.active)}
                        onClick={() => onChangeTab(tab.id)}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PageTabPanel
