import { client, axiosErrorHandler } from './setupAxios'
import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { IdDependenceType } from 'components/CommonAutoComplete'
import { BUILDS_PER_PAGE } from 'pages/BuildingsPage/helpers'
import { DirectionEnum } from 'types'

export const getAllRegions = createAppAsyncThunk('assets/getAllRegions', async (_, { getState, rejectWithValue }) => {
    const params = {
        size: BUILDS_PER_PAGE,
        page: getState().region.regionPaginationPage || 1,
        value: getState().region.searchRegionValue || '',
        customerId: getState().auth.user?.customerId || '',
        sortField: 'id',
        sortDirection: DirectionEnum.ASC,
    }

    try {
        const result = await client.get(`/api/regions/get-regions-by-customer-id`, { params })
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getAllRegionsForAutocomplete = createAppAsyncThunk<
    any,
    { page: number; search: string; customerId?: string; idDependence?: IdDependenceType }
>(
    'regions/get-all-regions-for-autocomplete',
    async ({ page, search, customerId, idDependence }, { getState, rejectWithValue }) => {
        const params = {
            size: BUILDS_PER_PAGE,
            page: page,
            keySearchValue: search,
            customerId: customerId ?? (getState().auth.user?.customerId || ''),
            sortField: 'name',
            sortDirection: DirectionEnum.ASC,
            ...idDependence,
        }

        try {
            const result = await client.get(`/api/regions/get-regions-by-customer-id`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreateRegion = {
    name: string
    description?: string
    customerId: string
}

export const createRegion = createAppAsyncThunk(
    'regions/createRegion',
    async (data: CreateRegion, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/regions/create-region`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type UpdateRegion = {
    name: string
    description?: string
    regionId: string
}

export const updateRegion = createAppAsyncThunk(
    'regions/updateRegion',
    async (data: UpdateRegion, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/regions/update-region`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteRegion = createAppAsyncThunk('regions/deleteRegion', async (id: string, { rejectWithValue }) => {
    try {
        const result = await client.delete(`/api/regions/delete-region`, {
            params: {
                regionId: id,
            },
        })

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})
