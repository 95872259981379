import styles from './styles.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks'
import { useFormik } from 'formik'
import { initTenantForm, TenantForm, tenantFormSchema } from '../../../../const'
import { userAction } from 'slices/users'
import React, { useEffect, useState } from 'react'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import CustomInput from 'ui-kit/components/CustomInput'
import SaveCancelPanel from 'components/SaveCancelPanel'
import TooltipMenu, { TooltipMenuItem } from '../../../../../../components/TooltipMenu'
import Icons, { ArchiveSVG } from '../../../../../../assets/icons'
import ImageLoader from '../../../../../../components/ImageLoader'
import { getTenantById, updateTenantCompany, uploadTenantAvatar } from '../../../../../../api/tenant'
import sendNotification from '../../../../../../lib/notification'
import { NOTIFICATION_STATUS } from '../../../../../../types'

const TenantProfileForm = () => {
    const dispatch = useAppDispatch()

    const [isEdit, setIsEdit] = useState(false)
    const selectedTenantProfile = useAppSelector(({ users }) => users.selectedTenantProfile)

    const { setValues, errors, getFieldProps, values, setFieldValue, handleSubmit } = useFormik<TenantForm>({
        initialValues: initTenantForm,
        onSubmit: async (valuesForm) => {
            if (!selectedTenantProfile) {
                return
            }

            const { avatarFile, ...sendValues } = valuesForm

            dispatch(userAction.setLoading(true))

            try {
                await dispatch(
                    updateTenantCompany({
                        ...sendValues,
                        tenantCompanyId: selectedTenantProfile.id,
                    }),
                ).unwrap()

                if (avatarFile) {
                    await dispatch(
                        uploadTenantAvatar({
                            tenantCompanyId: selectedTenantProfile.id,
                            avatar: avatarFile,
                        }),
                    ).unwrap()
                }

                await dispatch(getTenantById(selectedTenantProfile.id))
                setIsEdit(false)
            } catch (e) {
                sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
            }
            dispatch(userAction.setLoading(false))
        },
        validationSchema: tenantFormSchema,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
    })

    useEffect(() => {
        if (selectedTenantProfile) {
            setValues({
                ...(selectedTenantProfile as TenantForm),
                avatarUrl: selectedTenantProfile.avatar?.url,
            })
        }
    }, [selectedTenantProfile])

    return (
        <div className={styles.form}>
            <div className={styles.formContent}>
                <div className={styles.header}>
                    <div className={styles.nameWrap}>
                        {selectedTenantProfile && (
                            <>
                                <Paragraph size="md" weight="semi-bold">
                                    {selectedTenantProfile.name}
                                </Paragraph>

                                {/*<UserStatusBadge status={selectedTenantProfile.status} />*/}
                            </>
                        )}
                    </div>

                    <TooltipMenu>
                        <TooltipMenuItem icon={<Icons.Edit />} title="Edit" onClick={() => setIsEdit(true)} />

                        <TooltipMenuItem icon={<ArchiveSVG />} title={'Archive'} onClick={() => undefined} />
                    </TooltipMenu>
                </div>

                <ImageLoader
                    readOnly={!isEdit}
                    onChange={(file, url) => {
                        setFieldValue('avatarUrl', url)
                        setFieldValue('avatarFile', file)
                    }}
                    imgSrc={values.avatarUrl}
                />

                <CustomInput
                    disabled={!isEdit}
                    errorText={errors.name}
                    label={'Company name'}
                    id="name"
                    {...getFieldProps('name')}
                />
                <CustomInput
                    disabled={!isEdit}
                    errorText={errors.website}
                    label={'Website'}
                    id="website"
                    {...getFieldProps('website')}
                />
            </div>

            {isEdit && (
                <div className={styles.saveWrap}>
                    <SaveCancelPanel onSave={() => handleSubmit()} onCancel={() => setIsEdit(false)} />
                </div>
            )}
        </div>
    )
}

export default TenantProfileForm
