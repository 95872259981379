import styles from './styles.module.scss'
import NotesContainer from 'components/NotesContainer'
import { useAppSelector } from 'hooks'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import FilesTenantView from '../FilesTenantView'

const TenantFilesNotes = () => {
    const selectedTenantProfile = useAppSelector(({ users }) => users.selectedTenantProfile)

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <FilesTenantView />
            </div>
            {selectedTenantProfile && (
                <div className={styles.container}>
                    <Paragraph size="md" weight="semi-bold">
                        Notes
                    </Paragraph>
                    <NotesContainer
                        id={selectedTenantProfile?.id}
                        saveFieldName="tenantCompanyId"
                        height="calc(100% - 15px)"
                    />
                </div>
            )}
        </div>
    )
}

export default TenantFilesNotes
