import { client, axiosErrorHandler } from './setupAxios'
import { DirectionEnum, USER_TYPE } from 'types'
import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { User, UserStatus } from '../slices/users'
import { CreateUserForm } from '../pages/UsersTenantsPage/const'
import { BUILDS_PER_PAGE } from '../pages/BuildingsPage/helpers'
import { AbilitySettingForm } from '../pages/UsersTenantsPage/components/UserTenantProfile/components/UserSettings/config'
import { WorkOrderStatus } from '../components/NewWorkOrder/config'

interface createUserData {
    emailToken: string
    password: string
}

const createUser = createAppAsyncThunk(
    'users/createUser',
    async ({ emailToken, password }: createUserData, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/user/create-user`, {
                emailToken,
                password,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

const createInvitation = createAppAsyncThunk(
    'users/createInvitation',
    async (data: Partial<CreateUserForm> | { avatar: File | null }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/auth/send-invitation`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

const getUserById = createAppAsyncThunk('users/getUserById', async (id: string, { rejectWithValue }) => {
    try {
        const result = await client.get(`/api/user/get-user-by-id?id=${id}`)
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

interface getUsersListData {
    userRole?: string | string[]
    value?: string
    page?: number
    size?: number
    customerId?: string
    statuses?: UserStatus[]
    regionIdes?: string[]
    types?: USER_TYPE[]
    startDateAdded?: Date | null
    endDateAdded?: Date | null
    startLastActivity?: Date | null
    endLastActivity?: Date | null
    startLastLogin?: Date | null
    endLastLogin?: Date | null
    isArchived?: boolean
    tenantCompanyId?: string
}

const getUsersList = createAppAsyncThunk(
    'users/getUsers',
    async ({ value, size = 25, ...restParams }: getUsersListData, { rejectWithValue }) => {
        const params = {
            sortField: 'firstName',
            sortDirection: DirectionEnum.ASC,
            size,
            keySearchValue: value,
            ...restParams,
        }
        try {
            const result = await client.get(`/api/user/get-users-list`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

interface GetUsersListByRequesterRole {
    keySearchValue?: string
    page?: number
    size?: number
}

export const getUsersListByRequesterRole = createAppAsyncThunk(
    'users/getUsersListByRequesterRole',
    async ({ size = 25, ...restParams }: GetUsersListByRequesterRole, { rejectWithValue }) => {
        const params = {
            size,
            sortField: 'firstName',
            sortDirection: DirectionEnum.ASC,
            ...restParams,
        }
        try {
            const result = await client.get(`api/user/by-requester-role`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

const updateUser = createAppAsyncThunk(
    'users/updateUser',
    async (data: Partial<User> | { userId: string }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/user/update-user`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

const updateAvatar = createAppAsyncThunk('users/updateAvatar', async (data: any, { rejectWithValue }) => {
    try {
        const result = await client.post(`/api/user/upload-avatar`, data)
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

type UploadUserAvatar = {
    userId: string
    avatar: File
}

export const uploadUserAvatar = createAppAsyncThunk(
    'users/uploadUserAvatar',
    async (data: UploadUserAvatar, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/user/upload-avatar`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

interface updateStatusData {
    userId: string
    status: number
}

// const updateStatus = createAsyncThunk(
//     'users/updateStatus',
//     async ({userId, status}: updateStatusData, {rejectWithValue}) => {
//         try {
//             const result = await axios.patch(`${API_PATH}/users/change-user-status?userId=${userId}&status=${status}`);
//             return result.data;
//         } catch (e) {
//             return rejectWithValue(axiosErrorHandler(e));
//         }
//     }
// );

export const archiveUser = createAppAsyncThunk('users/archiveUser', async (id: string, { rejectWithValue }) => {
    try {
        const result = await client.delete(`/api/user/delete-user`, {
            params: { id },
        })
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const restoreUser = createAppAsyncThunk('users/restoreUser', async (id: string, { rejectWithValue }) => {
    try {
        const result = await client.patch(`/api/user/restore-user?id=${id}`)
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const resendInvitationUser = createAppAsyncThunk(
    'users/resendInvitationUser',
    async (email: string, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/auth/resend-invitation`, {
                email,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

interface GetUsersBuildingsList {
    userId: string
    //value?: string
    page?: number
    size?: number
}

export const getUsersBuildingsList = createAppAsyncThunk(
    'users/getUsersBuildingsList',
    async ({ page, size = 25, userId }: GetUsersBuildingsList, { rejectWithValue }) => {
        const params = {
            userId,
            sortField: 'id',
            sortDirection: DirectionEnum.ASC,
            page,
            size,
            //keySearchValue: value,
        }
        try {
            const result = await client.get(`/api/user/get-user-buildings`, { params })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type AddUserToBuilding = {
    userId: string
    buildingsId: string[]
}

export const addUserToBuilding = createAppAsyncThunk(
    'users/AddUserToBuilding',
    async (data: AddUserToBuilding, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/user/add-building-to-user`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type DeleteBuildingFromUser = {
    userId: string
    buildingId: string
}

export const deleteBuildingFromUser = createAppAsyncThunk(
    'users/deleteBuildingFromUser',
    async (params: DeleteBuildingFromUser, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/user/delete-building-from-user`, {
                params,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetUserFiles = {
    page: number
    userId: string
}

export const getUserFiles = createAppAsyncThunk(
    'users/getUserFiles',
    async (params: GetUserFiles, { getState, rejectWithValue }) => {
        try {
            const result = await client.get(`/api/user/files/get-user-files`, {
                params: {
                    ...params,
                    size: BUILDS_PER_PAGE,
                    sortField: 'id',
                    sortDirection: DirectionEnum.ASC,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreateUserFiles = {
    files: Array<File>
    userId: string
}

export const createUserFiles = createAppAsyncThunk(
    'users/createUserFiles',
    async (data: CreateUserFiles, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/user/files/create-user-file`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteUserFile = createAppAsyncThunk('users/deleteUserFile', async (id: string, { rejectWithValue }) => {
    try {
        const result = await client.delete(`/api/user/files/delete-user-file`, {
            params: {
                id,
            },
        })
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

type GetUserLoginHistory = {
    page: number
    userId: string
}

export const getUserLoginHistory = createAppAsyncThunk(
    'users/getUserLoginHistory',
    async (params: GetUserLoginHistory, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/user/get-users-login-history`, {
                params: {
                    ...params,
                    size: BUILDS_PER_PAGE,
                    sortField: 'id',
                    sortDirection: DirectionEnum.ASC,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetWorkOrdersListByEntity = {
    offset: number
    userId?: string
    tenantCompanyId?: string
    statuses?: Array<WorkOrderStatus>
}

export const getWorkOrdersListByEntity = createAppAsyncThunk(
    'users/getWorkOrdersListByEntity',
    async (params: GetWorkOrdersListByEntity, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/workOrders/get-workOrder-list-by-entity`, {
                params: {
                    ...params,
                    limit: BUILDS_PER_PAGE,
                    sortField: 'id',
                    sortDirection: DirectionEnum.ASC,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const updateUserAbilitySettingUser = createAppAsyncThunk(
    'users/updateUserAbilitySettingUser',
    async (data: AbilitySettingForm & { userId: string }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/user/ability-settings`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getUserSettings = createAppAsyncThunk(
    'users/getUserSettings',
    async (params: { userId: string }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/user/get-users-settings`, {
                params,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export { createUser, createInvitation, getUsersList, getUserById, updateUser, updateAvatar }
