import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { BUILDS_PER_PAGE } from 'pages/BuildingsPage/helpers'
import { client, axiosErrorHandler } from './setupAxios'
import { DirectionEnum } from 'types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RoomPoint } from 'slices/floorPlanViever'
import { IdDependenceType } from 'components/CommonAutoComplete'
import { RoomForm } from '../pages/BuildingProfile/components/RoomDetailForm/config'

export const getAllRoomsForAutocomplete = createAppAsyncThunk<
    any,
    { page: number; search: string; idDependence?: IdDependenceType }
>('assets/get-all-rooms-for-autocomplete', async ({ page, search, idDependence }, { getState, rejectWithValue }) => {
    const params = {
        size: BUILDS_PER_PAGE,
        page: page,
        keySearchValue: search ? search : undefined,
        sortField: 'name',
        sortDirection: DirectionEnum.ASC,
        ...idDependence,
    }

    try {
        const result = await client.get(`/api/rooms/get-rooms-by-entity`, { params })
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

type GetRoomsPointsByPageData = {
    pageId: string
}

//TODO: refactor

export const getRoomsPointsByPageId = createAsyncThunk(
    'rooms/GetRoomsPointsByPageData',
    async ({ pageId }: GetRoomsPointsByPageData, { rejectWithValue }) => {
        try {
            let rooms = [] as Array<RoomPoint>
            let count = 0
            let rowsCount = 0
            let isFirst = false

            while (rowsCount < count || !isFirst) {
                const result = await client.get(`/api/room/point/v2/with-rooms/${pageId}`, {
                    params: {
                        offset: rowsCount,
                        limit: 5,
                    },
                })

                count = result.data.count
                rooms = [...rooms, ...result.data.rooms]
                rowsCount = rooms.length
                isFirst = true
            }
            return {
                rooms,
            }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getRoomsIdesFromAffectedAreaAsset = createAppAsyncThunk(
    'rooms/getRoomsIdesFromAffectedAreaAsset',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/asset/affected-area/room/${id}`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

// export const getRoomsPointsByPageId = createAsyncThunk(
//     'rooms/GetRoomsPointsByPageData',
//     async ({ pageId }: GetRoomsPointsByPageData, { rejectWithValue }) => {
//         try {
//             const result = await axios.get(`${API_PATH}/api/room/point/${pageId}`)
//
//             return result.data
//         } catch (e) {
//             return rejectWithValue(axiosErrorHandler(e))
//         }
//     },
// )
//
// export const getPointsByRoomId = createAsyncThunk(
//     'rooms/GetPointsByRoomId',
//     async ({ pageId, roomId }: GetRoomsPointsByPageData & { roomId: string }, { rejectWithValue }) => {
//         try {
//             const result = await axios.get(`${API_PATH}/api/room/point/${pageId}/${roomId}`)
//
//             return result.data
//         } catch (e) {
//             return rejectWithValue(axiosErrorHandler(e))
//         }
//     },
// )

export const createRoomPoint = createAppAsyncThunk(
    'rooms/createRoom',
    async ({ id, points, pageId }: RoomPoint & { pageId: string }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/room/point`, {
                roomId: id,
                points,
                pageId,
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteRoomPointsById = createAppAsyncThunk(
    'room/deleteRoomPointsById',
    async ({ roomId, pageId }: { pageId: string; roomId: string }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/room/point/${pageId}/${roomId}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreateRoomsByCount = {
    floorId: string
    name: string
    numberOfRooms: number
}

export const createRoomsByCount = createAppAsyncThunk(
    'rooms/createRoomsByCount',
    async ({ floorId, ...data }: CreateRoomsByCount, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/floors/bulk-create-room/${floorId}`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreateRoomData = RoomForm & {
    floorId: string
}

export const createRoom = createAppAsyncThunk(
    'floors/createRoom',
    async (data: CreateRoomData, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/rooms/create-room`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type UpdateRoomData = RoomForm & {
    roomId: string
}

export const updateRoom = createAppAsyncThunk(
    'floors/updateRoom',
    async (data: UpdateRoomData, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/rooms/update-room`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreatRoomFiles = {
    files: Array<File>
    roomId: string
}

export const createRoomFiles = createAsyncThunk(
    'buildings/creatRoomFiles',
    async (data: CreatRoomFiles, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/rooms/files/create-room-file`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteRoomsFile = createAsyncThunk(
    'buildings/deleteRoomsFile',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/rooms/files/delete-room-file`, {
                params: {
                    id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
