import React, { useState } from 'react'
import FileIcon from 'components/FileIcon'
import TooltipMenu, { TooltipMenuItem } from 'components/TooltipMenu'
import Icons from 'assets/icons'
import { useInfinityScroll } from 'hooks'
import { CommonFileType } from 'types'
import classNames from 'classnames'
import { downloadFileUrl } from 'api/files'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { Popover } from '@mui/material'

import s from './styles.module.scss'

type FilesListType = {
    files: CommonFileType[] // add needed type
    onDeleteClick: (id: string) => void
    filesCount: number
    fetchNextData: () => void
    className?: string
}

export const FilesList = ({ files, onDeleteClick, filesCount, fetchNextData, className }: FilesListType) => {
    const [openViewDialog, setOpenViewDialog] = useState<CommonFileType | null>(null)
    const { setLastElement } = useInfinityScroll({
        fetchNextData,
        hasMore: files?.length < filesCount,
    })
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const onDownloadClick = async (file: CommonFileType) => {
        await downloadFileUrl(file.url, file.name)
    }

    if (!files?.length) {
        return null
    }
    const handleClick = (event: React.MouseEvent<HTMLDivElement>, file: CommonFileType) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
        setOpenViewDialog(file)
    }
    const handleClose = () => {
        setAnchorEl(null)
        setOpenViewDialog(null)
    }

    return (
        <section className={classNames(s.fileContainer, className)}>
            {files?.map((file, index, { length }) => {
                const refLast = index === length - 1 ? setLastElement : null

                return (
                    <div key={file.id} className={s.fileBox} ref={refLast}>
                        <div
                            className={s.box}
                            onClick={(e) =>
                                file.mimetype.includes('image') || file.mimetype.includes('video')
                                    ? handleClick(e, file)
                                    : undefined
                            }
                        >
                            {file.mimetype.includes('image') ? (
                                <img src={file.url} alt={file.name} draggable={false} className={s.img} />
                            ) : file.mimetype.includes('video') ? (
                                <video src={file.url} className={s.img} />
                            ) : (
                                <a href={file.url} target="_blank" download={file.name} rel="noreferrer">
                                    <div className={s.iconBox}>
                                        <FileIcon accept={file.mimetype} size={72} />
                                    </div>
                                </a>
                            )}
                        </div>
                        <Popover
                            open={!!openViewDialog}
                            onClose={() => setOpenViewDialog(null)}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleClose()
                            }}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                horizontal: 'center',
                                vertical: 'center',
                            }}
                            sx={{
                                padding: 0,
                                '& .MuiPopover-paper': {
                                    padding: 0,
                                    backgroundColor: 'transparent',
                                    borderRadius: '10px',
                                    boxShadow: openViewDialog?.mimetype.includes('image')
                                        ? '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 0px rgba(0, 0, 0, 0.12)'
                                        : 'none',
                                },
                            }}
                        >
                            {openViewDialog ? (
                                <div className={s.flyBox} onClick={() => setOpenViewDialog(file)}>
                                    {openViewDialog.mimetype.includes('image') ? (
                                        <img
                                            src={openViewDialog.url}
                                            alt={openViewDialog.name}
                                            draggable={false}
                                            className={s.flyImg}
                                        />
                                    ) : (
                                        <video src={openViewDialog.url} className={s.flyImg} muted autoPlay controls />
                                    )}
                                </div>
                            ) : null}
                        </Popover>

                        <div className={s.titleBox}>
                            <Paragraph
                                size={'s'}
                                color={'grey'}
                                onClick={() => setOpenViewDialog(file)}
                                className={s.link}
                            >
                                {file.name}
                            </Paragraph>
                            <TooltipMenu widthPopper={'10rem'}>
                                <TooltipMenuItem
                                    icon={<Icons.Download />}
                                    title="Download"
                                    onClick={() => onDownloadClick(file)}
                                />
                                <TooltipMenuItem
                                    icon={<Icons.Trash />}
                                    title="Delete"
                                    onClick={() => onDeleteClick(file.id)}
                                />
                            </TooltipMenu>
                        </div>
                    </div>
                )
            })}
        </section>
    )
}
