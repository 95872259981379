import styles from './styles.module.scss'
import { useAppDispatch, useAppSelector } from 'hooks'
import { currentCustomerId } from 'store/selectors'
import React from 'react'
import {
    BuildingAutoComplete,
    ItemForAutocompleteType,
    RegionAutoComplete,
    RoomAutoComplete,
} from 'components/CommonAutoComplete'
import { FormikProps } from 'formik/dist/types'
import { LeaseForm, LeaseType } from '../../types'

import CustomInput from '../../../../ui-kit/components/CustomInput'
import Icons from '../../../../assets/icons'
import { CustomDatePicker } from '../../../../ui-kit/components/CustomDatePicker'
import { Paragraph } from '../../../../ui-kit/components/Typography/Typography'
import FilesDropzoneLoader, { CurrentFilesList } from '../../../../components/FilesDropzoneLoader'
import CustomSelect from '../../../../ui-kit/components/CustomSelect'
import CustomCheckBox from '../../../../ui-kit/components/CustomCheckBox'
import { CommonFileType } from '../../../../types'
import { LeaseOptions } from '../../../../slices/users'

type Props = {
    form: FormikProps<LeaseForm>
    option?: LeaseOptions | null
    filesLease?: Array<CommonFileType>
    setFilesLoaded: (files: Array<File>) => void
    setFilesDeleted?: (files: Array<CommonFileType>) => void
}
const TenantLeaseForm = ({ form, option, setFilesLoaded, setFilesDeleted, filesLease }: Props) => {
    const { setFieldValue, errors, values, getFieldProps } = form

    const onChangeRegion = (item: ItemForAutocompleteType) => {
        setFieldValue('region', item)
        setFieldValue('building', null)
        setFieldValue('room', null)
    }

    const onChangeBuilding = (item: ItemForAutocompleteType) => {
        setFieldValue('building', item)
        setFieldValue('room', null)
    }

    const onChangeRoom = (item: ItemForAutocompleteType) => {
        setFieldValue(`room`, item)
    }

    const isFullDisable = option !== 'ADD' && option !== 'EDIT' && option !== 'RENEW'
    const isRenewDisable = option === 'RENEW'
    const isRelocateDisable = option === 'RELOCATE'

    return (
        <>
            <RegionAutoComplete
                errorText={errors.region}
                textFieldClassName={styles.autocomplete}
                label="Region"
                currentValue={values.region}
                onChangeCallback={onChangeRegion}
                disabled={(isFullDisable || isRenewDisable) && !isRelocateDisable}
            />
            <BuildingAutoComplete
                errorText={errors.building}
                textFieldClassName={styles.autocomplete}
                currentValue={values.building}
                onChangeCallback={onChangeBuilding}
                idDependence={values.region ? { regionIdes: [values.region.value] } : {}}
                label={'Building'}
                disabled={((isFullDisable || isRenewDisable) && !isRelocateDisable) || !values.region}
            />

            <RoomAutoComplete
                errorText={errors.room}
                label="Room/Office"
                textFieldClassName={styles.autocomplete}
                disabled={
                    ((isFullDisable || isRenewDisable) && !isRelocateDisable) || (!values.building && !values.region)
                }
                idDependence={values.building ? { buildingId: values.building.value } : {}}
                currentValue={values.room}
                onChangeCallback={onChangeRoom}
                disableActiveAndAmenity
            />

            {isRelocateDisable && (
                <CustomCheckBox
                    className={styles.checkboxWrap}
                    label="Enter New Lease Information"
                    checked={form.values.hasNewLeaseInformation}
                    onChange={(e, checked) => setFieldValue('hasNewLeaseInformation', checked)}
                />
            )}

            {((isRelocateDisable && form.values.hasNewLeaseInformation) || !isRelocateDisable) && (
                <>
                    <div className={styles.flexInputRow}>
                        <CustomDatePicker
                            disabled={isFullDisable && !isRelocateDisable}
                            errorText={errors.moveInDate}
                            className={styles.autocomplete}
                            label={'Move in date'}
                            dateValue={values.moveInDate}
                            onChange={(value) => value && setFieldValue('moveInDate', value)}
                        />

                        <CustomDatePicker
                            disabled={isFullDisable && !isRelocateDisable}
                            errorText={errors.leaseExpirationDate}
                            className={styles.autocomplete}
                            label={'Lease expiration date'}
                            dateValue={values.leaseExpirationDate}
                            onChange={(value) => value && setFieldValue('leaseExpirationDate', value)}
                        />
                    </div>

                    <div className={styles.flexInputRow}>
                        <CustomInput
                            disabled={isFullDisable && !isRelocateDisable}
                            startAdornment={<Icons.UsdSVG />}
                            type="number"
                            errorText={errors.leaseAmount}
                            label={'Lease Amount'}
                            width="50%"
                            id="leaseAmount"
                            inputProps={{
                                min: 0,
                            }}
                            {...getFieldProps('leaseAmount')}
                        />
                        <CustomSelect
                            disabled={isFullDisable && !isRelocateDisable}
                            label="Lease Type"
                            values={Object.values(LeaseType)}
                            value={values.leaseType}
                            onChange={(val) => setFieldValue('leaseType', val)}
                            background="#E2E6EB"
                            rootClassName={styles.select}
                        />
                    </div>

                    <CustomInput
                        disabled={isFullDisable && !isRelocateDisable}
                        label={'Note'}
                        id="description"
                        {...getFieldProps('description')}
                        multiline
                        rows={4}
                    />

                    {((filesLease && filesLease.length > 0) || !isFullDisable || isRelocateDisable) && (
                        <Paragraph className={styles.documentsTitle} size="s" weight="semi-bold">
                            Lease & Insurance Documentation.
                        </Paragraph>
                    )}

                    {option !== 'ADD' && filesLease && (
                        <CurrentFilesList
                            files={filesLease}
                            onDeleteFile={isFullDisable && !isRelocateDisable ? undefined : setFilesDeleted}
                        />
                    )}

                    {(!isFullDisable || isRelocateDisable) && <FilesDropzoneLoader onLoadFiles={setFilesLoaded} />}
                </>
            )}
        </>
    )
}

export default TenantLeaseForm
