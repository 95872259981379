import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit'

import {
    AssetReducer,
    UserReducer,
    AuthReducer,
    FloorViewerReducer,
    CustomerReducer,
    ToolsetReducer,
    BuildingReducer,
    RegionReducer,
    WorkOrderReducer,
    InventoryItemsReducer,
    EmergencyReducer,
    HistoryReducer,
    SubcontractorReducer,
    CalendarReducer,
    TeamReducer,
    PMReducer,
} from 'slices'

const rootReducer = combineReducers({
    auth: AuthReducer,
    toolset: ToolsetReducer,
    users: UserReducer,
    building: BuildingReducer,
    floorViewer: FloorViewerReducer,
    customers: CustomerReducer,
    assets: AssetReducer,
    region: RegionReducer,
    workOrders: WorkOrderReducer,
    inventoryItems: InventoryItemsReducer,
    history: HistoryReducer,
    emergency: EmergencyReducer,
    subcontractors: SubcontractorReducer,
    calendar: CalendarReducer,
    team: TeamReducer,
    pm: PMReducer,
})

const reducerProxy = (state: any, action: AnyAction) => {
    if (action.type === 'auth/logout/fulfilled') {
        return rootReducer(undefined, action)
    }

    return rootReducer(state, action)
}

export const store = configureStore({
    reducer: reducerProxy,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: (process.env.NODE_ENV || '').trim() !== 'production',
})

export type AppDispatch = typeof store.dispatch
export type StoreRootState = ReturnType<typeof store.getState>
