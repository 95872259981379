import React from 'react'
import { Box } from '@mui/material'
import cn from 'classnames'
import s from './styles.module.scss'

type CustomRemovedBoxType = {
    className?: string
    title: string
    id: string
    onDeleteClick?: (id: string) => void
    color?: string
}
export const CustomRemovedBox = ({ id, className, title, onDeleteClick, color = '#1b6bc0' }: CustomRemovedBoxType) => {
    return (
        <Box
            className={cn(s.box, onDeleteClick ? s.hover : '', className)}
            key={id}
            style={{ color, background: `${color}1A`, border: `1px solid ${color}` }}
        >
            <span style={{ color }}>{title}</span>
            {onDeleteClick && (
                <span onClick={() => onDeleteClick(id)} style={{ color }}>
                    &#10006;
                </span>
            )}
        </Box>
    )
}
