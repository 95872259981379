import { Route, Routes, useNavigate } from 'react-router-dom'
import React, { lazy } from 'react'
import { useAppSelector } from '../hooks'
import { permissionsUser } from '../store/selectors'
import { ROUTES } from './const'

const WorkOrderProfile = lazy(() => import('pages/WorkOrderProfile'))
const WorkOrderPage = lazy(() => import('pages/WorkOrdersPage'))

const WorkOrdersRoutes = () => {
    const navigate = useNavigate()
    const { isSuperAdmin } = useAppSelector(permissionsUser)

    if (isSuperAdmin) {
        navigate(ROUTES.DASHBOARD)
    }
    return (
        <Routes>
            <Route index element={<WorkOrderPage />} />
            <Route path="/:id" element={<WorkOrderProfile />} />
        </Routes>
    )
}

export default WorkOrdersRoutes
