import { FC, ReactNode, useState } from 'react'
import styles from '../../styles.module.scss'
import classNames from 'classnames'
import { TableCell, TableCellProps, TableSortLabel } from '@mui/material'
import { noop } from '../../../../lib/common'
import { DirectionEnum } from '../../../../types'

type Props = {
    children: ReactNode
    className?: string
    visible?: boolean
    width?: number | string
    sticky?: boolean
    sortable?: boolean
    sortColumn?: boolean
    direction?: DirectionEnum
    onSort?: (val: DirectionEnum) => void
    borderHide?: boolean
} & TableCellProps
const TableHeadData: FC<Props> = ({
    direction,
    sortColumn = false,
    sortable = false,
    onSort = noop,
    children,
    className,
    visible = true,
    sticky,
    width,
    borderHide,
    ...props
}) => {
    const [hover, setHover] = useState(false)

    if (!visible) {
        return null
    }

    const getDirection = () => {
        if (!sortColumn) {
            return 'asc'
        }

        return direction === DirectionEnum.ASC ? 'asc' : 'desc'
    }

    return (
        <TableCell
            onMouseOver={() => sortable && setHover(true)}
            onMouseLeave={() => sortable && setHover(false)}
            className={classNames(
                styles.tableHeadData,
                className,
                sticky && styles.stickyHead,
                borderHide && styles.borderHide,
            )}
            sx={{
                border: 'none',
                padding: '6px 10px',
                color: '#848a9b',
                background: 'inherit',
                fontSize: '12px',
            }}
            style={{ width }}
            {...props}
        >
            <TableSortLabel
                hideSortIcon={!sortable}
                active={sortColumn}
                direction={getDirection()}
                onClick={() =>
                    sortable &&
                    direction &&
                    onSort(direction === DirectionEnum.ASC ? DirectionEnum.DESC : DirectionEnum.ASC)
                }
                sx={{
                    '&.MuiTableSortLabel-root .MuiTableSortLabel-icon ': {
                        opacity: sortColumn ? 1 : hover ? 0.4 : 0,
                    },
                }}
            >
                {children}
            </TableSortLabel>
        </TableCell>
    )
}

export default TableHeadData
