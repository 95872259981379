import { useAppDispatch, useInfinityScroll } from 'hooks'
import { getWorkOrdersListByEntity } from 'api/workOrders'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import { useCallback, useEffect, useState } from 'react'
import { WorkOrderType } from 'slices/workOrder'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import CloseButton from 'components/CloseButton'
import { LargeWorkOrderCard } from 'components/WorkOrderCards'

import styles from './styles.module.scss'
import { getUsersTeamsList } from '../../api/teams'
import { TeamType } from '../../slices/team'
import { CardItemTeam } from '../../pages/WorkOrdersPage/components/WorkOrdersTeams/components/TeamCard'

type Props = {
    onClose: () => void
    userId: string
}

const TeamsListLayout = ({ userId, onClose }: Props) => {
    const dispatch = useAppDispatch()

    const [teams, setTeams] = useState<Array<TeamType>>([])

    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)

    const { setLastElement } = useInfinityScroll({
        fetchNextData: () => setPage(page + 1),
        hasMore: teams.length < count,
    })

    const getUserTeams = useCallback(async () => {
        try {
            const res = await dispatch(
                getUsersTeamsList({
                    page,
                    userId,
                }),
            ).unwrap()

            setTeams(res.rows)
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }, [userId])

    useEffect(() => {
        ;(async () => {
            await getUserTeams()
        })()
    }, [getUserTeams])

    return (
        <div className={styles.root}>
            <CloseButton onClose={onClose} />

            <Paragraph size="md" weight="semi-bold">
                Teams
            </Paragraph>

            <div className={styles.woList}>
                {teams.map((team, index, { length }) => {
                    const refLast = index === length - 1 ? setLastElement : null

                    return (
                        <div ref={refLast} key={team.id}>
                            <CardItemTeam team={team} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TeamsListLayout
