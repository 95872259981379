import styles from './styles.module.scss'
import { TableContainer } from '@mui/material'
import * as T from '../../../../../../../../components/Table'
import React from 'react'
import { useInfinityScroll } from '../../../../../../../../hooks'
import { Lease, LeaseOptions } from '../../../../../../../../slices/users'
import { Paragraph } from '../../../../../../../../ui-kit/components/Typography/Typography'
import dayjs from 'dayjs'
import TooltipMenu, { TooltipMenuItem } from '../../../../../../../../components/TooltipMenu'
import Icons, { FolderCrossSVG } from '../../../../../../../../assets/icons'
import { getColorLeaseStatus } from '../TenantLeaseHistory/types'
import StatusBadge from '../../../../../../../../components/StatusBadge'

type Props = {
    leases: Array<Lease>
    countLeases: number
    fetchNextData: () => void
    onSelectLease: (lease: Lease, option: LeaseOptions) => void
    onClickHistory: (lease: Lease) => void
    onClickEnd: (lease: Lease) => void
}

const TenantLeasesTable = ({
    leases,
    countLeases,
    fetchNextData,
    onSelectLease,
    onClickHistory,
    onClickEnd,
}: Props) => {
    const { setLastElement } = useInfinityScroll({
        fetchNextData,
        hasMore: leases.length < countLeases,
    })

    return (
        <TableContainer className={styles.tableContainer}>
            <T.Table stickyHeader>
                <T.TableHead>
                    <T.TableHeadRow>
                        <T.TableHeadData align="center">Room</T.TableHeadData>
                        <T.TableHeadData align="center">Region</T.TableHeadData>
                        <T.TableHeadData align="center">Building</T.TableHeadData>
                        <T.TableHeadData align="center">Status</T.TableHeadData>
                        <T.TableHeadData align="center">Sq. Ft.</T.TableHeadData>
                        <T.TableHeadData align="center">Move in date</T.TableHeadData>
                        <T.TableHeadData align="center">Lease expiration</T.TableHeadData>
                        <T.TableHeadData align="center">Lease Type</T.TableHeadData>
                        <T.TableHeadData width={25}>{''}</T.TableHeadData>
                    </T.TableHeadRow>
                </T.TableHead>

                <T.TableBody>
                    {leases.map((lease, index, { length }) => {
                        const refLast = index === length - 1 ? setLastElement : null

                        return (
                            <T.TableBodyRow key={lease.id} ref={refLast} onClick={() => onSelectLease(lease, 'VIEW')}>
                                <T.TableBodyData>
                                    <Paragraph size="s" weight="tiny">
                                        {lease.room.name}{' '}
                                    </Paragraph>
                                </T.TableBodyData>
                                <T.TableBodyData>
                                    <Paragraph size="s" weight="tiny">
                                        {lease.region.name}
                                    </Paragraph>
                                </T.TableBodyData>
                                <T.TableBodyData>
                                    <Paragraph size="s" weight="tiny">
                                        {lease.building.name}{' '}
                                    </Paragraph>
                                </T.TableBodyData>

                                <T.TableBodyData>
                                    <div className={styles.flexWrap}>
                                        <StatusBadge
                                            color={getColorLeaseStatus(lease.leaseStatus)}
                                            text={lease.leaseStatus}
                                        />
                                    </div>
                                </T.TableBodyData>
                                <T.TableBodyData>
                                    <Paragraph size="s" weight="tiny">
                                        {lease.room.area}
                                    </Paragraph>
                                </T.TableBodyData>
                                <T.TableBodyData>
                                    <Paragraph size="s" weight="tiny">
                                        {dayjs(lease.moveInDate).format('MM/DD/YY')}
                                    </Paragraph>
                                </T.TableBodyData>
                                <T.TableBodyData>
                                    <Paragraph size="s" weight="tiny">
                                        {dayjs(lease.leaseExpirationDate).format('MM/DD/YY')}
                                    </Paragraph>
                                </T.TableBodyData>
                                <T.TableBodyData borderHide>
                                    <Paragraph size="s" weight="tiny">
                                        {lease.leaseType}
                                    </Paragraph>
                                </T.TableBodyData>
                                <T.TableBodyData>
                                    <TooltipMenu>
                                        <TooltipMenuItem
                                            icon={<Icons.Edit />}
                                            title="Renew Lease"
                                            onClick={() => onSelectLease(lease, 'RENEW')}
                                        />
                                        <TooltipMenuItem
                                            icon={<Icons.TimeSVG />}
                                            title="Lease history"
                                            onClick={() => onClickHistory(lease)}
                                        />
                                        <TooltipMenuItem
                                            icon={<Icons.RedSendSVG color="#848A9B" />}
                                            title="Relocate To New Office"
                                            onClick={() => onSelectLease(lease, 'RELOCATE')}
                                        />
                                        <TooltipMenuItem
                                            icon={<FolderCrossSVG />}
                                            title="End Lease"
                                            onClick={() => onClickEnd(lease)}
                                        />
                                    </TooltipMenu>
                                </T.TableBodyData>
                            </T.TableBodyRow>
                        )
                    })}
                </T.TableBody>
            </T.Table>
        </TableContainer>
    )
}

export default TenantLeasesTable
