import * as yup from 'yup'
import { MUST_BE_PHONE, phoneV2RegExp, REQUIRED_FIELD, TOO_LONG } from 'pages/NewAssetPage/config'
import { LengthConstrains } from 'types'

export enum Availabilities {
    WORK_DAY = 'Day-Hours',
    OFF_WORK = 'Off-Hours',
    ALL_HOURS = '24-Hours',
}

export enum ContactTypes {
    SALES = 'Sales',
    CUSTOMER_SERVICE = 'Customer Service',
    PARTS = 'Parts',
    TECHNICIAN = 'Technician',
    DISPATCH = 'Dispatch',
}

export type NewSubcontractorType = {
    name: string
    assetCategoriesId: string[]
    availability?: Availabilities | null
    isEmergency: boolean
    approvedByProcurement: boolean
    address: string
    city: string
    state: string
    zipCode: string
    hoursOfOperation?: string
    phone: string
    afterHoursPhone?: string
    regionId: string
    noteText?: string
    noteFiles?: File[]
}

export type EditSubcontractorType = NewSubcontractorType & { subcontractorId: string }

export const newSubcontractorInit: NewSubcontractorType = {
    name: '',
    assetCategoriesId: [],
    availability: null,
    approvedByProcurement: false,
    isEmergency: false,
    address: '',
    city: '',
    state: '',
    zipCode: '',
    hoursOfOperation: undefined,
    phone: '',
    afterHoursPhone: '',
    regionId: '',
    noteText: undefined,
}

export const editSubcontractorInit: EditSubcontractorType = {
    subcontractorId: '',
    name: '',
    assetCategoriesId: [],
    availability: null,
    approvedByProcurement: false,
    isEmergency: false,
    address: '',
    city: '',
    state: '',
    zipCode: '',
    hoursOfOperation: undefined,
    phone: '',
    afterHoursPhone: '',
    regionId: '',
    noteText: undefined,
}

export const newSubcontractorSchema = yup.object().shape({
    name: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required(REQUIRED_FIELD),
    assetCategoriesId: yup.array().of(yup.string()).min(1, REQUIRED_FIELD),
    availability: yup.string().max(LengthConstrains.L_50, TOO_LONG).nullable(),
    address: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required(REQUIRED_FIELD),
    city: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required(REQUIRED_FIELD),
    state: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required(REQUIRED_FIELD),
    zipCode: yup.string().max(LengthConstrains.L_100, TOO_LONG).trim().required(REQUIRED_FIELD),
    hoursOfOperation: yup.string().max(LengthConstrains.L_50, TOO_LONG).nullable(),
    phone: yup
        .string()
        .matches(phoneV2RegExp, MUST_BE_PHONE)
        .max(LengthConstrains.L_50, TOO_LONG)
        .required(REQUIRED_FIELD),
    afterHoursPhone: yup.string().matches(phoneV2RegExp, MUST_BE_PHONE).max(LengthConstrains.L_50, TOO_LONG).nullable(),
    regionId: yup.string().max(LengthConstrains.L_50, TOO_LONG).required(REQUIRED_FIELD),
    noteText: yup.string().max(LengthConstrains.L_5000, TOO_LONG).nullable(),
})
