import { useAppSelector } from 'hooks/redux'
import { subcontractorsFiltersSelect } from 'store/selectors'
import { CompareKeyType, FiltersForReportsType } from 'components/CommonSavedView'
import { removeBlankFields } from 'lib/common'
import { ItemForAutocompleteType } from 'components/CommonAutoComplete'

export type SubcontractorReportsFiltersType = {
    customerId?: string

    regionId?: string
    cityId?: string
    lastUpdateDate?: { [key in CompareKeyType]?: Date }

    paranoid?: boolean
    hasEmergency?: boolean
    hasOpenWo?: boolean
    isApproved?: boolean

    availability?: string[]
}

export const useSubcontractorFiltersForReports = () => {
    const subcontractorFilters = useAppSelector(subcontractorsFiltersSelect)
    const filters: FiltersForReportsType<SubcontractorReportsFiltersType>[] = []
    let filtersCount = 0
    const otherFilters: { [key: string]: string | string[] | { [key: string]: string | string[] } }[] = []

    if (subcontractorFilters) {
        for (const [key, val] of Object.entries(removeBlankFields(subcontractorFilters))) {
            if (key === 'regions' && val?.length) {
                filters.push({ regionId: val?.map((item: ItemForAutocompleteType) => item?.value) })
                filtersCount += 1
            }
            if (key === 'cities' && val?.length) {
                filters.push({ cityId: val?.map((item: ItemForAutocompleteType) => item?.value) })
                filtersCount += 1
            }
            if (key === 'lastUpdateFrom') {
                filters.push({ lastUpdateDate: { '>=': val } })
            }
            if (key === 'lastUpdateTo') {
                filters.push({ lastUpdateDate: { '<=': val } })
                filtersCount += 1
            }
            if (key === 'isShowArchived') {
                filters.push({ paranoid: val })
                filtersCount += 1
            }
            if (key === 'availabilities') {
                filters.push({ availability: val })
                filtersCount += 1
            }
            if (key === 'hasEmergency') {
                filters.push({ hasEmergency: val })
                filtersCount += 1
            }
            if (key === 'hasOpenWo') {
                filters.push({ hasOpenWo: val })
                filtersCount += 1
            }
            if (key === 'isApproved') {
                filters.push({ isApproved: val })
                filtersCount += 1
            }

            if (key === 'responsibilities' && val?.length) {
                otherFilters.push({ responsibilities: { id: val } })
                filtersCount += 1
            } else {
                otherFilters.push({ responsibilities: {} })
            }
        }
    }

    return { filters, filtersCount, otherFilters }
}
