import { PageTabType } from 'components/PageTabPanel'

export const breadCrumbsArr = (workOrderNumber?: number) => {
    return [
        {
            name: 'All Work Orders',
            link: `/work-orders`,
        },
        {
            name: `WO #${workOrderNumber ?? ''}`,
            link: `#`,
        },
    ]
}

export enum WorkOrderProfileTab {
    DETAILS = 'DETAILS',
    FILES_PLANS = 'FILES_PLANS',
    ASSET_HISTORY = 'ASSET_HISTORY',
    ASSIGNMENTS = 'ASSIGNMENTS',
    COMPLETION_INFO = 'COMPLETION_INFO',
    NOTES = 'NOTES',
    //HISTORY = 'HISTORY',
}

export const tabs: PageTabType[] = [
    {
        id: WorkOrderProfileTab.DETAILS,
        label: 'Details',
    },
    {
        id: WorkOrderProfileTab.FILES_PLANS,
        label: 'Work Order Files',
    },
    {
        id: WorkOrderProfileTab.ASSET_HISTORY,
        label: 'Asset History',
    },
    {
        id: WorkOrderProfileTab.ASSIGNMENTS,
        label: 'Assignments',
    },
    {
        id: WorkOrderProfileTab.COMPLETION_INFO,
        label: 'WO Summary',
    },
    {
        id: WorkOrderProfileTab.NOTES,
        label: 'Notes',
    },
    /*{
        id: WorkOrderProfileTab.HISTORY,
        label: 'History',
    },*/
]
