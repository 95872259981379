import React, { FC, useState } from 'react'
import { InputLabel } from '@mui/material'
import classNames from 'classnames'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { DatePickerSVG } from 'assets/icons'
import dayjs from 'dayjs'

import styles from './styles.module.scss'

type Props = {
    width?: string
    errorText?: string
    className?: string
    dateValue?: null | string
} & ReactDatePickerProps
export const EmptyDatePicker: FC<Props> = ({ width = '100%', className, dateValue, errorText, onChange, ...rest }) => {
    const [value, setValue] = useState<Date | null>(null)
    return (
        <div style={{ width }} className={styles.root}>
            <DatePicker
                className={classNames(styles.datePickerContainer, errorText && styles.error, className)}
                onChange={(date, e) => {
                    setValue(date)
                    onChange && onChange(date, e)
                }}
                popperProps={{ strategy: 'fixed' }}
                popperPlacement="top-start"
                dateFormat={'M/d/yyyy'}
                customInput={
                    <DatePickerSVG
                        style={{
                            width: '24px!important',
                            height: '24px',
                        }}
                    />
                }
                {...rest}
            />
            <InputLabel className={styles.label} style={{ color: errorText ? '#F1416C' : '#202534' }}>
                {value ? dayjs(value).format('MM/DD/YY') : dateValue}
            </InputLabel>
        </div>
    )
}
