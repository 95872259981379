import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

const InventoryItemsPage = lazy(() => import('pages/InventoryItemsPage'))
const InventoryItemProfile = lazy(() => import('pages/InventoryItemProfile'))

export const InventoryItemsRoutes = () => {
    return (
        <Routes>
            <Route index element={<InventoryItemsPage />} />
            <Route path="/:typeId" element={<InventoryItemProfile />} />
        </Routes>
    )
}
