import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import { UserState } from './slice'
import { getUserById, getUsersList } from '../../api/user'
import { getTenantById, getTenantsList } from '../../api/tenant'
import sendNotification from '../../lib/notification'
import { NOTIFICATION_STATUS } from '../../types'
import { Tenant, User } from './types'

const extraReducers = (builder: ActionReducerMapBuilder<UserState>) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get users list
    builder.addCase(getUsersList.pending, (state) => {
        state.loading = true
    })
    builder.addCase(getUsersList.fulfilled, (state, action: PayloadAction<{ count: number; rows: Array<User> }>) => {
        state.users = action.payload.rows
        state.usersCount = action.payload.count
        state.loading = false
    })
    builder.addCase(getUsersList.rejected, (state) => {
        state.loading = false
        sendNotification('Error with users loading', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get users by id
    builder.addCase(getUserById.pending, (state) => {
        state.loading = true
    })
    builder.addCase(getUserById.fulfilled, (state, action: PayloadAction<User>) => {
        state.loading = false
        state.selectedUserProfile = action.payload
    })
    builder.addCase(getUserById.rejected, (state) => {
        state.loading = false
        sendNotification('Error with user data', NOTIFICATION_STATUS.ERROR)
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get tenants list
    builder.addCase(getTenantsList.pending, (state) => {
        state.loading = true
    })
    builder.addCase(
        getTenantsList.fulfilled,
        (state, action: PayloadAction<{ rows: Array<Tenant>; count: number }>) => {
            state.loading = false
            state.tenants = action.payload.rows
            state.tenantsCount = action.payload.count
        },
    )
    builder.addCase(getTenantsList.rejected, (state) => {
        state.loading = false
    })

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //get tenants by id
    builder.addCase(getTenantById.pending, (state) => {
        state.loading = true
    })
    builder.addCase(getTenantById.fulfilled, (state, action: PayloadAction<Tenant>) => {
        state.loading = false
        state.selectedTenantProfile = action.payload
    })
    builder.addCase(getTenantById.rejected, (state) => {
        state.loading = false
    })
    // builder.addCase(createInvitation.pending, (state) => {
    //     state.isUsersLoading = true
    // })
    // builder.addCase(createInvitation.fulfilled, (state, action) => {
    //     state.isUsersLoading = false
    //     state.users = [...state.users, action.payload]
    //     sendNotification(`Invitation sent to ${action.payload.email}`, NOTIFICATION_STATUS.SUCCESS)
    // })
    // builder.addCase(createInvitation.rejected, (state) => {
    //     state.isUsersLoading = false
    //     sendNotification('Error with users invitation', NOTIFICATION_STATUS.ERROR)
    // })
    //
    // builder.addCase(createUser.pending, (state) => {
    //     state.isUsersLoading = true
    //     state.passwordAccepted = false
    // })
    // builder.addCase(createUser.fulfilled, (state, action) => {
    //     state.isUsersLoading = false
    //     state.passwordAccepted = true
    //     sendNotification('Password created', NOTIFICATION_STATUS.SUCCESS)
    // })
    // builder.addCase(createUser.rejected, (state) => {
    //     state.isUsersLoading = false
    //     sendNotification('Error with password creation', NOTIFICATION_STATUS.ERROR)
    // })
    //
    // builder.addCase(getUserById.pending, (state) => {
    //     state.isUsersLoading = true
    // })
    // builder.addCase(getUserById.fulfilled, (state, action) => {
    //     state.isUsersLoading = false
    //     state.selectedUser = action.payload
    // })
    // builder.addCase(getUserById.rejected, (state) => {
    //     state.isUsersLoading = false
    //     sendNotification('Error with user data', NOTIFICATION_STATUS.ERROR)
    // })
    //
    // builder.addCase(deleteUser.pending, (state) => {
    //     state.isUsersLoading = true
    // })
    // builder.addCase(deleteUser.fulfilled, (state, action) => {
    //     state.isUsersLoading = false
    //     sendNotification('User successfully deleted', NOTIFICATION_STATUS.SUCCESS)
    // })
    // builder.addCase(deleteUser.rejected, (state) => {
    //     state.isUsersLoading = false
    //     sendNotification('Error with user deletion', NOTIFICATION_STATUS.ERROR)
    // })
    //
    // builder.addCase(updateUser.pending, (state) => {
    //     state.isUsersLoading = true
    // })
    // builder.addCase(updateUser.fulfilled, (state, action) => {
    //     state.isUsersLoading = false
    //     sendNotification('User successfully updated', NOTIFICATION_STATUS.SUCCESS)
    // })
    // builder.addCase(updateUser.rejected, (state) => {
    //     state.isUsersLoading = false
    //     sendNotification('Error with user update', NOTIFICATION_STATUS.ERROR)
    // })
    //
    // builder.addCase(updateAvatar.pending, (state) => {
    //     state.isUsersLoading = true
    // })
    // builder.addCase(updateAvatar.fulfilled, (state, action) => {
    //     state.isUsersLoading = false
    //     sendNotification('Avatar successfully updated', NOTIFICATION_STATUS.SUCCESS)
    // })
    // builder.addCase(updateAvatar.rejected, (state) => {
    //     state.isUsersLoading = false
    //     sendNotification('Error with user update', NOTIFICATION_STATUS.ERROR)
    // })
}

export default extraReducers
