import { Lease, LeaseOptions, LeaseStatus } from '../../../../../../../../slices/users'
import { ColorVariant } from '../../../../../../../../components/StatusBadge'

export type LeaseHistory = {
    id: string

    data: {
        option: LeaseOptions
        hasNewLeaseInformation: boolean
        createdAt: Date

        new: Lease
        old: Lease
    }
}

export const getColorLeaseHistory = (option: LeaseOptions): ColorVariant => {
    switch (option) {
        case 'RELOCATE':
            return ColorVariant.blue

        case 'RENEW':
            return ColorVariant.blue

        case 'END':
            return ColorVariant.yellow

        case 'EXPIRED':
            return ColorVariant.red

        default:
            return ColorVariant.grey
    }
}

export const getColorLeaseStatus = (option: LeaseStatus): ColorVariant => {
    switch (option) {
        case LeaseStatus.Active:
            return ColorVariant.green

        case LeaseStatus.Expired:
            return ColorVariant.red

        case LeaseStatus.Cancelled:
            return ColorVariant.yellow

        default:
            return ColorVariant.grey
    }
}
