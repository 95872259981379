import React, { MouseEventHandler } from 'react'
import styles from './TooltipMenu.module.scss'
import { noop } from 'lib/common'
import classNames from 'classnames'
import { Paragraph } from 'ui-kit/components/Typography/Typography'

type Props = {
    icon: React.ReactNode
    title: string
    onClick?: MouseEventHandler<HTMLDivElement> | undefined
    color?: string
    disabled?: boolean
}

const TooltipMenuItem: React.FC<Props> = ({ color, icon, title, onClick = noop, disabled = false }) => {
    return (
        <div className={classNames(styles.itemContainer, disabled && styles.disabled)} onClick={onClick}>
            <div className={styles.iconWrap}>{icon}</div>
            <Paragraph size={'s'} className={styles.itemTitle} style={{ color }}>
                {title}
            </Paragraph>
        </div>
    )
}

export default TooltipMenuItem
