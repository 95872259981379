import { Route, Routes, useNavigate } from 'react-router-dom'
import React, { lazy } from 'react'
import { useAppSelector } from '../hooks'
import { permissionsUser } from '../store/selectors'
import { ROUTES } from './const'

const TenantProfile = lazy(() => import('../pages/UsersTenantsPage/components/TenantProfile'))
const TenantsView = lazy(() => import('../pages/UsersTenantsPage/components/TenantsView/TenantsView'))

const TenantRoutes = () => {
    const navigate = useNavigate()
    const { isSuperAdmin } = useAppSelector(permissionsUser)

    if (isSuperAdmin) {
        navigate(ROUTES.DASHBOARD)
    }
    return (
        <Routes>
            <Route index element={<TenantsView />} />
            <Route path=":id/*" element={<TenantProfile />} />
        </Routes>
    )
}

export default TenantRoutes
