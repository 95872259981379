import { TablePagination } from '@mui/material'
import React, { FC } from 'react'

type Props = {
    count: number
    page: number
    rowsPerPage: number
    onPageChange: (page: number) => void
    subLabel?: string
}

const TableToolbarPagination = ({ count, page, rowsPerPage, onPageChange, subLabel = '' }: Props) => {
    return (
        <TablePagination
            count={count}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            onPageChange={(e, val) => onPageChange(val + 1)}
            labelDisplayedRows={({ from, to, count: all }) => `Showing ${from} - ${to} of ${all} ${subLabel}`}
            sx={{
                border: 'none',
                padding: 0,
                overflow: 'hidden',
                '& .MuiTablePagination-actions': {
                    position: 'absolute',
                    left: '-40px',

                    button: {
                        '&.Mui-disabled': {
                            color: '#C1C4CD',
                        },
                        color: '#848A9B',
                        padding: '0 8px',
                        borderRadius: '6px',
                    },
                },
                '& .MuiTablePagination-toolbar': {
                    minHeight: '38px',
                    marginLeft: '20px',
                },
                '& .MuiTablePagination-displayedRows': {
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    color: '#848A9B',
                },
                '& .MuiTablePagination-select': {
                    display: 'none',
                },
                '& .MuiTablePagination-selectLabel': {
                    display: 'none',
                },
                '& .MuiTablePagination-selectIcon': {
                    display: 'none',
                },
            }}
        />
    )
}

export default TableToolbarPagination
