import { AcceptFile } from 'types'
import { FC } from 'react'
import { getFileSvgImageByAccept } from 'assets/files-icons'

type Props = {
    accept: AcceptFile | string
    size?: number
}

const FileIcon: FC<Props> = ({ accept, size }) => {
    const img = getFileSvgImageByAccept(accept)

    return <img width={size} height={size} src={img} alt="type" draggable={false} />
}

export default FileIcon
