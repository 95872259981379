import styles from './styles.module.scss'
import { useAppSelector } from '../../../../../../hooks'
import React from 'react'
import Icons from '../../../../../../assets/icons'
import ProfileInfoCard from '../../../../../CustomersPage/components/CustomerProfile/components/ProfileInfoCard'
import ActiveWorkOrdersTable from '../../../ActiveWorkOrdersTable'

const InfoTenantProfile = () => {
    const selectedTenantProfile = useAppSelector(({ users }) => users.selectedTenantProfile)

    return (
        <div className={styles.root}>
            <div className={styles.infoCardsWrap}>
                <ProfileInfoCard
                    count={selectedTenantProfile?.ActiveWorkOrders || 0}
                    title={'Active Work Orders'}
                    icon={<Icons.OpenWorkOrder />}
                />
                <ProfileInfoCard
                    count={selectedTenantProfile?.ActiveWorkOrders || 0}
                    title={'Work Order Awaiting Review'}
                    icon={<Icons.PendingWorkOrder />}
                />
                <ProfileInfoCard
                    count={selectedTenantProfile?.ActiveWorkOrders || 0}
                    title={'Lifetime Work Orders Completed'}
                    icon={<Icons.CompletedWorkOrder />}
                />
            </div>
            <div className={styles.woList}>{<ActiveWorkOrdersTable tenantCompanyId={selectedTenantProfile?.id} />}</div>
        </div>
    )
}

export default InfoTenantProfile
