import s from './NewPassword.module.scss'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import AuthInput from 'ui-kit/components/AuthInput/AuthInput'
import { Button } from 'ui-kit/components/Button/Button'
import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { forgotPassword } from 'api/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks'
import AuthRoutes from '../../routes'
import { clearState } from 'slices/AuthSlice'
import { CircularProgress } from '@mui/material'
import { REQUIRED_FIELD, TOO_LONG } from 'pages/NewAssetPage/config'
import { LengthConstrains } from 'types'

const validationSchema = yup.object().shape({
    password: yup
        .string()
        .max(LengthConstrains.L_16, TOO_LONG)
        .trim()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
        )
        .required(REQUIRED_FIELD),
    confirmPassword: yup
        .string()
        .required(REQUIRED_FIELD)
        .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value
        }),
})

type NewPasswordForm = {
    password: string
    confirmPassword: string
}

const NewPassword = () => {
    const { state } = useLocation()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const isWaitingForResponse = useAppSelector(({ auth }) => auth.isWaitingForResponse)

    const { handleChange, values, errors, isValid, handleSubmit, resetForm } = useFormik<NewPasswordForm>({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validateOnChange: false,
        validationSchema: validationSchema,
        onSubmit: async (val) => {
            await dispatch(
                forgotPassword({
                    email: state.email || '',
                    password: val.password,
                    verificationCode: state.verificationCode || '',
                    callback: handleNavigateToLogin,
                }),
            )
        },
    })

    const handleNavigateToLogin = () => {
        navigate('/' + AuthRoutes.LOGIN, { replace: true })
    }

    useEffect(() => {
        if (!state.email) {
            dispatch(clearState())
            navigate('/')
        }
    }, [state])

    return (
        <div className={s.contentContainer}>
            <Paragraph className={s.logIn} color={'white'}>
                Create new password
            </Paragraph>
            <Paragraph className={s.title} color={'white'}>
                To change the current password , enter the new password in both fields
            </Paragraph>
            <div className={s.inputsContainer}>
                <AuthInput
                    id="password"
                    name="password"
                    type="password"
                    title="New password"
                    value={values.password}
                    onChange={handleChange}
                    error={errors.password}
                    onEnterPress={handleSubmit}
                    defaultShowError={!!errors.password}
                />
                <AuthInput
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    title="Confirm password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    error={errors.confirmPassword}
                    onEnterPress={handleSubmit}
                    defaultShowError={!!errors.confirmPassword}
                />
            </div>

            <Button className={s.logInBtn} onClick={() => handleSubmit()} variants={'filled-yellow'}>
                {isWaitingForResponse ? (
                    <CircularProgress
                        sx={{
                            color: 'white',
                            '& .MuiCircularProgress-svg': {
                                margin: 'auto',
                                height: 40,
                                width: 20,
                            },
                        }}
                        color="inherit"
                    />
                ) : (
                    <Paragraph size={'s'} weight={'semi-bold'}>
                        Next
                    </Paragraph>
                )}
            </Button>

            <div className={`${s.return} ${isWaitingForResponse ? 'opacity' : ''}`} onClick={handleNavigateToLogin}>
                Return to Login
            </div>
        </div>
    )
}

export default NewPassword
