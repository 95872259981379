import { SubcontractorType } from '../subcontractor'
import { CommonFileType } from '../../types'
import { WorkOrderType } from '../workOrder'

export enum EmergencyPageTab {
    EMERGENCY_PLANS = 'EMERGENCY_PLANS',
    EMERGENCY_CONTACTS = 'EMERGENCY_CONTACTS',
    MANAGE_PROCEDURES = 'MANAGE_PROCEDURES',
    INCIDENTS = 'INCIDENTS',
}

export enum EmergencyProfilePageTab {
    DETAILS = 'DETAILS',
    EMERGENCY_CONTACTS = 'EMERGENCY_CONTACTS',
}

export enum EmergencyProcedureType {
    SAFETY = 'SAFETY',
    COMMUNICATIONS = 'COMMUNICATIONS',
    RESPONSE = 'RESPONSE',
    RECOVERY = 'RECOVERY',
    MONITORING_AND_EVALUATION = 'MONITORING_AND_EVALUATION',
    COMPLIANCE_AND_REGULATION = 'COMPLIANCE_AND_REGULATION',
}

export enum EmergencyScenario {
    NATURAL_DISASTER = 'NATURAL_DISASTER',
    INFRASTRUCTURE_FAILURES = 'INFRASTRUCTURE_FAILURES',
    SECURITY_INCIDENTS = 'SECURITY_INCIDENTS',
    HEALTH_EMERGENCIES = 'HEALTH_EMERGENCIES',
    HAZARDOUS_MATERIAL_INCIDENTS = 'HAZARDOUS_MATERIAL_INCIDENTS',
    FIRE_EMERGENCY = 'FIRE_EMERGENCY',
}

export enum EmergencyReportGetPlanEnum {
    BY_SCENARIO = 'BY_SCENARIO',
    BY_AFFECTED_AREA = 'BY_AFFECTED_AREA',
    INFRASTRUCTURE_FAILURES = 'INFRASTRUCTURE_FAILURES',
}

export type PlanProcedure = {
    id: string
    file: {
        emergencyProcedure: {
            type: EmergencyProcedureType
        }
        name: string
        url: string
    }
}

export type EmergencyPlan = {
    id: string
    scenario: string
    name: string
    description: string
    createdAt: string
    lastReviewed: string
    subcontractors: Array<{ id: string; subcontractor: SubcontractorType }>
    frequency: string | null

    affectedAreas: Array<{ building: { name: string } }>
    ePlanEProcedureFiles: Array<PlanProcedure>
    workOrder: WorkOrderType | null
}

export type EmergencyReport = {
    id: string
    scenario: EmergencyScenario
    title: string
    description: string
    region: {
        id: string
        name: string
    } | null
    building: {
        id: string
        name: string
    } | null
    floor: {
        id: string
        name: string
    } | null
    room: {
        id: string
        name: string
    } | null
}

export type EmergencyProcedure = CommonFileType & {
    emergencyProcedure: {
        type: EmergencyProcedureType
    }
}

export type EmergencyProcedureThree = { [key: string]: Array<EmergencyProcedure> }
export type EmergencyProcedureCounts = { [key: string]: number }
