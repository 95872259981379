import React from 'react'
import Icons from 'assets/icons'
import cn from 'classnames'

import s from './styles.module.scss'

type ClearButtonType = {
    onClick?: () => void
    className?: string
}

export const ClearButton = ({ onClick, className }: ClearButtonType) => {
    return <Icons.CrossSVG onClick={onClick} className={cn(s.clear, className)} />
}
