import * as T from 'components/Table'
import React, { useMemo } from 'react'
import { useAppSelector } from 'hooks'
import TableItemRow from './TableItemRow'
import { TableContainer } from '@mui/material'
import classNames from 'classnames'
import styles from './styles.module.scss'
import {
    requestersRequiredColumns,
    techniciansRequiredColumns,
    supervisorsRequiredColumns,
    adminsRequiredColumns,
} from '../../../../../../slices/users/const'
import { USER_ROLE } from '../../../../../../types'

type Props = {
    userRole: USER_ROLE
}

const TableView = ({ userRole }: Props) => {
    const usersData = useAppSelector(({ users }) => users.users)

    const visibleRequestersColumns = useAppSelector(({ users }) => users.userRequesterSettings.columns)
    const visibleTechniciansColumns = useAppSelector(({ users }) => users.userTechniciansSettings.columns)
    const visibleSupervisorsColumns = useAppSelector(({ users }) => users.userSupervisorsSettings.columns)
    const visibleAdminsColumns = useAppSelector(({ users }) => users.userAdminsSettings.columns)
    const visibleSuperAdminsColumns = useAppSelector(({ users }) => users.userSuperAdminsSettings.columns)

    const visibleColumns = useMemo(() => {
        switch (userRole) {
            case USER_ROLE.REQUESTOR:
                return visibleRequestersColumns
            case USER_ROLE.TECHNICIAN:
                return visibleTechniciansColumns
            case USER_ROLE.SUPERVISOR:
                return visibleSupervisorsColumns
            case USER_ROLE.ADMIN:
                return visibleAdminsColumns
            case USER_ROLE.SUPERADMIN:
                return visibleSuperAdminsColumns

            default:
                return []
        }
    }, [
        visibleRequestersColumns,
        visibleTechniciansColumns,
        visibleSupervisorsColumns,
        visibleSuperAdminsColumns,
        visibleAdminsColumns,
        userRole,
    ])

    const requiredColumns = useMemo(() => {
        switch (userRole) {
            case USER_ROLE.REQUESTOR:
                return requestersRequiredColumns
            case USER_ROLE.TECHNICIAN:
                return techniciansRequiredColumns
            case USER_ROLE.SUPERVISOR:
                return supervisorsRequiredColumns
            case USER_ROLE.ADMIN:
            case USER_ROLE.SUPERADMIN:
                return adminsRequiredColumns

            default:
                return []
        }
    }, [userRole])

    return (
        <TableContainer className={classNames(styles.tableContainer)}>
            <T.Table stickyHeader>
                <T.TableHead>
                    <T.TableHeadRow>
                        <T.TableHeadData sticky>
                            <T.TableHeadData width={25}>{''}</T.TableHeadData>

                            {requiredColumns.map((column) => (
                                <T.TableHeadData width={200} align="center">
                                    {column.label}
                                </T.TableHeadData>
                            ))}
                        </T.TableHeadData>
                        {visibleColumns.map((column) => (
                            <T.TableHeadData align="center" key={column.name}>
                                {column.label}
                            </T.TableHeadData>
                        ))}
                    </T.TableHeadRow>
                </T.TableHead>

                <T.TableBody>
                    {usersData.map((row) => (
                        <TableItemRow
                            key={row.id}
                            row={row}
                            visibleColumns={visibleColumns}
                            requiredColumns={requiredColumns}
                        />
                    ))}
                </T.TableBody>
            </T.Table>
        </TableContainer>
    )
}

export default TableView
