import { StoreRootState } from '..'
import { AssetProfileTab } from 'pages/AssetProfile/config'
import {
    AffectedAreaInAssetType,
    AssetFiltersType,
    AssetPageType,
    CategoryThreeType,
    CurrentAssetType,
    FeedFromType,
    FeedToType,
    IAsset,
    LocationAssetType,
    PropInAssetType,
} from 'slices/asset'
import { AssetsListPageTab } from 'pages/AssetsPage/config'
import { AssetPoint } from 'slices/floorPlanViever'
import { CommonSortType, ListViewMode } from 'types'
import { TableColumnType } from 'components/Table'
import { CommonCurrentViewType } from 'components/CommonSavedView'

export const currentAssetSelect = (state: StoreRootState): CurrentAssetType => state.assets.currentAsset
export const isWaitingForResponseSelect = (state: StoreRootState): boolean => state.assets.isWaitingForResponse
export const isWaitingFloorPlanSelect = (state: StoreRootState): boolean => state.assets.isWaitingFloorPlan
export const assetProfileTabSelect = (state: StoreRootState): AssetProfileTab => state.assets.assetProfileTab
export const propsCurrentAssetSelect = (state: StoreRootState): PropInAssetType[] => state.assets.propsCurrentAsset
export const locationCurrentAssetSelect = (state: StoreRootState): LocationAssetType =>
    state.assets.locationCurrentAsset
export const assetsSearchValueSelect = (state: StoreRootState): string => state.assets.assetsSearchValue
export const assetCurrentPageSelect = (state: StoreRootState): number => state.assets.assetPaginationPage
export const assetsCountSelect = (state: StoreRootState): number => state.assets.assetsCount
export const assetListTabSelect = (state: StoreRootState): AssetsListPageTab => state.assets.assetListTab
export const assetsSelect = (state: StoreRootState): IAsset[] => state.assets.assets
export const feedToCurrentAssetSelect = (state: StoreRootState): FeedToType[] => state.assets.feedToCurrentAsset
export const feedFromCurrentAssetSelect = (state: StoreRootState): FeedFromType[] => state.assets.feedFromCurrentAsset
export const affectedAreaCurrentAssetSelect = (state: StoreRootState): AffectedAreaInAssetType[] =>
    state.assets.affectedAreaCurrentAsset
export const currentAssetPagesSelect = (state: StoreRootState): AssetPageType[] => state.assets.assetPages
export const selectedPlanPageSelect = (state: StoreRootState): AssetPageType | null => state.assets.selectedPlanPage
export const assetPointsOnPlanSelect = (state: StoreRootState): AssetPoint[] => state.assets.assetsPointsOnPlan
export const isNeedUpdatePointsSelect = (state: StoreRootState): boolean => state.assets.isNeedUpdatePoints
export const assetListModeSelect = (state: StoreRootState): ListViewMode => state.assets.assetsSettings.listMode
export const assetsColumnsSelect = (state: StoreRootState): TableColumnType[] => state.assets.assetsSettings.columns
export const assetsFiltersSelect = (state: StoreRootState): AssetFiltersType | null =>
    state.assets.assetsSettings.filters
export const assetsCurrentViewSelect = (state: StoreRootState): CommonCurrentViewType => ({
    id: state.assets.assetsSettings.currentViewId!,
    currentViewTitle: state.assets.assetsSettings.currentViewTitle!,
    isSystemView: state.assets.assetsSettings.isSystemCurrentView!,
})
export const assetsSortSelect = (state: StoreRootState): CommonSortType => ({
    sortField: state.assets.assetsSettings.sortField,
    sortDirection: state.assets.assetsSettings.sortDirection,
})

export const assetCategoriesSelect = (state: StoreRootState): CategoryThreeType[] => state.assets.categoriesThree
