import React, { useState } from 'react'
import Icons from 'assets/icons'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import CustomInput from 'ui-kit/components/CustomInput'
import { Button } from 'ui-kit/components/Button/Button'
import { CommonDialog } from 'components/CommonDialog'
import { createView, editColumnsView } from 'api/views'
import { CommonCurrentViewType, FiltersForReportsType } from 'components/CommonSavedView'
import { useAppDispatch, useAppSelector } from 'hooks'
import { CommonSortType, NOTIFICATION_STATUS } from 'types'
import { TableColumnType } from 'components/Table'
import { currentUserSelect } from 'store/selectors'
import { AssetReportsFiltersType } from 'hooks/assetsHooks/useAssetFiltersForReports'
import sendNotification from 'lib/notification'

import s from './styles.module.scss'

type ConfirmSaveViewType = {
    currentView: CommonCurrentViewType
    modelName: string
    setIsLoading: (isLoading: boolean) => void
    sort: CommonSortType
    columns?: TableColumnType[]
    search?: { field: string; search?: string }
    filters: FiltersForReportsType<AssetReportsFiltersType>[]
    staticSearch?: string
}

export const ConfirmSaveView = ({
    modelName,
    setIsLoading,
    currentView: { id, isSystemView, currentViewTitle },
    sort: { sortField, sortDirection },
    columns,
    search,
    filters,
    staticSearch,
}: ConfirmSaveViewType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const [viewName, setViewName] = useState('')
    const [isOpenSaveViewDialog, setIsOpenSaveViewDialog] = useState(false)

    const onSaveClick = () => {
        setIsOpenSaveViewDialog(true)
        setViewName(currentViewTitle ? currentViewTitle : '')
    }

    const onConfirmClick = async () => {
        setIsLoading(true)

        const and: FiltersForReportsType<AssetReportsFiltersType>[] = [{ customerId: user?.customerId ?? '' }]

        if (filters?.length) {
            and.push(...filters)
        }

        if (search && search.search) {
            and.push({ [search.field]: { search: search.search } })
        }

        try {
            currentViewTitle === viewName && !isSystemView
                ? await dispatch(
                      editColumnsView({
                          id,
                          modelName,
                          title: viewName,
                          frontend: {
                              titles: columns?.map((item) => item.label),
                              order: {
                                  [sortField]: sortDirection,
                              },
                              filters: { initial: { and } },
                          },
                          checkResponse: () => {
                              setIsOpenSaveViewDialog(false)
                          },
                      }),
                  ).unwrap()
                : await dispatch(
                      createView({
                          modelName,
                          title: viewName,
                          isSystemView: false,
                          frontend: {
                              titles: columns?.map((item) => item.label),
                              order: {
                                  [sortField]: sortDirection,
                              },
                              filters: { initial: { and } },
                          },
                          staticSearch,
                          checkResponse: () => {
                              setIsOpenSaveViewDialog(false)
                          },
                      }),
                  ).unwrap()
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <div className={s.iconBtn} onClick={onSaveClick}>
                <Icons.SaveSVG />
                <Paragraph size={'s'} weight={'semi-bold'}>
                    Save View
                </Paragraph>
            </div>
            <CommonDialog
                title={'Save This View'}
                onClose={() => setIsOpenSaveViewDialog(false)}
                isOpen={isOpenSaveViewDialog}
            >
                <CustomInput
                    label={'View Name'}
                    value={viewName}
                    variant={'white-grey'}
                    onChange={(e) => setViewName(e.currentTarget.value)}
                />
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        onConfirmClick()
                    }}
                    variants={'filled-blue'}
                    className={s.dialogButton}
                    disabled={!viewName}
                >
                    {viewName && currentViewTitle === viewName && !isSystemView ? 'Update' : 'Save'}
                </Button>
            </CommonDialog>
        </>
    )
}
