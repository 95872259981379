import { WorkOrderProfileTab } from 'pages/WorkOrderProfile/config'
import { CurrentWorkOrderType, WorkOrderFiltersType, WorkOrdersState } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialWorkOrderColumns, WorkOrdersListPageTab } from 'pages/WorkOrdersPage/config'
import { extraReducers } from './extraReducers'
import { dropInLS, getSettingsFromLS, LsKeys } from 'lib/workWithLS'
import { CommonSortType, DirectionEnum, ListViewMode } from 'types'
import { TableColumnType } from 'components/Table'

const initialState: WorkOrdersState = {
    currentWorkOrder: {} as CurrentWorkOrderType,
    workOrders: [],
    isWaitingForResponse: false,
    workOrderProfileTab: WorkOrderProfileTab.DETAILS,
    workOrderListTab: WorkOrdersListPageTab.WORK_ORDERS,
    workOrderPaginationPage: 1,
    workOrdersCount: 0,
    workOrdersSearchValue: '',
    inventoryItemsInWo: {
        items: [],
        count: 0,
        page: 1,
        isLoading: false,
    },
    workOrdersSettings: {
        filters: getSettingsFromLS('filters', LsKeys.WORK_ORDERS_SETTINGS, null),
        columns: getSettingsFromLS('columns', LsKeys.WORK_ORDERS_SETTINGS, initialWorkOrderColumns),
        currentViewId: getSettingsFromLS('currentViewId', LsKeys.WORK_ORDERS_SETTINGS, ''),
        currentViewTitle: getSettingsFromLS('currentViewTitle', LsKeys.WORK_ORDERS_SETTINGS, ''),
        listMode: getSettingsFromLS('listMode', LsKeys.WORK_ORDERS_SETTINGS, ListViewMode.TABLE),
        isSystemCurrentView: getSettingsFromLS('isSystemCurrentView', LsKeys.WORK_ORDERS_SETTINGS, false),
        sortField: getSettingsFromLS('sortField', LsKeys.WORK_ORDERS_SETTINGS, 'id'),
        sortDirection: getSettingsFromLS('sortDirection', LsKeys.WORK_ORDERS_SETTINGS, DirectionEnum.ASC),
    },
}

export const workOrderSlice = createSlice({
    name: 'workOrders',
    initialState,
    reducers: {
        clearCurrentWorkOrder: (state) => {
            state.currentWorkOrder = initialState.currentWorkOrder
        },
        clearWorkOrderSettings: (state) => {
            state.workOrderPaginationPage = initialState.workOrderPaginationPage
            state.workOrdersSearchValue = initialState.workOrdersSearchValue
        },
        setWorkOrderProfileTab: (state, action: PayloadAction<WorkOrderProfileTab>) => {
            state.workOrderProfileTab = action.payload
        },
        setWorkOrderListTab: (state, action: PayloadAction<WorkOrdersListPageTab>) => {
            state.workOrderListTab = action.payload
        },
        setWorkOrderPaginationPage: (state, action: PayloadAction<number>) => {
            state.workOrderPaginationPage = action.payload
        },
        setWorkOrdersSearchValue: (state, action: PayloadAction<string>) => {
            state.workOrdersSearchValue = action.payload
        },
        setIsWaitingForResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingForResponse = action.payload
        },

        //settings
        setWorkOrdersColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.WORK_ORDERS_SETTINGS, {
                columns: action.payload,
            })

            state.workOrdersSettings.columns = action.payload
        },
        setCurrentWorkOrderView: (
            state,
            action: PayloadAction<{ id: string; title: string; isSystemView: boolean }>,
        ) => {
            dropInLS(LsKeys.WORK_ORDERS_SETTINGS, {
                currentViewId: action.payload.id,
                currentViewTitle: action.payload.title,
                isSystemCurrentView: action.payload.isSystemView,
            })
            state.workOrdersSettings.currentViewId = action.payload.id
            state.workOrdersSettings.currentViewTitle = action.payload.title
            state.workOrdersSettings.isSystemCurrentView = action.payload.isSystemView
        },
        setWorkOrderFilters: (state, action: PayloadAction<WorkOrderFiltersType | null>) => {
            dropInLS(LsKeys.WORK_ORDERS_SETTINGS, { filters: action.payload })
            state.workOrdersSettings.filters = action.payload
        },
        setWorkOrderListMode: (state, action: PayloadAction<ListViewMode>) => {
            dropInLS(LsKeys.WORK_ORDERS_SETTINGS, { listMode: action.payload })
            state.workOrdersSettings.listMode = action.payload
        },
        setWorkOrderSort: (state, action: PayloadAction<CommonSortType>) => {
            const checkIsNewSortField = state.workOrdersSettings.sortField !== action.payload.sortField

            dropInLS(LsKeys.WORK_ORDERS_SETTINGS, {
                sortField: action.payload.sortField,
                sortDirection: checkIsNewSortField ? DirectionEnum.ASC : action.payload.sortDirection,
            })
            state.workOrdersSettings.sortField = action.payload.sortField
            state.workOrdersSettings.sortDirection = checkIsNewSortField
                ? DirectionEnum.ASC
                : action.payload.sortDirection
        },
        setInventoryInWoPage: (state, action: PayloadAction<number>) => {
            state.inventoryItemsInWo.page = action.payload
        },
        clearInventoryItemInWo: (state) => {
            state.inventoryItemsInWo = initialState.inventoryItemsInWo
        },
    },
    extraReducers,
})

export const {
    clearCurrentWorkOrder,
    setWorkOrderProfileTab,
    setWorkOrderListTab,
    setWorkOrderPaginationPage,
    setWorkOrdersSearchValue,
    clearWorkOrderSettings,
    setWorkOrdersColumns,
    setCurrentWorkOrderView,
    setWorkOrderFilters,
    setWorkOrderListMode,
    setIsWaitingForResponse,
    setWorkOrderSort,
    setInventoryInWoPage,
    clearInventoryItemInWo,
} = workOrderSlice.actions
export const WorkOrderReducer = workOrderSlice.reducer
