import React, { useEffect, useState } from 'react'
import { useAppDispatch } from 'hooks'
import { SmallContactCard } from 'components/Cards'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import { createContact, getContacts } from 'api/contacts'
import { Contact } from 'components/ContactsList/types'
import { CircularProgress } from '@mui/material'
import CloseButton from 'components/CloseButton'
import { ContactForm } from 'components/ContactForm'
import { CommonDialog } from 'components/CommonDialog'
import { ContactTypes } from 'components/NewSubcontractor/config'
import { TextButton } from 'ui-kit/components/Button'
import EmptyDataPlaceholder from 'components/EmptyDataPlaceholder'

import s from './styles.module.scss'

type PropsType = {
    onClose: () => void
    subcontractorId: string | null
    onChangeSelectedContacts: (contacts: Contact[]) => void
}

export const SendWoDetailsToSubDialog = ({ subcontractorId, onClose, onChangeSelectedContacts }: PropsType) => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [contacts, setContacts] = useState<Contact[]>([])
    const [selectedContacts, setSelectedContacts] = useState<Contact[]>([])
    const [addContact, setAddContact] = useState(false)

    const getContactsData = async () => {
        setIsLoading(true)
        try {
            const res = !!subcontractorId && (await dispatch(getContacts({ link: subcontractorId })).unwrap())

            setContacts(res?.payload)
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    const onChangeChecked = (contact: Contact) => {
        if (selectedContacts.some((i) => i.id === contact.id)) {
            setSelectedContacts(selectedContacts.filter((i) => i.id !== contact.id))
        } else {
            setSelectedContacts([contact, ...selectedContacts])
        }
    }

    const handleCreateContact = async (newContact: Partial<Contact>) => {
        try {
            if (subcontractorId) {
                await dispatch(
                    createContact({
                        ...newContact,
                        link: subcontractorId,
                    }),
                ).unwrap()
            }
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
        await getContactsData()
        setAddContact(false)
    }

    useEffect(() => {
        if (subcontractorId) {
            void getContactsData()
        }
    }, [subcontractorId])

    useEffect(() => {
        onChangeSelectedContacts(selectedContacts)
    }, [JSON.stringify(selectedContacts)])

    return (
        <>
            <CloseButton onClose={onClose} />
            {isLoading && <CircularProgress size={20} className={s.loading} />}
            <div className={s.container}>
                <TextButton text="+ Add New Contact" colorText="#1B6BC0" onClick={() => setAddContact(true)} />
                {contacts?.length ? (
                    contacts?.map((contact) => {
                        const checked = selectedContacts?.some((i) => i.id === contact.id)

                        return (
                            <SmallContactCard
                                contact={contact.user ?? contact}
                                onChange={() => onChangeChecked(contact)}
                                checked={checked}
                            />
                        )
                    })
                ) : (
                    <EmptyDataPlaceholder text="No Contacts" />
                )}
            </div>

            <CommonDialog
                title={'Add Contact'}
                onClose={() => setAddContact(false)}
                isOpen={addContact}
                dialogClassName={s.dialog}
            >
                <ContactForm
                    onClose={() => setAddContact(false)}
                    onSave={handleCreateContact}
                    types={Object.values(ContactTypes)}
                />
            </CommonDialog>
        </>
    )
}
