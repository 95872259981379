import { CommonType, TableSettingsType, USER_ROLE } from 'types'
import { TypeWO } from 'components/NewWorkOrder/config'
import { Availabilities } from 'components/NewSubcontractor/config'
import { NoteInWorkOrderType } from 'slices/workOrder'
import { TeamProfileTab } from 'pages/TeamProfile/config'

export enum TeamAssignmentMethod {
    SELF = 'Self-Assign',
    AUTO = 'Auto-Assign',
}

export type TeamFiltersType = {
    availabilities?: string[]
    requiresApproval?: boolean
    types?: string[]
    subTypes?: string[]
    minAvgWoCost?: number
    maxAvgWoCost?: number
    minAvgHourlyRate?: number
    maxAvgHourlyRate?: number
}

export type TeamsState = {
    currentTeam: CurrentTeamType
    teams: TeamType[]
    isWaitingForResponse: boolean
    teamProfileTab: TeamProfileTab
    teamPaginationPage: number
    teamsCount: number
    teamsSearchValue: string
    teamsSettings: TableSettingsType<TeamFiltersType>
    assignedTeams: AssignedTeamType[]
}

type AvatarType = CommonType & {
    key: string
    url: string
    mimetype: string
    size: number
    createdAt: Date
    updatedAt: Date
}

export type TeamType = {
    id: string
    name: string
    availability: Availabilities
    assignmentMethod: TeamAssignmentMethod
    requiresApproval: boolean
    workOrderTypes: TypeWO[]
    creatorId: string
    customerId: string
    regionId: string
    creationDate: Date
    lastUpdateDate: Date
    deletedAt: Date | null
    workInProgress: number
    workCompleted: number
    workOnHold: number
    workPendingReview: number
    users: UserInTeamType[]
    avatar: AvatarType | null
    region: CommonType
    buildingAssignments: CommonType[]
}

export type CurrentTeamType = TeamType & {
    notes: NoteInWorkOrderType[]
}

export type UserInTeamType = {
    id: string
    firstName: string
    lastName: string
    phone: string
    email: string
    hourlyRate: number | null
    region: CommonType
    avatar: AvatarType | null
    BucketUser: {
        id: string
        role: USER_ROLE
        userId: string
        bucketId: string
        creationDate: Date
        lastUpdateDate: Date
    }
}

export type AssignedTeamType = { id: string }
