import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks'
import { currentUserSelect } from 'store/selectors'
import useDebounce from 'hooks/useDebounce'
import { Autocomplete } from '@mui/material'
import { CustomShowMore } from 'ui-kit/components/CustomShowMore'
import { CustomTextField } from 'ui-kit/components/CustomTextField'
import { getUsersList } from 'api/user'
import { User, USER_ROLE } from 'types'
import { CustomRemovedBox } from 'ui-kit/components/CustomRemovedBox'
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { AutocompletePropsType, ItemForAutocompleteType } from '.'
import Icons from 'assets/icons'
import { capitalized } from 'lib/common'

const USERS_PER_PAGE = 15

export const UsersAutoComplete = ({
    userRole,
    label,
    handleBlur,
    errorText,
    currentValue,
    readOnly = false,
    textFieldClassName,
    isSearch = false,
    disableClearable = false,
    textFieldPlaceholder,
    width = '100%',
    onChangeCallback,
    onDeleteItemClick,
    multiple,
    idDependence,
    disabled,
}: AutocompletePropsType & { userRole: USER_ROLE | USER_ROLE[] }) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const [users, setUsers] = useState<Array<User>>([])
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const debouncedSearch = useDebounce(search, 500)

    const onInputChange = (event: any, newInputValue: string) => {
        setSearch(newInputValue)
        setPage(1)
    }

    const disabledCondition = count <= page * USERS_PER_PAGE || disableClearable

    const onShowMoreClick = (e: React.MouseEvent<HTMLParagraphElement>) => {
        e.preventDefault()
        setPage((prev) => prev + 1)
        getAllValues(page + 1)
    }

    const getAllValues = async (currentPage: number) => {
        setIsLoading(true)
        try {
            const res = await dispatch(
                getUsersList({
                    value: search ? search : undefined,
                    page: currentPage,
                    userRole,
                    size: USERS_PER_PAGE,
                    ...(idDependence as any),
                }),
            ).unwrap()

            setCount(res?.count)
            setUsers((prev) => (currentPage === 1 ? res.rows : [...res.rows, ...prev]))
        } catch (error: any) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!user || typeof idDependence === 'undefined' || search || readOnly) {
            return
        }
        getAllValues(page)
    }, [user, search, JSON.stringify(idDependence), readOnly])

    useEffect(() => {
        if (typeof idDependence === 'undefined' || readOnly) {
            return
        }
        if (debouncedSearch) {
            getAllValues(page)
        }
    }, [debouncedSearch, JSON.stringify(idDependence), readOnly])

    const getOptionLabel = useCallback((option: ItemForAutocompleteType) => {
        return option?.label ?? ''
    }, [])

    const isOptionEqualToValue = (option: ItemForAutocompleteType, value: ItemForAutocompleteType) =>
        option?.value === value?.value

    const getOptions = () => {
        return users?.map((item) => {
            return { value: item.id, label: `${item.firstName} ${item.lastName}` }
        })
    }

    const renderTags = useCallback(
        (value: ItemForAutocompleteType[], getTagProps: AutocompleteRenderGetTagProps) => {
            if (onDeleteItemClick) {
                return ''
            }

            if (value?.length === 1) {
                return (
                    <Paragraph
                        size={'s'}
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {value[0]?.label}
                    </Paragraph>
                )
            } else {
                return (
                    <Paragraph size={'s'} style={{ display: 'flex', gap: '.4rem' }}>
                        {value?.length}
                        <Paragraph size={'s'} color={'grey'}>
                            ({Array.isArray(userRole) ? 'user' : userRole?.toLowerCase()}s)
                        </Paragraph>
                    </Paragraph>
                )
            }
        },
        [onDeleteItemClick],
    )

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, option: ItemForAutocompleteType) => {
            const currentUser = users?.find((i) => i.id === option?.value)

            if (option.value === 'Not found') {
                return (
                    <li style={{ pointerEvents: 'none' }} {...props}>
                        <Icons.NotFoundSVG color="#848a9b" style={{ marginRight: '.4rem' }} />
                        <Paragraph size={'s'} color="grey">
                            Not found
                        </Paragraph>
                    </li>
                )
            }

            return (
                <li {...props}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Paragraph size={'s'} style={{ marginRight: '.4rem' }}>
                            {option?.label}
                        </Paragraph>

                        <Paragraph size={'s'} color="grey">
                            {currentUser?.role ? capitalized(currentUser?.role) : ''}
                        </Paragraph>
                    </div>
                </li>
            )
        },
        [users],
    )

    const getPlaceholder = useMemo(() => {
        setSearch('')
        if (multiple) {
            return currentValue?.length ? '' : textFieldPlaceholder
        }
        return !Array.isArray(currentValue) && currentValue?.value ? '' : textFieldPlaceholder
    }, [multiple, currentValue])

    return (
        <>
            <Autocomplete
                value={currentValue}
                disabled={disabled}
                readOnly={readOnly}
                onChange={(event, newValue) => {
                    onChangeCallback(newValue as ItemForAutocompleteType[] & ItemForAutocompleteType, users)
                    setSearch('')
                }}
                openOnFocus={true}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        flexWrap: 'nowrap',
                        padding: 0,
                    },
                }}
                multiple={multiple}
                inputValue={search}
                onInputChange={onInputChange}
                options={users?.length ? getOptions() : [{ value: 'Not found', label: 'Not found' }]}
                freeSolo
                renderTags={renderTags}
                getOptionLabel={(option) => getOptionLabel(option as ItemForAutocompleteType)}
                renderInput={(params) => (
                    <CustomTextField
                        label={label}
                        onBlur={handleBlur}
                        params={params}
                        errorText={errorText}
                        loading={isLoading}
                        className={textFieldClassName}
                        placeholder={getPlaceholder}
                        isSearch={isSearch}
                    />
                )}
                renderOption={renderOption}
                disableCloseOnSelect={multiple}
                filterOptions={(x) => x}
                limitTags={1}
                style={{ width }}
                loading={isLoading}
                isOptionEqualToValue={isOptionEqualToValue}
                disableClearable={disableClearable}
                PaperComponent={({ children }) => (
                    <CustomShowMore
                        onShowMoreClick={onShowMoreClick}
                        children={children}
                        text={'Show more'}
                        disabled={disabledCondition}
                    />
                )}
            />
            {onDeleteItemClick &&
                multiple &&
                currentValue?.map((item) => {
                    return (
                        <CustomRemovedBox
                            id={item?.value ?? ''}
                            title={item?.label ?? ''}
                            onDeleteClick={onDeleteItemClick}
                        />
                    )
                })}
        </>
    )
}
