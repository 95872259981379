import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AssetHistoryType, HistoryStateType } from '.'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import { getAssetHistory } from 'api/history'

const initialState: HistoryStateType = {
    isWaitingForResponse: false,
    assetHistory: [],
    assetHistoryPaginationPage: 1,
    assetHistoryCount: 0,
}

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setAssetHistoryPaginationPage: (state, action: PayloadAction<number>) => {
            state.assetHistoryPaginationPage = action.payload
        },
        clearAssetHistory: (state) => {
            state.assetHistory = initialState.assetHistory
            state.assetHistoryPaginationPage = initialState.assetHistoryPaginationPage
            state.assetHistoryCount = initialState.assetHistoryCount
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAssetHistory.pending, (state) => {
            state.isWaitingForResponse = true
        })
        builder.addCase(
            getAssetHistory.fulfilled,
            (state, action: PayloadAction<{ count: number; histories: AssetHistoryType[] }>) => {
                state.isWaitingForResponse = false
                state.assetHistory = action.payload.histories
                state.assetHistoryCount = action.payload.count
            },
        )
        builder.addCase(getAssetHistory.rejected, (state, action) => {
            state.isWaitingForResponse = false
            sendNotification(
                (action.payload as string) || 'Something wrong. Please try again',
                NOTIFICATION_STATUS.ERROR,
            )
        })
    },
})

export const { setAssetHistoryPaginationPage, clearAssetHistory } = historySlice.actions
export const HistoryReducer = historySlice.reducer
