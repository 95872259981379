import { CompareKeyType, FiltersForReportsType } from 'components/CommonSavedView'
import { useAppSelector } from 'hooks/redux'
import { removeBlankFields } from 'lib/common'
import { ItemForAutocompleteType } from 'components/CommonAutoComplete'
import { UserStatus } from '../../slices/users'
import useGetUserSettings from '../../pages/UsersTenantsPage/hooks/useGetUserSettings'
import { USER_ROLE, USER_TYPE } from '../../types'

export type UserReportsFiltersType = {
    customerId?: string
    regionId?: string
    role?: USER_ROLE
    status?: UserStatus[]
    type?: USER_TYPE[]
    creationDate?: { [key in CompareKeyType]?: Date }
    lastLogin?: { [key in CompareKeyType]?: Date }
    lastActivity?: { [key in CompareKeyType]?: Date }
    deletedAt?: any
}

export const useUserFiltersForReports = (userRole: USER_ROLE) => {
    const userFilters = useGetUserSettings(userRole)?.filters
    const filters: FiltersForReportsType<UserReportsFiltersType>[] = []
    let filtersCount = 0

    filters.push({ role: userRole })

    if (userFilters) {
        for (const [key, val] of Object.entries(removeBlankFields(userFilters))) {
            if (key === 'regions' && val?.length) {
                filters.push({ regionId: val?.map((item: ItemForAutocompleteType) => item?.value) })
                filtersCount += 1
            }

            if (key === 'statuses' && val?.length) {
                filters.push({ status: val })
                filtersCount += 1
            }

            if (key === 'types' && val?.length) {
                filters.push({ type: val })
                filtersCount += 1
            }

            if (key === 'startDateAdded') {
                filters.push({ creationDate: { '>=': val } })
            }
            if (key === 'endDateAdded') {
                filters.push({ creationDate: { '<=': val } })
                filtersCount += 1
            }

            if (key === 'startLastActivity') {
                filters.push({ lastActivity: { '>=': val } })
            }
            if (key === 'endLastActivity') {
                filters.push({ lastActivity: { '<=': val } })
                filtersCount += 1
            }

            if (key === 'startLastLogin') {
                filters.push({ lastLogin: { '>=': val } })
            }
            if (key === 'endLastLogin') {
                filters.push({ lastLogin: { '<=': val } })
                filtersCount += 1
            }
        }
    }

    return { filters, filtersCount }
}
