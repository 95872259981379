import { CommonFileType, USER_ROLE, USER_TYPE } from '../../types'
import { RegionType } from '../region'
import { AutocompleteType } from '../../components/CommonAutoComplete'
import { Building } from '../building'
import { Room } from '../floorPlanViever'
import { LeaseType } from '../../pages/UsersTenantsPage/types'

export enum UserProfilePageTab {
    PROFILE = 'PROFILE',
    WO_HISTORY = 'WO_HISTORY',
    FILES_NOTES = 'FILES_NOTES',
    LOGIN_HISTORY = 'LOGIN_HISTORY',
    SETTINGS = 'SETTINGS',
    ASSIGNMENTS = 'ASSIGNMENTS',
}

export enum TenantProfileRoutes {
    PROFILE = 'v/profile',
    USERS = 'v/users',
    LEASES = 'v/leases',
    CONTACTS = 'v/contacts',
    NOTES = 'v/notes',
}

export enum UserStatus {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    ARCHIVED = 'ARCHIVED',
}

export type User = {
    id: string
    firstName: string
    lastName: string
    email: string
    type: USER_TYPE
    phone: string
    region: RegionType | null
    regionId: string | null
    role: USER_ROLE
    hourlyRate: string
    isTenant: boolean
    initialSetup: boolean
    title: string
    lastActivity: string
    lastLogin: string
    creationDate: string
    status: UserStatus
    customerId: string
    isExternal?: boolean

    avatar: CommonFileType | null

    buildingAssignments: Array<Building>
    tenantCompanyId: string | null

    TechActiveWorkOrdersNumber: number
    TechLifetimeWoCompleted: number
    TechYTDWoCompleted: number
    TechTotalHourLogged: number
    TechTotalWorkOrdersNumber: number
    TechOverdueWorkOrdersNumber: number

    SupervisorOverdueWorkOrdersNumber: number
    SupervisorOverduePMNumber: number
    SupervisorAssetsNumber: number
    SupervisorTotalWorkOrdersNumber: number
    SupervisorActiveWorkOrdersNumber: number
    SupervisorBuildingsNumber: number

    ReqActiveWorkOrdersNumber: number
    ReqTotalWorkOrdersNumber: number
}

export type LoginHistory = {
    id: string
    creationDate: string
    activity: string
}

export type PermissionsUser = {
    isAdmin: boolean
    isRequestor: boolean
    isTechnician: boolean
    isSupervisor: boolean
    isSuperAdmin: boolean
    isFmPro: boolean

    isMain: boolean
}

export const initialPermissions: PermissionsUser = {
    isAdmin: false,
    isRequestor: false,
    isTechnician: false,
    isSupervisor: false,
    isSuperAdmin: false,
    isFmPro: false,

    isMain: false,
}

export type UserFiltersType = {
    statuses?: UserStatus[]
    regions?: AutocompleteType[]
    types?: USER_TYPE[]
    startDateAdded?: Date | null
    endDateAdded?: Date | null
    startLastActivity?: Date | null
    endLastActivity?: Date | null
    startLastLogin?: Date | null
    endLastLogin?: Date | null
    isArchived?: boolean
}

export enum TenantStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
}

export type Tenant = {
    id: string
    name: string
    website: string
    status: TenantStatus
    leaseFees: number
    leases: Array<Lease>
    buildingCount: number
    regionsCount: number
    totalSqFeet: number
    usersCount: number
    totalUsersInTenantCompanyCount: number
    customerId: string
    creationDate: string

    ActiveWorkOrders: number
    AwaitingRewiewWorkOrders: number
    LifetimeWorkOrdersCompleted: number

    avatar: CommonFileType | null
}

export type LeaseOptions = 'RENEW' | 'VERSION' | 'ADD' | 'VIEW' | 'EDIT' | 'RELOCATE' | 'END' | 'EXPIRED'

export enum LeaseStatus {
    Active = 'Active',
    Expired = 'Expired',
    Cancelled = 'Cancelled',
}

export type Lease = {
    id: string
    region: RegionType
    building: Building
    room: Room
    moveInDate: Date | null
    leaseExpirationDate: Date | null
    leaseType: LeaseType
    leaseStatus: LeaseStatus
    leaseAmount: number
    files: Array<CommonFileType>
}
