import { SubcontractorProfileTab } from 'pages/SubcontractorProfile/config'
import { SubcontractorFiltersType, SubcontractorsState, SubcontractorType } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CommonSortType, DirectionEnum, ListViewMode } from 'types'
import { dropInLS, getSettingsFromLS, LsKeys } from 'lib/workWithLS'
import { TableColumnType } from 'components/Table'
import { extraReducers } from './extraReducers'
import { initialSubcontractorsColumns } from 'pages/SubcontractorsPage/config'

const initialState: SubcontractorsState = {
    currentSubcontractor: {} as SubcontractorType,
    subcontractors: [],
    isWaitingForResponse: false,
    subcontractorProfileTab: SubcontractorProfileTab.CONTACTS,
    subcontractorPaginationPage: 1,
    subcontractorsCount: 0,
    subcontractorsSearchValue: '',
    subcontractorsSettings: {
        filters: getSettingsFromLS('filters', LsKeys.SUBCONTRACTORS_SETTINGS, null),
        columns: getSettingsFromLS('columns', LsKeys.SUBCONTRACTORS_SETTINGS, initialSubcontractorsColumns),
        listMode: getSettingsFromLS('listMode', LsKeys.SUBCONTRACTORS_SETTINGS, ListViewMode.TABLE),
        sortField: getSettingsFromLS('sortField', LsKeys.SUBCONTRACTORS_SETTINGS, 'id'),
        sortDirection: getSettingsFromLS('sortDirection', LsKeys.SUBCONTRACTORS_SETTINGS, DirectionEnum.ASC),
    },
}

export const subcontractorsSlice = createSlice({
    name: 'subcontractors',
    initialState,
    reducers: {
        clearCurrentSubcontractor: (state) => {
            state.currentSubcontractor = initialState.currentSubcontractor
        },
        clearSubcontractorSettings: (state) => {
            state.subcontractorPaginationPage = initialState.subcontractorPaginationPage
            state.subcontractorsSearchValue = initialState.subcontractorsSearchValue
        },
        setSubcontractorProfileTab: (state, action: PayloadAction<SubcontractorProfileTab>) => {
            state.subcontractorProfileTab = action.payload
        },
        setSubcontractorPaginationPage: (state, action: PayloadAction<number>) => {
            state.subcontractorPaginationPage = action.payload
        },
        setSubcontractorsSearchValue: (state, action: PayloadAction<string>) => {
            state.subcontractorsSearchValue = action.payload
        },
        setIsWaitingForResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingForResponse = action.payload
        },

        //settings
        setSubcontractorsColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.SUBCONTRACTORS_SETTINGS, {
                columns: action.payload,
            })

            state.subcontractorsSettings.columns = action.payload
        },
        setSubcontractorsFilters: (state, action: PayloadAction<SubcontractorFiltersType | null>) => {
            dropInLS(LsKeys.SUBCONTRACTORS_SETTINGS, { filters: action.payload })
            state.subcontractorsSettings.filters = action.payload
        },
        setSubcontractorListMode: (state, action: PayloadAction<ListViewMode>) => {
            dropInLS(LsKeys.SUBCONTRACTORS_SETTINGS, { listMode: action.payload })
            state.subcontractorsSettings.listMode = action.payload
        },
        setSubcontractorSort: (state, action: PayloadAction<CommonSortType>) => {
            const checkIsNewSortField = state.subcontractorsSettings.sortField !== action.payload.sortField

            dropInLS(LsKeys.SUBCONTRACTORS_SETTINGS, {
                sortField: action.payload.sortField,
                sortDirection: checkIsNewSortField ? DirectionEnum.ASC : action.payload.sortDirection,
            })
            state.subcontractorsSettings.sortField = action.payload.sortField
            state.subcontractorsSettings.sortDirection = checkIsNewSortField
                ? DirectionEnum.ASC
                : action.payload.sortDirection
        },
    },
    extraReducers,
})

export const {
    clearCurrentSubcontractor,
    clearSubcontractorSettings,
    setSubcontractorProfileTab,
    setSubcontractorsSearchValue,
    setSubcontractorPaginationPage,
    setSubcontractorSort,
    setSubcontractorListMode,
    setSubcontractorsFilters,
    setSubcontractorsColumns,
    setIsWaitingForResponse,
} = subcontractorsSlice.actions

export const SubcontractorReducer = subcontractorsSlice.reducer
