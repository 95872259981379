import { ItemForAutocompleteType } from '../../components/CommonAutoComplete'

export enum LeaseType {
    GROSS = 'Gross',
    SINGLE_NET = 'Single Net',
    DOUBLE_NET = 'Double Net',
    TRIPLE_NET = 'Triple Net',
    MODIFIED_NET = 'Modified Net',
    MODIFIED_GROSS = 'Modified Gross',
    PERCENTAGE = 'Percentage',
    GROUND = 'Ground',
    INDUSTRIAL = 'Industrial',
}

export type LeaseForm = {
    region: ItemForAutocompleteType | null
    regionId: string | null

    building: ItemForAutocompleteType | null
    buildingId: string | null

    room: ItemForAutocompleteType | null
    roomId: string | null

    leaseExpirationDate: Date | null
    moveInDate: Date | null
    leaseAmount: number
    description: string

    leaseFiles: Array<File> | null
    files: Array<File> | null
    leaseType: LeaseType

    hasNewLeaseInformation: boolean
}
