import { CardListContainer } from 'components/CardsContentView'
import React from 'react'
import { User, userAction } from '../../../../../../slices/users'
import CardViewItem from './CardViewItem'
import styles from './styles.module.scss'
import { useAppDispatch } from '../../../../../../hooks'

type Props = {
    users: Array<User>
}

const CardView = ({ users }: Props) => {
    const dispatch = useAppDispatch()
    const handleClickCard = (user: User) => {
        dispatch(userAction.setSelectedUserCard(user))
    }
    return (
        <CardListContainer className={styles.cardsWrap}>
            {users.map((user) => (
                <CardViewItem key={user.id} user={user} onClick={handleClickCard} />
            ))}
        </CardListContainer>
    )
}

export default CardView
