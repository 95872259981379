import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import { AssignedTeamType, CurrentTeamType, TeamsState, TeamType } from 'slices/team/types'
import { CommonResponseType, NOTIFICATION_STATUS } from 'types'
import sendNotification from 'lib/notification'
import {
    addUserToTeam,
    createTeam,
    editTeam,
    editTeamImage,
    getAssignedTeams,
    getTeamById,
    getTeamsList,
    removeUserFromTeam,
} from 'api/teams'

export const extraReducers = (builder: ActionReducerMapBuilder<TeamsState>) => {
    builder.addCase(getTeamsList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getTeamsList.fulfilled, (state, action: PayloadAction<CommonResponseType<TeamType>>) => {
        state.isWaitingForResponse = false
        state.teams = action.payload.rows
        state.teamsCount = action.payload.count
    })
    builder.addCase(getTeamsList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getTeamById.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getTeamById.fulfilled, (state, action: PayloadAction<CurrentTeamType>) => {
        state.isWaitingForResponse = false
        state.currentTeam = action.payload
    })
    builder.addCase(getTeamById.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })

    builder.addCase(addUserToTeam.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(addUserToTeam.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(addUserToTeam.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(removeUserFromTeam.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(removeUserFromTeam.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(removeUserFromTeam.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(createTeam.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createTeam.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(createTeam.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(editTeam.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(editTeam.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(editTeam.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(editTeamImage.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(editTeamImage.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(editTeamImage.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getAssignedTeams.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getAssignedTeams.fulfilled,
        (state, action: PayloadAction<{ count: number; payload: AssignedTeamType[] }>) => {
            state.isWaitingForResponse = false
            state.assignedTeams = action.payload.payload
        },
    )
    builder.addCase(getAssignedTeams.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
}
