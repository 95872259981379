import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react'
import s from './Button.module.scss'
import SvgSelector from 'assets/SvgSelector'

export const Button: FC<TButton> = ({
    buttonProps,
    size = 'md',
    iconOnly,
    leftIconId,
    variants = 'outline',
    children,
    className,
    position = 'center',
    type = 'button',
    ...restProps
}) => {
    if (iconOnly) {
        return (
            <button
                className={`${s[variants]} ${s.button} ${s.iconButton} ${s['iconButton_' + size]}
         ${className}`}
                {...restProps}
            >
                <span className={s.centerBtnContent}>
                    <SvgSelector id={iconOnly} />
                </span>
            </button>
        )
    }

    return (
        <button type={type} className={`${s[variants]} ${s.button} ${className}`} {...restProps}>
            <div className={`${s.centerBtnContent} ${s[position]}`}>
                {leftIconId && <SvgSelector id={leftIconId} />}
                {children}
                {buttonProps?.label}
            </div>
        </button>
    )
}

type TButton = {
    type?: 'button' | 'submit'
    size?: 'sm' | 'md'
    iconOnly?: string
    leftIconId?: string
    variants?: 'outline' | 'filled-blue' | 'filled-red' | 'filled-yellow' | 'poor' | 'filled-grey' | 'text'
    position?: 'left' | 'center'
    buttonProps?: {
        label: string
        action: () => void
    }
    disabled?: boolean
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
