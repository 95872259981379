import styles from './styles.module.scss'

import dashboardSrc from 'assets/img/placeholder-screen/dashboard.png'
import woTimeLineSrc from 'assets/img/placeholder-screen/wo-timeline.png'
import pmSrc from 'assets/img/placeholder-screen/pm-details.png'
import supportSrc from 'assets/img/placeholder-screen/support.png'
import inventorySrc from 'assets/img/placeholder-screen/inventory.png'

export enum PlaceHolderScreenEnum {
    DASHBOARD = 'DASHBOARD',
    SUPPORT = 'SUPPORT',
    INVENTORY = 'INVENTORY',
    PM = 'PM',
    WO_TIMELINE = 'WO_TIMELINE',
}

type Props = {
    screen: PlaceHolderScreenEnum
}
const PlaceHolderScreen = ({ screen }: Props) => {
    switch (screen) {
        case PlaceHolderScreenEnum.DASHBOARD:
            return (
                <div className={styles.root}>
                    <img className={styles.img} src={dashboardSrc} alt="Dashboard" />
                </div>
            )
        case PlaceHolderScreenEnum.SUPPORT:
            return (
                <div className={styles.root}>
                    <img className={styles.img} src={supportSrc} alt="Support" />
                </div>
            )
        case PlaceHolderScreenEnum.INVENTORY:
            return (
                <div className={styles.root}>
                    <img className={styles.img} src={inventorySrc} alt="Inventory" />
                </div>
            )
        case PlaceHolderScreenEnum.WO_TIMELINE:
            return (
                <div className={styles.root}>
                    <img className={styles.img} src={woTimeLineSrc} alt="WO-timeline" />
                </div>
            )
        case PlaceHolderScreenEnum.PM:
            return (
                <div className={styles.root}>
                    <img className={styles.img} src={pmSrc} alt="Pm-details" />
                </div>
            )

        default:
            return null
    }
}

export default PlaceHolderScreen
