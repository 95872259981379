import { createAsyncThunk } from '@reduxjs/toolkit'
import { client, axiosErrorHandler } from './setupAxios'
import { createAppAsyncThunk } from 'common/createAppAsyncThunk'

type GetPlansData = {
    id: string
    offset?: number
    limit?: number
}

type GetPlanByIdReq = {
    id: string
    callbackErr?: () => void
}

export const getPlanById = createAppAsyncThunk(
    'plans/getById',
    async ({ id, callbackErr }: GetPlanByIdReq, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/plan/${id}`)

            return result.data
        } catch (e) {
            callbackErr && callbackErr()
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getPagesPlanByRootId = createAppAsyncThunk(
    'plans/getPagesPlanById',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/plan/document-by-rootId/${id}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getPlansTypesList = createAsyncThunk('plans/getTypesList', async (_, { rejectWithValue }) => {
    try {
        const result = await client.get(`/plans/plan-types`, {})
        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const getPlansListByFloorId = createAsyncThunk(
    'plans/getPlansListByFloorId',
    async ({ id, offset = 0, limit = 100 }: GetPlansData, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/plan/by-plan-type/${id}`, {
                params: {
                    offset,
                    limit,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createPlan = createAppAsyncThunk('plans/create', async (data: FormData, { rejectWithValue }) => {
    try {
        const result = await client.post(`/api/plan`, data)

        return result.data
    } catch (e) {
        return rejectWithValue(axiosErrorHandler(e))
    }
})

export const deleteFloorPlan = createAppAsyncThunk(
    'plans/deleteFloorPlan',
    async (id: string, { rejectWithValue, dispatch }) => {
        try {
            const result = await client.delete(`api/plan/${id}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deletePagePlan = createAppAsyncThunk(
    'plans/deleteFloorPlan',
    async (fileId: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/plan/page/${fileId}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type RenameFloorPlan = {
    id: string
    name: string
}

export const renameFloorPlan = createAppAsyncThunk(
    'plans/renameFloorPlan',
    async (data: RenameFloorPlan, { rejectWithValue, dispatch }) => {
        try {
            const result = await client.patch(`api/plan/rename`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const renamePagePlan = createAppAsyncThunk(
    'plans/renamePagePlan',
    async (data: RenameFloorPlan, { rejectWithValue, dispatch }) => {
        try {
            const result = await client.patch(`/api/plan/rename-file`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
