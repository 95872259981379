import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    AssetPoint,
    AssetsRoomPanelTab,
    AssetCategory,
    Floor,
    FloorPlan,
    Room,
    TypePlan,
    AssetsByCategory,
    AssetThree,
    TypesAssetThree,
    AssetsHidden,
    RoomPoint,
    RoomsHidden,
    PagesPlan,
    SelectedPage,
    Asset,
    FloorPlanLeftPanel,
    AssetType,
} from './types'
import extraReducers from './extraReducers'
import { CoordsXY } from '../../components/KonvaStageFloorPlan'

export interface FloorsState {
    isWaitingForResponse: boolean
    assetTypeLoading: boolean
    assetsLoading: boolean
    planImageLoading: boolean
    assetsRoomPanelTab: AssetsRoomPanelTab
    floors: Array<Floor>
    plansByFloor: Array<FloorPlan>
    selectedFloorId: string | null
    selectedFloor: Floor | null
    editRoom: Room | null

    targetCoords: CoordsXY | null

    categoriesAssets: Array<AssetsByCategory>
    affectedAreaSelectedId: string | null

    searchAssets: Array<Asset>
    searchStringAssets: string

    currentPlan: FloorPlan | null
    pagesPlan: PagesPlan | null
    selectedPage: SelectedPage | null
    selectedNumberPage: number

    assetsPoints: Array<AssetPoint>
    objectsPoints: Array<AssetPoint>
    roomsPoints: Array<RoomPoint>
    planTypes: Array<TypePlan>
    rooms: Array<Room>

    categories: Array<AssetCategory>
    assetThree: AssetThree
    typeAssetThree: TypesAssetThree

    startRelationshipPoint: AssetPoint | null
    showPlottedOnly: boolean
    showRelationships: boolean

    categoriesHidden: AssetsHidden
    typesHidden: AssetsHidden
    assetsHidden: AssetsHidden
    roomsHidden: RoomsHidden

    //new
    floorPlanLeftPanel: FloorPlanLeftPanel | null
    assetsLayerShow: boolean
    roomsLayerShow: boolean
    objectsLayerShow: boolean
    assetRelationSipsLayerShow: boolean
    assetInfoId: string | null
    assetAssignmentsId: string | null

    filterAssetsAssigment: Array<string> | null
    filterRoomsAssigment: Array<string> | null
    filterObjectsAssigment: Array<string> | null

    filterAssetCategories: Array<AssetCategory>
    filterAssetTypes: Array<AssetType>

    addAssetAffectedAreaId: string | null
    roomsAffectedAreasIds: Array<string>
    selectedAddRoomsAffectedAreasIds: Array<string>
    selectedDeleteRoomsAffectedAreasIds: Array<string>
}

const initialState: FloorsState = {
    targetCoords: null,
    assetsRoomPanelTab: AssetsRoomPanelTab.ASSETS,
    isWaitingForResponse: false,
    assetTypeLoading: false,
    assetsLoading: false,
    planImageLoading: false,
    floors: [],
    plansByFloor: [],
    selectedFloorId: null,
    selectedFloor: null,
    categoriesAssets: [],

    searchAssets: [],
    searchStringAssets: '',
    affectedAreaSelectedId: null,

    currentPlan: null,
    pagesPlan: null,
    selectedPage: null,
    selectedNumberPage: 0,

    assetsPoints: [],
    objectsPoints: [],
    roomsPoints: [],
    planTypes: [],
    rooms: [],
    editRoom: null,

    categories: [],
    assetThree: {},
    typeAssetThree: {},

    showPlottedOnly: false,
    showRelationships: true,
    startRelationshipPoint: null,

    categoriesHidden: {},
    typesHidden: {},
    assetsHidden: {},
    roomsHidden: {},

    //
    floorPlanLeftPanel: null,
    assetsLayerShow: true,
    assetRelationSipsLayerShow: true,
    roomsLayerShow: true,
    objectsLayerShow: true,
    assetInfoId: null,
    assetAssignmentsId: null,

    filterAssetsAssigment: null,
    filterRoomsAssigment: null,
    filterObjectsAssigment: null,
    filterAssetCategories: [],
    filterAssetTypes: [],

    addAssetAffectedAreaId: null,
    roomsAffectedAreasIds: [],
    selectedAddRoomsAffectedAreasIds: [],
    selectedDeleteRoomsAffectedAreasIds: [],
}

export const floorViewerSlice = createSlice({
    name: 'floorViewer',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isWaitingForResponse = action.payload
        },
        setFilterAssetsCategories: (state, action: PayloadAction<Array<AssetCategory>>) => {
            state.filterAssetCategories = action.payload
        },
        setFilterAssetsTypes: (state, action: PayloadAction<Array<AssetType>>) => {
            state.filterAssetTypes = action.payload
        },
        setFilterAssetsAssigment: (state, action: PayloadAction<Array<string> | null>) => {
            state.filterAssetsAssigment = action.payload
        },
        setFilterRoomsAssigment: (state, action: PayloadAction<Array<string> | null>) => {
            state.filterRoomsAssigment = action.payload
        },
        setFilterObjectsAssigment: (state, action: PayloadAction<Array<string> | null>) => {
            state.filterObjectsAssigment = action.payload
        },
        setAssetInfoId: (state, action: PayloadAction<string | null>) => {
            state.assetInfoId = action.payload
        },
        setAssetAssignmentsId: (state, action: PayloadAction<string | null>) => {
            state.filterAssetsAssigment = null
            state.filterRoomsAssigment = null
            state.assetAssignmentsId = action.payload
        },
        setPlanImageLoading: (state, action: PayloadAction<boolean>) => {
            state.planImageLoading = action.payload
        },
        setAssetsRoomPanelTab: (state, action: PayloadAction<AssetsRoomPanelTab>) => {
            state.assetsRoomPanelTab = action.payload
        },

        setSelectedFloor: (state, action: PayloadAction<string | null>) => {
            state.selectedFloorId = action.payload
            state.selectedFloor = state.floors.find((item) => item.id === action.payload) || null
        },

        setAffectedAreaSelectedId: (state, action: PayloadAction<string | null>) => {
            state.affectedAreaSelectedId = action.payload
        },

        setSelectedPage: (state, action: PayloadAction<SelectedPage | null>) => {
            state.selectedPage = action.payload
        },
        setSelectedNumberPage: (state, action: PayloadAction<number>) => {
            state.selectedNumberPage = action.payload
        },

        setAssetsLayerShow: (state, action: PayloadAction<boolean>) => {
            state.assetsLayerShow = action.payload
        },

        setRoomsLayerShow: (state, action: PayloadAction<boolean>) => {
            state.roomsLayerShow = action.payload
        },

        setObjectsLayerShow: (state, action: PayloadAction<boolean>) => {
            state.objectsLayerShow = action.payload
        },

        setAssetRelationSipsLayerShow: (state, action: PayloadAction<boolean>) => {
            state.assetRelationSipsLayerShow = action.payload
        },

        setCurrentPlan: (state, action: PayloadAction<FloorPlan | null>) => {
            state.currentPlan = action.payload

            if (action.payload) {
                state.selectedPage = {
                    id: action.payload.document.id,
                    file: action.payload.document.rootFile,
                    page: action.payload.document.page,
                }
            } else {
                state.selectedPage = null
            }
        },

        setStartRelationShipPoint: (state, action: PayloadAction<AssetPoint | null>) => {
            state.startRelationshipPoint = action.payload
        },

        setTargetCoords: (state, action: PayloadAction<CoordsXY | null>) => {
            state.targetCoords = action.payload
        },
        clearCurrentPlan: (state) => {
            state.currentPlan = null
        },

        clearSelectedPage: (state) => {
            state.selectedPage = null
            state.pagesPlan = null
        },

        clearAssetPoints: (state) => {
            state.assetsPoints = []
        },

        clearRoomPoints: (state) => {
            state.roomsPoints = []
        },

        clearCategoryAssetsThrees: (state) => {
            //state.categories = []
            state.typeAssetThree = {}
            state.assetThree = {}
        },

        createNewRoom: (state, action: PayloadAction<Room>) => {
            state.rooms = [...state.rooms, action.payload]
        },
        setEditRoom: (state, action: PayloadAction<Room | null>) => {
            state.editRoom = action.payload
        },

        setShowPlottedOnly: (state, action: PayloadAction<boolean>) => {
            state.showPlottedOnly = action.payload
        },

        setSearchStringAssets: (state, action: PayloadAction<string>) => {
            state.searchStringAssets = action.payload
        },

        setShowRelationships: (state, action: PayloadAction<boolean>) => {
            state.showRelationships = action.payload
        },

        setCategoriesHidden: (state, action: PayloadAction<{ id: string; isHidden: boolean }>) => {
            const { id, isHidden } = action.payload
            state.typesHidden = {}
            state.assetsHidden = {}
            state.categoriesHidden = {
                ...state.categoriesHidden,
                [id]: isHidden,
            }
        },

        setTypesHidden: (state, action: PayloadAction<{ id: string; isHidden: boolean }>) => {
            const { id, isHidden } = action.payload
            state.assetsHidden = {}
            state.typesHidden = {
                ...state.typesHidden,
                [id]: isHidden,
            }
        },

        setAssetsHidden: (state, action: PayloadAction<{ id: string; isHidden: boolean }>) => {
            const { id, isHidden } = action.payload
            state.assetsHidden = {
                ...state.assetsHidden,
                [id]: isHidden,
            }
        },

        setRoomsHidden: (state, action: PayloadAction<{ id: string; isHidden: boolean }>) => {
            const { id, isHidden } = action.payload
            state.roomsHidden = {
                ...state.roomsHidden,
                [id]: isHidden,
            }
        },
        updateAssetPosition: (state, action: PayloadAction<{ fromId: string; x: number; y: number }>) => {
            const { fromId, x, y } = action.payload

            state.assetsPoints = state.assetsPoints.map((point) => {
                if (point.from.id === fromId) {
                    return {
                        ...point,
                        fromX: x,
                        fromY: y,
                    }
                }

                if (point.to && point.to.id === fromId) {
                    return {
                        ...point,
                        toX: x,
                        toY: y,
                    }
                }
                return point
            })
        },

        setFloorPlanLeftPanel: (state, action: PayloadAction<FloorPlanLeftPanel | null>) => {
            state.floorPlanLeftPanel = action.payload
        },
        setAddAssetAffectedAreaId: (state, action: PayloadAction<string | null>) => {
            if (action.payload !== null) {
                state.assetsLayerShow = false
                state.objectsLayerShow = false
                state.assetRelationSipsLayerShow = false
            } else {
                state.assetsLayerShow = true
                state.objectsLayerShow = true
                state.assetRelationSipsLayerShow = true

                state.roomsAffectedAreasIds = []
                state.selectedAddRoomsAffectedAreasIds = []
                state.selectedDeleteRoomsAffectedAreasIds = []
            }
            state.addAssetAffectedAreaId = action.payload
            state.roomsLayerShow = true
        },

        setSelectedAddRoomsAffectedAreasIds: (state, action: PayloadAction<string>) => {
            if (state.selectedAddRoomsAffectedAreasIds.some((item) => item === action.payload)) {
                state.selectedAddRoomsAffectedAreasIds = state.selectedAddRoomsAffectedAreasIds.filter(
                    (item) => item !== action.payload,
                )
            } else {
                state.selectedAddRoomsAffectedAreasIds = [...state.selectedAddRoomsAffectedAreasIds, action.payload]
            }
        },
        setSelectedDeleteRoomsAffectedAreasIds: (state, action: PayloadAction<string>) => {
            if (state.selectedDeleteRoomsAffectedAreasIds.some((item) => item === action.payload)) {
                state.selectedDeleteRoomsAffectedAreasIds = state.selectedDeleteRoomsAffectedAreasIds.filter(
                    (item) => item !== action.payload,
                )
            } else {
                state.selectedDeleteRoomsAffectedAreasIds = [
                    ...state.selectedDeleteRoomsAffectedAreasIds,
                    action.payload,
                ]
            }
        },
    },
    extraReducers,
})

export const floorPlanViewerActions = floorViewerSlice.actions
export const FloorViewerReducer = floorViewerSlice.reducer
