import classNames from 'classnames'
import styles from './styles.module.scss'
import CustomCheckBox from 'ui-kit/components/CustomCheckBox'
import PlanTypeIcon from '../PlanTypeIcon/PlanTypeIcon'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import React from 'react'
import { Asset } from 'slices/floorPlanViever'

type Props = {
    asset: Asset
    checked: boolean
    disabled?: boolean
    onSelectAsset: (id: string, checked: boolean, asset: Asset) => void
    isSearch?: boolean
    isHidden?: boolean
}

const AssetCheckBoxRow = ({ asset, checked, onSelectAsset, isSearch = false, disabled, isHidden }: Props) => {
    return (
        <div
            className={classNames(
                styles.assetRow,
                checked && styles.checked,
                isSearch && styles.search,
                (isHidden || disabled) && styles.disabled,
            )}
        >
            <CustomCheckBox
                checked={checked}
                className={styles.assetCheckBox}
                onChange={(e) => onSelectAsset(asset.id, e.target.checked, asset)}
                label={
                    <div className={styles.accordionRowWrap}>
                        <PlanTypeIcon
                            src={asset.category?.file?.url}
                            planType={asset?.category?.name}
                            color={asset?.category?.color}
                            size={30}
                        />

                        <Paragraph size="s" weight="tiny">
                            {asset.name}
                        </Paragraph>
                    </div>
                }
            />
        </div>
    )
}

export default AssetCheckBoxRow
