import { clearToken } from 'slices/AuthSlice'
import { ColumnForFeType } from 'components/CommonSavedView'
import { LsKeys } from 'lib/workWithLS'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

export const getOptions = <T, K extends keyof T>(array: T[], valueField: K, labelField: K) => {
    return array?.map((item) => {
        return { value: item[valueField], label: item[labelField] }
    })
}

export const getOptionsFromEnum = (obj: object) => {
    return Object.entries(obj).map(([key, value]) => {
        return { value, label: value }
    })
}

export const getOptionsFromEnumWithKeys = (obj: object) => {
    return Object.entries(obj).map(([key, value]) => {
        return { value: key, label: value }
    })
}

export const noop = () => {
    return
}

export const sendWillBeSoon = () => {
    return sendNotification('This functionality will be added soon', NOTIFICATION_STATUS.INFO)
}

export const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat(['ban', 'id']).format(date)
}

export const formatBigNumber = (n: number) => {
    if (n >= 1e3 && n < 1e6) {
        return +(n / 1e3).toFixed(1) + ' K'
    }
    if (n >= 1e6 && n < 1e9) {
        return +(n / 1e6).toFixed(1) + ' M'
    }
    if (n >= 1e9 && n < 1e12) {
        return +(n / 1e9).toFixed(1) + ' B'
    }
    if (n >= 1e12) {
        return +(n / 1e12).toFixed(1) + ' T'
    }

    return n.toString()
}

export const checkIsGoodStatus = (status?: number) => {
    return status && status >= 200 && status < 400
}

export const wordSplit = (word: string) => {
    return word
        .replace(/_/g, '')
        .split(/(?=[A-Z])/)
        .map((oneWord) => oneWord[0].toUpperCase() + oneWord.slice(1).toLowerCase())
        .join(' ')
}

export const clearLS = (dispatch: Dispatch<AnyAction>) => {
    localStorage.removeItem(LsKeys.REFRESH)
    localStorage.removeItem(LsKeys.TOKEN)
    localStorage.removeItem(LsKeys.IS_AUTH)

    dispatch(clearToken())
}

export const transformRightPointToNormal = (obj: Array<any>, assetId: string) =>
    obj.map((o) => {
        if (o.type === 'ARROW') {
            return [{ ...o }, { ...o, from: { ...o.to }, fromX: o.toX, fromY: o.toY, to: null, type: 'POINT' }]
        }
        return o
    })

export const transformLeftPointToNormal = (obj: Array<any>) =>
    obj.map((o) => {
        if (o.type === 'ARROW') {
            return [{ ...o }, { ...o, to: null, type: 'POINT' }]
        }
        return o
    })

export const removeBlankFields = (obj: { [key: string]: any }) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v))
}

export const capitalized = (str: string) => {
    const lowStr = str.toLowerCase()
    return lowStr.charAt(0).toUpperCase() + lowStr.slice(1)
}

export const replaceIdx = (idx: number, columnForFE?: ColumnForFeType) => {
    if (!columnForFE) {
        return
    }

    columnForFE.idx = idx

    if (columnForFE?.columnsForFE) {
        replaceIdx(idx, columnForFE.columnsForFE[0])
    }

    return columnForFE
}

export const printReport = (res: Blob) => {
    const blobURL = URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))

    const iframe = document.createElement('iframe')
    document.body.appendChild(iframe)
    iframe.style.display = 'none'
    iframe.src = blobURL
    iframe.onload = () => {
        setTimeout(() => {
            iframe.focus()
            iframe.contentWindow?.print()
        }, 1)
    }
}

//color converter
export const hsl2hex = (h?: number) => {
    if (!h) {
        return
    }

    const s = 1
    const l = 0.5
    const a = s * Math.min(l, 1 - l)
    const f = (n: number, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    const rgb = [f(0), f(8), f(4)]

    return (
        '#' +
        rgb
            .map((x) =>
                Math.round(x * 255)
                    .toString(16)
                    .padStart(2, '0'),
            )
            .join('')
    )
}

export const hex2hsl = (hex: string | null) => {
    if (!hex) {
        return
    }

    const parseData = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    const r = parseData ? parseInt(parseData[1], 16) : 0
    const g = parseData ? parseInt(parseData[2], 16) : 0
    const b = parseData ? parseInt(parseData[3], 16) : 0

    const v = Math.max(r, g, b)
    const c = v - Math.min(r, g, b)
    //f = 1 - Math.abs(v + v - c - 1)
    const h = c && (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c)
    return 60 * (h < 0 ? h + 6 : h)
}

export const filterPassedTime = (minDate: Date, date: Date) => {
    const currentDate = new Date(minDate)
    const selectedDate = new Date(date)

    return currentDate.getTime() < selectedDate.getTime()
}

export const getTimeDifference = (startDate: Date, endDate: Date): number => {
    return +(dayjs(endDate).diff(dayjs(startDate), 'm') / 60).toFixed(2)
}

export const getDatePlusOneHour = (date: Date) => {
    return new Date(date.setHours(date.getHours() + 1))
}

export const getDateMinusOneHour = (date: Date) => {
    return new Date(date.setHours(date.getHours() - 1))
}
