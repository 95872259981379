import styles from './styles.module.scss'
import Icons from 'assets/icons'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { ReactNode } from 'react'

type Props = {
    text: string
    icon?: ReactNode
}
const EmptyDataPlaceholder = ({ text, icon }: Props) => {
    return (
        <div className={styles.root}>
            <div className={styles.placeholderWrap}>
                {icon || <Icons.EmptyDataSmileSVG />}
                <Paragraph size="s" weight="tiny" className={styles.textNoData} color="grey" position="center">
                    {text}
                </Paragraph>
            </div>
        </div>
    )
}

export default EmptyDataPlaceholder
