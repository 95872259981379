import React from 'react'
import {
    AutocompleteRenderInputParams,
    CircularProgress,
    InputAdornment,
    InputLabel,
    TextField,
    Tooltip,
} from '@mui/material'
import classNames from 'classnames'
import Icons from 'assets/icons'
import s from './styles.module.scss'
import { TextFieldProps } from '@mui/material/TextField/TextField'

type CustomTextFieldType = {
    label?: string
    width?: string
    errorText?: string
    className?: string
    params?: AutocompleteRenderInputParams
    loading?: boolean
    isSearch?: boolean
} & TextFieldProps

export const CustomTextField = ({
    label,
    width = '100%',
    params,
    className,
    errorText,
    loading,
    isSearch,
    ...rest
}: CustomTextFieldType) => {
    return (
        <div style={{ width }}>
            {!!label && <InputLabel className={s.label}>{label}</InputLabel>}

            <Tooltip title={errorText ? errorText : ''} placement={'right'}>
                <TextField
                    className={classNames(s.container, errorText && s.error, className)}
                    {...params}
                    {...rest}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            fontFamily: 'Poppins',
                            fontSize: '12px',
                            padding: '0 !important',
                            '& fieldset': {
                                borderColor: 'transparent !important',
                            },
                        },
                    }}
                    InputProps={{
                        ...params?.InputProps,
                        startAdornment: isSearch ? (
                            <InputAdornment position="start">
                                <Icons.SearchSVG />
                            </InputAdornment>
                        ) : (
                            params?.InputProps.startAdornment
                        ),
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={15} /> : null}
                                {params?.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            </Tooltip>
        </div>
    )
}
