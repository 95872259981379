import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import styles from '../../styles.module.scss'
import { TableHead as MuiTableHead } from '@mui/material'

type Props = {
    children: ReactNode
    className?: string
}
const TableHead: FC<Props> = ({ children, className }) => {
    return <MuiTableHead className={classNames(styles.tableHead, className)}>{children}</MuiTableHead>
}

export default TableHead
