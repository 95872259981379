import React from 'react'
import { useNavigate } from 'react-router-dom'
import s from './styles.module.scss'
import { Paragraph } from '../../../../ui-kit/components/Typography/Typography'
import AuthInput from '../../../../ui-kit/components/AuthInput/AuthInput'
import { Button } from '../../../../ui-kit/components/Button/Button'
import { CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import { verifyPassword } from '../../../../api/auth'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import * as yup from 'yup'
import AuthRoutes from '../../routes'
import { emailRegExp, MUST_BE_EMAIL, REQUIRED_FIELD, TOO_LONG } from 'pages/NewAssetPage/config'
import { LengthConstrains } from 'types'

type LoginForm = {
    email: string
    password: string
}

const validationSchema = yup.object().shape({
    email: yup.string().matches(emailRegExp, MUST_BE_EMAIL).required(REQUIRED_FIELD),
    password: yup.string().max(LengthConstrains.L_16, TOO_LONG).trim().required(REQUIRED_FIELD),
})

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const isWaitingForResponse = useAppSelector(({ auth }) => auth.isWaitingForResponse)

    const { handleChange, values, errors, isValid, handleSubmit, resetForm } = useFormik<LoginForm>({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (val, actions) => {
            await dispatch(verifyPassword({ ...val, callback: handleNavigateToVerify }))
        },
    })

    const handleNavigateToVerify = () => {
        navigate('/' + AuthRoutes.VERIFY, { replace: true, state: { ...values, mode: 'login' } })
    }

    const handleForgotPasswordClick = () => {
        navigate('/' + AuthRoutes.FORGOT, { replace: true })
    }

    return (
        <form className={s.contentContainer}>
            <Paragraph className={`${s.logIn} ${isWaitingForResponse ? 'opacity' : ''}`} color={'white'}>
                Log in
            </Paragraph>
            <Paragraph className={`${s.title} ${isWaitingForResponse ? 'opacity' : ''}`} color={'white'}>
                Please login to access your management portal
            </Paragraph>
            <div className={s.inputsContainer}>
                <AuthInput
                    id="email"
                    title="Email"
                    value={values.email}
                    onChange={handleChange}
                    error={errors.email}
                    disabled={isWaitingForResponse}
                    onEnterPress={handleSubmit}
                />
                <AuthInput
                    id="password"
                    title="Password"
                    value={values.password}
                    onChange={handleChange}
                    type="password"
                    error={errors.password}
                    disabled={isWaitingForResponse}
                    onEnterPress={handleSubmit}
                />
            </div>
            <Button
                className={s.logInBtn}
                onClick={() => handleSubmit()}
                disabled={!isValid}
                variants={'filled-yellow'}
            >
                {isWaitingForResponse ? (
                    <CircularProgress
                        sx={{
                            color: 'white',
                            '& .MuiCircularProgress-svg': {
                                margin: 'auto',
                                height: 40,
                                width: 20,
                            },
                        }}
                        color="inherit"
                    />
                ) : (
                    <Paragraph size={'s'} weight={'semi-bold'}>
                        Log in
                    </Paragraph>
                )}
            </Button>
            <div
                className={`${s.forgotPasswordLink} ${isWaitingForResponse ? 'opacity' : ''}`}
                onClick={handleForgotPasswordClick}
            >
                Forgot password?
            </div>
        </form>
    )
}

export default Login
