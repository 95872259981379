import { useEffect, useState } from 'react'
import { CategoryInAssetType } from 'slices/asset'
import { getCategoriesForAssetList } from 'api/assets'
import { useAppDispatch } from 'hooks/redux'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import { useInfinityScroll } from 'hooks/index'

type UseAssetCategoriesType = {
    isAllow: boolean
}

const CATEGORIES_LIMIT = 100

export const useAssetCategories = ({ isAllow }: UseAssetCategoriesType) => {
    const dispatch = useAppDispatch()
    const [assetCategories, setAssetCategories] = useState<CategoryInAssetType[]>([])
    const [isCategoriesLoading, setIsCategoriesLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)

    const { setLastElement: setCategoryLastElement } = useInfinityScroll({
        fetchNextData: () => setPage(page + 1),
        hasMore: assetCategories.length < count,
    })

    const getAssetCategories = async () => {
        setIsCategoriesLoading(true)
        try {
            const res = await dispatch(
                getCategoriesForAssetList({ limit: CATEGORIES_LIMIT, offset: CATEGORIES_LIMIT * (page - 1) }),
            ).unwrap()
            if (res) {
                setAssetCategories(page === 1 ? res.categories : [...assetCategories, ...res.categories])
                setCount(res?.count)
            }
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsCategoriesLoading(false)
        }
    }
    useEffect(() => {
        if (isAllow) {
            getAssetCategories()
        }
    }, [isAllow, page])

    return { assetCategories, setCategoryLastElement, isCategoriesLoading }
}
