import styles from './styles.module.scss'
import { RadioGroup } from '@mui/material'
import CustomRadio from 'ui-kit/components/CustomRadio'
import classNames from 'classnames'
import SaveCancelPanel from 'components/SaveCancelPanel'

export type TypeAddContact = 'system' | 'non_system'

type Props = {
    typeAddContact: TypeAddContact
    setTypeAddContact: (val: TypeAddContact) => void
    onContinue: () => void
    systemLabel?: string
    nonSystemLabel?: string
}
const SelectSystemVariantDialog = ({
    typeAddContact,
    setTypeAddContact,
    onContinue,
    systemLabel = 'System User',
    nonSystemLabel = 'Non-System User',
}: Props) => {
    return (
        <>
            <RadioGroup
                defaultValue={'system'}
                aria-labelledby="contact-radio"
                name="contact-radio"
                value={typeAddContact}
                sx={{
                    alignItems: 'center',
                }}
                onChange={(e) => setTypeAddContact(e.target.value as TypeAddContact)}
            >
                <CustomRadio
                    label={systemLabel}
                    formClassName={classNames(styles.radioBtnRow, typeAddContact === 'system' && styles.active)}
                    value={'system'}
                />
                <CustomRadio
                    label={nonSystemLabel}
                    formClassName={classNames(styles.radioBtnRow, typeAddContact === 'non_system' && styles.active)}
                    value={'non_system'}
                />
            </RadioGroup>

            <SaveCancelPanel onSave={onContinue} saveButtonText="Continue" className={styles.btnWrap} />
        </>
    )
}

export default SelectSystemVariantDialog
