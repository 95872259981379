import styles from './FilesDropzoneLoader.module.scss'
import { Tooltip } from '@mui/material'
import FileIcon from '../FileIcon'
import { filesize } from 'filesize'
import Icons from '../../assets/icons'
import React from 'react'

type Props = {
    files: Array<File>
    onDelete: (file: File) => void
}
const AddedFilesList = ({ files, onDelete }: Props) => {
    return (
        <div className={styles.filesWrap}>
            {files.map((file, i) => (
                <Tooltip key={file.name + i} title={file.name}>
                    <div className={styles.fileRow}>
                        <FileIcon accept={file.type} size={25} />
                        <span>{file.name}</span>
                        <div className={styles.fileSize}>{filesize(file.size)}</div>
                        <div className={styles.delete} onClick={() => onDelete(file)}>
                            <Icons.CrossSVG />
                        </div>
                    </div>
                </Tooltip>
            ))}
        </div>
    )
}

export default AddedFilesList
