import { PageTabType } from 'components/PageTabPanel'

export const breadCrumbsArr = (name: string) => {
    return [
        {
            name: 'All Preventative Maintenance',
            link: `/preventative-maintenance`,
        },
        {
            name: `${name ?? ''}`,
            link: `#`,
        },
    ]
}

export enum PMProfileTab {
    DETAILS = 'DETAILS',
    FILES_MOPS = 'FILES_MOPS',
    REPORTS = 'REPORTS',
    SERVICER = 'SERVICER',
    MAINTENANCE = 'MAINTENANCE',
    NOTES = 'NOTES',
    //PM_HISTORY = 'PM_HISTORY',
}

export const tabs: PageTabType[] = [
    {
        id: PMProfileTab.DETAILS,
        label: 'Details',
    },
    {
        id: PMProfileTab.FILES_MOPS,
        label: 'Files',
    },
    {
        id: PMProfileTab.REPORTS,
        label: 'Reports',
    },
    {
        id: PMProfileTab.SERVICER,
        label: 'Servicer',
    },
    {
        id: PMProfileTab.MAINTENANCE,
        label: 'Maintenance',
    },
    {
        id: PMProfileTab.NOTES,
        label: 'Notes',
    },
    /*{
        id: PMProfileTab.PM_HISTORY,
        label: 'PM History',
    },*/
]
