import React, { useEffect } from 'react'
import s from './styles.module.scss'
import { Paragraph } from '../../../../ui-kit/components/Typography/Typography'
import AuthInput from '../../../../ui-kit/components/AuthInput/AuthInput'
import { Button } from '../../../../ui-kit/components/Button/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import AuthRoutes from '../../routes'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { verifyCodeForgot, verifyCodeLogin, verifyPassword } from '../../../../api/auth'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { clearState } from '../../../../slices/AuthSlice'
import { MUST_BE_NUMBER, REQUIRED_FIELD } from 'pages/NewAssetPage/config'

const validationSchema = yup.object().shape({
    code: yup.number().typeError(MUST_BE_NUMBER).required(REQUIRED_FIELD),
})

type CodeForm = {
    code: number | undefined
}

type Props = {
    mode: 'login' | 'forgot'
}

const VerificationCode = ({ mode }: Props) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { state } = useLocation()
    const isWaitingForResponse = useAppSelector(({ auth }) => auth.isWaitingForResponse)

    const { handleChange, values, errors, handleSubmit, isValid } = useFormik<CodeForm>({
        initialValues: {
            code: undefined,
        },
        validationSchema: validationSchema,
        onSubmit: async (val, actions) => {
            if (state && state.mode === 'login') {
                await dispatch(
                    verifyCodeLogin({
                        email: state.email || '',
                        password: state.password || '',
                        verificationCode: val.code || 0,
                    }),
                )
            }

            if (state && state.mode === 'forgot') {
                await dispatch(
                    verifyCodeForgot({
                        email: state.email || '',
                        verificationCode: val.code || 0,
                        callback: handleNavigateToNewPassword,
                    }),
                )
            }
        },
    })

    const handleNavigateToNewPassword = () => {
        navigate('/' + AuthRoutes.NEW_PASSWORD, {
            replace: true,
            state: {
                email: state.email || '',
                verificationCode: values.code || 0,
            },
        })
    }

    const handleResendCode = async () => {
        if (state) {
            await dispatch(verifyPassword({ email: state.email, password: state.password }))
        }
    }

    const onBackToLoginClick = () => {
        navigate('/' + AuthRoutes.LOGIN, { replace: true })
    }

    useEffect(() => {
        if (!state?.mode) {
            dispatch(clearState())
            navigate('/', { replace: true })
        }
    }, [state])

    return (
        <div className={s.contentContainer}>
            <Paragraph className={`${s.logIn} ${isWaitingForResponse ? 'opacity' : ''}`} color={'white'}>
                Verification code has been sent
            </Paragraph>
            <Paragraph className={`${s.title} ${isWaitingForResponse ? 'opacity' : ''}`} color={'white'}>
                Please enter the verification code that was sent to you email
            </Paragraph>
            <div className={s.inputsContainer}>
                <AuthInput
                    id="code"
                    name="code"
                    title="Verification code"
                    value={values.code}
                    onChange={handleChange}
                    type="number"
                    error={errors.code}
                    disabled={isWaitingForResponse}
                    onEnterPress={handleSubmit}
                />
            </div>

            <div className={`${s.resend_title} ${isWaitingForResponse ? 'opacity' : ''}`}>
                Didn’t receive a code?{' '}
                <span className={s.resend_link} onClick={handleResendCode}>
                    Resend
                </span>
            </div>

            <Button
                className={s.logInBtn}
                onClick={() => handleSubmit()}
                variants={'filled-yellow'}
                disabled={!isValid}
            >
                {isWaitingForResponse ? (
                    <CircularProgress
                        sx={{
                            color: 'white',
                            '& .MuiCircularProgress-svg': {
                                margin: 'auto',
                                height: 40,
                                width: 20,
                            },
                        }}
                        color="inherit"
                    />
                ) : (
                    <Paragraph size={'s'} weight={'semi-bold'}>
                        Next
                    </Paragraph>
                )}
            </Button>
            <div
                className={`${s.forgotPasswordLink} ${isWaitingForResponse ? 'opacity' : ''}`}
                onClick={onBackToLoginClick}
            >
                Return {mode === 'login' && 'to Login'}
            </div>
        </div>
    )
}

export default VerificationCode
