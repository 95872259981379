import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { client, axiosErrorHandler } from './setupAxios'

type CreatePointData = {
    pageId: string
    fromId: string
    fromX: number
    fromY: number
    toId?: string
    toX?: number
    toY?: number
}

export const createPoint = createAppAsyncThunk(
    'points/createPoint',
    async (data: CreatePointData, { rejectWithValue, dispatch, getState }) => {
        try {
            const result = await client.post(`/api/asset/points`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createObjectPoint = createAppAsyncThunk(
    'points/createObjectPoint',
    async (data: CreatePointData, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/objects/points`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type UpdatePointData = {
    id: string
    fromId: string
    fromX: number
    fromY: number
}

export const updatePoint = createAppAsyncThunk(
    'points/updatePoint',
    async ({ id, ...data }: UpdatePointData, { rejectWithValue, dispatch, getState }) => {
        try {
            const result = await client.patch(`/api/asset/points/${id}`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const updateObjectPoint = createAppAsyncThunk(
    'points/updateObjectPoint',
    async ({ id, ...data }: UpdatePointData, { rejectWithValue, dispatch, getState }) => {
        try {
            const result = await client.patch(`/api/objects/points/${id}`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type DeletePointData = {
    pageId: string
    fromId: string
}

export const deletePoint = createAppAsyncThunk(
    'points/deletePoint',
    async ({ pageId, fromId }: DeletePointData, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/asset/points/${pageId}/${fromId}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
export const deletePointById = createAppAsyncThunk(
    'points/deletePointById',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/asset/points/${id}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteObjectPointBy = createAppAsyncThunk(
    'points/deleteObjectPointBy',
    async ({ pageId, fromId }: DeletePointData, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/objects/points/${pageId}/${fromId}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetPointsByPageData = {
    pageId: string
    categoryIdes?: Array<string>
    typeIdes?: Array<string>
    assetIdes?: Array<string>
    offset?: number
    limit?: number
    leftIncludeAssetId?: string
    rightIncludeAssetId?: string
}

export const getAssetPointsByPageId = createAppAsyncThunk(
    'points/getPointsByPage',
    async (
        { offset = 0, pageId, limit = 100, categoryIdes, typeIdes, assetIdes }: GetPointsByPageData,
        { rejectWithValue, dispatch, getState },
    ) => {
        try {
            const result = await client.get(`/api/asset/points/${pageId}`, {
                params: {
                    offset,
                    limit,
                    'categoryIdes[]': categoryIdes,
                    'typeIdes[]': typeIdes,
                    'assetIdes[]': assetIdes,
                },
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getObjectPointsByPageId = createAppAsyncThunk(
    'points/getObjectPointsByPageId',
    async ({ offset = 0, pageId, limit = 100 }: GetPointsByPageData, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/objects/points/${pageId}`, {
                params: {
                    offset,
                    limit,
                },
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getLeftAssetPointsByPageIdForAsset = createAppAsyncThunk(
    'points/getLeftAssetPointsByPageIdForAsset',
    async (
        { offset = 0, pageId, limit = 100, leftIncludeAssetId }: GetPointsByPageData,
        { rejectWithValue, dispatch },
    ) => {
        try {
            const result = await client.get(`/api/asset/points/${pageId}`, {
                params: {
                    offset,
                    limit,
                    leftIncludeAssetId,
                },
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getRightAssetPointsByPageIdForAsset = createAppAsyncThunk(
    'points/getRightAssetPointsByPageIdForAsset',
    async (
        { offset = 0, pageId, limit = 100, rightIncludeAssetId }: GetPointsByPageData,
        { rejectWithValue, dispatch },
    ) => {
        try {
            const result = await client.get(`/api/asset/points/${pageId}`, {
                params: {
                    offset,
                    limit,
                    rightIncludeAssetId,
                },
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
