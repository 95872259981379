import React from 'react'
import { CommonType } from 'types'
import PlanTypeIcon from 'components/PlanTypeIcon/PlanTypeIcon'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { CardContainer, CardFlexRow } from 'components/CardsContentView'
import CardRowItem from 'components/CardsContentView/CardRowItem'

import s from './AssetCard.module.scss'

type Props = {
    category: CommonType & {
        color: string
        file: { url: string } | null
    }
    name: string
    type: CommonType
    equipmentId?: string
    onCardClick: () => void
}

export const AssetSmallCard = ({ onCardClick, ...asset }: Props) => {
    return (
        <CardContainer className={s.box} onClick={onCardClick}>
            <CardFlexRow justifyContent="flex-start">
                <PlanTypeIcon
                    src={asset?.category.file?.url}
                    planType={asset?.category.name}
                    color={asset?.category.color}
                    size={30}
                />
                <div>
                    <Paragraph size={'s'} className={s.noWrap}>
                        {asset.name}
                    </Paragraph>
                    <CardFlexRow justifyContent="space-between" className={s.row}>
                        <CardRowItem value={asset.type.name} title={'Type:'} className={s.noWrap} />
                        <CardRowItem value={asset.equipmentId} title={'Equipment ID:'} className={s.noWrap} />
                    </CardFlexRow>
                </div>
            </CardFlexRow>
        </CardContainer>
    )
}
