import { Route, Routes, useNavigate } from 'react-router-dom'
import React, { lazy } from 'react'
import { useAppSelector } from '../hooks'
import { permissionsUser } from '../store/selectors'
import { ROUTES } from './const'

const BuildingsPage = lazy(() => import('pages/BuildingsPage'))
const BuildingProfile = lazy(() => import('pages/BuildingProfile'))

const BuildingsRoutes = () => {
    const navigate = useNavigate()
    const { isSuperAdmin } = useAppSelector(permissionsUser)

    if (isSuperAdmin) {
        navigate(ROUTES.DASHBOARD)
    }

    return (
        <Routes>
            <Route index element={<BuildingsPage />} />
            <Route path=":id" element={<BuildingProfile />} />

            <Route path="*" element={<BuildingsPage />} />
        </Routes>
    )
}

export default BuildingsRoutes
