import pdf from './pdf-icon.svg'
import csv from './csv-icon.svg'
import doc from './doc-icon.svg'
import jpg from './jpg-icon.svg'
import png from './png-icon.svg'
import tiff from './tiff-icon.svg'
import defaultFile from './default-icon.svg'
import xls from './xls-icon.svg'
import { ReactComponent as PdfSmall } from './pdf-small-icon.svg'
import { ReactComponent as XlsxSmall } from './xlsx-small-icom.svg'
import { ReactComponent as CsvSmall } from './csv-small-icon.svg'
import { AcceptFile } from 'types'

export const getFileSvgImageByAccept = (accept: AcceptFile | string) => {
    switch (accept) {
        case AcceptFile.DOC_1:
        case AcceptFile.DOC_2:
            return doc

        case AcceptFile.PDF:
            return pdf

        case AcceptFile.XLS_1:
        case AcceptFile.XLS_2:
        case AcceptFile.XLS_3:
        case AcceptFile.XLS_4:
        case AcceptFile.XLS_5:
            return xls

        case AcceptFile.CSV:
            return csv

        case AcceptFile.JPG_1:
        case AcceptFile.JPG_2:
            return jpg

        case AcceptFile.PNG:
            return png
        case AcceptFile.TIFF_1:
        case AcceptFile.TIFF_2:
            return tiff

        default:
            return defaultFile
    }
}

export const FileIcons = { PdfSmall, CsvSmall, XlsxSmall }
