import * as T from 'components/Table'

export const customersTableColumns: T.TableColumnType[] = [
    { label: 'Sign Up Date', name: 'creationDate' },
    { label: 'Status', name: 'status' },
    { label: 'City/State', name: 'cityState' },
    { label: 'Total Sq Ft', name: 'totalArea' },

    { label: '# of Buildings', name: 'buildingsCount' },
    { label: 'Total Assets', name: 'assetsCount' },
    { label: 'Total Users', name: 'usersCount' },
    { label: 'Total Inventory', name: 'inventoryItemsCount' },
    { label: 'Website', name: 'website' },
    { label: 'Subscription Terms', name: 'subscriptionTerms' },
    { label: 'Subscription Fee', name: 'subscriptionFee' },
    { label: 'Payment Terms', name: 'paymentTerms' },
    { label: 'Tax ID#', name: 'taxId' },
]

export const visibleCustomersTableColumns: T.TableColumnType[] = [
    { label: 'Sign Up Date', name: 'creationDate' },
    { label: 'Status', name: 'status' },
    { label: 'City/State', name: 'cityState' },
    { label: 'Total Sq Ft', name: 'totalArea' },
]

export const requiredCustomersTableColumns: T.TableColumnType[] = [{ label: 'Company Name', name: 'name' }]
