import { StoreRootState } from '..'
import { SubcontractorFiltersType, SubcontractorType } from 'slices/subcontractor'
import { SubcontractorProfileTab } from 'pages/SubcontractorProfile/config'
import { CommonSortType, ListViewMode } from 'types'
import { TableColumnType } from 'components/Table'

export const currentSubcontractorSelect = (state: StoreRootState): SubcontractorType =>
    state.subcontractors.currentSubcontractor
export const isLoadingSubcontractorSelect = (state: StoreRootState): boolean =>
    state.subcontractors.isWaitingForResponse
export const subcontractorProfileTabSelect = (state: StoreRootState): SubcontractorProfileTab =>
    state.subcontractors.subcontractorProfileTab
export const subcontractorSearchValueSelect = (state: StoreRootState): string =>
    state.subcontractors.subcontractorsSearchValue
export const subcontractorCurrentPageSelect = (state: StoreRootState): number =>
    state.subcontractors.subcontractorPaginationPage
export const subcontractorsCountSelect = (state: StoreRootState): number => state.subcontractors.subcontractorsCount
export const subcontractorsSelect = (state: StoreRootState): SubcontractorType[] => state.subcontractors.subcontractors
export const subcontractorListModeSelect = (state: StoreRootState): ListViewMode =>
    state.subcontractors.subcontractorsSettings.listMode
export const subcontractorsSortSelect = (state: StoreRootState): CommonSortType => ({
    sortField: state.subcontractors.subcontractorsSettings.sortField,
    sortDirection: state.subcontractors.subcontractorsSettings.sortDirection,
})
export const subcontractorsColumnsSelect = (state: StoreRootState): TableColumnType[] =>
    state.subcontractors.subcontractorsSettings.columns
export const subcontractorsFiltersSelect = (state: StoreRootState): SubcontractorFiltersType | null =>
    state.subcontractors.subcontractorsSettings.filters
