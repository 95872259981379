import { useAppSelector } from 'hooks/redux'
import { inventoryItemsFiltersSelect } from 'store/selectors'
import { CompareKeyType, FiltersForReportsType } from 'components/CommonSavedView'
import { removeBlankFields } from 'lib/common'

export type InventoryItemReportsFiltersType = {
    customerId?: string

    categoryId?: string[]
    typeId?: string[]
    hasOpenTransferRequests?: boolean
    hasQuantity?: boolean
    hasLowStockAlert?: boolean
    isAllocatedForWO?: boolean
    avgStockAge?: { [key in CompareKeyType]?: number }
}

export const useInventoryFiltersForReports = () => {
    const inventoryItemFilters = useAppSelector(inventoryItemsFiltersSelect)
    const filters: FiltersForReportsType<InventoryItemReportsFiltersType>[] = []
    let filtersCount = 0

    if (inventoryItemFilters) {
        for (const [key, val] of Object.entries(removeBlankFields(inventoryItemFilters))) {
            if (key === 'categoryIdes') {
                filters.push({ categoryId: val })
                filtersCount += 1
            }
            if (key === 'typeIdes') {
                filters.push({ typeId: val })
                filtersCount += 1
            }
            if (key === 'avgStockAgeFrom') {
                filters.push({ avgStockAge: { '>=': val } })
            }
            if (key === 'avgStockAgeTo') {
                filters.push({ avgStockAge: { '<=': val } })
                filtersCount += 1
            }
            if (key === 'hasOpenTransferRequests') {
                filters.push({ hasOpenTransferRequests: val })
                filtersCount += 1
            }
            if (key === 'hasQuantity') {
                filters.push({ hasQuantity: val })
                filtersCount += 1
            }
            if (key === 'hasLowStockAlert') {
                filters.push({ hasLowStockAlert: val })
                filtersCount += 1
            }
            if (key === 'isAllocatedForWO') {
                filters.push({ isAllocatedForWO: val })
                filtersCount += 1
            }
        }
    }

    return { filters, filtersCount }
}
