import React, { Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import AppLayout from 'components/AppLayout/AppLayout'
import ProgressBar from 'components/ProgressBar'
import AuthPage from 'pages/AuthPage'
import { useAppDispatch, useAppSelector } from 'hooks'
import { getMe } from 'api/auth'
import { getAssignedTeams } from 'api/teams'
import { useUserCheckRolePermission } from 'hooks/userHooks'
import InternalRoutes, { FloorPlanRoutes } from 'routes'
import { ROUTES } from 'routes/const'

export const App = () => {
    const dispatch = useAppDispatch()
    const isAuth = useAppSelector((state) => state.auth.isAuth)

    useUserCheckRolePermission()

    useEffect(() => {
        if (isAuth) {
            ;(async () => {
                await dispatch(getMe())
                await dispatch(getAssignedTeams())
            })()
        }
    }, [isAuth])

    if (!isAuth) {
        return (
            <>
                <AuthPage />
                <ToastContainer className="toastContainer" position="top-center" limit={1} />
            </>
        )
    }

    return (
        <Suspense
            fallback={
                <AppLayout>
                    <ProgressBar show />
                </AppLayout>
            }
        >
            <Routes>
                <Route
                    path="*"
                    element={
                        <AppLayout>
                            <InternalRoutes />
                        </AppLayout>
                    }
                />
            </Routes>
            <ToastContainer className="toastContainer" position="top-center" limit={1} />
        </Suspense>
    )
}
