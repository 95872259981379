import { Radio, CheckboxProps, FormControlLabel, RadioProps } from '@mui/material'
import { RadioOffSVG, RadioOnSVG } from '../../../assets/ui-kit'
import styles from './styles.module.scss'
import React, { ReactNode } from 'react'
import classNames from 'classnames'

type Props = {
    label?: ReactNode
    formClassName?: string
} & RadioProps
const CustomRadio = ({ label, formClassName, ...props }: Props) => (
    <FormControlLabel
        className={classNames(styles.formControlLabel, formClassName)}
        control={
            <Radio sx={{ padding: 0 }} icon={<RadioOffSVG />} checkedIcon={<RadioOnSVG />} disableRipple {...props} />
        }
        label={label}
        labelPlacement="start"
    />
)

export default CustomRadio
