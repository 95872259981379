import React, { useMemo } from 'react'

import classNames from 'classnames'

import TooltipMenu, { TooltipMenuItem } from 'components/TooltipMenu'
import TableToolbarPagination from 'components/TableToolbarPagination'
import CardTableSwitch from 'components/CardTableSwitch'

import SearchInput from 'ui-kit/components/SearchInput/SearchInput'
import { Button } from 'ui-kit/components/Button/Button'

import { useAppDispatch, useAppSelector } from 'hooks'

import Icons from 'assets/icons'
import styles from './styles.module.scss'
import { userAction } from '../../../../../../slices/users'
import UsersColumns from './UsersColumns'
import { TextButton } from '../../../../../../ui-kit/components/Button'
import UserFilters from '../UsersFilter/UserFilters'
import { useUserFiltersForReports } from '../../../../../../hooks/userHooks'
import { ExportTooltip } from '../../../../../../components/ExportTooltip'
import useGetUserSettings from '../../../../hooks/useGetUserSettings'
import { requiredUserColumns } from '../../../../../../slices/users/const'
import { DirectionEnum, NOTIFICATION_STATUS, USER_ROLE } from '../../../../../../types'
import { FiltersForReportsType } from '../../../../../../components/CommonSavedView'
import { createDocument } from '../../../../../../api/views'
import { printReport } from '../../../../../../lib/common'
import { UserReportsFiltersType } from '../../../../../../hooks/userHooks/useUserFiltersForReports'
import sendNotification from '../../../../../../lib/notification'
import { ConfirmSaveView } from '../../../../../../components/ConfirmSaveView'
import { ImportModelsEnum, setImportDialog } from '../../../../../../slices/ToolsetSlice'
import useCrudUserPermission from '../../../../hooks/useCrudUserPermission'
import { permissionsUser } from '../../../../../../store/selectors'

type Props = {
    userRole: USER_ROLE
    onAddUserClick: () => void
    hideSaveView?: boolean
}
const TableToolbar = ({ userRole, onAddUserClick, hideSaveView }: Props) => {
    const dispatch = useAppDispatch()
    const { isRequestor } = useAppSelector(permissionsUser)

    const settings = useGetUserSettings(userRole)

    const page = useAppSelector(({ users }) => users.usersPage)
    const search = useAppSelector(({ users }) => users.usersSearch)
    const usersCount = useAppSelector(({ users }) => users.usersCount)
    const usersListMode = useAppSelector(({ users }) => users.usersListMode)

    const isCrud = useCrudUserPermission(userRole)

    const { filters, filtersCount } = useUserFiltersForReports(userRole)

    const requiredColumns = useMemo(() => {
        const columns = requiredUserColumns[userRole]
        return columns || []
    }, [userRole])

    const importModel = useMemo(() => {
        switch (userRole) {
            case USER_ROLE.ADMIN:
                return ImportModelsEnum.USER_ADMIN
            case USER_ROLE.REQUESTOR:
                return ImportModelsEnum.USER_REQUESTOR
            case USER_ROLE.TECHNICIAN:
                return ImportModelsEnum.USER_TECHNICIAN
            case USER_ROLE.FMPRO:
                return ImportModelsEnum.USER_FMPRO
            case USER_ROLE.SUPERVISOR:
                return ImportModelsEnum.USER_SUPERVISOR
            case USER_ROLE.SUPERADMIN:
                return ImportModelsEnum.USER_SUPERADMIN

            default:
                return null
        }
    }, [userRole])

    const onPrintReportClick = async () => {
        const and: FiltersForReportsType<UserReportsFiltersType>[] = []

        if (filters?.length) {
            and.push(...filters)
        }

        if (search) {
            // @ts-ignore
            and.push({ firstName: { search } })
        }

        try {
            const res = await dispatch(
                createDocument({
                    docType: 'pdf',
                    modelName: 'user',
                    titles: requiredColumns.concat(settings?.columns || []).map((item) => item.label),
                    // order: {
                    //     [sort.sortField]: sort.sortDirection,
                    // },
                    filters: { initial: { and } },
                }),
            ).unwrap()

            if (res) {
                printReport(res)
            }
        } catch (e: any) {
            sendNotification((e.message as string) || 'Something went wrong', NOTIFICATION_STATUS.ERROR)
        }
    }

    return (
        <div className={styles.tableToolbarRoot}>
            <div className={styles.flexWrap}>
                <SearchInput
                    placeholder="Search Users"
                    className={styles.searchInput}
                    searchText={search}
                    onChangeSearchText={(val) => {
                        dispatch(userAction.setUsersSearch(val))
                    }}
                />

                <TooltipMenu
                    button={<Icons.TableSettings />}
                    classesRoot={styles.filters}
                    closeByClick={false}
                    heightPopper="500px"
                >
                    <UsersColumns userRole={userRole} />
                </TooltipMenu>

                <TooltipMenu
                    button={
                        <TextButton
                            text={
                                <>
                                    Filter
                                    {!!filtersCount && <div className={styles.filterCount}>{filtersCount}</div>}
                                </>
                            }
                            icon={<Icons.FilterSVG />}
                        />
                    }
                    closeByClick={false}
                    heightPopper="100%"
                    classesRoot={styles.filters}
                >
                    <UserFilters userRole={userRole} />
                </TooltipMenu>

                {isCrud && (
                    <Button
                        variants={'filled-grey'}
                        style={{ minWidth: 156, width: 156 }}
                        onClick={() => {
                            dispatch(setImportDialog({ isOpen: !!importModel, table: importModel }))
                        }}
                    >
                        Import Users
                    </Button>
                )}

                {isCrud && (
                    <Button variants={'filled-blue'} style={{ width: 252 }} onClick={onAddUserClick}>
                        + Add User
                    </Button>
                )}
            </div>
            <div className={classNames(styles.flexWrap, styles.spaceBetween)}>
                <TableToolbarPagination
                    count={usersCount}
                    page={page}
                    rowsPerPage={25}
                    onPageChange={(pageValue) => dispatch(userAction.setUsersPage(pageValue))}
                    subLabel={`Users from All Users Overview`}
                />

                <div className={styles.flexWrap}>
                    {settings && !hideSaveView && !isRequestor && (
                        <ConfirmSaveView
                            modelName={'user'}
                            currentView={{
                                id: settings.currentViewId!,
                                currentViewTitle: settings.currentViewTitle!,
                                isSystemView: settings.isSystemCurrentView!,
                            }}
                            setIsLoading={() => undefined}
                            sort={{ sortField: 'id', sortDirection: DirectionEnum.ASC }}
                            columns={requiredColumns.concat(settings?.columns || [])}
                            search={{ field: 'firstName', search }}
                            filters={filters}
                        />
                    )}

                    <ExportTooltip
                        modelName={'user'}
                        setIsLoading={() => undefined}
                        titleButton={<TooltipMenuItem icon={<Icons.TableExport />} title="Export" />}
                        className={styles.export}
                        sort={undefined}
                        columns={requiredColumns.concat(settings?.columns || [])}
                        search={{ field: 'firstName', search }}
                        filters={filters}
                    />

                    <TextButton text="Print Report" icon={<Icons.TablePrint />} onClick={onPrintReportClick} />

                    <CardTableSwitch
                        activeMode={usersListMode}
                        onChangeMode={(mode) => dispatch(userAction.setUsersListMode(mode))}
                    />
                </div>
            </div>
        </div>
    )
}

export default TableToolbar
