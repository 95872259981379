import { CategoryNameEnum, TypePlanName } from '../../types'

import architectural from './architectural.svg'
import electrical from './electrical.svg'
import plumbing from './plumbing.svg'
import hvac from './hvac.svg'
import fireProtection from './fire-protection.svg'
import structural from './structural.svg'
import landscaping from './landscaping.svg'
import defaultImg from './default.svg'
import equipment from './equipment.svg'
import mechanical from './mechanical.svg'
import fffAndE from './ff-and-e.svg'
import technology from './technology.svg'
import security from './security.svg'
import lighting from './lighting.svg'

export const getSvgImageByType = (type?: TypePlanName | CategoryNameEnum | string) => {
    switch (type) {
        case CategoryNameEnum.Equipment:
            return equipment
        case CategoryNameEnum.HVAC_AND_Mechanical:
            return mechanical
        case CategoryNameEnum.Furniture:
            return fffAndE
        case CategoryNameEnum.Lighting:
            return lighting
        case CategoryNameEnum.Technology:
            return technology
        case CategoryNameEnum.Security:
            return security
        case TypePlanName.Architectural:
            return architectural
        case TypePlanName.Electrical:
            return electrical
        case TypePlanName.Plumbing:
            return plumbing
        case TypePlanName.HVAC:
            return hvac
        case TypePlanName.FireProtection:
        case CategoryNameEnum.FireSafety:
            return fireProtection
        case TypePlanName.Structural:
            return structural
        case TypePlanName.Landscaping:
            return landscaping

        default:
            return defaultImg
    }
}
