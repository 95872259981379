import { axiosErrorHandler, client } from './setupAxios'
import { EmergencyProcedureType, EmergencyScenario } from '../slices/emergency'
import { createAppAsyncThunk } from '../common/createAppAsyncThunk'

type GetEmergencyPlanList = {
    offset?: number
    searchString: string
    limit?: number
    buildingId?: string
}

export const getEmergencyPlanList = createAppAsyncThunk(
    'emergency/getEmergencyPlanList',
    async ({ searchString, buildingId, offset = 0, limit = 100 }: GetEmergencyPlanList, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/emergency-plan/search`, {
                params: {
                    offset,
                    limit,
                    buildingId,
                    searchString: searchString.length > 0 ? searchString : undefined,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getEmergencyPlanAffectedAreaById = createAppAsyncThunk(
    'emergency/getEmergencyPlanAffectedAreaById',
    async ({ id }: { id: string }, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/emergency/affected-area`, {
                params: {
                    id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getEmergencyPlanAffectedAreaList = createAppAsyncThunk(
    'emergency/getEmergencyPlanAffectedAreaList',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/emergency/affected-area/many`, {
                params: {
                    emergencyPlanId: id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreateUpdateEmergencyScenario = {
    id?: string
    scenario: EmergencyScenario
}

export const createUpdateEmergencyScenario = createAppAsyncThunk(
    'emergency/createUpdateEmergencyScenario',
    async ({ id, scenario }: CreateUpdateEmergencyScenario, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/emergency-plan`, {
                id,
                scenario,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createEmergencyScenarioReport = createAppAsyncThunk(
    'emergency/createEmergencyScenarioReport',
    async (scenario: EmergencyScenario, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/emergency-report`, {
                scenario,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreateAffectedAreaEmergencyPlan = {
    id?: string
    buildingId: string
    emergencyPlanId: string
}

export const createAffectedAreaEmergencyPlan = createAppAsyncThunk(
    'emergency/createAffectedAreaEmergencyPlan',
    async (data: CreateAffectedAreaEmergencyPlan, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/emergency/affected-area`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type AddFloorAffectedArea = {
    floorId: string
    affectedAreaId: string
}

export const deleteEmergencyAffectedArea = createAppAsyncThunk(
    'emergency/deleteEmergencyAffectedArea',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/emergency/affected-area`, {
                params: {
                    id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const addFloorAffectedArea = createAppAsyncThunk(
    'emergency/addFloorAffectedArea',
    async (data: AddFloorAffectedArea, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency/affected-area/add-floor`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type AddRoomAffectedArea = {
    roomId: string
    affectedAreaId: string
}

export const addRoomAffectedArea = createAppAsyncThunk(
    'emergency/addFloorAffectedArea',
    async (data: AddRoomAffectedArea, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency/affected-area/add-room`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type AddAssetAffectedArea = {
    assetId: string
    affectedAreaId: string
}

export const addAssetAffectedArea = createAppAsyncThunk(
    'emergency/addFloorAffectedArea',
    async (data: AddAssetAffectedArea, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency/affected-area/add-asset`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type EmergencyContactToPlan = {
    subcontractorId: string
    emergencyPlanId: string
}

export const addEmergencyContactToPlan = createAppAsyncThunk(
    'emergency/addEmergencyContactToPlan',
    async (data: EmergencyContactToPlan, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/subcontractor/add-emergency-plan`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const detachEmergencyContactToPlan = createAppAsyncThunk(
    'emergency/addEmergencyContactToPlan',
    async (data: EmergencyContactToPlan, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/subcontractor/detach-emergency-plan`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type UpdatePlanEmergencyName = {
    id: string
    name: string
    description: string
}

export const updatePlanEmergencyName = createAppAsyncThunk(
    'emergency/updatePlanEmergencyName',
    async (data: UpdatePlanEmergencyName, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-plan/name-description`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const incrementPlanEmergency = createAppAsyncThunk(
    'emergency/incrementPlanEmergency',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-plan/increment-level`, {
                id,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const decrementPlanEmergency = createAppAsyncThunk(
    'emergency/decrementPlanEmergency',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-plan/decrement-level`, {
                id,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getMyStepEmergencyPlan = createAppAsyncThunk(
    'emergency/getMyStepEmergencyPlan',
    async (_, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/emergency-plan/my-unfilled`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type UpdateEmergencyPlanFrequency = {
    id: string
    frequencyStartDate: Date
    frequency: string
}

export const updateEmergencyPlanFrequency = createAppAsyncThunk(
    'emergency-plan/updateEmergencyReportFrequency',
    async (data: UpdateEmergencyPlanFrequency, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-plan/frequency`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getEmergencyPlanById = createAppAsyncThunk(
    'emergency/getEmergencyPlanById',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/emergency-plan`, {
                params: {
                    id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreateEmergencyProcedure = {
    files: Array<File>
    type: EmergencyProcedureType
    emergencyPlanId?: string
}

export const createEmergencyProcedure = createAppAsyncThunk(
    'emergency/createEmergencyProcedure',
    async (data: CreateEmergencyProcedure, { rejectWithValue }) => {
        try {
            const result = await client.post(
                `/api/emergency-procedure`,
                {
                    ...data,
                    title: data.type,
                },
                { headers: { 'Content-Type': 'multipart/form-data' } },
            )
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type AttachEmergencyProcedure = {
    fileId: string
    emergencyPlanId: string
}

export const attachEmergencyProcedure = createAppAsyncThunk(
    'emergency/attachEmergencyProcedure',
    async (data: AttachEmergencyProcedure, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/emergency-procedure/add-to-emergency-plan`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteEmergencyProcedureFromPlan = createAppAsyncThunk(
    'emergency/deleteEmergencyProcedureFromPlan',
    async (data: AttachEmergencyProcedure, { rejectWithValue }) => {
        try {
            const result = await client.put(`/api/emergency-procedure/delete-from-emergency-plan`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type GetEmergencyProcedures = {
    offset?: number
    searchString?: string
    limit?: number
    emergencyPlanId?: string
    type: string
}

export const getEmergencyProcedures = createAppAsyncThunk(
    'emergency/getEmergencyProcedures',
    async (
        { searchString, emergencyPlanId, type, offset = 0, limit = 100 }: GetEmergencyProcedures,
        { rejectWithValue },
    ) => {
        try {
            const result = await client.get(`/api/emergency-procedure/many`, {
                params: {
                    offset,
                    limit,
                    emergencyPlanId,
                    type,
                    searchString,
                },
            })
            return { ...result.data, type }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getEmergencyProceduresForCheckList = createAppAsyncThunk(
    'emergency/getEmergencyProceduresForCheckList',
    async (
        { searchString, emergencyPlanId, type, offset = 0, limit = 100 }: GetEmergencyProcedures,
        { rejectWithValue },
    ) => {
        try {
            const result = await client.get(`/api/emergency-procedure/many`, {
                params: {
                    offset,
                    limit,
                    emergencyPlanId,
                    type,
                    searchString,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteFileProcedure = createAppAsyncThunk(
    'emergency/deleteFileProcedure',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/file/${id}`)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteEmergencyPlan = createAppAsyncThunk(
    'emergency/deleteEmergencyPlan',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/emergency-plan`, {
                params: {
                    id,
                },
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
