import * as T from 'components/Table'
import styles from './styles.module.scss'
import { Avatar } from '@mui/material'
import React, { FC } from 'react'
import classNames from 'classnames'

import { User, userAction } from '../../../../../../slices/users'
import TableBodyItem from './TableBodyItem'
import { useDispatch } from 'react-redux'

type Props = {
    row: User
    onClick?: (item: User) => void
    visibleColumns: Array<T.TableColumnType>
    requiredColumns: Array<T.TableColumnType>
}

const TableItemRow: FC<Props> = ({ row, visibleColumns, requiredColumns }) => {
    const dispatch = useDispatch()

    const handleClickRow = () => {
        dispatch(userAction.setSelectedUserCard(row))
    }

    return (
        <T.TableBodyRow className={classNames(styles.relativeRow)} onClick={handleClickRow}>
            <T.TableBodyData sticky component="th">
                <T.TableBodyData width={25}>
                    <Avatar
                        alt={row.firstName}
                        src={row.avatar?.url}
                        sx={{ width: 24, height: 24 }}
                        variant="rounded"
                    />
                </T.TableBodyData>
                {requiredColumns.map((column) => (
                    <T.TableBodyData width={200} key={column.name} align="center" clickable>
                        <TableBodyItem nameColumn={column.name} user={row} />
                    </T.TableBodyData>
                ))}
            </T.TableBodyData>

            {visibleColumns.map((column) => (
                <T.TableBodyData key={column.name} align="center" className={styles.td} clickable>
                    <TableBodyItem nameColumn={column.name} user={row} />
                </T.TableBodyData>
            ))}
        </T.TableBodyRow>
    )
}

export default TableItemRow
