import accessControlRequest from './access-contros-request.svg'
import amenityBooking from './amenity-booking.svg'
import correctiveMaintenance from './corrective-maintenance.svg'
import emergency from './emergency.svg'
import eventSupport from './event-support.svg'
import projectPlanning from './project-planning.svg'
import recurringPm from './recurring-pm.svg'
import serviceRequest from './service-request.svg'

import { TypeWO } from '../../components/NewWorkOrder/config'

export const getSvgImageTypeWo = (type?: TypeWO | string) => {
    switch (type) {
        case TypeWO.ACCESS_CONTROL:
            return accessControlRequest
        case TypeWO.AMENITY_SPACE_BOOKING:
            return amenityBooking
        case TypeWO.CORRECTIVE_MAINTENANCE:
            return correctiveMaintenance
        case TypeWO.EVENT_SUPPORT:
            return eventSupport
        case TypeWO.PROJECT:
            return projectPlanning
        case TypeWO.RECURRING:
            return recurringPm
        case TypeWO.PREVENTIVE_MAINTENANCE:
            return recurringPm
        case TypeWO.SERVICE_REQUEST:
            return serviceRequest
        case TypeWO.EMERGENCY:
            return emergency

        default:
            return accessControlRequest
    }
}
