import {
    EmergencyPageTab,
    EmergencyPlan,
    EmergencyProcedureCounts,
    EmergencyProcedureThree,
    EmergencyProfilePageTab,
    EmergencyReport,
} from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import extraReducers from './extraReducers'
import { SubcontractorType } from '../subcontractor'
import { dropInLS, getSettingsFromLS, LsKeys } from '../../lib/workWithLS'
import { DirectionEnum, ListViewMode, TableSettingsType } from '../../types'
import {
    visibleEmergencyContactsTableColumns,
    visibleEmergencyPlanTableColumns,
    visibleEmergencyIncidentsTableColumns,
} from './config'
import { TableColumnType } from '../../components/Table'
import { WorkOrderType } from '../workOrder'

export interface EmergencyState {
    emergencyPageTab: EmergencyPageTab
    emergencyProfilePageTab: EmergencyProfilePageTab
    planActiveStep: number
    reportActiveStep: number
    newReportActive: EmergencyReport | null

    loading: boolean
    currentCreatePlanEmergencyId: string | null
    profilePlanEmergency: EmergencyPlan | null

    emergencyContacts: Array<SubcontractorType>
    emergencyProcedureThree: EmergencyProcedureThree
    emergencyProcedureCounts: EmergencyProcedureCounts
    emergencyContactsCount: number
    emergencyContactsPage: number
    emergencyContactsSearch: string

    emergencyProceduresSearch: string

    emergencyPlans: Array<EmergencyPlan>
    emergencyPlansPage: number
    emergencyPlansCount: number
    emergencyPlansSearch: string

    emergencyIncidents: Array<WorkOrderType>
    emergencyIncidentsPage: number
    emergencyIncidentsCount: number
    emergencyIncidentsSearch: string

    selectedEmergencyContacts: Array<{ id: string; subcontractor: SubcontractorType }>

    emergencyPlanSettings: TableSettingsType<any>
    emergencyContactsSettings: TableSettingsType<any>
    emergencyIncidentsSettings: TableSettingsType<any>
}

const initialState: EmergencyState = {
    emergencyPageTab: EmergencyPageTab.EMERGENCY_PLANS,
    emergencyProfilePageTab: EmergencyProfilePageTab.DETAILS,
    planActiveStep: 0,
    reportActiveStep: 0,
    loading: false,
    currentCreatePlanEmergencyId: null,
    profilePlanEmergency: null,

    newReportActive: null,

    emergencyContacts: [],
    emergencyProcedureThree: {},
    emergencyProcedureCounts: {},
    emergencyContactsPage: 1,
    emergencyContactsSearch: '',
    emergencyContactsCount: 0,

    emergencyProceduresSearch: '',

    emergencyPlans: [],
    emergencyPlansPage: 1,
    emergencyPlansCount: 0,
    emergencyPlansSearch: '',

    emergencyIncidents: [],
    emergencyIncidentsPage: 1,
    emergencyIncidentsCount: 0,
    emergencyIncidentsSearch: '',

    selectedEmergencyContacts: [],

    emergencyPlanSettings: {
        filters: null,
        columns: getSettingsFromLS('columns', LsKeys.EMERGENCY_PLAN_SETTINGS, visibleEmergencyPlanTableColumns),
        currentViewId: '',
        currentViewTitle: '',
        isSystemCurrentView: false,
        listMode: getSettingsFromLS('listMode', LsKeys.EMERGENCY_PLAN_SETTINGS, ListViewMode.TABLE),
        sortDirection: DirectionEnum.ASC,
        sortField: 'name',
    },

    emergencyContactsSettings: {
        filters: null,
        columns: getSettingsFromLS('columns', LsKeys.EMERGENCY_CONTACTS_SETTINGS, visibleEmergencyContactsTableColumns),
        currentViewId: '',
        currentViewTitle: '',
        isSystemCurrentView: false,
        listMode: getSettingsFromLS('listMode', LsKeys.EMERGENCY_CONTACTS_SETTINGS, ListViewMode.TABLE),
        sortDirection: DirectionEnum.ASC,
        sortField: 'name',
    },
    emergencyIncidentsSettings: {
        filters: null,
        columns: getSettingsFromLS(
            'columns',
            LsKeys.EMERGENCY_INCIDENTS_SETTINGS,
            visibleEmergencyIncidentsTableColumns,
        ),
        currentViewId: '',
        currentViewTitle: '',
        isSystemCurrentView: false,
        listMode: getSettingsFromLS('listMode', LsKeys.EMERGENCY_INCIDENTS_SETTINGS, ListViewMode.TABLE),
        sortDirection: DirectionEnum.ASC,
        sortField: 'name',
    },
}

export const emergencySlice = createSlice({
    name: 'emergency',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setEmergencyPageTab: (state, action: PayloadAction<EmergencyPageTab>) => {
            state.emergencyPageTab = action.payload
        },
        setEmergencyProfilePageTab: (state, action: PayloadAction<EmergencyProfilePageTab>) => {
            state.emergencyProfilePageTab = action.payload
        },

        setPlanActiveStep: (state, action: PayloadAction<number>) => {
            state.planActiveStep = action.payload
        },
        setCurrentCreatePlanEmergencyId: (state, action: PayloadAction<string | null>) => {
            state.currentCreatePlanEmergencyId = action.payload
        },

        setEmergencyContactsPage: (state, action: PayloadAction<number>) => {
            state.emergencyContactsPage = action.payload
        },
        setEmergencyContactsSearch: (state, action: PayloadAction<string>) => {
            state.emergencyContactsSearch = action.payload
        },
        setEmergencyPlansSearch: (state, action: PayloadAction<string>) => {
            state.emergencyPlansSearch = action.payload
        },
        setEmergencyPlansPage: (state, action: PayloadAction<number>) => {
            state.emergencyPlansPage = action.payload
        },

        setEmergencyProceduresSearch: (state, action: PayloadAction<string>) => {
            state.emergencyProcedureThree = {}
            state.emergencyProcedureCounts = {}

            state.emergencyProceduresSearch = action.payload
        },

        clearEmergencyProceduresThree: (state) => {
            state.emergencyProceduresSearch = ''
            state.emergencyProcedureThree = {}
            state.emergencyProcedureCounts = {}
        },
        setSelectedEmergencyContacts: (
            state,
            action: PayloadAction<Array<{ id: string; subcontractor: SubcontractorType }>>,
        ) => {
            state.selectedEmergencyContacts = action.payload
        },

        setEmergencyIncidentsSearch: (state, action: PayloadAction<string>) => {
            state.emergencyIncidentsPage = 1
            state.emergencyIncidentsSearch = action.payload
        },
        setEmergencyIncidentsPage: (state, action: PayloadAction<number>) => {
            state.emergencyIncidentsPage = action.payload
        },

        //settings
        setColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.EMERGENCY_PLAN_SETTINGS, {
                columns: action.payload,
            })

            state.emergencyPlanSettings.columns = action.payload
        },
        setListMode: (state, action: PayloadAction<ListViewMode>) => {
            dropInLS(LsKeys.EMERGENCY_PLAN_SETTINGS, {
                listMode: action.payload,
            })

            state.emergencyPlanSettings.listMode = action.payload
        },

        setContactsColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.EMERGENCY_CONTACTS_SETTINGS, {
                columns: action.payload,
            })

            state.emergencyContactsSettings.columns = action.payload
        },
        setContactsListMode: (state, action: PayloadAction<ListViewMode>) => {
            dropInLS(LsKeys.EMERGENCY_CONTACTS_SETTINGS, {
                listMode: action.payload,
            })

            state.emergencyContactsSettings.listMode = action.payload
        },

        setIncidentsColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.EMERGENCY_INCIDENTS_SETTINGS, {
                columns: action.payload,
            })

            state.emergencyIncidentsSettings.columns = action.payload
        },
        // setIncidentsListMode: (state, action: PayloadAction<ListViewMode>) => {
        //     dropInLS(LsKeys.EMERGENCY_INCIDENTS_SETTINGS, {
        //         listMode: action.payload,
        //     })
        //
        //     state.emergencyContactsSettings.listMode = action.payload
        // },
    },
    extraReducers,
})

export const emergencyActions = emergencySlice.actions
export const EmergencyReducer = emergencySlice.reducer
