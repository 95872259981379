import styles from './styles.module.scss'
import { ColorVariant } from './types'
import classNames from 'classnames'
import { capitalized } from '../../lib/common'

type Props = {
    color: ColorVariant
    size?: string | number
    text: string
}

const StatusBadge = ({ color, size, text }: Props) => {
    return (
        <div
            className={classNames(styles.root, styles[color])}
            style={{
                fontSize: size,
            }}
        >
            {capitalized(text)}
        </div>
    )
}

export default StatusBadge
