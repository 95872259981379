import s from './ForgotPassword.module.scss'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import AuthInput from 'ui-kit/components/AuthInput/AuthInput'
import { Button } from 'ui-kit/components/Button/Button'
import { CircularProgress } from '@mui/material'
import React from 'react'
import { useFormik } from 'formik'
import { verifyEmail } from 'api/auth'
import * as yup from 'yup'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useNavigate } from 'react-router-dom'
import AuthRoutes from '../../routes'
import { emailRegExp, MUST_BE_EMAIL, REQUIRED_FIELD } from 'pages/NewAssetPage/config'

const validationSchema = yup.object().shape({
    email: yup.string().matches(emailRegExp, MUST_BE_EMAIL).required(REQUIRED_FIELD),
})

type EmailForm = {
    email: string
}

const ForgotPassword = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const isWaitingForResponse = useAppSelector(({ auth }) => auth.isWaitingForResponse)

    const { handleChange, values, errors, handleSubmit, isValid } = useFormik<EmailForm>({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (val) => {
            await dispatch(
                verifyEmail({
                    ...val,
                    callback: handleNavigateToVerify,
                }),
            )
        },
    })

    const handleNavigateToVerify = () => {
        navigate('/' + AuthRoutes.VERIFY, { replace: true, state: { email: values.email, mode: 'forgot' } })
    }

    const handleToLoginClick = () => {
        navigate('/' + AuthRoutes.LOGIN, { replace: true })
    }

    return (
        <div className={s.contentContainer}>
            <Paragraph className={s.logIn} color={'white'}>
                Forgot password
            </Paragraph>
            <Paragraph className={s.title} color={'white'}>
                Please enter your email to reset your password
            </Paragraph>
            <div className={s.inputsContainer}>
                <AuthInput
                    id="email"
                    name="email"
                    title="Email"
                    value={values.email}
                    onChange={handleChange}
                    error={errors.email}
                    onEnterPress={handleSubmit}
                />
            </div>

            <Button
                className={s.logInBtn}
                onClick={() => handleSubmit()}
                variants={'filled-yellow'}
                disabled={!isValid || values.email.length === 0}
            >
                {isWaitingForResponse ? (
                    <CircularProgress
                        sx={{
                            color: 'white',
                            '& .MuiCircularProgress-svg': {
                                margin: 'auto',
                                height: 40,
                                width: 20,
                            },
                        }}
                        color="inherit"
                    />
                ) : (
                    <Paragraph size={'s'} weight={'semi-bold'}>
                        Next
                    </Paragraph>
                )}
            </Button>

            <div className={s.forgotPasswordLink} onClick={handleToLoginClick}>
                Return to Log in
            </div>
        </div>
    )
}

export default ForgotPassword
