import { CommonFileType, TypePlanName } from '../../types'
import { CoordsXY } from '../../components/KonvaStageFloorPlan'
import { Building } from '../building'
import { Lease, LeaseStatus } from '../users'

export interface FileFloorPlan {
    id: string
    name: string
    url: string
    width: number
    height: number
    mimetype: string
    size: number
    createdAt: string
}

export interface PageRoot {
    id: string
    rootFile: FileFloorPlan
    page: number
}

export interface SelectedPage {
    id: string
    file: FileFloorPlan
    page: number
}

export interface PageChild {
    id: string
    page: number
    childFile: FileFloorPlan
}

export interface PagesPlan {
    root: PageRoot
    children: Array<PageChild>
}

export interface FloorPlan {
    id: string
    buildingId: string
    building: Building | null
    floorId: string
    name: string
    document: PageRoot
    documentSize: string
    pageCount: number
    planTypeId: string
    planTypes: TypePlan
    creationDate: string
    assets: Array<Asset>
    file: CommonFileType
}
export interface Room {
    id: string
    name: string
    description: string
    isAmenity: boolean
    area: number
    roomFiles: Array<CommonFileType>
    floor: {
        building: {
            id: string
            name: string
        }
        id: string
    }
    leases: Array<Lease>
}

export interface ObjectPlan {
    id: string
    name: string
    category: AssetCategory
    types: AssetType
    objectGroupId: string
}

export interface GrouoObjectPlan {
    id: string
    name: string
    objectCount: number
}

export interface Floor {
    id: string
    name: string
    label: string
    description: string
    buildingId: string
    PMCount: number
    WOCount: number
    assetsCount: number
    plansCount: number
    roomsCount: number
    area: number

    floorPlans: Array<FloorPlan>
}

export interface AssetPoint {
    id: string
    pageId: string
    fromX: number
    fromY: number
    from: Asset & ObjectPlan
    toX: number | null
    toY: number | null
    to: Asset | null
    type?: 'POINT' | 'ARROW'
    toId: string | null
    fromId: string | null
    color?: string
    objectsInGroupCount?: number
}

export type Asset = {
    id: string
    name: string

    category: AssetCategory
    equipmentId: string
    types: { id: string; name: string }
    isNew: boolean
    pointsFrom: Array<{ id: string }>
}

export type DragItem = ObjectPlan & Asset

export type AssetCategory = {
    id: string
    name: string
    assetsCount: number
    newCount: number
    file: FileFloorPlan | null
    inventoryItemsCount: number
    color: string
}

export type AssetsByCategory = AssetCategory & { data: Array<Asset> }

export type AssetType = {
    id: string
    assetsCount: number
    newCount: number
    inventoryItemsCount: number
    name: string
    categoryId: string
    categories: AssetCategory
}

export type TypePlan = {
    id: string
    name: TypePlanName
}

export type RoomPoint = {
    id: string
    name: string
    points: Array<CoordsXY>
}

export enum AssetsRoomPanelTab {
    ASSETS = 'ASSETS',
    ROOMS = 'ROOMS',
}

export type AssetThree = {
    [key: string]: Array<Asset>
}

export type TypesAssetThree = {
    [key: string]: Array<AssetType>
}

export type AssetsHidden = {
    [id: string]: boolean
}
export type RoomsHidden = {
    [id: string]: boolean
}

export enum FloorPlanLeftPanel {
    ADD_ASSET = 'ADD_ASSET',
    ADD_ROOM = 'ADD_ROOM',
    ADD_OBJECT = 'ADD_OBJECT',
    LAYERS = 'LAYERS',
    FILTERS = 'FILTERS',
}
