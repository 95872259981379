import { useAppSelector } from 'hooks/redux'
import { currentUserSelect, workOrdersFiltersSelect } from 'store/selectors'
import { CompareKeyType, FiltersForReportsType } from 'components/CommonSavedView'
import { removeBlankFields } from 'lib/common'
import { ItemForAutocompleteType } from 'components/CommonAutoComplete'
import { WorkOrderStatus } from 'components/NewWorkOrder/config'
import { USER_ROLE } from 'types'

export type WorkOrderReportsFiltersType = {
    customerId?: string
    creatorId?: string

    buildingId?: string
    priority?: string[]
    filtersOr?: { type?: string[]; subType?: string[] }[]
    endDate?: { [key in CompareKeyType]?: Date }
    status?: WorkOrderStatus
}

export const useWorkOrderFiltersForReports = () => {
    const user = useAppSelector(currentUserSelect)
    const workOrderFilters = useAppSelector(workOrdersFiltersSelect)
    const filters: FiltersForReportsType<WorkOrderReportsFiltersType>[] = []
    let filtersCount = 0
    const filtersOr: { type?: string[]; subType?: string[] }[] = []

    if (workOrderFilters) {
        for (const [key, val] of Object.entries(removeBlankFields(workOrderFilters))) {
            if (key === 'buildings' && val?.length) {
                filters.push({ buildingId: val?.map((item: ItemForAutocompleteType) => item?.value) })
                filtersCount += 1
            }
            if (key === 'priorities' && val?.length) {
                filters.push({ priority: val?.map((item: string) => item) })
                filtersCount += 1
            }
            if (key === 'types' && val?.length) {
                filtersOr.push({ type: val?.map((item: string) => item) })
            }
            if (key === 'subTypes' && val?.length) {
                filtersOr.push({ subType: val?.map((item: string) => item) })
            }
            if (key === 'completionDateFrom') {
                filters.push({ endDate: { '>=': val } })
            }
            if (key === 'completionDateTo') {
                filters.push({ endDate: { '<=': val } })
                filtersCount += 1
            }
        }

        if (filtersOr?.length) {
            filters.push({ filtersOr })
            filtersCount += 1
        }
    }

    if (user?.role === USER_ROLE.REQUESTOR) {
        filters.push({ creatorId: user.id })
    }

    return { filters, filtersCount }
}
