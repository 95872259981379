import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/index'
import { NOTIFICATION_STATUS } from 'types'
import { fetchSimpleParts } from 'api/partsInventory'
import sendNotification from 'lib/notification'
import { currentUserSelect } from 'store/selectors'

export type ResSimpleInventoryPartType = {
    payload: {
        key: string
        data: { [key: string]: string }[]
        createdAt: Date
        updatedAt: Date
    }
}

type UseInventoryType = {
    workOrderId: string
    isAllow?: boolean
}

export const useSimpleInventoryParts = ({ workOrderId, isAllow = true }: UseInventoryType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const [isLoading, setIsLoading] = useState(false)
    const [simpleInventoryItems, setSimpleInventoryItems] = useState<{ [key: string]: string }[]>([])

    const getInventory = async () => {
        setIsLoading(true)
        try {
            const res: ResSimpleInventoryPartType = await dispatch(fetchSimpleParts({ key: workOrderId })).unwrap()

            if (res?.payload?.data) {
                setSimpleInventoryItems(res.payload.data)
            }
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!user || !isAllow) {
            return
        }
        getInventory()
    }, [user, workOrderId, isAllow])

    return { simpleInventoryItems, isLoading, getInventory }
}
