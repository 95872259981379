import styles from './styles.module.scss'
import { useAppDispatch } from '../../../../../../../../hooks'
import { sendErrorNotification } from '../../../../../../../../lib/notification'
import { getLeaseHistory } from '../../../../../../../../api/tenant'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { LeaseHistory } from './types'
import LeaseHistoryCard from './LeaseHistoryCard'
import { Paragraph } from '../../../../../../../../ui-kit/components/Typography/Typography'
import CloseButton from '../../../../../../../../components/CloseButton'
import { CircularProgress } from '@mui/material'
import EmptyDataPlaceholder from 'components/EmptyDataPlaceholder'

type Props = {
    onClose: () => void
    leaseId: string
}

const TenantLeaseHistory = ({ leaseId, onClose }: Props) => {
    const dispatch = useAppDispatch()

    const [leaseHistory, setLeaseHistory] = useState<Array<LeaseHistory>>([])
    const [loading, setLoading] = useState(false)

    const getLeaseHistoryData = useCallback(async () => {
        setLoading(true)
        try {
            const res = await dispatch(
                getLeaseHistory({
                    leaseId,
                    offset: 0,
                }),
            ).unwrap()

            setLeaseHistory(res.histories)
        } catch (e) {
            sendErrorNotification(e)
        }
        setLoading(false)
    }, [leaseId])

    useEffect(() => {
        ;(async () => {
            await getLeaseHistoryData()
        })()
    }, [getLeaseHistoryData])

    return (
        <div className={styles.root}>
            <CloseButton onClose={onClose} />

            <div className={styles.content}>
                <Paragraph size="md" weight="semi-bold" position="center">
                    Lease history
                </Paragraph>

                {leaseHistory.length > 0
                    ? leaseHistory.map((history) => {
                          const option = history.data.option

                          return (
                              <Fragment key={history.id}>
                                  <LeaseHistoryCard
                                      option={option}
                                      date={history.data.createdAt}
                                      lease={history.data.new}
                                  />
                              </Fragment>
                          )
                      })
                    : !loading && <EmptyDataPlaceholder text="There are no any Lease History records" />}

                {loading && <CircularProgress />}
            </div>
        </div>
    )
}

export default TenantLeaseHistory
