import { useAppDispatch } from 'hooks/redux'
import { getMinMaxAssetCost } from 'api/assets'
import { useEffect, useState } from 'react'

type UseAssetMinMaxCostType = {
    isAllow?: boolean
    setIsLoading?: (value: boolean) => void
}
export const useAssetMinMaxCost = ({ isAllow, setIsLoading }: UseAssetMinMaxCostType) => {
    const dispatch = useAppDispatch()
    const [cost, setCost] = useState<{ minCost: number; maxCost: number }>({ minCost: 0, maxCost: 0 })

    const getAssetMinMaxCost = async () => {
        setIsLoading && setIsLoading(true)
        try {
            const res = await dispatch(getMinMaxAssetCost()).unwrap()
            setCost({ minCost: res.payload.minValue, maxCost: res.payload.maxValue })
        } catch (e: any) {
            console.log(e)
        } finally {
            setIsLoading && setIsLoading(false)
        }
    }

    useEffect(() => {
        if (isAllow) {
            getAssetMinMaxCost()
        }
    }, [isAllow])

    return cost
}
