export const EXTENSIONS = ['xlsx', 'xls']

export enum ErrorCodeEnum {
    UNEXPECTED_COLUMN = 'UNEXPECTED_COLUMN',
    MISSING_REQUIRED_FIELD = 'MISSING_REQUIRED_FIELD',
    INVALID_VALUE = 'INVALID_VALUE',
    INVALID_LENGTH = 'INVALID_LENGTH',
    INVALID_FORMAT = 'INVALID_FORMAT',
}

export enum ExpectedFieldType {
    DATE = 'date',
    NUMBER = 'number',
    CHECKBOX = 'checkbox',
    TEXT = 'text',
    RADIO = 'radio',
}

export type ErrorType = {
    error_code: string
    positionY?: number
    positionX: number
    received: string | null
    message: string
    expectedType?: ExpectedFieldType
    fetch?: {
        url: string
        requiredParams: string[]
        responseKey: string
        belongsTo: string | null
    }[]
}

export type ErrorFieldType = {
    value: string | number | null
    error: ErrorType
    isValid: boolean
}

export type ValidatedColumnType = { [key: string]: { [key: string]: string } }

export type AdditionalInformationType = {
    data: {
        message: string
        name: string
        response: string
        status: number
    }
    message: string
}

export type UncreatedType = {
    data: any
    reason: any
}

export type ResponseImportType = {
    createdLength: number
    isValid: boolean
    uncreated: UncreatedType[]
    unexpectedTitles: { [key: string]: boolean }
    createdExample?: { [key: string]: any }[]
    validated: ValidatedColumnType
    additionalInformation: AdditionalInformationType[]
    invalidTitles: { [key: string]: boolean }
    errors: ErrorType[]
}
