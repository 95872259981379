import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import sendNotification from '../../lib/notification'
import { DirectionEnum, ListViewMode, NOTIFICATION_STATUS, TableSettingsType } from '../../types'
import { createCustomer, deleteCustomer, getCustomerById, getCustomerList } from '../../api/customers'
import { Customer } from './types'
import { visibleCustomersTableColumns } from './config'
import { TableColumnType } from '../../components/Table'
import { dropInLS, getSettingsFromLS, LsKeys } from '../../lib/workWithLS'

export interface CustomerState {
    customers: Array<Customer>

    customersPage: number
    customersCount: number
    customersSearch: string

    selectedCustomer: Customer | null
    loading: boolean

    customerSettings: TableSettingsType<any>
}

const initialState: CustomerState = {
    customers: [],
    customersPage: 1,
    customersCount: 0,
    customersSearch: '',

    selectedCustomer: null,
    loading: false,

    customerSettings: {
        filters: null,
        columns: getSettingsFromLS('columns', LsKeys.CUSTOMERS_SETTINGS, visibleCustomersTableColumns),
        currentViewId: '',
        currentViewTitle: '',
        isSystemCurrentView: false,
        listMode: getSettingsFromLS('listMode', LsKeys.CUSTOMERS_SETTINGS, ListViewMode.TABLE),
        sortDirection: DirectionEnum.ASC,
        sortField: 'name',
    },
}

export const customersSLice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setCustomersSearch: (state, action: PayloadAction<string>) => {
            state.customersSearch = action.payload
        },
        setCustomersPage: (state, action: PayloadAction<number>) => {
            state.customersPage = action.payload
        },

        //settings
        setColumns: (state, action: PayloadAction<TableColumnType[]>) => {
            dropInLS(LsKeys.CUSTOMERS_SETTINGS, {
                columns: action.payload,
            })

            state.customerSettings.columns = action.payload
        },
        setListMode: (state, action: PayloadAction<ListViewMode>) => {
            dropInLS(LsKeys.CUSTOMERS_SETTINGS, {
                listMode: action.payload,
            })

            state.customerSettings.listMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomerList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(
            getCustomerList.fulfilled,
            (state, action: PayloadAction<{ count: number; rows: Array<Customer> }>) => {
                state.customers = action.payload.rows
                state.customersCount = action.payload.count
                state.loading = false
            },
        )
        builder.addCase(getCustomerList.rejected, (state) => {
            state.loading = false
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        })

        builder.addCase(getCustomerById.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getCustomerById.fulfilled, (state, action: PayloadAction<Customer | null>) => {
            state.loading = false
            state.selectedCustomer = action.payload
        })
        builder.addCase(getCustomerById.rejected, (state) => {
            state.loading = false
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        })

        builder.addCase(createCustomer.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createCustomer.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(createCustomer.rejected, (state) => {
            state.loading = false
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        })

        builder.addCase(deleteCustomer.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteCustomer.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(deleteCustomer.rejected, (state) => {
            state.loading = false
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        })
    },
})

export const CustomerReducer = customersSLice.reducer
export const customersActions = customersSLice.actions
