import React, { ReactNode } from 'react'
import TooltipMenu, { TooltipMenuItem } from 'components/TooltipMenu'
import { FileIcons } from 'assets/files-icons'
import { createDocument } from 'api/views'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks'
import { FiltersForReportsType, ViewType } from 'components/CommonSavedView'
import cn from 'classnames'
import { AssetReportsFiltersType } from 'hooks/assetsHooks/useAssetFiltersForReports'
import { TableColumnType } from 'components/Table'
import { CommonSortType, NOTIFICATION_STATUS } from 'types'
import { currentUserSelect } from 'store/selectors'
import sendNotification from 'lib/notification'

import s from './styles.module.scss'

type ExportTooltipType = {
    modelName: string
    setIsLoading: (isLoading: boolean) => void
    view?: ViewType
    titleButton: ReactNode
    className?: string
    sort?: CommonSortType
    columns?: TableColumnType[]
    search?: { field: string; search?: string }
    filters?: FiltersForReportsType<AssetReportsFiltersType>[]
    otherFilters?: { [key: string]: string | string[] | { [key: string]: string | string[] } }[]
    staticSearch?: string
}

export const ExportTooltip = ({
    setIsLoading,
    modelName,
    view,
    titleButton,
    className,
    filters,
    sort,
    columns,
    search,
    otherFilters,
}: ExportTooltipType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const onDownloadClick = async (docType: 'pdf' | 'csv' | 'excel', extension: string) => {
        setIsLoading(true)
        const and: FiltersForReportsType<AssetReportsFiltersType>[] = [{ customerId: user?.customerId ?? '' }]

        if (filters?.length) {
            and.push(...filters)
        }

        if (search && search.search) {
            and.push({ [search.field]: { search: search.search } })
        }

        try {
            const res = await dispatch(
                createDocument(
                    view
                        ? { docType, modelName, viewId: view.id }
                        : {
                              docType,
                              modelName,
                              titles: columns?.map((item) => item.label),
                              order: sort ? { [sort?.sortField]: sort?.sortDirection } : undefined,
                              filters: otherFilters?.length
                                  ? {
                                        initial: { and },
                                        ...Object.assign({}, ...otherFilters),
                                    }
                                  : { initial: { and } },
                          },
                ),
            ).unwrap()

            if (res) {
                const link = document.createElement('a')

                // @ts-ignore
                link.href = URL.createObjectURL(new Blob([res]))
                link.download = view
                    ? `${view.title}-${dayjs(new Date()).format('MM-DD-YYYY')}.${extension}`
                    : `${dayjs(new Date()).format('MM-DD-YYYY')}.${extension}`

                link.click()
                link.remove()
                URL.revokeObjectURL(link.href)
            }
        } catch (e: any) {
            sendNotification(e?.message ?? 'Something went wrong', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <TooltipMenu widthPopper={'12rem'} button={titleButton} classesRoot={cn(s.insideTooltip, className)}>
            <TooltipMenuItem
                icon={<FileIcons.PdfSmall />}
                title="Download PDF"
                onClick={() => onDownloadClick('pdf', 'pdf')}
            />
            <TooltipMenuItem
                icon={<FileIcons.XlsxSmall />}
                title="Download XLSX"
                onClick={() => onDownloadClick('excel', 'xlsx')}
            />
            <TooltipMenuItem
                icon={<FileIcons.CsvSmall />}
                title="Download CSV"
                onClick={() => onDownloadClick('csv', 'csv')}
            />
        </TooltipMenu>
    )
}
