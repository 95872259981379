import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material'
import { CheckBoxOffSVG, CheckBoxOnSVG } from '../../../assets/ui-kit'
import styles from './styles.module.scss'
import React, { ReactNode } from 'react'
import classNames from 'classnames'
const CustomCheckBox = (props: CheckboxProps & { label?: ReactNode; className?: string }) => (
    <FormControlLabel
        className={classNames(styles.formControlLabel, props.className)}
        control={
            <Checkbox
                sx={{ padding: 0 }}
                icon={<CheckBoxOffSVG />}
                checkedIcon={<CheckBoxOnSVG />}
                disableRipple
                {...props}
            />
        }
        label={props.label}
        labelPlacement="start"
        sx={{
            width: !props.label ? 'fit-content' : undefined,
        }}
    />
)

export default CustomCheckBox
