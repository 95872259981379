import { createAppAsyncThunk } from 'common/createAppAsyncThunk'
import { axiosErrorHandler, client } from 'api/setupAxios'
import { AxiosResponse } from 'axios'
import {
    CreateDocumentType,
    CreateViewType,
    CurrentViewType,
    EditColumnsViewType,
    GetViewsListType,
    ResponseViewType,
} from 'components/CommonSavedView'
import { checkIsGoodStatus } from 'lib/common'
import { ImportModelsEnum } from 'slices/ToolsetSlice'

export const getViewsList = createAppAsyncThunk(
    'view/getViewsList',
    async (arg: GetViewsListType, { rejectWithValue }) => {
        try {
            const result = await client.get<ResponseViewType, AxiosResponse<ResponseViewType>>(`/api/views/many`, {
                params: { ...arg },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getCurrentView = createAppAsyncThunk(
    'view/getCurrentView',
    async ({ id }: { id: string }, { rejectWithValue }) => {
        try {
            const result = await client.get<CurrentViewType, AxiosResponse<CurrentViewType>>(`/api/views/${id}`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const toggleFavoriteView = createAppAsyncThunk(
    'view/toggleFavoriteView',
    async ({ id, checkResponse }: { id: string; checkResponse?: () => void }, { rejectWithValue }) => {
        try {
            const result = await client.patch<any, AxiosResponse<any>>(`/api/views/toggle-favorite/${id}`)
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteView = createAppAsyncThunk(
    'view/deleteView',
    async ({ id, checkResponse }: { id: string; checkResponse?: () => void }, { rejectWithValue }) => {
        try {
            const result = await client.delete(`/api/views/${id}`)

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createView = createAppAsyncThunk<any, CreateViewType & { checkResponse?: () => void }>(
    'view/createView',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/views`, { ...arg })
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const editColumnsView = createAppAsyncThunk<any, EditColumnsViewType & { checkResponse?: () => void }>(
    'view/editColumnsView',
    async ({ checkResponse, id, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/views/${id}`, { ...arg })
            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const createDocument = createAppAsyncThunk<any, CreateDocumentType & { checkResponse?: () => void }>(
    'view/createDocument',
    async ({ checkResponse, docType, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/docs/${docType}`, { ...arg }, { responseType: 'arraybuffer' })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const everythingMany = createAppAsyncThunk<any, any & { checkResponse?: () => void }>(
    'view/everythingMany',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/everything/many?offset=0&limit=25`, { ...arg })

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
export type CreateImportType = {
    file: File
    table: ImportModelsEnum
    replacements?: string //{ [key: string]: { [key: string]: string } }
}

export const createImport = createAppAsyncThunk<any, CreateImportType & { checkResponse?: () => void }>(
    'toolset/createImport',
    async ({ checkResponse, ...arg }, { rejectWithValue }) => {
        try {
            const result = await client.post(
                `/api/docs/import`,
                { ...arg },
                { headers: { 'Content-Type': 'multipart/form-data' } },
            )

            if (checkIsGoodStatus(result?.status)) {
                checkResponse && checkResponse()
            }

            return result.data
        } catch (e) {
            // @ts-ignore
            return e?.response?.data //rejectWithValue(axiosErrorHandler(e))
        }
    },
)
