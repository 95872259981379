import { useAppDispatch, useInfinityScroll } from 'hooks'
import { getWorkOrdersListByEntity } from 'api/workOrders'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import { useCallback, useEffect, useState } from 'react'
import { WorkOrderType } from 'slices/workOrder'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import CloseButton from 'components/CloseButton'
import { LargeWorkOrderCard } from 'components/WorkOrderCards'

import styles from './styles.module.scss'
import { WorkOrderStatus } from '../NewWorkOrder/config'

export type WorkOrdersLayout = {
    userId?: string
    subcontractorId?: string
    buildingId?: string
    floorId?: string
    roomId?: string
    isOverdue?: boolean
    showPM?: boolean
    title?: string
    statuses?: Array<WorkOrderStatus>
}

type Props = WorkOrdersLayout & {
    onClose: () => void
}

const WorkOrdersListLayout = ({
    userId,
    statuses,
    onClose,
    title,
    showPM,
    subcontractorId,
    floorId,
    roomId,
    isOverdue,
    buildingId,
}: Props) => {
    const dispatch = useAppDispatch()

    const [workOrders, setWorkOrders] = useState<Array<WorkOrderType>>([])

    const [offset, setOffset] = useState(0)
    const [count, setCount] = useState(0)

    const { setLastElement } = useInfinityScroll({
        fetchNextData: () => setOffset(workOrders.length),
        hasMore: workOrders.length < count,
    })

    const getWoList = useCallback(async () => {
        try {
            const res = await dispatch(
                getWorkOrdersListByEntity({
                    offset,
                    userId,
                    statuses,
                    isOverdue,
                }),
            ).unwrap()

            if (offset === 0) {
                setWorkOrders(res.rows)
            } else {
                setWorkOrders([...workOrders, ...res.rows])
            }
            setCount(res.count)
        } catch {
            sendNotification('Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }, [userId, statuses, offset, buildingId, floorId, roomId, isOverdue, showPM, subcontractorId])

    useEffect(() => {
        ;(async () => {
            await getWoList()
        })()
    }, [getWoList])

    return (
        <div className={styles.root}>
            <CloseButton onClose={onClose} />

            <Paragraph size="md" weight="semi-bold">
                {title || 'Work Orders'}
            </Paragraph>

            <div className={styles.woList}>
                {workOrders.map((wo, index, { length }) => {
                    const refLast = index === length - 1 ? setLastElement : null

                    return (
                        <div ref={refLast} key={wo.id}>
                            <LargeWorkOrderCard workOrder={wo} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default WorkOrdersListLayout
