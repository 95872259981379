import styles from './styles.module.scss'
import classNames from 'classnames'
import React, { FC } from 'react'
import { Button } from '../../ui-kit/components/Button/Button'

type Props = {
    className?: string
    saveButtonText?: string
    cancelButtonText?: string
    onCancel?: () => void
    onSave: () => void
    alignButtons?: 'center' | 'flex-end' | 'flex-start'
    disabledConfirm?: boolean
    saveButtonVariant?: 'text' | 'filled-grey' | 'outline' | 'filled-blue' | 'filled-red' | 'filled-yellow' | 'poor'
}
const SaveCancelPanel: FC<Props> = ({
    className,
    onCancel,
    onSave,
    saveButtonText = 'Save',
    cancelButtonText = 'Cancel',
    alignButtons = 'center',
    saveButtonVariant = 'filled-blue',
    disabledConfirm = false,
}) => {
    return (
        <div className={classNames(styles.root, className)} style={{ justifyContent: alignButtons }}>
            {onCancel && (
                <Button
                    variants={'filled-grey'}
                    onClick={(e) => {
                        e.stopPropagation()
                        onCancel()
                    }}
                >
                    {cancelButtonText}
                </Button>
            )}

            <Button
                disabled={disabledConfirm}
                variants={saveButtonVariant}
                onClick={(e) => {
                    e.stopPropagation()
                    onSave()
                }}
            >
                {saveButtonText}
            </Button>
        </div>
    )
}

export default SaveCancelPanel
