import axios from 'axios'
import { printReport } from 'lib/common'

export const downloadFileUrl = async (url: string, name: string) => {
    return axios({
        url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const href = URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(href)
    })
}

export const printFileUrl = async (url: string) => {
    return axios({
        url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        printReport(response.data)
    })
}
