import styles from './PureCustomSelect.module.scss'
import { CircularProgress, InputLabel, MenuItem, Select, SelectProps, Tooltip } from '@mui/material'
import React, { Dispatch, FC, ReactNode } from 'react'
import { Paragraph } from '../Typography/Typography'
import classNames from 'classnames'
import s from 'ui-kit/components/SupportTicketTypesSelect/styles.module.scss'

export type CustomSelectType = {
    value: string
    label: string | ReactNode
    icon?: ReactNode
    description?: string
}

type Props = {
    label?: string
    values: Array<CustomSelectType>
    rootClassName?: string
    backgroundColor?: string
    errorText?: string
    setLastElement?: Dispatch<any>
    menuHeight?: string
    isLoading?: boolean
} & SelectProps

const PureCustomSelect: FC<Props> = ({
    label,
    errorText,
    backgroundColor = '#F4F5F7',
    values,
    rootClassName,
    setLastElement,
    menuHeight = 'auto',
    isLoading = false,
    ...rest
}) => {
    return (
        <div className={classNames(styles.root, rootClassName)}>
            {label !== undefined && (
                <>
                    <InputLabel className={s.label}>
                        {label}
                        {isLoading && <CircularProgress size={10} style={{ marginLeft: 10 }} />}
                    </InputLabel>
                </>
            )}
            <Tooltip title={errorText ? errorText : ''} placement={'right'}>
                <Select
                    sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor,
                        padding: 0,
                        borderRadius: '8px',
                        //paddingRight: '40px',
                        overflow: 'hidden',
                        '& .MuiInputBase-input': {
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            padding: '7px 10px',
                            backgroundColor: 'inherit',
                            whiteSpace: 'normal',
                            fontSize: '12px',
                            lineHeight: '24px',
                            fontFamily: 'Poppins',
                            minHeight: '37px !important',
                            boxSizing: 'border-box',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: errorText ? '1px solid #F1416C !important' : 'none !important',
                        },
                        '& .MuiOutlinedInput-notchedOutline:hover': {
                            border: errorText ? '1px solid #F1416C !important' : 'none !important',
                        },
                    }}
                    MenuProps={{
                        sx: {
                            height: menuHeight,
                            '& li': {
                                fontSize: '12px',
                                fontFamily: "'Poppins', sans-serif",
                            },
                        },
                    }}
                    renderValue={(value) => {
                        const currentVal = values?.find((item) => item.value === value)
                        return rest?.renderValue ? (
                            rest.renderValue(value)
                        ) : (
                            <>
                                {currentVal?.icon ? <div className={styles.iconItem}>{currentVal.icon}</div> : null}
                                {currentVal?.label ?? ''}
                            </>
                        )
                    }}
                    {...rest}
                >
                    {values?.map((item, index, { length }) => {
                        const refLast = index === length - 1 ? setLastElement : null

                        return (
                            <MenuItem
                                key={item.value}
                                value={item.value}
                                className={item?.description ? styles.item : ''}
                                ref={refLast}
                            >
                                <div className={styles.row}>
                                    {!!item?.icon && <div className={styles.iconItem}>{item.icon}</div>}
                                    {item.label}
                                </div>

                                {item?.description && (
                                    <Paragraph size={'xs'} color={'grey'} className={styles.description}>
                                        {item?.description}
                                    </Paragraph>
                                )}
                            </MenuItem>
                        )
                    })}
                </Select>
            </Tooltip>
        </div>
    )
}

export default PureCustomSelect
