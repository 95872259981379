import React, { FC, ReactNode } from 'react'
import styles from '../../styles.module.scss'
import classNames from 'classnames'
import { SxProps, TableCell, TableCellProps } from '@mui/material'

type Props = {
    children: ReactNode
    className?: string
    visible?: boolean
    width?: number | string
    sticky?: boolean
    clickable?: boolean
    borderHide?: boolean
} & TableCellProps
const TableBodyData: FC<Props> = ({
    children,
    borderHide,
    className,
    visible = true,
    sticky,
    clickable,
    width,
    ...props
}) => {
    if (!visible) {
        return null
    }

    return (
        <TableCell
            align="center"
            className={classNames(
                styles.tableBodyData,
                className,
                sticky && styles.sticky,
                borderHide && styles.borderHide,
            )}
            sx={{
                border: 'none',
                padding: '0 10px',
                //TODO: ??? color: clickable ? '#009EF7' : '#202534',
                color: '#202534',
                background: 'inherit',
                cursor: clickable ? 'pointer' : 'default',
                fontSize: '12px',
            }}
            style={{ minWidth: width, width }}
            {...props}
        >
            {children}
        </TableCell>
    )
}

export default TableBodyData
