import React, { useEffect, useRef, useState } from 'react'
import { setIsWaitingForResponse, WorkOrderAssetType } from 'slices/workOrder'
import * as T from 'components/Table'
import cn from 'classnames'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { useAppDispatch } from 'hooks'
import { getWoDetails } from 'api/workOrders'
import sendNotification from 'lib/notification'
import { NOTIFICATION_STATUS } from 'types'
import generatePDF from 'react-to-pdf'
import dayjs from 'dayjs'
import { TableContainer } from '@mui/material'

import s from './styles.module.scss'

export type WoDetailResponseType = {
    woDetailsData: {
        number: number
        title: string
        creationDate: Date
        type: string
        subType: string
        priority: string
        description: string
        specialInstructions: string
        assets: {
            equipmentId: string
            manufacturer: string
            model: string
            serialNumber: string
            name: string
            WorkOrderAsset: WorkOrderAssetType
            types: { name: string }
            category: { name: string }
        }[]
        building: {
            address: string
            city: string
            state: string | null
            zipCode: string | null
        }
        subcontractor: { name: string } | null
        supervisors: {
            email: string
            firstName: string
            lastName: string
            phone: string
        }[]
    }
    woFilesData: {
        files: string[]
        notes: {
            creator: { firstName: string; lastName: string }
            creationDate: Date
            noteFiles: { url: string }[]
            text: string
        }[]
    }
}

const TableRow = ({ title, value }: { title: string; value: string }) => {
    return (
        <T.TableBodyRow>
            <T.TableBodyData align="left" style={{ fontSize: '8px', background: '#fff', border: '0.5px solid #DDD' }}>
                {title}
            </T.TableBodyData>
            <T.TableBodyData align="left" style={{ fontSize: '8px', background: '#fff', border: '0.5px solid #DDD' }}>
                {value}
            </T.TableBodyData>
        </T.TableBodyRow>
    )
}

const TableHead = ({
    title,
    color,
    isGeneral = false,
}: {
    title: string
    color: 'grey' | 'black' | 'white' | 'blue' | 'red'
    isGeneral?: boolean
}) => {
    return (
        <T.TableHeadRow className={cn(s.tableHeader, isGeneral ? s.blue : s.grey)}>
            <T.TableHeadData align="left" style={{ border: 'none!important' }}>
                <Paragraph size={'s'} color={color} style={{ fontSize: '8px' }}>
                    {title}
                </Paragraph>
            </T.TableHeadData>
            <T.TableHeadData align="left" style={{ border: 'none!important' }}>
                {''}
            </T.TableHeadData>
        </T.TableHeadRow>
    )
}

type PropsType = {
    workOrderId: string
    setFile: (file: File) => void
    setIsLoading?: (isLoading: boolean) => void
}

export const GetWoDetailsComponent = ({ workOrderId, setFile, setIsLoading }: PropsType) => {
    const dispatch = useAppDispatch()
    const targetRef = useRef<HTMLDivElement | null>(null)
    const [details, setDetails] = useState<WoDetailResponseType | null>(null)

    const getDetails = async () => {
        setIsLoading ? setIsLoading(true) : dispatch(setIsWaitingForResponse(true))
        try {
            const res: { payload: WoDetailResponseType } = await dispatch(getWoDetails({ id: workOrderId })).unwrap()

            if (res) {
                setDetails(res.payload)
            }
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading ? setIsLoading(false) : dispatch(setIsWaitingForResponse(false))
        }
    }

    const getPdf = async () => {
        setIsLoading ? setIsLoading(true) : dispatch(setIsWaitingForResponse(true))
        try {
            if (details) {
                const pdf = await generatePDF(targetRef, {
                    filename: `Details for Work Order #${details.woDetailsData.number}`,
                    method: 'build',
                    page: { margin: { top: 7, right: 10, left: 10, bottom: 20 } },
                    overrides: { canvas: { scale: 4 } },
                })

                const newFile = new File(
                    [pdf.output('blob')],
                    `Details for Work Order #${details.woDetailsData.number}.pdf`,
                )
                setFile(newFile)
            }
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        } finally {
            setIsLoading ? setIsLoading(false) : dispatch(setIsWaitingForResponse(false))
        }
    }

    useEffect(() => {
        if (workOrderId) {
            void getDetails()
        }
    }, [workOrderId])

    useEffect(() => {
        if (details) {
            void getPdf()
        }
    }, [details])

    return details ? (
        <section ref={targetRef} className={s.hiddenBox}>
            <img src="/assets/woDetails/logo.svg" alt="logo" className={s.logo} />

            <Paragraph size={'xl'}>Work Order Details</Paragraph>
            <Paragraph
                size={'s'}
                color={'red'}
                weight={'semi-bold'}
                style={{ fontSize: '11px' }}
                className={s.woNumber}
            >
                #{details?.woDetailsData.number} - {details?.woDetailsData.title}
            </Paragraph>

            <div className={s.titleBox}>
                {details.woDetailsData.subcontractor ? (
                    <div>
                        <Paragraph size={'s'} color={'grey'} weight={'semi-bold'} style={{ fontSize: '7px' }}>
                            PREPARED FOR
                        </Paragraph>
                        <Paragraph size={'s'} style={{ fontSize: '8px' }}>
                            {details.woDetailsData.subcontractor?.name}
                        </Paragraph>
                    </div>
                ) : (
                    <div />
                )}
                <div>
                    <Paragraph size={'s'} color={'grey'} weight={'semi-bold'} style={{ fontSize: '7px' }}>
                        CREATED DATE
                    </Paragraph>
                    <Paragraph size={'s'} style={{ fontSize: '8px' }}>
                        {dayjs(details.woDetailsData.creationDate).format('MM/DD/YYYY')}
                    </Paragraph>
                </div>
            </div>

            <div className={s.titleBox}>
                <div>
                    <Paragraph size={'s'} color={'grey'} weight={'semi-bold'} style={{ fontSize: '7px' }}>
                        WO LOCATION
                    </Paragraph>
                    <div>
                        <Paragraph size={'s'} style={{ fontSize: '7px' }}>
                            {details.woDetailsData.building?.address}
                        </Paragraph>
                        <Paragraph size={'s'} style={{ fontSize: '7px' }}>
                            {`${details.woDetailsData.building?.city}
                                    ${
                                        details.woDetailsData.building?.state
                                            ? ', ' + details.woDetailsData.building?.state
                                            : ''
                                    }
                                    ${
                                        details.woDetailsData.building?.zipCode
                                            ? ', ' + details.woDetailsData.building?.zipCode
                                            : ''
                                    }`}
                        </Paragraph>
                    </div>
                </div>
                {!!details.woDetailsData.supervisors?.length && (
                    <div>
                        <div className={s.supervisorTitle}>
                            <Paragraph size={'s'} color={'grey'} weight={'semi-bold'} style={{ fontSize: '7px' }}>
                                WO SUPERVISOR(s)
                            </Paragraph>
                        </div>

                        <div className={s.supervisorBox}>
                            {details.woDetailsData.supervisors?.map((supervisor, i) => {
                                return (
                                    <div className={s.supervisorRow} key={i}>
                                        <Paragraph size={'s'} style={{ fontSize: '7px' }}>
                                            {supervisor?.firstName + ' ' + supervisor?.lastName}&nbsp;
                                        </Paragraph>
                                        <Paragraph size={'s'} color={'grey'} style={{ fontSize: '7px' }}>
                                            {supervisor?.phone ?? ''}&nbsp;
                                        </Paragraph>
                                        <Paragraph size={'s'} color={'grey'} style={{ fontSize: '7px' }}>
                                            {supervisor?.email ?? ''}
                                        </Paragraph>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>

            <TableContainer className={s.table}>
                <T.Table>
                    <T.TableHead>
                        <TableHead title={'Work Order Details'} color={'white'} isGeneral />
                    </T.TableHead>

                    <T.TableBody>
                        <TableRow title={'Work Order Type'} value={details.woDetailsData.type} />
                        <TableRow title={'Priority'} value={details.woDetailsData.priority} />
                        <TableRow title={'Description'} value={details.woDetailsData.description} />
                        <TableRow title={'Special Instructions'} value={details.woDetailsData.specialInstructions} />
                    </T.TableBody>

                    {details.woDetailsData.assets?.map((asset, i) => {
                        return (
                            <>
                                <T.TableHead>
                                    <TableHead title={`Asset#${i + 1} Details`} color={'black'} />
                                </T.TableHead>

                                <T.TableBody>
                                    <TableRow
                                        title={'Name/Equipment ID'}
                                        value={asset.name + ' / ' + asset.equipmentId}
                                    />
                                    <TableRow
                                        title={'Category/Type'}
                                        value={asset.category?.name + ' / ' + asset.types?.name}
                                    />
                                    <TableRow title={'Manufacturer'} value={asset.manufacturer} />
                                    <TableRow title={'Model'} value={asset.model} />
                                    <TableRow title={'Serial Number'} value={asset.serialNumber} />
                                </T.TableBody>
                            </>
                        )
                    })}
                </T.Table>
            </TableContainer>

            {!!details.woFilesData.files?.length && (
                <div className={s.container}>
                    <Paragraph size={'s'}>WO Files:</Paragraph>
                    <div className={s.fileBox}>
                        {details.woFilesData.files.map((url, i) => {
                            return ['.jpg', 'jpeg', '.png'].includes(url.slice(-4)) ? (
                                <div key={i} className={s.fileContainer}>
                                    <img src={url} alt={url} className={s.file} />
                                </div>
                            ) : (
                                <div key={i} className={s.fileContainer}>
                                    <Paragraph className={s.fileUrl}>{url}</Paragraph>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
            {!!details.woFilesData?.notes?.length && (
                <div className={s.container}>
                    <Paragraph size={'s'}>WO Notes:</Paragraph>
                    <div className={s.noteBox}>
                        {details.woFilesData.notes?.map((item, i) => {
                            return (
                                <div key={i} className={s.noteContainer}>
                                    <div className={s.noteHeader}>
                                        <Paragraph style={{ fontSize: '7px' }}>
                                            {item.creator.firstName + ' ' + item.creator.lastName}
                                        </Paragraph>
                                        <Paragraph style={{ fontSize: '7px', marginLeft: '5px' }} color={'grey'}>
                                            {dayjs(item.creationDate).format('MM/DD/YYYY, h:mm A')}
                                        </Paragraph>
                                    </div>

                                    <Paragraph style={{ fontSize: '7px', marginTop: '5px' }}>{item.text}</Paragraph>
                                    {!!item.noteFiles?.length && (
                                        <div className={s.container}>
                                            <div className={s.noteFileBox}>
                                                {item.noteFiles?.map((note, index) => {
                                                    return ['.jpg', 'jpeg', '.png'].includes(note.url.slice(-4)) ? (
                                                        <div key={index} className={s.noteFileContainer}>
                                                            <img src={note.url} alt={note.url} className={s.file} />
                                                        </div>
                                                    ) : (
                                                        <div key={index} className={s.noteFileContainer}>
                                                            <Paragraph className={s.fileUrl}>{note.url}</Paragraph>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </section>
    ) : null
}
