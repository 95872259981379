import * as T from '../../../../../../components/Table'
import CustomCheckBox from '../../../../../../ui-kit/components/CustomCheckBox'
import { capitalized } from '../../../../../../lib/common'
import React from 'react'
import { User } from 'types'

type Props = {
    user: User
    active: boolean
    disabled: boolean
}

const UserItemRow = ({ user, active, disabled }: Props) => {
    return (
        <>
            <T.TableBodyData width={25}>
                <CustomCheckBox disabled={disabled} checked={active} />
            </T.TableBodyData>
            <T.TableBodyData>{user.firstName + ' ' + user.lastName}</T.TableBodyData>
            <T.TableBodyData>{user.email}</T.TableBodyData>
            <T.TableBodyData>{capitalized(user.role)}</T.TableBodyData>
            <T.TableBodyData>{user.title}</T.TableBodyData>
            <T.TableBodyData>{user.type}</T.TableBodyData>
        </>
    )
}

export default UserItemRow
