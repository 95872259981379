import { ReactNode } from 'react'

import styles from './styles.module.scss'
import classNames from 'classnames'

type Props = {
    children: ReactNode
    className?: string
}
const CardListContainer = ({ children, className }: Props) => {
    return <div className={classNames(styles.listContainer, className)}>{children}</div>
}

export default CardListContainer
