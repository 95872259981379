import React from 'react'
import { TableColumnType } from 'components/Table'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { FormControl, FormGroup } from '@mui/material'
import { orderBy } from 'lodash'
import CustomCheckBox from 'ui-kit/components/CustomCheckBox'
import cn from 'classnames'

import s from './styles.module.scss'
import { Button } from 'ui-kit/components/Button/Button'

type CommonColumnsViewType = {
    tableColumns: TableColumnType[]
    visibleTableColumns: TableColumnType[]
    onChangeTableColumn: (columns: TableColumnType[]) => void
}

export const CommonColumnsView = ({
    tableColumns,
    onChangeTableColumn,
    visibleTableColumns,
}: CommonColumnsViewType) => {
    const handleChangeViewColumn = (column: TableColumnType, isVisible: boolean) => {
        if (!isVisible) {
            onChangeTableColumn([...visibleTableColumns, column])
        } else {
            onChangeTableColumn(visibleTableColumns.filter((item) => item.name !== column.name))
        }
    }

    const handleClearAllClick = () => {
        if (visibleTableColumns.length === 0) {
            onChangeTableColumn(tableColumns)
        } else {
            onChangeTableColumn([])
        }
    }

    return (
        <div className={s.container}>
            <Paragraph position={'center'} weight={'semi-bold'} size={'md'}>
                Show/hide columns
            </Paragraph>
            <FormControl className={s.formControl}>
                <FormGroup className={s.formGroup}>
                    {orderBy(tableColumns, 'label').map((column) => {
                        const isVisible = visibleTableColumns.some((item) => item.name === column.name)

                        return (
                            <div key={column.name} className={cn(s.checkbox, isVisible && s.checked)}>
                                <CustomCheckBox
                                    onChange={() => handleChangeViewColumn(column, isVisible)}
                                    checked={isVisible}
                                    name={column.name}
                                    label={column.label}
                                />
                            </div>
                        )
                    })}
                </FormGroup>
            </FormControl>
            <div className={s.buttonsGroup}>
                <Button variants={'filled-blue'} onClick={handleClearAllClick}>
                    {visibleTableColumns.length === 0 ? 'Select All' : 'Clear All'}
                </Button>
            </div>
        </div>
    )
}
