import React, { useCallback, useState } from 'react'
import { Paragraph } from 'ui-kit/components/Typography/Typography'
import { Drawer, Tooltip } from '@mui/material'
import { Button } from 'ui-kit/components/Button/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { StoreRootState } from 'store'
import Icons from 'assets/icons'
import { useAppDispatch, useAppSelector } from 'hooks'
import { logout } from 'api/auth'
import { NewWorkOrder } from 'components/NewWorkOrder'

import s from './SideNavBar.module.scss'
import { navBarLinks } from './config'
import { ROUTES } from '../../routes/const'
import { permissionsUser } from '../../store/selectors'
import { LsKeys, restoreState, saveState } from '../../lib/workWithLS'

const SideNavBar = () => {
    const dispatch = useAppDispatch()
    const { role } = useSelector((state: StoreRootState) => state.auth)
    const { isAdmin, isSuperAdmin, isSupervisor, isTechnician } = useAppSelector(permissionsUser)

    const [isExpanded, setExpendState] = useState(restoreState(LsKeys.IS_EXPAND_NAVBAR) !== false)
    const [isOpenAddWorkOrder, setIsOpenAddWorkOrder] = useState(false)

    const navigate = useNavigate()
    const { pathname } = useLocation()
    const handleClick = (link: ROUTES) => {
        if (link === ROUTES.SIGN_OUT) {
            dispatch(logout())
            return
        }

        navigate(`${link}`)
    }

    const checkPathName = useCallback(
        (path: string) => {
            return pathname.includes(path)
        },
        [pathname],
    )

    const onAddWorkOrderClick = () => {
        setIsOpenAddWorkOrder((prev) => !prev)
    }

    const handleExpand = () => {
        setExpendState(!isExpanded)
        saveState(LsKeys.IS_EXPAND_NAVBAR, !isExpanded)
    }

    if (!role) {
        return null
    }

    return (
        <div className={s.sideOuter}>
            <div className={isExpanded ? `${s.sideNavContainer}` : `${s.sideNavContainer} ${s.sideNavContainerNX}`}>
                <div className={s.navUpper}>
                    <div className={s.navHeading}>
                        {isExpanded && (
                            <div className={s.navBrand}>
                                <img src="/assets/icons/logo.svg" alt="logo" />
                            </div>
                        )}

                        {!isExpanded && (
                            <div className={s.navMiniBrand}>
                                <img src="/assets/icons/mini-logo.svg" alt="logo" />
                            </div>
                        )}
                    </div>

                    <button
                        className={isExpanded ? `${s.hamburger} ${s.hamburgerIn}` : `${s.hamburger} ${s.hamburgerOut}`}
                        onClick={handleExpand}
                    >
                        {isExpanded ? <Icons.sidebarArrowLeft /> : <Icons.sidebarArrowRight />}
                    </button>

                    <div className={s.navMenu}>
                        {navBarLinks.map((navBarLink) => {
                            if (!navBarLink.roles.includes(role)) {
                                return null
                            }

                            return (
                                <Tooltip title={navBarLink.title} key={navBarLink.title} arrow placement={'right'}>
                                    <span
                                        className={checkPathName(navBarLink.link) ? s.menuItemActive : s.menuItem}
                                        onClick={() => handleClick(navBarLink.link)}
                                    >
                                        <img className={s.navIcon} src={navBarLink.icon} alt={navBarLink.title} />
                                        {isExpanded && (
                                            <Paragraph color={'white'} className={s.navText}>
                                                {navBarLink.title}
                                            </Paragraph>
                                        )}
                                    </span>
                                </Tooltip>
                            )
                        })}
                    </div>
                </div>

                <div className={s.navBottom}>
                    {isSuperAdmin && (
                        <Button
                            position="left"
                            onClick={() => handleClick(ROUTES.CUSTOMERS)}
                            variants={'filled-blue'}
                            leftIconId={'nav-pencil'}
                        >
                            {isExpanded && 'Manage Customers'}
                        </Button>
                    )}

                    {!isSuperAdmin && (
                        <Button
                            position="left"
                            onClick={onAddWorkOrderClick}
                            variants={'filled-blue'}
                            leftIconId={'nav-settings'}
                        >
                            {isExpanded && 'Create Work Order'}
                        </Button>
                    )}

                    {(isAdmin || isTechnician || isSupervisor) && (
                        <Button
                            position="left"
                            variants={'filled-red'}
                            leftIconId={'nav-alert'}
                            onClick={() => {
                                navigate(ROUTES.EMERGENCY)
                            }}
                        >
                            {isExpanded && 'Emergency Management'}
                        </Button>
                    )}
                </div>
            </div>

            <Drawer anchor="right" open={isOpenAddWorkOrder} onClose={onAddWorkOrderClick}>
                <NewWorkOrder onCancel={onAddWorkOrderClick} />
            </Drawer>
        </div>
    )
}

export default SideNavBar
