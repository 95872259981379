import { createAsyncThunk } from '@reduxjs/toolkit'
import { axiosErrorHandler, client } from './setupAxios'
import { Contact } from '../components/ContactsList/types'

type GetContacts = {
    link: string
    email?: string
}

export const getContacts = createAsyncThunk(
    'contacts/getContacts',
    async (params: GetContacts, { rejectWithValue }) => {
        try {
            const result = await client.get(`/api/contacts/v2/many`, {
                params,
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type CreateContact2 = Partial<Contact> & {
    link: string
}

export const createContact = createAsyncThunk(
    'contacts/createContact2',
    async (data: CreateContact2, { rejectWithValue }) => {
        try {
            const result = await client.post(`/api/contacts/v2`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type UpdateContact2 = Partial<Contact> & {
    id: string
}

export const updateContact = createAsyncThunk(
    'contacts/updateContact2',
    async ({ id, ...data }: UpdateContact2, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/contacts/v2/${id}`, data)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

type AttachRemoveEmailContact = {
    email: string
    link: string
}

export const attachEmailContact = createAsyncThunk(
    'contacts/attachEmailContact',
    async ({ email, link }: AttachRemoveEmailContact, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/contacts/v2/add/${email}/${link}`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const removeEmailContact = createAsyncThunk(
    'contacts/removeEmailContact',
    async ({ email, link }: AttachRemoveEmailContact, { rejectWithValue }) => {
        try {
            const result = await client.patch(`/api/contacts/v2/remove/${email}/${link}`)
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
