import LoginLayout from './components/LoginLayout'
import { Routes, Route, Navigate } from 'react-router-dom'
import AuthRoutes from './routes'
import Login from './components/Login'
import VerificationCode from './components/VerificationCode'
import ForgotPassword from './components/ForgotPassword'
import NewPassword from './components/NewPassword'
import AcceptInvitationPage from './components/AcceptInvitation/AcceptInvitation'
import React from 'react'

const AuthPage = () => {
    return (
        <LoginLayout>
            <Routes>
                <Route path={AuthRoutes.LOGIN} element={<Login />} />
                <Route path={AuthRoutes.VERIFY} element={<VerificationCode mode="login" />} />
                <Route path={AuthRoutes.FORGOT} element={<ForgotPassword />} />
                <Route path={AuthRoutes.NEW_PASSWORD} element={<NewPassword />} />
                <Route path={AuthRoutes.ACCEPT_INVITE} element={<AcceptInvitationPage />} />

                <Route path="*" element={<Navigate to={AuthRoutes.LOGIN} />} />
            </Routes>
        </LoginLayout>
    )
}

export default AuthPage
