import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import { CurrentPMType, PMsState, PMType } from './types'
import { createPM, editPM, getAllPMsList, getAllWorkOrdersInPM, getPMById } from 'api/pMs'
import { CommonOtherResponseType, NOTIFICATION_STATUS } from 'types'
import sendNotification from 'lib/notification'

export const extraReducers = (builder: ActionReducerMapBuilder<PMsState>) => {
    builder.addCase(getAllPMsList.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getAllPMsList.fulfilled, (state, action: PayloadAction<CommonOtherResponseType<PMType>>) => {
        state.isWaitingForResponse = false
        state.pMs = action.payload.payload
        state.pMsCount = action.payload.count
    })
    builder.addCase(getAllPMsList.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getAllWorkOrdersInPM.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(
        getAllWorkOrdersInPM.fulfilled,
        (state, action: PayloadAction<CommonOtherResponseType<CurrentPMType>>) => {
            state.isWaitingForResponse = false
            state.allWOsInPm = action.payload.payload
            state.currentPM = action.payload?.payload[0]
        },
    )
    builder.addCase(getAllWorkOrdersInPM.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(getPMById.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(getPMById.fulfilled, (state, action: PayloadAction<CurrentPMType>) => {
        state.isWaitingForResponse = false
        state.currentPM = action.payload
    })
    builder.addCase(getPMById.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(createPM.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(createPM.fulfilled, (state) => {
        state.isWaitingForResponse = false
    })
    builder.addCase(createPM.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
    builder.addCase(editPM.pending, (state) => {
        state.isWaitingForResponse = true
    })
    builder.addCase(editPM.fulfilled, (state) => {
        state.isWaitingForResponse = false
        sendNotification('PM has been edited', NOTIFICATION_STATUS.SUCCESS)
    })
    builder.addCase(editPM.rejected, (state, action) => {
        state.isWaitingForResponse = false
        sendNotification((action.payload as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
    })
}
