import styles from './styles.module.scss'

import React, { FC } from 'react'
import { Box, CircularProgress, CircularProgressProps } from '@mui/material'
import cn from 'classnames'

type Props = {
    show: boolean
    value?: number | null
    size?: number
    className?: string
}

const ProgressBar: FC<Props> = ({ show, value, size, className }) => {
    if (!show) {
        return null
    }
    return (
        <div className={cn(styles.progressBarRoot, className)}>
            {value ? <CircularProgressWithLabel value={value} size={size} /> : <CircularProgress size={size} />}
        </div>
    )
}

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <div className={styles.positionCenter}>
                <span className={styles.spanProgressText}>{`${Math.round(props.value)}%`}</span>
            </div>
        </Box>
    )
}

export default ProgressBar
