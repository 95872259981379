import styles from './styles.module.scss'
import classNames from 'classnames'
import Icons from '../../assets/icons'
import React from 'react'
import { ListViewMode } from '../../types'

type Props = {
    activeMode: ListViewMode
    onChangeMode: (mode: ListViewMode) => void
}
const CardTableSwitch = ({ activeMode, onChangeMode }: Props) => {
    return (
        <div className={styles.root}>
            <div
                className={classNames(styles.modeBtn, activeMode === ListViewMode.CARD && styles.active)}
                onClick={() => onChangeMode(ListViewMode.CARD)}
            >
                <Icons.CardsView />
            </div>
            <div
                className={classNames(styles.modeBtn, activeMode === ListViewMode.TABLE && styles.active)}
                onClick={() => onChangeMode(ListViewMode.TABLE)}
            >
                <Icons.TableView />
            </div>
        </div>
    )
}

export default CardTableSwitch
