import { StoreRootState } from 'store/index'
import { CommonSortType, ListViewMode } from 'types'
import { TableColumnType } from 'components/Table'
import { CommonCurrentViewType } from 'components/CommonSavedView'
import {
    CurrentInventoryItemType,
    CurrentPurchaseRequestType,
    CurrentVendorType,
    InventoryItemProfileTab,
    InventoryItemsFiltersType,
    PurchaseRequestType,
    InventoryDetailsType,
    VendorsFiltersType,
    VendorType,
    CurrentInventoryDetailType,
} from 'slices/inventoryItems'
import { InventoryItemsListTab } from 'pages/InventoryItemsPage/config'

export const isLoadingInventoryItemSelect = (state: StoreRootState): boolean =>
    state.inventoryItems.isWaitingForResponse

//Inventory Items
export const currentInventoryItemSelect = (state: StoreRootState): CurrentInventoryDetailType =>
    state.inventoryItems.currentInventoryItem
export const inventoryItemsSearchValueSelect = (state: StoreRootState): string =>
    state.inventoryItems.inventoryItemsSearchValue

export const inventoryItemProfileTabSelect = (state: StoreRootState): InventoryItemProfileTab =>
    state.inventoryItems.inventoryItemProfileTab
export const inventoryItemCurrentPageSelect = (state: StoreRootState): number =>
    state.inventoryItems.inventoryItemsPaginationPage
export const inventoryItemsCountSelect = (state: StoreRootState): number => state.inventoryItems.inventoryItemsCount
export const inventoryItemsListTabSelect = (state: StoreRootState): InventoryItemsListTab =>
    state.inventoryItems.inventoryItemsListTab
export const inventoryItemsSelect = (state: StoreRootState): InventoryDetailsType[] =>
    state.inventoryItems.inventoryItems
export const inventoryItemListModeSelect = (state: StoreRootState): ListViewMode =>
    state.inventoryItems.inventoryItemsSettings.listMode
export const inventoryItemsColumnsSelect = (state: StoreRootState): TableColumnType[] =>
    state.inventoryItems.inventoryItemsSettings.columns
export const inventoryItemsFiltersSelect = (state: StoreRootState): InventoryItemsFiltersType | null =>
    state.inventoryItems.inventoryItemsSettings.filters
export const inventoryItemsCurrentViewSelect = (state: StoreRootState): CommonCurrentViewType => ({
    id: state.inventoryItems.inventoryItemsSettings.currentViewId!,
    currentViewTitle: state.inventoryItems.inventoryItemsSettings.currentViewTitle!,
    isSystemView: state.inventoryItems.inventoryItemsSettings.isSystemCurrentView!,
})
export const inventoryItemsSortSelect = (state: StoreRootState): CommonSortType => ({
    sortField: state.inventoryItems.inventoryItemsSettings.sortField,
    sortDirection: state.inventoryItems.inventoryItemsSettings.sortDirection,
})

//Vendors
export const currentVendorSelect = (state: StoreRootState): CurrentVendorType => state.inventoryItems.currentVendor
export const vendorsSearchValueSelect = (state: StoreRootState): string => state.inventoryItems.vendorsSearchValue
export const vendorCurrentPageSelect = (state: StoreRootState): number => state.inventoryItems.vendorsPaginationPage
export const vendorsCountSelect = (state: StoreRootState): number => state.inventoryItems.vendorsCount
export const vendorsSelect = (state: StoreRootState): VendorType[] => state.inventoryItems.vendors
export const vendorListModeSelect = (state: StoreRootState): ListViewMode =>
    state.inventoryItems.vendorsSettings.listMode
export const vendorsColumnsSelect = (state: StoreRootState): TableColumnType[] =>
    state.inventoryItems.vendorsSettings.columns
export const vendorsFiltersSelect = (state: StoreRootState): VendorsFiltersType | null =>
    state.inventoryItems.vendorsSettings.filters
export const vendorsSortSelect = (state: StoreRootState): CommonSortType => ({
    sortField: state.inventoryItems.vendorsSettings.sortField,
    sortDirection: state.inventoryItems.vendorsSettings.sortDirection,
})

//Purchase Requests
export const currentPurchaseRequestSelect = (state: StoreRootState): CurrentPurchaseRequestType =>
    state.inventoryItems.currentPurchaseRequest
export const purchaseRequestCurrentPageSelect = (state: StoreRootState): number =>
    state.inventoryItems.purchaseRequestsPaginationPage
export const purchaseRequestsCountSelect = (state: StoreRootState): number => state.inventoryItems.purchaseRequestsCount
export const purchaseRequestsSelect = (state: StoreRootState): PurchaseRequestType[] =>
    state.inventoryItems.purchaseRequests
export const purchaseRequestListModeSelect = (state: StoreRootState): ListViewMode =>
    state.inventoryItems.purchaseRequestsSettings.listMode
export const purchaseRequestsColumnsSelect = (state: StoreRootState): TableColumnType[] =>
    state.inventoryItems.purchaseRequestsSettings.columns
export const purchaseRequestsFiltersSelect = (state: StoreRootState): VendorsFiltersType | null =>
    state.inventoryItems.purchaseRequestsSettings.filters
export const purchaseRequestsSortSelect = (state: StoreRootState): CommonSortType => ({
    sortField: state.inventoryItems.purchaseRequestsSettings.sortField,
    sortDirection: state.inventoryItems.purchaseRequestsSettings.sortDirection,
})
