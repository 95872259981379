import React, { FC } from 'react'
import styles from './styles.module.scss'
import { InputLabel } from '@mui/material'
import classNames from 'classnames'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { DatePickerSVG } from 'assets/icons'

type Props = {
    label?: string
    width?: string
    errorText?: string
    className?: string
    dateFrom?: Date | null
    dateTo?: Date | null
} & ReactDatePickerProps<true>
export const CustomRangeDatePicker: FC<Props> = ({
    label,
    width = '100%',
    className,
    errorText,
    dateFrom,
    dateTo,
    ...rest
}) => {
    return (
        <div style={{ width }}>
            {!!label && <InputLabel className={styles.label}>{label}</InputLabel>}

            <DatePicker
                showIcon
                selectsRange
                monthsShown={2}
                className={classNames(styles.datePickerContainer, errorText && styles.error, className)}
                dateFormat={'M/d/yyyy'}
                startDate={dateFrom ? new Date(dateFrom) : null}
                endDate={dateTo ? new Date(dateTo) : null}
                popperProps={{ strategy: 'fixed' }}
                popperPlacement="top-start"
                icon={
                    <DatePickerSVG
                        style={{
                            width: '24px',
                            height: '24px',
                        }}
                    />
                }
                {...rest}
            />
        </div>
    )
}
