import { CustomSelectType } from '../../../../../../ui-kit/components/CustomSelect/CustomSelect'
import { UserStatus } from '../../../../../../slices/users'

export const statusSelect: CustomSelectType[] = [
    {
        value: UserStatus.ACTIVE,
        label: 'Active',
    },
    {
        value: UserStatus.BLOCKED,
        label: 'Blocked',
    },
    {
        value: UserStatus.PENDING,
        label: 'Pending',
    },
    {
        value: UserStatus.ARCHIVED,
        label: 'Archived',
    },
]
