import { createAppAsyncThunk } from '../common/createAppAsyncThunk'
import { UtilityInsurance } from '../slices/building'
import { client, axiosErrorHandler } from './setupAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const createUtilityInsurance = createAppAsyncThunk(
    'buildings/createUtilityInsurance',
    async (data: FormData, { rejectWithValue }) => {
        try {
            const result = await client.post(`api/buildings/utilities/create-utilitiesOrInsurance`, data)

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const updateUtilityInsurance = createAppAsyncThunk(
    'buildings/updateUtilityInsurance',
    async (data: Partial<UtilityInsurance>, { rejectWithValue }) => {
        try {
            const result = await client.patch(`api/buildings/utilities/update-utilitiesOrInsurance`, {
                type: data.type,
                providerName: data.providerName,
                account: data.account,
                number: data.number,
                email: data.email,
                utilitiesOrInsuranceId: data.id,
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const deleteUtilityInsurance = createAppAsyncThunk(
    'buildings/deleteUtilityInsurance',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.delete(`api/buildings/utilities/delete-utilitiesOrInsurance`, {
                params: {
                    utilitiesOrInsuranceId: id,
                },
            })

            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getUtilitiesListByBuildingId = createAsyncThunk(
    'floors/getUtilitiesListByBuildingId',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.get(`api/buildings/utilities/get-utilities-by-building-id`, {
                params: {
                    buildingId: id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)

export const getInsuranceListByBuildingId = createAsyncThunk(
    'floors/getInsuranceListByBuildingId',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await client.get(`api/buildings/utilities/get-insurance-by-building-id`, {
                params: {
                    buildingId: id,
                },
            })
            return result.data
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e))
        }
    },
)
