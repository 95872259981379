import React, { FocusEventHandler, useEffect, useState } from 'react'
import { Autocomplete, MenuItem } from '@mui/material'
import { CustomShowMore } from 'ui-kit/components/CustomShowMore'
import { getOptions } from 'lib/common'
import { CustomTextField } from 'ui-kit/components/CustomTextField'
import useDebounce from 'hooks/useDebounce'
import { useAppDispatch, useAppSelector } from 'hooks'
import { BUILDS_PER_PAGE } from 'pages/BuildingsPage/helpers'
import { currentUserSelect } from 'store/selectors'
import { IdDependenceType, ItemForAutocompleteType } from '.'
import { Paragraph } from 'ui-kit/components/Typography/Typography'

import s from './styles.module.scss'

type RegionAutoCompleteType = {
    errorText?: string
    handleBlur?: FocusEventHandler
    onChangeCallback: (item: ItemForAutocompleteType, values?: any[]) => void
    // eslint-disable-next-line @typescript-eslint/ban-types
    getValues: Function
    label?: string
    currentValueOutside?: ItemForAutocompleteType | null
    idDependence?: IdDependenceType
    textFieldClassName?: string
    disableClearable?: boolean
    payloadValue?: string
    textFieldPlaceholder?: string
    isSearch?: boolean
    readOnly?: boolean
    disabled?: boolean
    width?: string | number
}
export const CommonAutoComplete = ({
    onChangeCallback,
    handleBlur,
    label,
    errorText,
    getValues,
    currentValueOutside,
    idDependence,
    textFieldClassName,
    disableClearable = false,
    payloadValue = 'rows',
    textFieldPlaceholder,
    isSearch = false,
    readOnly = false,
    disabled = false,

    width = '100%',
}: RegionAutoCompleteType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(currentUserSelect)
    const [values, setValues] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [currentValue, setCurrentValue] = useState<ItemForAutocompleteType>({ value: '', label: '' })
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const debouncedSearch = useDebounce(search, 500)

    const onInputChange = (event: any, newInputValue: string) => {
        setSearch(newInputValue)
        setPage(1)
    }
    const getAllValues = async () => {
        setIsLoading(true)
        try {
            const res = await dispatch(getValues({ page, search, idDependence })).unwrap()
            setCount(res?.count)
            if (Object.keys(res).includes(payloadValue)) {
                setValues((prev) => (page === 1 ? res[payloadValue] : [...res[payloadValue], ...prev]))
            }
        } catch (error: any) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const disabledCondition = count <= page * BUILDS_PER_PAGE || disableClearable
    const onChange = (event: any, newValue: ItemForAutocompleteType | null) => {
        if (newValue) {
            onChangeCallback(newValue, values)
            setSearch('')
            setCurrentValue(newValue)
        }
    }

    const onShowMoreClick = (e: React.MouseEvent<HTMLParagraphElement>) => {
        e.preventDefault()
        setPage((prev) => prev + 1)
    }

    useEffect(() => {
        if (!user) {
            return
        }
        if (typeof idDependence === 'object' && !currentValue?.value) {
            getAllValues()
        }
    }, [
        JSON.stringify(idDependence?.regionIdes),
        JSON.stringify(idDependence?.floorIdes),
        JSON.stringify(idDependence?.roomIdes),
        JSON.stringify(idDependence?.buildingIdes),
        idDependence?.regionId,
        idDependence?.buildingId,
        //idDependence?.floorId,
        idDependence?.categoryId,
        idDependence?.typeIdes,
        idDependence?.categoryIdes,
        idDependence?.assetId,
        user,
        page,
        debouncedSearch,
    ])

    useEffect(() => {
        if (currentValueOutside) {
            setCurrentValue(currentValueOutside)
        }
        if (currentValueOutside === null) {
            setCurrentValue({ value: '', label: '' })
        }
        // else if (!currentValueOutside?.value) {
        //     setValues([])
        // }
    }, [currentValueOutside])

    return (
        <Autocomplete
            value={currentValue}
            disabled={disabled}
            onChange={onChange}
            readOnly={readOnly}
            renderOption={(props, option) => (
                <MenuItem {...props} key={option.value}>
                    <Paragraph size={'s'}>{option.label}</Paragraph>
                </MenuItem>
            )}
            PaperComponent={({ children }) => (
                <CustomShowMore
                    onShowMoreClick={onShowMoreClick}
                    children={children}
                    text={'Show more'}
                    disabled={disabledCondition}
                    className={s.commonAutoComplete_showMore}
                />
            )}
            filterOptions={(x) => x}
            disableClearable={disableClearable}
            inputValue={search}
            onInputChange={onInputChange}
            options={values?.length ? getOptions(values, 'id', 'name') : []}
            renderInput={(params) => (
                <CustomTextField
                    label={label}
                    onBlur={(e) => {
                        setSearch('')
                        handleBlur && handleBlur(e)
                    }}
                    params={params}
                    errorText={errorText}
                    loading={isLoading}
                    className={textFieldClassName}
                    placeholder={textFieldPlaceholder}
                    isSearch={isSearch}
                />
            )}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            loading={isLoading}
            style={{ width }}
        />
    )
}
