import styles from './styles.module.scss'
import { Paragraph } from '../../ui-kit/components/Typography/Typography'
import { TextButton } from '../../ui-kit/components/Button'
import React, { useCallback, useEffect, useState } from 'react'
import { Contact } from './types'
import { useAppDispatch } from '../../hooks'
import { attachEmailContact, createContact, getContacts } from '../../api/contacts'
import sendNotification from '../../lib/notification'
import { NOTIFICATION_STATUS, User, USER_ROLE } from '../../types'
import ContactItemCard from './ContactItemCard'
import { CommonDialog } from '../CommonDialog'
import { ContactForm } from '../ContactForm'
import UsersCheckBoxList from '../../pages/BuildingProfile/components/DetailsBuildingView/components/UsersCheckBoxList'
import { v4 } from 'uuid'
import SelectSystemVariantDialog, { TypeAddContact } from '../SelectSystemVariantDialog'

type Props = {
    link: string
    customerId?: string
    types?: Array<string>
    tenantCompanyId?: string
    disableUsers?: boolean
    userRole?: USER_ROLE
    newContacts?: Array<Contact>
    setNewContacts?: (arr: Array<Contact>) => void
    disabled?: boolean
}

const ContactsList = ({
    link,
    types,
    customerId,
    disableUsers,
    userRole,
    newContacts,
    setNewContacts,
    tenantCompanyId,
    disabled = false,
}: Props) => {
    const dispatch = useAppDispatch()

    const isCreateState = setNewContacts !== undefined && newContacts !== undefined

    const [addNonSystemContact, setAddNonSystemContact] = useState(false)
    const [addSystemContact, setAddSystemContact] = useState(false)

    const [typeAddContact, setTypeAddContact] = useState<TypeAddContact>('system')
    const [openSelectAddContact, setOpenSelectAddContact] = useState(false)

    const [contacts, setContacts] = useState<Array<Contact>>([])

    const handleCloseContact = () => {
        setAddNonSystemContact(false)
        setOpenSelectAddContact(false)
        setAddSystemContact(false)
    }

    const handleContinueTypeAddContact = () => {
        setOpenSelectAddContact(false)
        if (typeAddContact === 'system') {
            setAddSystemContact(true)
        }
        if (typeAddContact === 'non_system') {
            setAddNonSystemContact(true)
        }
    }

    const getContactsData = useCallback(async () => {
        try {
            const res = await dispatch(getContacts({ link })).unwrap()
            setContacts(res.payload)
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }
    }, [link])

    const handleCreateSystemContacts = async (users: Array<User>) => {
        try {
            for (const user of users) {
                await dispatch(
                    attachEmailContact({
                        email: user.email,
                        link,
                    }),
                ).unwrap()
            }
            await getContactsData()
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }

        handleCloseContact()
    }

    const handleCreateContact = async (newContact: Partial<Contact>) => {
        try {
            if (isCreateState) {
                setNewContacts([...newContacts, { ...(newContact as Contact), id: v4() }])
            } else {
                await dispatch(
                    createContact({
                        ...newContact,
                        link,
                    }),
                ).unwrap()

                await getContactsData()
            }
        } catch (e) {
            sendNotification((e as string) || 'Something wrong. Please try again', NOTIFICATION_STATUS.ERROR)
        }

        handleCloseContact()
    }

    const handleOpenAddContact = () => {
        if (disableUsers) {
            setAddNonSystemContact(true)
        } else {
            setOpenSelectAddContact(true)
        }
    }

    const handleUpdateStateContact = (newContact: Contact) => {
        if (isCreateState) {
            setNewContacts(newContacts.map((item) => (item.id === newContact.id ? newContact : item)))
        }
    }

    const handleDeleteStateContact = (newContact: Contact) => {
        if (isCreateState) {
            setNewContacts(newContacts.filter((item) => item.id !== newContact.id))
        }
    }

    useEffect(() => {
        ;(async () => {
            if (!isCreateState) {
                await getContactsData()
            }
        })()
    }, [isCreateState, getContactsData])

    return (
        <div className={styles.root}>
            <div className={styles.headerRow}>
                <Paragraph size="md" weight="semi-bold">
                    Contacts
                </Paragraph>
                {!disabled && <TextButton text="+ Add Contact" colorText="#1B6BC0" onClick={handleOpenAddContact} />}
            </div>

            <div className={styles.cardsWrap}>
                {newContacts &&
                    newContacts.map((contact) => (
                        <ContactItemCard
                            key={contact.id}
                            types={types}
                            link={link}
                            contact={contact}
                            onUpdateContact={handleUpdateStateContact}
                            onDeleteContact={handleDeleteStateContact}
                        />
                    ))}

                {contacts.map((contact) => (
                    <ContactItemCard
                        key={contact.id}
                        types={types}
                        link={link}
                        contact={contact}
                        update={getContactsData}
                        disabled={disabled}
                    />
                ))}
            </div>

            <CommonDialog
                title="What type of contact would you like to add?"
                onClose={() => setOpenSelectAddContact(false)}
                isOpen={openSelectAddContact}
            >
                <SelectSystemVariantDialog
                    typeAddContact={typeAddContact}
                    setTypeAddContact={setTypeAddContact}
                    onContinue={handleContinueTypeAddContact}
                />
            </CommonDialog>

            <CommonDialog
                title={'Add Contact'}
                onClose={handleCloseContact}
                isOpen={addNonSystemContact}
                dialogClassName={styles.dialog}
            >
                <ContactForm onClose={handleCloseContact} onSave={handleCreateContact} types={types} />
            </CommonDialog>

            <CommonDialog
                title="Choose System User"
                onClose={() => setAddSystemContact(false)}
                isOpen={addSystemContact}
                width="1000px"
            >
                <UsersCheckBoxList
                    tenantCompanyId={tenantCompanyId}
                    customerId={customerId}
                    onAddContacts={handleCreateSystemContacts}
                    userRole={userRole}
                    oldUserIds={contacts.map((item) => (item.user ? item.user.id : ''))}
                />
            </CommonDialog>
        </div>
    )
}

export default ContactsList
