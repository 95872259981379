import React from 'react'
import s from './SearchInput.module.scss'
import classNames from 'classnames'
import Icons from '../../../assets/icons'
import { noop } from 'lib/common'

type SearchProps = {
    placeholder?: string
    searchText?: string
    onChangeSearchText?: (text: string) => void
    className?: string
    readOnly?: boolean
}

const SearchInput = ({
    placeholder,
    className,
    searchText,
    readOnly = false,
    onChangeSearchText = noop,
}: SearchProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        onChangeSearchText(e.target.value)
    }

    return (
        <div className={s.root}>
            <div className={s.iconWrap}>
                <Icons.SearchSVG />
            </div>
            <input
                className={classNames(s.input, className)}
                type="search"
                placeholder={placeholder}
                onChange={handleChange}
                value={searchText}
                readOnly={readOnly}
            />
        </div>
    )
}

export default SearchInput
