import { TableColumnType } from 'components/Table'
import { UserStatus } from './slices/users'

export enum DirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum USER_ROLE {
    SUPERADMIN = 'SUPERADMIN',
    ADMIN = 'ADMIN',
    SUPERVISOR = 'SUPERVISOR',
    REQUESTOR = 'REQUESTOR',
    TECHNICIAN = 'TECHNICIAN',
    VENDOR = 'VENDOR',
    FMPRO = 'FMPRO',
}

export enum USER_TYPE {
    MAIN = 'Main',
    SUPPORT = 'Support',
    SALES = 'Sales',
    VENDOR = 'Vendor',
    EMERGENCY = 'Emergency',
    MAINTENANCE = 'Maintenance',
    BILLING = 'Billing',
    ITTECHNOLOGY = 'It/technology',
    LEGAL = 'Legal',
    SECURITY = 'Security',
    OTHER = 'Other',
    TRADE = 'Trade',
    MANAGER = 'Manager',
    CONTRACTOR = 'Contractor',
    LEASING = 'Leasing',
    CLEANING = 'Cleaning',
    GROUNDS_CREW = 'Grounds Crew',
    JANITORIAL = 'Janitorial',
    RECEPTION = 'Reception',
}

export enum NOTIFICATION_STATUS {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}

export enum LengthConstrains {
    L_12 = 12,
    L_16 = 16,
    L_20 = 20,
    L_50 = 50,
    L_100 = 100,
    L_200 = 200,
    L_500 = 500,
    L_1000 = 1000,
    L_5000 = 5000,
}

export interface User {
    id: string
    role: USER_ROLE
    firstName: string
    lastName: string
    regionId: string | null
    customerId: string
    title: string
    email: string
    phone: string
    type: string
    status: UserStatus
    avatar: CommonFileType | null
}

export enum TypePlanName {
    Electrical = 'Electrical',
    Architectural = 'Architectural',
    Plumbing = 'Plumbing',
    HVAC = 'HVAC',
    FireProtection = 'Fire Protection',
    Protection = 'Protection',
    Structural = 'Structural',
    Landscaping = 'Landscaping',
    General = 'General',
}

export enum CategoryNameEnum {
    Electrical = 'Electrical',
    Landscaping = 'Landscaping',
    Lighting = 'Lighting',
    HVAC_AND_Mechanical = 'HVAC & Mechanical',
    Technology = 'Technology',
    FireSafety = 'Fire Safety',
    Security = 'Security',
    Equipment = 'Equipment',
    Furniture = 'Furniture',
    Plumbing = 'Plumbing',
    Architectural = 'Architectural',
}
export type CommonType = {
    id: string
    name: string
}

export type CommonFileType = CommonType & {
    url: string
    fileKey: string
    mimetype: string
    size: number
    createdAt: string
    key?: string
    height: number
    width: number
}

export enum AcceptFile {
    DOC_1 = 'application/msword',
    DOC_2 = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    PDF = 'application/pdf',
    XLS_1 = 'application/excel',
    XLS_2 = 'application/vnd.ms-excel',
    XLS_3 = 'application/x-excel',
    XLS_4 = 'application/x-msexcel',
    XLS_5 = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    CSV = '.csv',
    JPG_1 = 'image/jpeg',
    JPG_2 = 'image/pjpeg',
    PNG = 'image/png',
    TIFF_1 = 'image/tiff',
    TIFF_2 = 'image/x-tiff',
}

export type CreatorType = {
    id: string
    companyName: string | null
    phone: string
    firstName: string
    lastName: string
    role: USER_ROLE
    email: string | null
    avatar: CommonFileType | null
}

export type CommonResponseType<T> = {
    count: number
    rows: T[]
}

export type CommonOtherResponseType<T> = {
    count: number
    payload: T[]
}

export type ColumnType<T> = {
    name: keyof T
    label: string
}

export enum ListViewMode {
    CARD = 'CARD',
    TABLE = 'TABLE',
}

export type TableSettingsType<T> = {
    filters: T | null
    columns: TableColumnType[]
    currentViewId?: string
    currentViewTitle?: string
    isSystemCurrentView?: boolean
    listMode: ListViewMode
} & CommonSortType

export type CommonSortType = {
    sortField: string
    sortDirection: DirectionEnum
}
